import SystemActions from 'utils/roles/SystemActions';

export default class SuperAdminRole extends SystemActions {
  constructor(payload) {
    super();
    this.payload = payload;
    this.home.view = true;
    this.home.goToLive = true;

    this.profile.view = true;
    this.profile.update = true;
    this.profile.updatePassword = true;

    this.contactMessage.actions = true;
    this.contactMessage.markAsRead = true;
    this.contactMessage.view = true;

    this.auction.view = true;
    this.auction.filter = true;
    this.auction.create = this.validateArea();
    this.auction.actions = this.validateArea();
    this.auction.massiveCopy = this.validateArea();
    this.auction.massiveCopyToSale = this.validateArea();

    this.auctionManager.view = true;
    this.auctionManager.create = this.validateArea();
    this.auctionManager.update = this.validateArea();

    this.lot.view = true;
    this.lot.filter = this.validateArea();
    this.lot.massiveUpload = this.validateArea();
    this.lot.create = this.validateArea();
    this.lot.actions = this.validateArea();

    this.lotDetail.view = true;
    this.lotDetail.edit = this.validateArea();

    this.lotManager.view = this.validateArea();
    this.lotManager.history = this.validateArea();
    this.lotManager.update = this.validateArea();

    this.user.view = true;
    this.user.actions = this.validateUserActionArea();

    this.userEdition.view = true;
    this.userEdition.update = true;

    this.orderLot.view = this.validateArea();
    this.orderLot.update = this.validateArea();

    this.guarantee.view = true;
    this.guarantee.update = true;
    this.guarantee.report = true;
    this.guarantee.massiveRefund = true;

    this.guaranteeAndPayment.view = true;
    this.guaranteeAndPayment.update = true;
    this.guaranteeAndPayment.report = true;
    this.guaranteeAndPayment.massiveRefund = true;
    this.guaranteeAndPayment.addPayment = true;

    this.payment.view = true;
    this.payment.update = true;

    this.integration.view = true;
    this.integration.adjudications = true;
    this.integration.guarantees = true;
    this.integration.auction = true;
    this.integration.lots = true;
    this.integration.users = true;
    this.integration.clear = true;

    this.maintainer.view = true;
    this.maintainer.update = true;
    this.maintainer.delete = true;

    this.guaranteeManager.view = true;
    this.newPayment.view = true;

    this.external.view = true;
    this.viewReport.view = true;
    this.featuredLotsManager.view = this.validateArea();
    this.contactsManager.view = this.validateArea();
    this.brandsManager.view = this.validateArea();
    this.modelsManager.view = this.validateArea();
    this.currencyManager.view = true;

    this.adjudications.view = true;

    this.systemChanges.view = true;

    this.transfers.view = true;
    this.transfers.update = true;
    this.vehicleTypes.view = this.validateArea();
    this.vehicleTypes.update = this.validateArea();
    this.vehicleTypes.delete = this.validateArea();

    this.postureManager.view = true;

    this.mandates.view = true;
    this.mandatesLogin.view = true;
    this.mandates.updateLotStatus = true;
    this.mandatesBulkLoad.view = true;
  }

  validateArea() {
    if (this.payload?.areaId === 'all') {
      return true;
    }

    return this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString();
  }

  validateUserActionArea() {
    return (
      this.payload?.areaId?.toString() === '' ||
      !this.payload?.areaId ||
      this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString()
    );
  }
}
