import camelize from 'camelcase-keys-deep';
import { mapSyncFlag } from 'utils/functions';

export default class Guarantee {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  isProperties() {
    return this.auction() != null;
  }

  isFurniture() {
    return this.auction() == null;
  }

  auction() {
    return this.auctionId ? this.env().Auction.getById(this.auctionId) : null;
  }

  user() {
    return this.env().User.getById(this.userId);
  }

  syncUser() {
    return this.env().User.getById(this.syncedBy);
  }

  offers() {
    return this.env().Offer.findAllBy('guaranteeId', this.id);
  }

  lots() {
    return this.offers().map((offer) => offer.lot());
  }

  payments() {
    return this.env().Payment.findAllBy('guaranteeId', this.id);
  }

  linked() {
    return this.env().LinkedGuarantee.findAllBy('currentId', this.id);
  }

  isComplete() {
    const payments = this.payments();
    const paidAmount = payments
      .filter((offer) => offer.stateCode === 'COM')
      .reduce((result, offer) => result + Number(offer.amount), 0);
    return !this.refundAt && !this.syncFlag && paidAmount >= this.totalAmount;
  }

  isPending() {
    const payments = this.payments();
    return payments.some((offer) => offer.stateCode === 'PEN');
  }

  isUsed() {
    return this.offers().some((offer) => offer.lot().isAdjudicated());
  }

  confirmedAmount() {
    return (
      this.payments()
        ?.filter((payment) => payment.stateCode === 'COM')
        .reduce((acc, payment) => acc + Number(payment.amount), 0) ?? 0
    );
  }

  accountableAmount() {
    return (
      this.payments()
        ?.filter((payment) => payment.stateCode === 'COM' || payment.stateCode === 'PEN')
        .reduce((acc, payment) => acc + Number(payment.amount), 0) ?? 0
    );
  }

  status(userId, roleId) {
    const confirmedAmount = this.confirmedAmount();
    const payments = this.payments();
    const pendingPayments = payments?.filter((payment) => payment.stateCode === 'PEN');

    if (this.isUsed() && (roleId != null || this.userId === userId)) {
      return 'Utilizada';
    }
    if (payments.length === 0 && !this.syncFlag && !this.refundAt) {
      return 'Sin pagos';
    }
    if (
      pendingPayments.length === 0 &&
      payments.length > 0 &&
      confirmedAmount < this.totalAmount &&
      !this.syncFlag &&
      !this.refundAt
    ) {
      return 'Por pagar';
    }
    if (pendingPayments.length > 0 && !this.syncFlag && !this.refundAt) {
      return 'Por validar';
    }
    if (
      confirmedAmount >= this.totalAmount &&
      this.totalAmount > 0 &&
      !this.syncFlag &&
      !this.refundAt
    ) {
      return 'Constituída';
    }
    if (this.refundAt != null) {
      return roleId === null ? 'Deshabilitado' : 'Devuelta';
    }
    if (this.refundAt == null && this.syncFlag != null) {
      return mapSyncFlag(this.syncFlag);
    }
    return 'Inválida';
  }

  constitutionDate() {
    const confirmedAmount = this.confirmedAmount();
    const confirmedPayments = [...this.payments()].filter((pymnt) => pymnt.stateCode === 'COM');
    const pendingPayments = [...this.payments()].filter((payment) => payment.stateCode === 'PEN');
    if (confirmedPayments.length && !pendingPayments.length) {
      const lastConfirmedPayment = [...confirmedPayments].pop();
      if (confirmedAmount >= this.totalAmount) {
        return lastConfirmedPayment.authorizationDate;
      }
    }
    return null;
  }
}
