// import { cloneElement } from 'react';
import { createReducer } from 'utils/reducer/utils.reducer';
import { TransfersLotDetailsTypes } from '../types/TransferLotDetails.types';

const initialState = {
  controls: {
    loading: false,
    openCertificate: false,
    openVersionsCertificate: false,
    openUploadCertificate: false,
    openUploadInvoiceTemplate: false,
    openUploadInvoiceDocument: false,
    openUploadSignedFormDocument: false,
    openUploadCivilRegistration: false,
    openUploadRegistrationEntry: false,
    openInvoice: false,
    openInvoiceTemplate: false,
    openWorksheet: false,
    openForm23: false,
    openSignedForm: false,
    openRequestNumber: false,
    openInscriptionCertificate: false,
    openRegistrationEntry: false,
    openAproveCertificate: false,
    openAproveInvoiceTemplate: false,
    openAproveInvoice: false,
    openAproveMandate: false,
    openAproveWorksheet: false,
    openAproveFormtwentythree: false,
    openAproveSignedFormtwentythree: false,
    openAproveCivilRegistration: false,
    openAproveCompleteTransfer: false,
    openAproveRegistrationEntry: false,
    openUploadFormTwentythree: false,
    openRejectTransferMessage: false,
    openRejectTransfer: false,
    rejectTransferMessage: '',
    typeOfUpload: 'new',
    requestNumber: '',
    certificate: [],
    invoiceTemplate: [],
    invoiceDocument: [],
    formTwentyThreeDocument: [],
    signedFormTwentyThreeDocument: [],
    civilRegistrationDocument: [],
    registrationEntryDocument: [],
    openRejectCertificate: false,
    openRejectInvoiceTemplate: false,
    openRejectInvoice: false,
    openRejectMandate: false,
    openRejectWorksheet: false,
    openRejectFormtwentythree: false,
    openRejectSignedFormtwentythree: false,
    openRejectCivilRegistration: false,
    openRejectCompleteTransfer: false,
    openRejectRegistrationEntry: false,
    toggleDeleteTransfer: false,
  },
  data: {
    transfer: [],
    lot: [],
    transferredVehicle: [],
    transferBuyer: [],
    transferOwner: [],
    model: [],
    brand: [],
    images: [],
    recordCertificateDocuments: [],
    transferMiscellaneous: [],
    invoiceDocument: [],
    entryCertificate: [],
    transferInvoice: [],
    transferMandate: [],
    formTwentyThreeDocument: [],
    transferForm: [],
    transferWorksheet: [],
    signedFormTwentyThreeDocument: [],
    transferTaxe: [],
    civilRegistrationDocument: [],
    transferRequest: [],
    vehicleType: [],
    registrationEntryDocument: [],
    transferRegistrationEntry: [],
  },
};

const TransferLotDetailsReducer = createReducer(initialState, {
  [`${TransfersLotDetailsTypes.OPEN_RECORD_CERTIFICATE}`](state) {
    state.controls.openCertificate = !state.controls.openCertificate;
  },
  [`${TransfersLotDetailsTypes.OPEN_INVOICE}`](state) {
    state.controls.openInvoice = !state.controls.openInvoice;
  },
  [`${TransfersLotDetailsTypes.OPEN_VERSIONS_CERTIFICATE}`](state) {
    state.controls.openVersionsCertificate = !state.controls.openVersionsCertificate;
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_CERTIFICATE}`](state) {
    state.controls.openUploadCertificate = !state.controls.openUploadCertificate;
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_INVOICE_DOCUMENT}`](state) {
    state.controls.openUploadInvoiceDocument = !state.controls.openUploadInvoiceDocument;
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_CIVIL_REGISTRATION}`](state) {
    state.controls.openUploadCivilRegistration = !state.controls.openUploadCivilRegistration;
  },
  [`${TransfersLotDetailsTypes.OPEN_INVOICE_TEMPLATE}`](state) {
    state.controls.openInvoiceTemplate = !state.controls.openInvoiceTemplate;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_CERTIFICATE}`](state) {
    state.controls.openAproveCertificate = !state.controls.openAproveCertificate;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_INVOICE_TEMPLATE}`](state) {
    state.controls.openAproveInvoiceTemplate = !state.controls.openAproveInvoiceTemplate;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_INVOICE}`](state) {
    state.controls.openAproveInvoice = !state.controls.openAproveInvoice;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_MANDATE}`](state) {
    state.controls.openAproveMandate = !state.controls.openAproveMandate;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_WORKSHEET}`](state) {
    state.controls.openAproveWorksheet = !state.controls.openAproveWorksheet;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_FORM_TWENTYTHREE}`](state) {
    state.controls.openAproveFormtwentythree = !state.controls.openAproveFormtwentythree;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_SIGNED_FORM_TWENTYTHREE}`](state) {
    state.controls.openAproveSignedFormtwentythree =
      !state.controls.openAproveSignedFormtwentythree;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_CIVIL_REGISTRATION}`](state) {
    state.controls.openAproveCivilRegistration = !state.controls.openAproveCivilRegistration;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_COMPLETE_TRANSFER}`](state) {
    state.controls.openAproveCompleteTransfer = !state.controls.openAproveCompleteTransfer;
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_INVOICE_TEMPLATE}`](state) {
    state.controls.openUploadInvoiceTemplate = !state.controls.openUploadInvoiceTemplate;
    state.controls.typeOfUpload = 'new';
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_TWENTYTHREE_FORM}`](state) {
    state.controls.openUploadFormTwentythree = !state.controls.openUploadFormTwentythree;
    state.controls.typeOfUpload = 'new';
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_SIGNED_TWENTYTHREE_FORM}`](state) {
    state.controls.openUploadSignedFormDocument = !state.controls.openUploadSignedFormDocument;
    state.controls.typeOfUpload = 'new';
  },
  [`${TransfersLotDetailsTypes.CLOSE_CERTIFICATE_DIALOG}`](state) {
    state.controls.openVersionsCertificate = false;
    state.controls.openUploadCertificate = false;
    state.controls.typeOfUpload = 'new';
  },
  [`${TransfersLotDetailsTypes.HANDLE_CHANGE}`](state, action) {
    state.controls.typeOfUpload = action.payload;
  },
  [`${TransfersLotDetailsTypes.CHANGE_CONTROLS}`](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [`${TransfersLotDetailsTypes.HANDLE_DOC}`](state, action) {
    state.controls.certificate = action.payload;
  },
  [`${TransfersLotDetailsTypes.HANDLE_INVOICE_DOC}`](state, action) {
    state.controls.invoiceDocument = action.payload;
  },
  [`${TransfersLotDetailsTypes.HANDLE_TWENTYTHREE_DOC}`](state, action) {
    state.controls.formTwentyThreeDocument = action.payload;
  },
  [`${TransfersLotDetailsTypes.HANDLE_SIGNED_TWENTYTHREE_DOC}`](state, action) {
    state.controls.signedFormTwentyThreeDocument = action.payload;
  },
  [`${TransfersLotDetailsTypes.HANDLE_CIVIL_REGISTRATION_DOC}`](state, action) {
    state.controls.civilRegistrationDocument = action.payload;
  },
  [`${TransfersLotDetailsTypes.HANDLE_INVOICE}`](state, action) {
    state.controls.invoiceTemplate = action.payload;
  },
  [`${TransfersLotDetailsTypes.OPEN_WORKSHEET}`](state) {
    state.controls.openWorksheet = !state.controls.openWorksheet;
  },
  [`${TransfersLotDetailsTypes.OPEN_FORM_TWENTY_THREE}`](state) {
    state.controls.openForm23 = !state.controls.openForm23;
  },
  [`${TransfersLotDetailsTypes.OPEN_SIGNED_FORM}`](state) {
    state.controls.openSignedForm = !state.controls.openSignedForm;
  },
  [`${TransfersLotDetailsTypes.OPEN_INSCRIPTION_CERTIFICATE}`](state) {
    state.controls.openInscriptionCertificate = !state.controls.openInscriptionCertificate;
  },
  [`${TransfersLotDetailsTypes.OPEN_REQUEST_NUMBER}`](state) {
    state.controls.openRequestNumber = !state.controls.openRequestNumber;
  },
  [`${TransfersLotDetailsTypes.GET_STATE_FROM_API_SUCCESS}`](state, action) {
    state.data.transfer = action.payload.data.transfer;
    state.data.lot = action.payload.data.lot;
    state.data.transferredVehicle = action.payload.data.transferredVehicle;
    state.data.transferBuyer = action.payload.data.transferBuyer;
    state.data.transferOwner = action.payload.data.transferOwner;
    state.data.model = action.payload.data.model;
    state.data.brand = action.payload.data.brand;
    state.data.images = action.payload.data.images;
    state.data.recordCertificateDocuments = action.payload.data.recordCertificateDocuments;
    state.data.transferMiscellaneous = action.payload.data.transferMiscellaneous;
    state.data.invoiceDocument = action.payload.data.invoiceDocument;
    state.data.entryCertificate = action.payload.data.entryCertificate;
    state.data.transferInvoice = action.payload.data.transferInvoice;
    state.data.transferMandate = action.payload.data.transferMandate;
    state.data.formTwentyThreeDocument = action.payload.data.formTwentyThreeDocument;
    state.data.transferForm = action.payload.data.transferForm;
    state.data.transferWorksheet = action.payload.data.transferWorksheet;
    state.data.signedFormTwentyThreeDocument = action.payload.data.signedFormTwentyThreeDocument;
    state.data.transferTaxe = action.payload.data.transferTaxe;
    state.data.civilRegistrationDocument = action.payload.data.civilRegistrationDocument;
    state.data.transferRequest = action.payload.data.transferRequest;
    state.data.vehicleType = action.payload.data.vehicleType;
    state.data.registrationEntryDocument = action.payload.data.registrationEntryDocument;
    state.data.transferRegistrationEntry = action.payload.data.transferRegistrationEntry;
    state.controls.requestNumber = action.payload.data.transfer.requestNumber;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_RECORD_CERTIFICATE}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_DOCUMENT}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_TWENTYTHREE_DOC}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_SIGNED_TWENTYTHREE_DOC}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_CIVIL_REGISTRATION_DOC}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.CREATE_REQUEST_NUMBER}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE_SUCCESS}`](state) {
    state.controls.loading = false;
    state.controls.openUploadInvoiceTemplate = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_RECORD_CERTIFICATE_SUCCESS}`](state, action) {
    state.data.recordCertificateDocuments = [
      ...state.data.recordCertificateDocuments,
      action.payload.data.transferDocument,
    ];
    state.controls.loading = false;
    state.controls.openUploadCertificate = false;
    state.controls.typeOfUpload = 'new';
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE_SUCCESS}`](state, action) {
    state.data.transferMiscellaneous = action.payload.data.transferMiscellaneous;
    state.controls.openUploadInvoiceTemplate = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_DOCUMENT_SUCCESS}`](state, action) {
    state.data.invoiceDocument = action.payload.data.invoiceDocument;
    state.controls.openUploadInvoiceDocument = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_TWENTYTHREE_DOC_SUCCESS}`](state, action) {
    state.data.formTwentyThreeDocument = action.payload.data.formTwentyThreeDocument;
    state.controls.formTwentyThreeDocument = false;
    state.controls.openUploadFormTwentythree = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_SIGNED_TWENTYTHREE_DOC_SUCCESS}`](state, action) {
    state.data.signedFormTwentyThreeDocument = action.payload.data.signedFormTwentyThreeDocument;
    state.controls.signedFormTwentyThreeDocument = false;
    state.controls.openUploadSignedFormDocument = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_CIVIL_REGISTRATION_DOC_SUCCESS}`](state, action) {
    state.data.civilRegistrationDocument = action.payload.data.civilRegistrationDocument;
    state.controls.civilRegistrationDocument = false;
    state.controls.openUploadCivilRegistration = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.COMPLETE_STEP}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.COMPLETE_STEP_SUCCESS}`](state, action) {
    state.data.entryCertificate = action.payload.data.entryCertificate;
    state.data.transferInvoice = action.payload.data.transferInvoice;
    state.data.transferMandate = action.payload.data.transferMandate;
    state.data.transferMiscellaneous = action.payload.data.transferMiscellaneous;
    state.data.transferForm = action.payload.data.transferForm;
    state.data.transferWorksheet = action.payload.data.transferWorksheet;
    state.data.transferTaxe = action.payload.data.transferTaxe;
    state.data.transferRequest = action.payload.data.transferRequest;
    state.data.transfer = action.payload.data.transfer;
    state.data.transferRegistrationEntry = action.payload.data.transferRegistrationEntry;
    state.controls.loading = false;
    state.controls.openAproveCertificate = false;
    state.controls.openAproveInvoice = false;
    state.controls.openAproveFormtwentythree = false;
    state.controls.openAproveSignedFormtwentythree = false;
    state.controls.openAproveCivilRegistration = false;
    state.controls.openAproveCompleteTransfer = false;
    state.controls.openAproveInvoiceTemplate = false;
    state.controls.openAproveMandate = false;
    state.controls.openAproveWorksheet = false;
    state.controls.openAproveRegistrationEntry = false;
    state.controls.openRejectCertificate = false;
    state.controls.openRejectInvoiceTemplate = false;
    state.controls.openRejectInvoice = false;
    state.controls.openRejectMandate = false;
    state.controls.openRejectWorksheet = false;
    state.controls.openRejectFormtwentythree = false;
    state.controls.openRejectSignedFormtwentythree = false;
    state.controls.openRejectCivilRegistration = false;
    state.controls.openRejectCompleteTransfer = false;
    state.controls.openRejectRegistrationEntry = false;
  },
  [`${TransfersLotDetailsTypes.CREATE_REQUEST_NUMBER_SUCCESS}`](state, action) {
    state.controls.requestNumber = action.payload.data.transfer.requestNumber;
    state.controls.loading = false;
    state.controls.openRequestNumber = false;
  },
  // FAIL
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE_FAIL}`](state) {
    state.controls.loading = false;
    state.controls.openUploadInvoiceTemplate = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_RECORD_CERTIFICATE_FAIL}`](state) {
    state.controls.loading = false;
    state.controls.openUploadCertificate = false;
    state.controls.typeOfUpload = 'new';
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE_FAIL}`](state) {
    state.controls.openUploadInvoiceTemplate = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_INVOICE_DOCUMENT_FAIL}`](state) {
    state.controls.openUploadInvoiceDocument = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_TWENTYTHREE_DOC_FAIL}`](state) {
    state.controls.formTwentyThreeDocument = false;
    state.controls.openUploadFormTwentythree = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_SIGNED_TWENTYTHREE_DOC_FAIL}`](state) {
    state.controls.signedFormTwentyThreeDocument = false;
    state.controls.openUploadSignedFormDocument = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_CIVIL_REGISTRATION_DOC_FAIL}`](state) {
    state.controls.civilRegistrationDocument = false;
    state.controls.openUploadCivilRegistration = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.COMPLETE_STEP_FAIL}`](state) {
    state.controls.loading = false;
    state.controls.openAproveCertificate = false;
    state.controls.openAproveInvoice = false;
    state.controls.openAproveFormtwentythree = false;
    state.controls.openAproveSignedFormtwentythree = false;
    state.controls.openAproveCivilRegistration = false;
    state.controls.openAproveCompleteTransfer = false;
    state.controls.openAproveInvoiceTemplate = false;
    state.controls.openAproveMandate = false;
    state.controls.openAproveWorksheet = false;
    state.controls.openRejectCertificate = false;
  },
  [`${TransfersLotDetailsTypes.RESET_FORM}`](state) {
    state.controls.requestNumber = '';
  },
  [`${TransfersLotDetailsTypes.OPEN_UPLOAD_REGISTRATION_ENTRY}`](state) {
    state.controls.openUploadRegistrationEntry = !state.controls.openUploadRegistrationEntry;
  },
  [`${TransfersLotDetailsTypes.HANDLE_REGISTRATION_ENTRY_DOC}`](state, action) {
    state.controls.registrationEntryDocument = action.payload;
  },
  [`${TransfersLotDetailsTypes.OPEN_REGISTRATION_ENTRY}`](state) {
    state.controls.openRegistrationEntry = !state.controls.openRegistrationEntry;
  },
  [`${TransfersLotDetailsTypes.OPEN_APROVE_REGISTRATION_ENTRY}`](state) {
    state.controls.openAproveRegistrationEntry = !state.controls.openAproveRegistrationEntry;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_REGISTRATION_ENTRY_DOC}`](state) {
    state.controls.loading = true;
  },
  [`${TransfersLotDetailsTypes.UPLOAD_REGISTRATION_ENTRY_DOC_SUCCESS}`](state, action) {
    state.data.registrationEntryDocument = action.payload.data.registrationEntryDocument;
    state.controls.registrationEntryDocument = false;
    state.controls.openUploadRegistrationEntry = false;
    state.controls.typeOfUpload = 'new';
    state.controls.loading = false;
  },
  [`${TransfersLotDetailsTypes.OPEN_REJECT_TRANSFER}`](state) {
    state.controls.openRejectTransferMessage = !state.controls.openRejectTransferMessage;
    state.controls.rejectTransferMessage = '';
  },
  [`${TransfersLotDetailsTypes.SEND_REJECT_TRANSFER_SUCCESS}`](state) {
    state.controls.openRejectTransferMessage = false;
    state.controls.rejectTransferMessage = '';
  },
  [`${TransfersLotDetailsTypes.SEND_REJECT_TRANSFER_FAIL}`](state) {
    state.controls.openRejectTransferMessage = false;
    state.controls.rejectTransferMessage = '';
  },
  [`${TransfersLotDetailsTypes.TOGGLE_REJECT_TRANSFER}`](state) {
    state.controls.openRejectTransfer = !state.controls.openRejectTransfer;
  },
  [`${TransfersLotDetailsTypes.TOGGLE_REJECT_STEP}`](state, action) {
    state.controls[action.payload.control] = !state.controls[action.payload.control];
  },
  [`${TransfersLotDetailsTypes.REJECT_TRANSFER_SUCCESS}`](state, action) {
    state.data.entryCertificate = action.payload.data.entryCertificate;
    state.data.transferInvoice = action.payload.data.transferInvoice;
    state.data.transferMandate = action.payload.data.transferMandate;
    state.data.transferMiscellaneous = action.payload.data.transferMiscellaneous;
    state.data.transferForm = action.payload.data.transferForm;
    state.data.transferWorksheet = action.payload.data.transferWorksheet;
    state.data.transferTaxe = action.payload.data.transferTaxe;
    state.data.transferRequest = action.payload.data.transferRequest;
    state.data.transfer = action.payload.data.transfer;
    state.data.transferRegistrationEntry = action.payload.data.transferRegistrationEntry;
    state.controls.loading = false;
    state.controls.openRejectTransfer = false;
  },
  [`${TransfersLotDetailsTypes.REJECT_TRANSFER_FAIL}`](state) {
    state.controls.loading = false;
    state.controls.openRejectTransfer = false;
  },
  [`${TransfersLotDetailsTypes.TOGGLE_DELETE_TRANSFER}`](state) {
    state.controls.toggleDeleteTransfer = !state.controls.toggleDeleteTransfer;
  },
});

export default TransferLotDetailsReducer;
