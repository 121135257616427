const footerStyles = (theme) => ({
  container: {
    color: 'white',
    backgroundColor: '#415364',
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '0px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  firstColumn: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      borderRight: '1px solid white',
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
    },
  },
  column: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '0px',
    },
  },
  logo: {
    maxWidth: '200px',
  },
  followUs: {
    paddingTop: theme.spacing(2),
  },
  copyright: {
    backgroundColor: '#415364',
    color: 'white',
    textAlign: 'center',
  },
  copyrightInner: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  copyrightDivider: {
    width: '75%',
    margin: 'auto',
    height: '0.5px',
    backgroundColor: '#A5BAC9',
  },
  titleDivider: {
    width: '100%',
    margin: 'auto',
    height: '0.5px',
    backgroundColor: '#A5BAC9',
  },
  detail: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '0px',
    },
  },
  bottomDetail: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  footerTitle: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    textTransform: 'uppercase',
    color: '#FFF',
  },
  socialButton: {
    color: '#FFF',
    padding: 0,
  },
  simpleText: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    textDecoration: 'none',
    color: '#FFF',
  },
  subHeader: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: 500,
    textDecoration: 'none',
    color: '#FFF',
  },
  copyrightText: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    textDecoration: 'none',
    color: 'white',
  },
  centerGrid: {
    alignSelf: 'center',
  },
  headContainer: {
    marginTop: 10,
  },
});

export default footerStyles;
