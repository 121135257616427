import React, { useState } from 'react';

/* Material UI */
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

function SelectField(props) {
  const [open, setOpen] = useState(false);

  const {
    inputProps,
    onChange,
    disabled,
    native,
    shrink,
    value,
    items,
    title,
    id,
    required,
    styled,
    name,
    param,
    error,
    errorMsg,
    sx,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isDisable = Boolean(disabled);

  const styledLabel = {
    fontFamily: 'Montserrat, Roboto',
    fontSize: '11px',
    fontWeight: '500',
    opacity: 0.8,
  };
  const styledSelect = styled && {
    background: 'white',
    borderRadius: '10px',
    marginTop: 5,
    fontFamily: 'Montserrat, Roboto',
    color: '#0D47A1',
    fontWeight: '500',
  };
  const shrinkLabel = styled && {
    fontFamily: 'Montserrat, Roboto',
    color: '#0D47A1',
    fontWeight: '500',
    marginTop: '-3px',
  };

  const styledFormControl = styled && { marginBottom: '10px', width: '100%' };

  return (
    <FormControl
      id={id}
      sx={sx}
      required={required}
      variant={styled ? 'outlined' : 'standard'}
      size={styled ? 'small' : 'medium'}
      style={styledFormControl}>
      {styled && shrink ? (
        <label htmlFor={id}>
          <Typography color="primary" variant="caption" component="span" style={styledLabel}>
            {title}
          </Typography>
        </label>
      ) : (
        <InputLabel shrink={shrink} htmlFor={id} style={shrinkLabel} margin="dense">
          {title}
        </InputLabel>
      )}
      <Select
        id={id}
        inputProps={{
          id,
          ...inputProps,
        }}
        style={styledSelect}
        autoWidth
        native={native}
        disabled={isDisable}
        onChange={onChange}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        name={name || title}
        data-param={param}
        error={error}>
        {items}
      </Select>
      {error && <FormHelperText error>{errorMsg || 'Campo requerido'}</FormHelperText>}
    </FormControl>
  );
}

export default SelectField;
