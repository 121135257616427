import { spawn, takeLatest, put } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, update, post } from 'utils/api/api';
import { appActions, SET_ERROR } from 'commons/reducer/commons.reducer';
import { CurrencyManagerActions } from '../actions/CurrencyManager.actions';

function* initialQuery() {
  yield takeLatest(CurrencyManagerActions.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: CurrencyManagerActions.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: CurrencyManagerActions.GET_STATE_FROM_API_FAIL });

    const result = yield controlledCall(
      get,
      'api/v1/units',
      undefined,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
    yield put(appActions.setLoading(false));
  });
}

function* createUnit() {
  yield takeLatest(CurrencyManagerActions.CREATE_CURRENCY, function* (action) {
    const { name, code, factor, symbol } = action.controls;
    const body = { name, code, factor, symbol };
    const successFunction = (payload) => ({
      type: CurrencyManagerActions.CREATE_CURRENCY_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: CurrencyManagerActions.CREATE_CURRENCY_FAIL,
      payload,
    });

    const result = yield controlledCall(post, 'api/v1/units', body, successFunction, failFunction);

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* updateCurrency() {
  yield takeLatest(CurrencyManagerActions.EDIT_CURRENCY, function* (action) {
    const { toEditId, name, code, factor, symbol } = action.controls;
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: CurrencyManagerActions.EDIT_CURRENCY_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: CurrencyManagerActions.EDIT_CURRENCY_FAIL });

    yield controlledCall(
      update,
      `api/v1/units/${toEditId}`,
      { name, code, factor, symbol },
      successFunction,
      failFunction,
    );

    yield put(appActions.setLoading(false));
  });
}

export default function* CurencyManagerSaga() {
  yield spawn(initialQuery);
  yield spawn(createUnit);
  yield spawn(updateCurrency);
}
