import { put, spawn, takeLatest } from 'redux-saga/effects';

import { get, getDocument, objectToQueryString, post } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { unformatParse } from 'utils/functions';
import { appActions } from 'commons/reducer/commons.reducer';
import { GUARANTEES, guaranteesActions } from '../reducer/guarantees.reducer';

function* initialQuery() {
  yield takeLatest(GUARANTEES.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('filter-guarantees', true));
    let filters = {
      ...action.filters,
      minAmount: unformatParse(action.filters.minAmount),
      maxAmount: unformatParse(action.filters.maxAmount),
    };
    filters = Object.keys(filters).reduce((result, filter) => {
      if (filters[filter] == null) {
        return result;
      }
      // eslint-disable-next-line no-param-reassign
      result[filter] = filters[filter];
      return result;
    }, {});
    yield controlledCall(
      get,
      `api/v1/guarantees?${new URLSearchParams(filters)}`,
      undefined,
      guaranteesActions.initialQuerySuccess,
      guaranteesActions.initialQueryFail,
    );
    yield put(appActions.setLoader('filter-guarantees', false));
  });
}

function* acknowledgeRefund() {
  yield takeLatest(GUARANTEES.ACKNOWLEDGE_REFUND, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      post,
      `api/v1/guarantees/${action.id}/refund/acknowledge`,
      undefined,
      guaranteesActions.acknowledgeRefundSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se marcó la garantía como "devuelta" exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* changeAmount() {
  yield takeLatest(GUARANTEES.CHANGE_AMOUNT, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      post,
      `api/v1/guarantees/${action.id}/changeAmount`,
      { amount: action.amount },
      guaranteesActions.changeAmountSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se modificó el monto de la garantía exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* setFlag() {
  yield takeLatest(GUARANTEES.SET_FLAG, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      post,
      `api/v1/guarantees/${action.id}/setSyncFlag`,
      { syncFlag: action.syncFlag },
      guaranteesActions.setFlagSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se marcó la garantía exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* getReport() {
  yield takeLatest(GUARANTEES.GET_REPORT, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      getDocument('reporteGarantías', 'xlsx', true),
      `api/v1/guarantees/getReport?${objectToQueryString(action.filters)}`,
      undefined,
      undefined,
      undefined,
      true,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Descargando reporte'));
    }
    yield put(appActions.setLoading(false));
  });
}

export default function* guaranteesSaga() {
  yield spawn(initialQuery);
  yield spawn(acknowledgeRefund);
  yield spawn(changeAmount);
  yield spawn(setFlag);
  yield spawn(getReport);
}
