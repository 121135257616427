const styles = () => ({
  root: {
    textAlign: 'center',
    padding: '10px !important',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageLogo: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '30%',
  },
  title: {
    fontSize: '19px',
    fontWeight: '400',
    margin: '20px 20px 20px 20px',
    fontFamily: 'Roboto, sans-serif',
    color: '#838383 !important',
  },
  text: {
    lineHeight: '1.6em !important',
    fontSize: '17px !important',
    color: '#838383 !important',
  },
});

export default styles;
