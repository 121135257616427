import { spawn, takeLatest, put } from 'redux-saga/effects';

/* Project */
import { appActions, SET_ERROR } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { get, update, post, destroy } from 'utils/api/api';
import { DirectSaleModelsManagerActions } from '../actions/DirectSaleModelsManager.actions';

function* initialQuery() {
  yield takeLatest(DirectSaleModelsManagerActions.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));
    const successFunction = (payload) => ({
      type: DirectSaleModelsManagerActions.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: DirectSaleModelsManagerActions.GET_STATE_FROM_API_FAIL });

    yield controlledCall(get, 'api/v1/brand_models', undefined, successFunction, failFunction);
    yield put(appActions.setLoading(false));
  });
}

function* createBrandModel() {
  yield takeLatest(DirectSaleModelsManagerActions.CREATE_MODELS, function* (action) {
    yield put(appActions.setLoading(true));
    const body = action.controls;

    const successFunction = (payload) => ({
      type: DirectSaleModelsManagerActions.CREATE_MODELS_SUCCESS,
      payload,
    });

    const result = yield controlledCall(post, 'api/v1/brand_models', body, successFunction);
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha creado el modelo exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* updateBrandModel() {
  yield takeLatest(DirectSaleModelsManagerActions.EDIT_MODELS, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: DirectSaleModelsManagerActions.EDIT_MODELS_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: DirectSaleModelsManagerActions.EDIT_MODELS_FAIL,
      payload,
    });

    const result = yield controlledCall(
      update,
      `api/v1/brand_models/${body.id}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* deleteBrandModel() {
  yield takeLatest(DirectSaleModelsManagerActions.DELETE_MODELS, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: DirectSaleModelsManagerActions.DELETE_MODELS_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: DirectSaleModelsManagerActions.DELETE_MODELS_FAIL,
      payload,
    });

    const result = yield controlledCall(
      destroy,
      `api/v1/brand_models/${body.id}`,
      undefined,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* DirectSaleModelsManagerSaga() {
  yield spawn(initialQuery);
  yield spawn(createBrandModel);
  yield spawn(updateBrandModel);
  yield spawn(deleteBrandModel);
}
