import camelize from 'camelcase-keys-deep';

export default class LinkedLot {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  current() {
    return this.env().Lot.getById(this.currentId);
  }
}
