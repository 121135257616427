import React from 'react';

// Material
import { CircularProgress, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// Project
import tattersallLogo from 'utils/images/logo-tattersall.png';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';

function MailForm(props) {
  const { controls, onChangeText, onSubmit, state } = props;
  const history = useHistory();

  const disabledButton = !state.email;
  return (
    <Grid container spacing={3} justifyContent="center" style={{ width: '100%', margin: 'auto' }}>
      <Grid item xs={9}>
        <img src={tattersallLogo} style={{ width: '100%', marginTop: 20 }} alt="logo" />
      </Grid>
      <Grid item xs={9}>
        <TextField
          label="Correo electrónico"
          type="email"
          id="email"
          variant="standard"
          value={state.email}
          onChange={onChangeText}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={9}>
        <IconButton edge="start" onClick={() => history.goBack()} size="large">
          <ArrowBackIcon />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          style={{ float: 'right', marginTop: '5px' }}
          disabled={controls.loading || disabledButton}>
          {controls.loading ? <CircularProgress /> : 'Recuperar contraseña'}
        </Button>
      </Grid>
      {controls.error && (
        <Grid item xs={12}>
          <Typography style={{ color: 'red' }} align="center">
            {controls.error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default MailForm;
