import camelize from 'camelcase-keys-deep';

export default class BillingData {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  person() {
    return this.env().Person.findBy('billingDataId', this.id);
  }

  bank() {
    return this.env().Bank.getById(this.bankId);
  }

  accountType() {
    return this.env().AccountType.getById(this.accountTypeId);
  }

  country() {
    return this.env().Country.getById(this.countryId);
  }

  commune() {
    return this.env().Commune.getById(this.communeId);
  }

  region() {
    return this.env().Region.getById(this.commune()?.regionId);
  }
}
