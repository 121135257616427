import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import { m2ToHa } from 'utils/functions';
import { defaultCurreny } from 'utils/helpers/helper';

// Local constants
const PATH = 'lotsManager/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TEMPLATE_VALUES_CHANGED = `${PATH}TEMPLATE_VALUES_CHANGED`;
const ADD_POLYGON = `${PATH}ADD_POLYGON`;
const DELETE_POLYGON = `${PATH}DELETE_POLYGON`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const REMOVE_FILE = `${PATH}REMOVE_FILE`;
const SET_IMAGES = `${PATH}SET_IMAGES`;
const SET_CROPS = `${PATH}SET_CROPS`;
const SET_CROPPED_IMAGES = `${PATH}SET_CROPPED_IMAGES`;
const SET_FILES_NAMES = `${PATH}SET_FILES_NAMES`;
const SET_IMAGES_META = `${PATH}SET_IMAGES_META`;
const TOGGLE_SAT_MESSAGE = `${PATH}TOGGLE_SAT_MESSAGE`;
const RESET = `${PATH}RESET`;
const CLOSE_HISTORY_MODAL = `${PATH}CLOSE_HISTORY_MODAL`;
const UPDATE_META = `${PATH}UPDATE_META`;
const DELETE_IMAGE = `${PATH}DELETE_IMAGE`;

// Global constants for saga
export const LOTS_MANAGER = {
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  UPLOAD_LOT_DOCUMENT_SUCCESS: `${PATH}UPLOAD_LOT_DOCUMENT_SUCCESS`,
  UPLOAD_LOT_DOCUMENT_FAIL: `${PATH}UPLOAD_LOT_DOCUMENT_FAIL`,
  UPLOAD_LOT_DOCUMENT: `${PATH}UPLOAD_LOT_DOCUMENT`,
  DESTROY_LOT_DOCUMENT_SUCCESS: `${PATH}DESTROY_LOT_DOCUMENT_SUCCESS`,
  DESTROY_LOT_DOCUMENT_FAIL: `${PATH}DESTROY_LOT_DOCUMENT_FAIL`,
  DESTROY_LOT_DOCUMENT: `${PATH}DESTROY_LOT_DOCUMENT`,
  CREATE_LOT_SUCCESS: `${PATH}CREATE_LOT_SUCCESS`,
  CREATE_LOT_FAIL: `${PATH}CREATE_LOT_FAIL`,
  CREATE_LOT: `${PATH}CREATE_LOT`,
  EDIT_LOT_SUCCESS: `${PATH}EDIT_LOT_SUCCESS`,
  EDIT_LOT_FAIL: `${PATH}EDIT_LOT_FAIL`,
  EDIT_LOT: `${PATH}EDIT_LOT`,
  SAT_INTEGRATION_SUCCESS: `${PATH}SAT_INTEGRATION_SUCCESS`,
  SAT_INTEGRATION_FAIL: `${PATH}SAT_INTEGRATION_FAIL`,
  SAT_INTEGRATION: `${PATH}SAT_INTEGRATION`,
  GET_HISTORY_SUCCESS: `${PATH}GET_HISTORY_SUCCESS`,
  GET_HISTORY_FAIL: `${PATH}GET_HISTORY_FAIL`,
  GET_HISTORY: `${PATH}GET_HISTORY`,
  GET_BASIC_INFO: `${PATH}GET_BASIC_INFO`,
  GET_BASIC_INFO_SUCCESS: `${PATH}GET_BASIC_INFO_SUCCESS`,
  GET_BASIC_INFO_FAIL: `${PATH}GET_BASIC_INFO_FAIL`,
};

// actions
export const lotsManagerActions = {
  reset: createAction(RESET),
  setFilesNames: createAction(SET_FILES_NAMES, 'filesNames'),
  setImages: createAction(SET_IMAGES, 'images'),
  setCrops: createAction(SET_CROPS, 'crops'),
  setCroppedImages: createAction(SET_CROPPED_IMAGES, 'croppedImages'),
  setImagesMeta: createAction(SET_IMAGES_META, 'meta'),
  updateMeta: createAction(UPDATE_META, 'index', 'meta'),
  deleteImage: createAction(DELETE_IMAGE, 'id'),
  removeFile: createAction(REMOVE_FILE, 'index', 'isNew'),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  templatesValuesChanged: createAction(TEMPLATE_VALUES_CHANGED, 'controls'),
  addPolygon: createAction(ADD_POLYGON, 'path'),
  deletePolygon: createAction(DELETE_POLYGON, 'path'),
  // get state from api
  initialQuerySuccess: createAction(LOTS_MANAGER.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(LOTS_MANAGER.INITIAL_QUERY_FAIL, 'payload'),
  initialQuery: createAction(LOTS_MANAGER.INITIAL_QUERY, 'lotId', 'auctionId', 'reset'),
  // upload document
  uploadLotDocumentSuccess: createAction(LOTS_MANAGER.UPLOAD_LOT_DOCUMENT_SUCCESS, 'payload'),
  uploadLotDocumentFail: createAction(LOTS_MANAGER.UPLOAD_LOT_DOCUMENT_FAIL, 'payload'),
  uploadLotDocument: createAction(LOTS_MANAGER.UPLOAD_LOT_DOCUMENT, 'controls'),
  // desttoy document
  destroyLotDocumentSuccess: createAction(LOTS_MANAGER.DESTROY_LOT_DOCUMENT_SUCCESS, 'payload'),
  destroyLotDocumentFail: createAction(LOTS_MANAGER.DESTROY_LOT_DOCUMENT_FAIL, 'payload'),
  destroyLotDocument: createAction(LOTS_MANAGER.DESTROY_LOT_DOCUMENT, 'id'),
  // create lot
  createLotSuccess: createAction(LOTS_MANAGER.CREATE_LOT_SUCCESS, 'payload'),
  createLotFail: createAction(LOTS_MANAGER.CREATE_LOT_FAIL, 'payload'),
  createLot: createAction(LOTS_MANAGER.CREATE_LOT, 'controls', 'imagesFiles', 'saveAndContinue'),
  // edit lot
  editLotSuccess: createAction(LOTS_MANAGER.EDIT_LOT_SUCCESS, 'payload'),
  editLotFail: createAction(LOTS_MANAGER.EDIT_LOT_FAIL, 'payload'),
  editLot: createAction(LOTS_MANAGER.EDIT_LOT, 'controls', 'imagesFiles'),
  // sat integration
  satIntegrationSuccess: createAction(LOTS_MANAGER.SAT_INTEGRATION_SUCCESS, 'payload'),
  satIntegrationFail: createAction(LOTS_MANAGER.SAT_INTEGRATION_FAIL, 'payload'),
  satIntegration: createAction(LOTS_MANAGER.SAT_INTEGRATION, 'license'),
  // History
  getHistorySuccess: createAction(LOTS_MANAGER.GET_HISTORY_SUCCESS, 'payload'),
  getHistoryFail: createAction(LOTS_MANAGER.GET_HISTORY_FAIL, 'payload'),
  getHistory: createAction(LOTS_MANAGER.GET_HISTORY, 'controls'),
  // clear message and notification
  toggleSatMessage: createAction(TOGGLE_SAT_MESSAGE, 'controls'),
  closeHistoryModal: createAction(CLOSE_HISTORY_MODAL),
  getBasicInfo: createAction(LOTS_MANAGER.GET_BASIC_INFO, 'id'),
  getBasicInfoSuccess: createAction(LOTS_MANAGER.GET_BASIC_INFO_SUCCESS, 'payload'),
};

const basicInfo = {
  lotImages: [],
  detailLines: [],
  entitiesIds: [],
  polygons: [],
  // location
  address: '',
  communeId: '',
  regionId: '',
  long: 0,
  lat: 0,
  // video
  urlVideo: '',
  showVideo: false,
  // other controls
  mapReady: false,
  loadingImage: false,
  saveAndContinue: false,
  templateLineValues: {},
  // lot info
  description: '',
  ivaCode: '',
  utilArea: null,
  unitId: '',
  name: '',
  biddingEmail: '',
  area: null,
  areaUnitId: '',
  builtAreaUnitId: '',
  guaranteeAmount: 10,
  guaranteeUnit: '1',
  minimumUnitId: '',
  limitParticipants: 0,
};

const invalidControls = {
  lotCategoryId: false,
  unitId: false,
  stateId: false,
  name: false,
  lotType: false,
  mandateId: false,
  mandateBaseId: false,
  newSellerId: false,
  biddingEmail: false,
};

const initialState = {
  lotId: null,
  controls: {
    // basic info
    lotCategoryId: '',
    commission: 0,
    sellerCommission: 0,
    auctionId: '',
    mandateId: '',
    mandateBaseId: '',
    lotType: '',
    stateId: '',
    sale: false,
    lease: false,
    attachedFiles: [],
    deletedFilesIds: [],
    filesNames: [],
    images: [],
    crops: [],
    croppedImages: [],
    queryComplete: false,
    fullDescription: '',
    ...basicInfo,
    license: '',
    // cropped image modal
    croppedIsOpen: false,
    satIntegrationData: null,
    satError: false,
    satNotification: false,
    satMessage: '',
    firstQuery: false,
    oldLot: false,
    imagesMeta: [],
    deletedImages: [],
    price: null,
    increase: null,
    index: null,
    auctionInfo: null,
    dataLoaded: false,
    brandId: null,
    brandModelId: null,
  },
  cloneControlls: {
    historyIsOpen: false,
    historicalLots: [],
    lotHistoryId: null,
  },
  invalidControls: { ...invalidControls },
  ids: {
    sellersUsers: [],
    contactUsers: [],
    auctionContacts: [],
  },
  imagesData: [],
};

const lotsManager = createReducer(initialState, {
  [RESET](state) {
    state.controls = initialState.controls;
    state.invalidControls = initialState.invalidControls;
    state.ids = initialState.ids;
    state.imagesData = initialState.imagesData;
  },
  [SET_FILES_NAMES](state, action) {
    state.controls.filesNames = action.filesNames;
  },
  [SET_IMAGES](state, action) {
    state.controls.images = action.images;
  },
  [SET_CROPS](state, action) {
    state.controls.crops = action.crops;
  },
  [SET_CROPPED_IMAGES](state, action) {
    state.controls.croppedImages = action.croppedImages;
  },
  [SET_IMAGES_META](state, action) {
    state.controls.imagesMeta = action.meta;
  },
  [UPDATE_META](state, action) {
    state.controls.imagesMeta[action.index] = {
      ...state.controls.imagesMeta[action.index],
      ...action.meta,
    };
  },
  [DELETE_IMAGE](state, action) {
    state.controls.deletedImages = [...state.controls.deletedImages, action.id];
  },
  [REMOVE_FILE](state, action) {
    if (!action.isNew) {
      state.controls.deletedFilesIds = [
        ...state.controls.deletedFilesIds,
        state.controls.attachedFiles[action.index].id,
      ];
    }
    const newFiles = [...state.controls.attachedFiles];
    newFiles.splice(action.index, 1);
    state.controls.attachedFiles = [...newFiles];
  },
  [TOGGLE_FORM](state, action) {
    state.controls[action.modal] = !state.controls[action.modal];
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [TEMPLATE_VALUES_CHANGED](state, action) {
    state.controls.templateLineValues = {
      ...state.controls.templateLineValues,
      ...action.controls,
    };
  },

  [ADD_POLYGON](state, action) {
    state.controls.polygons = [...state.controls.polygons, action.path];
  },
  [DELETE_POLYGON](state, action) {
    state.controls.polygons = state.controls.polygons.filter((p) => p !== action.path);
  },
  [LOTS_MANAGER.UPLOAD_LOT_DOCUMENT](state) {
    state.controls.loadingImage = true;
    state.controls.croppedIsOpen = false;
  },
  [LOTS_MANAGER.UPLOAD_LOT_DOCUMENT_SUCCESS](state, action) {
    state.controls.loadingImage = false;
    state.controls.lotImages = [...state.controls.lotImages, action.payload.lotDocumentId];
  },
  [LOTS_MANAGER.DESTROY_LOT_DOCUMENT_SUCCESS](state, action) {
    state.controls.lotImages = state.controls.lotImages.filter(
      (li) => action.payload.item.id !== li,
    );
  },
  [LOTS_MANAGER.CREATE_LOT_SUCCESS](state) {
    // state.controls = { ...state.controls, ...basicInfo };
    state.invalidControls = { ...invalidControls };
  },
  [LOTS_MANAGER.EDIT_LOT_SUCCESS](state) {
    // state.controls = { ...state.controls, ...basicInfo };
    state.invalidControls = { ...invalidControls };
  },
  [LOTS_MANAGER.CREATE_LOT](state) {
    state.controls.mapReady = true;
  },
  [LOTS_MANAGER.INITIAL_QUERY](state, action) {
    if (action.reset) {
      state.controls = initialState.controls;
      state.controls.loading = true;
    }
  },
  [LOTS_MANAGER.INITIAL_QUERY_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.dataLoaded = true;
    state.ids.sellersUsers = action.payload.ids.sellersUsers;
    state.ids.contactsUsers = action.payload.ids.contactsUsers;
    state.ids.auctionContacts = action.payload.ids.auctionContacts;
    const lotId = Number(state.controls.lotId);
    if (lotId && !state.controls.firstQuery) {
      // lot information
      const lot = Object.values(action.payload.objects.lots).find((l) => l.id === lotId);
      if (lot.sale && lot.lease === false) {
        state.controls.lotType = 'sale';
      } else if (lot.lease) {
        state.controls.lotType = 'lease';
      } else {
        let auctionType = null;
        if (action.payload.auctionType) {
          auctionType = Object.values(action.payload.objects.auctionTypes).find(
            (at) => at.id === action.payload.auctionType.id,
          );
        }
        state.controls.lotType = auctionType ? auctionType.code : '';
      }

      state.controls.mandateBaseId = lot.mandateBaseId;
      state.controls.lotCategoryId = lot.lotCategoryId;
      state.controls.unitA = lot.lotCategoryId;
      state.controls.description = lot.description;
      state.controls.urlVideo = lot.urlVideo;
      state.controls.utilArea =
        lot.builtAreaUnitId?.toString() === '2' ? m2ToHa(Number(lot.utilArea)) : lot.utilArea;
      state.controls.increase = lot.increase;
      state.controls.ivaCode = lot.ivaCode;
      state.controls.stateId = lot.stateId;
      state.controls.fullDescription = lot.fullDescription;
      state.controls.unitId = lot.unitId || defaultCurreny;
      state.controls.price = Number(lot.price);
      state.controls.name = lot.name;
      state.controls.biddingEmail = lot.biddingEmail;
      state.controls.area =
        lot.areaUnitId?.toString() === '2' ? m2ToHa(Number(lot.area)) : lot.area;
      state.controls.guaranteeAmount = lot.guaranteeAmount ? Number(lot.guaranteeAmount) : 10;
      state.controls.guaranteeUnit = lot.guaranteeAmount ? '2' : '1';
      state.controls.areaUnitId = lot.areaUnitId;
      state.controls.builtAreaUnitId = lot.builtAreaUnitId;
      state.controls.index = lot.index;
      state.cloneControlls.lotHistoryId = lot.lotHistoryId;
      state.controls.minimumUnitId = lot.minimumUnitId || defaultCurreny;
      //
      state.controls.limitParticipants = lot.limitParticipants;
      //
      const { brandModelId } = lot;
      state.controls.brandModelId = brandModelId;
      if (brandModelId) {
        const currentModel = action.payload.objects.brandModels[brandModelId];
        if (currentModel) {
          state.controls.brandId = currentModel.brandId;
        }
      }

      if (Number(lot.stateId) === 1 || lot.sale || lot.lease) {
        if (lot.mandateId) {
          const mandate = Object.values(action.payload.objects.mandates).find(
            (m) => m.id === lot.mandateId,
          );
          state.controls.newSellerId = mandate ? mandate.newSellerId : null;
          state.controls.commission = mandate ? mandate.commission : 0;
          state.controls.sellerCommission = mandate ? mandate.sellerCommission : 0;
        }
      } else if (lot.mandateBaseId) {
        const base = Object.values(action.payload.objects.mandateBases).find(
          (mb) => mb.id === lot.mandateBaseId,
        );
        const mandate = Object.values(action.payload.objects.mandates).find(
          (m) => m.id === base.mandateId,
        );
        state.controls.auctionId = mandate.auctionId;
        state.controls.mandateId = mandate.id;
      }

      // location
      const location = Object.values(action.payload.objects.locations).find(
        (l) => l.id === lot.locationId,
      );
      if (location) {
        state.controls.communeId = location.communeId;
        state.controls.address = location.address;
        state.controls.regionId = action.payload.objects.communes[location.communeId]?.regionId;
        state.controls.long = location.long;
        state.controls.lat = location.lat;
      }

      // contacts
      state.controls.entitiesIds = Object.values(action.payload.objects.lotContacts)
        .filter((lc) => lc.lotId === lotId)
        .map((lc) => lc.entityId);

      // detailLines
      state.controls.detailLines = Object.values(action.payload.objects.detailLines).filter(
        (ld) => ld.lotId === lotId,
      );

      // polygons
      state.controls.polygons = Object.values(action.payload.objects.polygons)
        .filter((p) => p.locationId === location?.id)
        .map((p) => p.path);
      // map ready
      state.controls.mapReady = true;

      state.controls.attachedFiles = Object.values(action.payload.objects.lotFiles);

      // invalid control
      state.invalidControls = invalidControls;

      state.imagesData = [...action.payload.images];

      state.controls.queryComplete = true;
      state.controls.firstQuery = true;
    }
    if (!lotId && !state.controls.firstQuery) {
      state.controls.minimumUnitId = defaultCurreny;
      state.controls.unitId = defaultCurreny;
    }
  },
  [LOTS_MANAGER.SAT_INTEGRATION_SUCCESS](state, action) {
    state.controls.satIntegrationData = action.payload;
  },
  [LOTS_MANAGER.SAT_INTEGRATION_FAIL](state) {
    state.controls.satError = true;
    state.controls.satMessage = 'Falló la integración con SAT';
  },
  [TOGGLE_SAT_MESSAGE](state) {
    state.controls.satError = false;
    state.controls.satNotification = false;
    state.controls.satMessage = '';
  },
  [LOTS_MANAGER.GET_HISTORY_SUCCESS](state, action) {
    state.cloneControlls.historicalLots = action.payload.historicalLots;
    state.cloneControlls.historyIsOpen = true;
  },
  [CLOSE_HISTORY_MODAL](state) {
    state.cloneControlls.historyIsOpen = false;
  },
  [LOTS_MANAGER.GET_BASIC_INFO_SUCCESS](state, action) {
    state.controls.lotId = action.payload.lotId;
  },
});

export default lotsManager;
