import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import Layout from 'commons/containers/Layout/Layout';

function ComponentWrapper(props) {
  const { component: Component, requireSearchParams, ...rest } = props;

  if (requireSearchParams && requireSearchParams.length) {
    const params = new URLSearchParams(window.location.search);

    // eslint-disable-next-line no-restricted-syntax
    for (const requireParam of requireSearchParams) {
      if (!params.get(requireParam)) {
        return <Redirect to="/" />;
      }
    }
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
}

function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      // eslint-disable-next-line react/no-unstable-nested-components
      component={(props) => (
        <ComponentWrapper
          {...props}
          component={Component}
          requireSearchParams={rest.options.requireSearchParams}
        />
      )}
    />
  );
}

export default PublicRoute;
