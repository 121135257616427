/* eslint-disable */
import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import { LOGIN } from 'modules/main/Login/reducer/login.reducer';
import { removeManyKeysByFilter } from 'utils/functions';
import { RESET_PASSWORD } from 'modules/main/ResetPassword/reducer/resetPassword.reducer';
import { REGISTER } from 'modules/main/Register/reducer/register.reducer';
import { USERS } from 'modules/main/Users/reducer/users.reducer';
import { CONTACT_MESSAGES } from 'modules/main/ContactMessages/reducer/contactMessages.reducer';
import { AUCTION_CATEGORIES } from 'modules/main/AuctionCategories/reducer/auctionCategories.reducer';
import { LOT_CATEGORIES } from 'modules/main/LotCategories/reducer/lotCategories.reducer';
import { AUCTIONS } from 'modules/main/Auctions/reducer/auctions.reducer';
import { AUCTIONS_MANAGER } from 'modules/main/AuctionsManager/reducer/auctionManager.reducer';
import { GUARANTEE_MANAGER } from 'modules/main/GuaranteeManager/reducer/guaranteeManager.reducer';
import { LOTS_MANAGER } from 'modules/main/LotsManager/reducer/lotsManager.reducer';
import { LOTS, RESET_FILTERS } from 'modules/main/Lots/reducer/lots.reducer';
import { WEBPAY_FINAL } from 'modules/main/WebpayFinal/reducer/webpayFinal.reducer';
import { PAYMENTS } from 'modules/main/Payments/reducer/payments.reducer';
import { GUARANTEES } from 'modules/main/Guarantees/reducer/guarantees.reducer';
import { GUARANTEES_UNIFICATION } from 'modules/main/GuaranteesAndPayments/reducer/newGuarantee.reducer';
import { PAYMENTS_UNIFICATION } from 'modules/main/GuaranteesAndPayments/reducer/newPayment.reducer';
import { LOT_DETAILS } from 'modules/main/LotDetails/reducer/lotDetails.reducer';
import { ORDER_LOTS } from 'modules/main/EntitledLots/reducer/entitledLots.reducer';
import { USER_PROFILE } from 'modules/main/UserProfile/reducer/userProfile.reducer';
import { EDIT_USER } from 'modules/main/EditUser/reducer/editUser.reducer';
import { cachedEnvironment } from 'utils/reduxActions';
import { SELLERS_MANAGER } from 'modules/main/SellersManager/reducer/sellersManager.reducer';
import { FEATURED_LOTS_MANAGER } from 'modules/main/FeaturedLotsManager/reducer/FeaturedLotsManager.reducer';
import {
  GLOBAL_FILTER_RESET,
  INITIAL_QUERY,
  INITIAL_QUERY_FAIL,
  INITIAL_QUERY_SUCCESS,
  MANUAL_AREA_CHANGE,
} from 'utils/actions/global.actions';
import { DirectSaleContactsManagerActions } from 'modules/direct-sale/DirectSaleContactsManager/actions/DirectSaleContactsManager.actions';
import { DirectSaleModelsManagerActions } from 'modules/direct-sale/DirectSaleModelsManager/actions/DirectSaleModelsManager.actions';
import { BrandsManagerActions } from 'modules/main/BrandsManager/actions/BrandsManager.actions';
import { DirectSaleFeaturesActions } from 'modules/direct-sale/DirectSaleLots/actions/DirectSaleFeatures.actions';
import { DirectSaleActions } from 'modules/direct-sale/DirectSale/actions/DirectSale.actions';
import { VIEW_REPORT } from 'modules/main/ViewReport/reducer/viewReport.reducer';
import { NEW_PAYMENT } from 'modules/main/NewPayment/reducer/newPayment.reducer';
import { AdjudicationsTypes } from 'modules/main/Adjudications/types/adjudications.types';
import { guaranteesActions } from 'modules/main/GuaranteesAndPayments/reducer/newGuarantee.reducer';
import { CurrencyManagerActions } from 'modules/main/CurrencyManager/actions/CurrencyManager.actions';
import { SystemChangesTypes } from 'modules/main/SystemChanges/types/SystemChanges.types';
import { FinishedAuctionsTypes } from 'modules/main/FinishedAuctions/types/FinishedAuctions.types';
import { PosturesManagerTypes } from 'modules/main/PosturesManager/types/posturesManager.types';
import { TransfersLoginTypes } from '../../modules/transferences/TransferManagerLogin/types/transfersLogin.types';
import { TransfersLotDetailsTypes } from '../../modules/transferences/TransferManagerLotDetails/types/TransferLotDetails.types';
import { RecordCertificateFormTypes } from '../../modules/transferences/RecordCertificateForm/types/RecordCertificateForm.types';
import { VehicleTypesManagerTypes } from '../../modules/main/VehicleTypesManager/types/VehicleTypesManager.types';
import TransfersTypes from 'modules/transferences/Transfers/Transfers.types';
import { MANDATES_LOGIN } from 'modules/mandates-tracking/Login/reducer/mandatesLogin.reducer';
// GLOBAL

export const LOG_OUT = 'app/LOG_OUT';
export const TOGGLE_DRAWER = 'app/TOGGLE_DRAWER';
export const TOGGLE_FORM = 'app/TOGGLE_FORM';
export const TOGGLE_ERROR = 'app/TOGGLE_ERROR';
export const TOGGLE_SUCCESS = 'app/TOGGLE_SUCCESS';
export const TOGGLE_WARNING = 'app/TOGGLE_WARNING';
export const TOGGLE_GOTO_AUCTION = 'app/TOGGLE_GOTO_AUCTION';
export const TOGGLE_EMAIL_REMINDER = 'app/TOGGLE_EMAIL_REACTION';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_SUCCESS = 'app/SET_SUCCESS';
export const SET_ALERT_DIALOG = 'app/SET_ALERT_DIALOG';
export const CLOSE_ALERT_DIALOG = 'app/CLOSE_ALERT_DIALOG';
export const SET_WARNING = 'app/SET_WARNING';
export const SET_GOTO_AUCTION = 'app/SET_GOTO_AUCTION';
export const SET_EMAIL_REMINDER = 'app/SET_EMAIL_REMINDER';
export const SET_LOADER = 'app/SET_LOADER';
export const FORM_CONTROLS_CHANGED = 'app/FORM_CONTROLS_CHANGED';
export const TOGGLE_CONTACT_FORM = 'app/TOGGLE_CONTACT_FORM';
export const SEARCH_CONTROLS_CHANGED = 'app/SEARCH_CONTROLS_CHANGED';
export const SET_LOADING = 'app/SET_LOADING';
export const CLEAN_SEARCH_QUERY = 'app/CLEAN_SEARCH_QUERY';
export const SET_GUARANTEE_MODAL = 'app/SET_GUARANTEE_MODAL';
export const TOGGLE_GUARANTEE_MODAL = 'app/TOGGLE_GUARANTEE_MODAL';
export const SET_PAYMENT_MODAL = 'app/SET_PAYMENT_MODAL';
export const TOGGLE_PAYMENT_MODAL = 'app/TOGGLE_PAYMENT_MODAL';
export const TOGGLE_PARTICIPATE_MODAL = 'app/TOGGLE_PARTICIPATE_MODAL';
export const CLEAN_LOTS_CREATED = 'app/CLEAN_LOTS_CREATED';
export const SET_GLOBAL_BACKGROUND = 'app/SET_GLOBAL_BACKGROUND';

// api
export const APP = {
  INITIAL_QUERY_SUCCESS,
  INITIAL_QUERY_FAIL,
  INITIAL_QUERY,
  CREATE_CONTACT_MESSAGE_SUCCESS: 'app/CONTACT_MESSAGE_SUCCESS',
  CREATE_CONTACT_MESSAGE_FAIL: 'app/CONTACT_MESSAGE_FAIL',
  CREATE_CONTACT_MESSAGE: 'app/CONTACT_MESSAGE',
  SEARCH_QUERY_SUCCESS: 'app/SEARCH_QUERY_SUCCESS',
  SEARCH_QUERY_FAIL: 'app/SEARCH_QUERY_FAIL',
  SEARCH_QUERY: 'app/SEARCH_QUERY',
  AUTO_SEARCH_AUCTIONS_SUCCESS: 'app/AUTO_SEARCH_AUCTIONS_SUCCESS',
  AUTO_SEARCH_AUCTIONS_FAIL: 'app/AUTO_SEARCH_AUCTIONS_FAIL',
  AUTO_SEARCH_AUCTIONS: 'app/AUTO_SEARCH_AUCTIONS',
  // sockets
  ACTIVATE: 'app/ACTIVATE',
  // CONNECT_SOCKET_SUCCESS: 'app/CONNECT_SOCKET_SUCCESS',
  OBJECTS_FROM_SOCKET_RECEIVED: 'app/OBJECTS_FROM_SOCKET_RECEIVED',
  DELETE_OBJECTS_FROM_SOCKET_RECEIVED: 'app/DELETE_OBJECTS_FROM_SOCKET_RECEIVED',
  MERGE_CONTROLS: 'app/MERGE_CONTROLS',
  FETCH_BALANCE: 'app/FETCH_BALANCE',
  FETCH_BALANCE_SUCCESS: 'app/FETCH_BALANCE_SUCCESS',
  FETCH_BALANCE_FAIL: 'app/FETCH_BALANCE_FAIL',
  VIEWED_GLOBAL_DIALOG: 'app/VIEWED_GLOBAL_DIALOG',
  UPDATE_LAYOUT_STYLES: 'app/UPDATE_LAYOUT_STYLES',
  SET_SOCKET_ID: 'app/SET_SOCKET_ID',
  RESET_SOCKET_ID: 'app/RESET_SOCKET_ID',
};

export const appActions = {
  // api fetch
  fetchBalance: createAction(APP.FETCH_BALANCE),
  fetchBalanceSuccess: createAction(APP.FETCH_BALANCE_SUCCESS, 'payload'),
  fetchBalanceFail: createAction(APP.FETCH_BALANCE_FAIL, 'payload'),
  logOut: createAction(LOG_OUT),
  setUser: createAction(LOGIN.GET_USER_SUCCESS, 'payload'),
  // others
  formControlsChanged: createAction(FORM_CONTROLS_CHANGED, 'controls'),
  searchControlsChanged: createAction(SEARCH_CONTROLS_CHANGED, 'controls'),
  toggleError: createAction(TOGGLE_ERROR),
  toggleSuccess: createAction(TOGGLE_SUCCESS),
  toggleWarning: createAction(TOGGLE_WARNING),
  toggleGotoAuction: createAction(TOGGLE_GOTO_AUCTION),
  toggleEmailReminder: createAction(TOGGLE_EMAIL_REMINDER),
  toggleGuaranteeModal: createAction(TOGGLE_GUARANTEE_MODAL),
  togglePaymentModal: createAction(TOGGLE_PAYMENT_MODAL),
  toggleDrawer: createAction(TOGGLE_DRAWER),
  setError: createAction(SET_ERROR, 'msg'),
  setSuccess: createAction(SET_SUCCESS, 'msg'),
  setAlertDialog: createAction(SET_ALERT_DIALOG, 'payload'),
  setWarning: createAction(SET_WARNING, 'msg'),
  setGotoAuction: createAction(SET_GOTO_AUCTION, 'msg'),
  setEmailReminder: createAction(SET_EMAIL_REMINDER, 'msg'),
  setGuaranteeModal: createAction(SET_GUARANTEE_MODAL, 'payload'),
  setPaymentModal: createAction(SET_PAYMENT_MODAL, 'payload'),
  setLoader: createAction(SET_LOADER, 'id', 'state'),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  toggleContactForm: createAction(TOGGLE_CONTACT_FORM, 'lotId'),
  setLoading: createAction(SET_LOADING, 'state'),
  cleanSearchQuery: createAction(CLEAN_SEARCH_QUERY),
  manualAreaChange: createAction(MANUAL_AREA_CHANGE, 'areaId'),
  toggleParticipateModal: createAction(TOGGLE_PARTICIPATE_MODAL),
  cleanLotsCreated: createAction(CLEAN_LOTS_CREATED),
  // sockets
  activate: createAction(APP.ACTIVATE),
  // connectSocketSuccess: createAction(APP.CONNECT_SOCKET_SUCCESS, 'socket'),
  objectsFromSocketReceived: createAction(APP.OBJECTS_FROM_SOCKET_RECEIVED, 'payload'),
  deleteObjectsFromSocketReceived: createAction(APP.DELETE_OBJECTS_FROM_SOCKET_RECEIVED, 'payload'),
  mergeControls: createAction(APP.MERGE_CONTROLS, 'payload'),
  // api
  initialQuerySuccess: createAction(APP.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(APP.INITIAL_QUERY_FAIL, 'payload'),
  initialQuery: createAction(APP.INITIAL_QUERY),
  // contact messages
  createContactMessageSuccess: createAction(APP.CREATE_CONTACT_MESSAGE_SUCCESS, 'payload'),
  createContactMessageFail: createAction(APP.CREATE_CONTACT_MESSAGE_FAIL, 'payload'),
  createContactMessage: createAction(APP.CREATE_CONTACT_MESSAGE, 'controls'),
  //get search lots
  searchQuerySuccess: createAction(APP.SEARCH_QUERY_SUCCESS, 'payload'),
  searchQueryFail: createAction(APP.SEARCH_QUERY_FAIL, 'payload'),
  searchQuery: createAction(APP.SEARCH_QUERY, 'controls'),
  // auto complete
  autoSearchAuctionsSuccess: createAction(APP.AUTO_SEARCH_AUCTIONS_SUCCESS, 'payload'),
  autoSearchAuctionsFail: createAction(APP.AUTO_SEARCH_AUCTIONS_FAIL, 'payload'),
  autoSearchAuctions: createAction(APP.AUTO_SEARCH_AUCTIONS, 'controls'),
  globalFiltersReset: createAction(GLOBAL_FILTER_RESET),
  viewedGlobalDialog: createAction(APP.VIEWED_GLOBAL_DIALOG),
  // transfers filters queue
  getTransferSocketSuccess: createAction(TransfersTypes.GET_STATE_FROM_API_SUCCESS, 'payload'),
  getTransferSocketFail: createAction(TransfersTypes.GET_STATE_FROM_API_FAIL),
  // socket
  setSocketId: createAction(APP.SET_SOCKET_ID, 'payload'),
  resetSocketId: createAction(APP.RESET_SOCKET_ID),
};

const defaultObjects = {
  areas: {},
  auctionCategories: {},
  auctions: {},
  auctionTypes: {},
  auctionStates: {},
  accountTypes: {},
  banks: {},
  billingData: {},
  communes: {},
  regions: {},
  payments: {},
  webTransactions: {},
  receipts: {},
  contactMessages: {},
  contacts: {},
  documents: {},
  entities: {},
  guaranteeStates: {},
  guarantees: {},
  guaranteesUnification: {},
  paymentsUnification: {},
  lotCategories: {},
  lotDocuments: {},
  lotStates: {},
  lots: {},
  lotsCreated: {},
  lotsId: {},
  locations: {},
  mandates: {},
  mandateBases: {},
  offers: {},
  people: {},
  roles: {},
  sellers: {},
  templates: {},
  templateLines: {},
  users: {},
  units: {},
  areaUnits: {},
  detailLines: {},
  visitForms: {},
  linkedAuctions: {},
  linkedLots: {},
  linkedGuarantees: {},
  linkedSellers: {},
  linkedUsers: {},
  lotWithRights: {},
  adjudications: {},
  lotFiles: {},
  linkedAdjudications: {},
  directSaleContacts: {},
  brands: {},
  brandModels: {},
  systemChanges: {},
  vehicleTypes: {},
  postures: {},
};
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const initialState = {
  contactLotId: null,
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  alertDialog: false,
  alertDialogMsg: '',
  alertDialogType: '',
  waningMsg: '',
  warning: false,
  bgImage: null,
  objects: {
    areas: {},
    auctionCategories: {},
    auctions: {},
    auctionTypes: {},
    auctionStates: {},
    auctionContacts: {},
    lotContacts: {},
    accountTypes: {},
    banks: {},
    billingData: {},
    communes: {},
    regions: {},
    payments: {},
    webTransactions: {},
    receipts: {},
    contactMessages: {},
    contacts: {},
    documents: {},
    entities: {},
    guaranteeStates: {},
    guarantees: {},
    guaranteesUnification: {},
    paymentsUnification: {},
    lotCategories: {},
    lotDocuments: {},
    lotStates: {},
    lots: {},
    lotsCreated: {},
    lotsId: {},
    locations: {},
    mandates: {},
    mandateBases: {},
    offers: {},
    people: {},
    roles: {},
    sellers: {},
    templates: {},
    templateLines: {},
    users: {},
    units: {},
    detailLines: {},
    visitForms: {},
    linkedAuctions: {},
    linkedLots: {},
    linkedGuarantees: {},
    linkedSellers: {},
    linkedUsers: {},
    lotWithRights: {},
    adjudications: {},
    lotFiles: {},
    linkedAdjudications: {},
    areaUnits: {},
    directSaleContacts: {},
    brands: {},
    brandModels: {},
    vehicleTypes: {},
    postures: {},
  },
  formControls: {
    name: '',
    email: '',
    phone: '',
    areaId: 1,
    typePerson: null,
    lastName: '',
    rut: '',
    company: '',
    message: '',
    lotCategoryId: null,
    loading: false,
    typeBusiness: null,
    typeInterest: null,
    typeChannel: [],
    captcha: undefined,
    optIn: false,
  },
  searchControls: {
    areaId: urlParams.get('areaId') || 0,
    searchParaments: urlParams.get('searchParaments') || '',
    limit: 12,
    offset: 0,
    naturallySearching: false,
    loadedPages: {},
    anchorEl: null,
    numLotsQuery: 0,
    redirectingLots: false,
    manualAreaChanged: false,
    page: 0,
    //minRange: 0,
    //maxRange: 0
  },
  auctionControls: {
    edit: false,
    auction: null,
  },
  contactFormOpen: false,
  clients: {},
  areas: [],
  auctionCategories: [],
  lotCategories: [],
  auth: {},
  user: {},
  loaders: {},
  isLoading: false,
  autoComplete: {
    auctions: {},
    searchingAuctions: false,
  },
  controls: {
    propertiesIsLive: false,
    furnituresIsLive: false,
    test: 0,
  },
  viewedGlobalDialog: false,
  layoutStyles: {
    minHeight: '82vh',
    width: '97vw',
  },
  socketId: null,
};

const mergeObjects = (state, action) => {
  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });
  cachedEnvironment.value = null;
  state.objects = newObjects;
};

// const deleteObject = (state, action) => {
//   state.objects[action.payload.type] = normalizeObj(
//     Object.values(state.objects[action.payload.type]).filter(item => item.id !== action.payload.item.id),
//   );
//   cachedEnvironment.value = null;
// };

const deleteSocketObjects = (state, action) => {
  Object.keys(action.payload.response.ids).forEach((key) => {
    state.objects[key] = removeManyKeysByFilter(
      state.objects[key],
      action.payload.response.ids[key],
    );
  });
  cachedEnvironment.value = null;
};

const deleteObjects = (state, action) => {
  if (
    action.payload.deleted === true ||
    action.payload.deleted === undefined ||
    action.payload.deleted === null
  ) {
    Object.keys(action.payload.ids).forEach((key) => {
      state.objects[key] = removeManyKeysByFilter(state.objects[key], action.payload.ids[key]);
    });
    cachedEnvironment.value = null;
  } else {
    mergeObjects(state, action);
  }
};

const mergeDeleteObjects = (state, action) => {
  let newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });
  Object.keys(action.payload.ids).forEach((key) => {
    newObjects[key] = removeManyKeysByFilter(newObjects[key], action.payload.ids[key]);
  });
  state.objects = newObjects;
  cachedEnvironment.value = null;
};

function mergeActions(actions) {
  return actions.reduce((result, action) => ({ ...result, [action]: mergeObjects }), {});
}

const app = createReducer(initialState, {
  ...mergeActions([
    APP.OBJECTS_FROM_SOCKET_RECEIVED,
    CONTACT_MESSAGES.INITIAL_QUERY_SUCCESS,
    USERS.INITIAL_QUERY_SUCCESS,
    USERS.ROLE_ASSIGMENT_SUCCESS,
    USERS.SAVE_SELLER_SUCCESS,
    AUCTION_CATEGORIES.INITIAL_QUERY_SUCCESS,
    AUCTION_CATEGORIES.CREATE_AUCTION_CATEGORY_SUCCESS,
    AUCTION_CATEGORIES.EDIT_AUCTION_CATEGORY_SUCCESS,
    ORDER_LOTS.INITIAL_QUERY_SUCCESS,
    ORDER_LOTS.GET_AUCTION_LOTS_FROM_API_SUCCESS,
    ORDER_LOTS.CREATE_LOTS_WITH_RIGHTS_SUCCESS,
    LOT_CATEGORIES.INITIAL_QUERY_SUCCESS,
    LOT_CATEGORIES.CREATE_LOT_CATEGORY_SUCCESS,
    LOT_CATEGORIES.EDIT_LOT_CATEGORY_SUCCESS,
    AUCTIONS_MANAGER.INITIAL_QUERY_SUCCESS,
    AUCTIONS.INITIAL_QUERY_SUCCESS,
    LOTS_MANAGER.UPLOAD_LOT_DOCUMENT_SUCCESS,
    LOTS_MANAGER.INITIAL_QUERY_SUCCESS,
    LOTS_MANAGER.GET_BASIC_INFO_SUCCESS,
    LOTS.INITIAL_QUERY_SUCCESS,
    LOTS.MASS_UPLOAD_LOTS_SUCCESS,
    GUARANTEE_MANAGER.INITIAL_QUERY_SUCCESS,
    GUARANTEE_MANAGER.CREATE_GUARANTEE_SUCCESS,
    PAYMENTS.INITIAL_QUERY_SUCCESS,
    PAYMENTS_UNIFICATION.INITIAL_QUERY_SUCCESS,
    GUARANTEES.INITIAL_QUERY_SUCCESS,
    GUARANTEES_UNIFICATION.INITIAL_QUERY_SUCCESS,
    LOT_DETAILS.INITIAL_QUERY_SUCCESS,
    LOT_DETAILS.CREATE_VISIT_FORM_SUCCESS,
    WEBPAY_FINAL.INITIAL_QUERY_SUCCESS,
    PAYMENTS.REJECT_RECEIPT_SUCCESS,
    GUARANTEES_UNIFICATION.REJECT_RECEIPT_SUCCESS,
    USER_PROFILE.INITIAL_QUERY_SUCCESS,
    USER_PROFILE.SUBMIT_FORM_SUCCESS,
    // GUARANTEES.INIT_REFUND_SUCCESS,
    // GUARANTEES_UNIFICATION.INIT_REFUND_SUCCESS,
    GUARANTEES.ACKNOWLEDGE_REFUND_SUCCESS,
    GUARANTEES_UNIFICATION.ACKNOWLEDGE_REFUND_SUCCESS,
    PAYMENTS.EDIT_AMOUNT_SUCCESS,
    GUARANTEES_UNIFICATION.EDIT_AMOUNT_SUCCESS,
    PAYMENTS.ACCEPT_RECEIPT_SUCCESS,
    GUARANTEES_UNIFICATION.ACCEPT_RECEIPT_SUCCESS,
    EDIT_USER.INITIAL_QUERY_SUCCESS,
    CONTACT_MESSAGES.ASSIGN_USER_SUCCESS,
    GUARANTEES.CHANGE_AMOUNT_SUCCESS,
    GUARANTEES_UNIFICATION.CHANGE_AMOUNT_SUCCESS,
    GUARANTEES.SET_FLAG_SUCCESS,
    GUARANTEES_UNIFICATION.UNSET_FLAG_SUCCESS,
    GUARANTEES_UNIFICATION.SET_FLAG_SUCCESS,
    SELLERS_MANAGER.INITIAL_QUERY_SUCCESS,
    SELLERS_MANAGER.CREATE_SELLER_SUCCESS,
    LOTS.GET_CLONE_INFO_SUCCESS,
    LOTS_MANAGER.GET_HISTORY_SUCCESS,
    LOTS.GET_INFO_CATALOG_SUCCESS,
    PAYMENTS.RETRY_PAYMENT_SUCCESS,
    GUARANTEES_UNIFICATION.RETRY_PAYMENT_SUCCESS,
    PAYMENTS.UNDO_PAYMENT_SUCCESS,
    GUARANTEES_UNIFICATION.UNDO_PAYMENT_SUCCESS,
    GUARANTEES_UNIFICATION.ENABLE_PAYMENT_SUCCESS,
    GUARANTEES_UNIFICATION.DISABLE_PAYMENT_SUCCESS,
    GUARANTEES_UNIFICATION.REJECT_PAYMENT_SUCCESS,
    FEATURED_LOTS_MANAGER.GET_STATE_FROM_API_SUCCESS,
    FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED_SUCCESS,
    DirectSaleContactsManagerActions.GET_STATE_FROM_API_SUCCESS,
    DirectSaleContactsManagerActions.EDIT_CONTACT_SUCCESS,
    DirectSaleContactsManagerActions.CREATE_CONTACT_SUCCESS,
    DirectSaleModelsManagerActions.GET_STATE_FROM_API_SUCCESS,
    DirectSaleModelsManagerActions.CREATE_MODELS_SUCCESS,
    DirectSaleModelsManagerActions.EDIT_MODELS_SUCCESS,
    BrandsManagerActions.GET_STATE_FROM_API_SUCCESS,
    BrandsManagerActions.CREATE_BRAND_SUCCESS,
    BrandsManagerActions.EDIT_BRAND_SUCCESS,
    DirectSaleFeaturesActions.GET_STATE_FROM_API_SUCCESS,
    DirectSaleFeaturesActions.FILTER_LOTS_SUCCESS,
    DirectSaleActions.GET_STATE_FROM_API_SUCCESS,
    VIEW_REPORT.GET_SATE_FROM_API_SUCCESS,
    NEW_PAYMENT.GET_STATE_FROM_API_SUCCESS,
    USERS.HANDLE_ENABLE_USER_SUCCESS,
    USERS.GET_USER_ADJUDICATIONS_SUCCESS,
    AdjudicationsTypes.GET_STATE_FROM_API_SUCCESS,
    guaranteesActions.unsetFlagSuccess,
    CurrencyManagerActions.GET_STATE_FROM_API_SUCCESS,
    CurrencyManagerActions.CREATE_CURRENCY_SUCCESS,
    CurrencyManagerActions.EDIT_CURRENCY_SUCCESS,
    SystemChangesTypes.GET_STATE_FROM_API_SUCCESS,
    FinishedAuctionsTypes.GET_STATE_FROM_API_SUCCESS,
    TransfersLotDetailsTypes.GET_STATE_FROM_API_SUCCESS,
    RecordCertificateFormTypes.CREATE_CERTIFICATE_SUCCESS,
    RecordCertificateFormTypes.GET_STATE_FROM_API_SUCCESS,
    VehicleTypesManagerTypes.GET_STATE_FROM_API_SUCCESS,
    VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE_SUCCESS,
    VehicleTypesManagerTypes.UPDATE_VEHICLE_TYPE_SUCCESS,
    PosturesManagerTypes.GET_STATE_FROM_API_SUCCESS,
  ]),
  [MANUAL_AREA_CHANGE](state, action) {
    state.searchControls = {
      ...initialState.searchControls,
      manualAreaChanged: true,
      areaId: action.areaId,
    };
    // state.searchControls.manualAreaChanged = true;
    // state.searchControls.areaId = action.areaId;
    // state.searchControls.searchParaments = '';
    state.objects.auctionCategories = {};
    state.objects.lotCategories = {};
  },
  [APP.AUTO_SEARCH_AUCTIONS](state) {
    state.autoComplete.searchingAuctions = true;
  },
  [APP.AUTO_SEARCH_AUCTIONS_SUCCESS](state, action) {
    state.autoComplete.searchingAuctions = false;
    mergeObjects(state, action);
    state.autoComplete = { ...state.autoComplete, ...action.payload.autoComplete };
  },
  [APP.AUTO_SEARCH_AUCTIONS_FAIL](state) {
    state.autoComplete.searchingAuctions = false;
  },
  [CLEAN_SEARCH_QUERY](state, action) {
    state.isLoading = action.state;
    state.searchControls.searchParaments = '';
  },
  //loading
  [SET_LOADING](state, action) {
    state.isLoading = action.state;
  },
  // sockets
  [APP.DELETE_OBJECTS_FROM_SOCKET_RECEIVED]: deleteSocketObjects,
  // success
  [APP.CREATE_CONTACT_MESSAGE_SUCCESS](state, action) {
    mergeObjects(state, action);
    state.contactFormOpen = false;
    state.formControls.name = '';
    state.formControls.email = '';
    state.formControls.phone = '';
    state.formControls.phone = '';
    state.formControls.areaId = null;
    state.formControls.message = '';
    state.formControls.lotCategoryId = null;
    state.formControls.loading = false;
    state.formControls.lastName = '';
    state.formControls.company = '';
    state.formControls.rut = '';
    state.formControls.typeBusiness = null;
    state.formControls.typeInterest = null;
  },
  [APP.INITIAL_QUERY_SUCCESS](state, action) {
    mergeObjects(state, action);
    state.loadingUser = false;
    state.isLoading = false;
    state.user = action.payload.user;
    state.globals = { ...state.globals, ...action.payload.globals };
  },
  [APP.FETCH_BALANCE_SUCCESS](state, action) {
    state.user.balance = action.payload.balance;
    state.user.propertyBalance = action.payload.propertyBalance;
  },
  [APP.MERGE_CONTROLS](state, action) {
    state.controls = { ...state.controls, ...action.payload };
    state.formControls.loading = false;
    state.contactFormOpen = false;
  },
  // users
  [AUCTION_CATEGORIES.DELETE_AUCTION_CATEGORY_SUCCESS]: deleteObjects,
  [SELLERS_MANAGER.DELETE_SELLER_SUCCESS]: deleteObjects,
  // order lots
  // lot categories
  [LOT_CATEGORIES.DELETE_LOT_CATEGORY_SUCCESS]: deleteObjects,
  // auctions
  [AUCTIONS.DELETE_AUCTION_SUCCESS]: deleteObjects,
  // lots manager
  [LOTS_MANAGER.DESTROY_LOT_DOCUMENT_SUCCESS]: deleteObjects,
  // lots
  [LOTS.DELETE_LOT_SUCCESS]: deleteObjects,
  [LOTS.MASS_UPLOAD_LOTS](state) {
    state.objects.lotsCreated = {};
  },
  [APP.SEARCH_QUERY_SUCCESS](state, action) {
    const { loadedPages, numLotsQuery, areaId } = action.payload.others;
    state.searchControls.loadedPages =
      numLotsQuery !== -1 ? loadedPages : { ...state.searchControls.loadedPages, ...loadedPages };
    state.searchControls.numLotsQuery =
      numLotsQuery !== -1 ? numLotsQuery : state.searchControls.numLotsQuery;
    if (areaId?.toString() !== state.searchControls.areaId?.toString())
      state.searchControls.areaId = areaId;
    state.isLoading = false;
    mergeObjects(state, action);
  },
  // others
  [LOTS_MANAGER.EDIT_LOT_SUCCESS]: mergeDeleteObjects,
  [AUCTIONS_MANAGER.EDIT_AUCTION_SUCCESS]: mergeDeleteObjects,
  [DirectSaleContactsManagerActions.DELETE_CONTACT_SUCCESS]: deleteObjects,
  [DirectSaleModelsManagerActions.DELETE_MODELS_SUCCESS]: deleteObjects,
  [BrandsManagerActions.DELETE_BRAND_SUCCESS]: deleteObjects,
  [VehicleTypesManagerTypes.DELETE_VEHICLE_TYPE_SUCCESS]: deleteObjects,
  [LOGIN.LOGIN_USER_FAIL](state, action) {
    state.errorMsg = action.payload.errorMsg;
    state.error = true;
  },
  [MANDATES_LOGIN.LOGIN_USER_FAIL](state, action) {
    state.errorMsg = action.payload.errorMsg;
    state.error = true;
  },
  [TransfersLoginTypes.LOGIN_USER_FAIL](state, action) {
    state.errorMsg = action.payload.errorMsg;
    state.error = true;
  },
  // [REGISTER.REGISTER_FAIL](state, action) {
  //   state.errorMsg = action.payload;
  //   state.error = true;
  // },
  [REGISTER.REGISTER_SUCCESS](state, action) {
    state.successMsg = action.payload.message;
    state.success = true;
  },
  [RESET_PASSWORD.SEND_EMAIL_SUCCESS](state, action) {
    state.successMsg = action.payload.message;
    state.success = true;
  },
  [RESET_PASSWORD.SAVE_PASSWORD_SUCCESS](state, action) {
    state.successMsg = action.payload.message;
    state.success = true;
  },
  [LOGIN.GET_USER_SUCCESS](state, action) {
    if (action.payload == null) {
      state.objects = initialState.objects;
      state.user = initialState.user;
    }
    state.user = action.payload?.user || {};
    state.loadingUser = false;
  },
  [LOGIN.LOGIN_USER_SUCCESS](state, action) {
    state.user = action.payload.user;
    mergeObjects(state, action);
  },
  [MANDATES_LOGIN.LOGIN_USER_SUCCESS](state, action) {
    state.user = action.payload.user;
    mergeObjects(state, action);
  },
  [LOGIN.VERIFY_SUCCESS](state, action) {
    mergeObjects(state, action);
    state.loadingUser = false;
    state.user = action.payload.user;
  },
  [LOGIN.GET_USER](state) {
    state.loadingUser = true;
  },
  [LOGIN.GET_USER_FAIL](state) {
    state.user = {};
    state.loadingUser = false;
  },
  [TransfersLoginTypes.GET_USER_SUCCESS](state, action) {
    if (action.payload == null) {
      state.objects = initialState.objects;
      state.user = initialState.user;
    }
    state.user = action.payload?.user || {};
    state.loadingUser = false;
  },
  [TransfersLoginTypes.LOGIN_USER_SUCCESS](state, action) {
    state.user = action.payload.user;
    mergeObjects(state, action);
  },
  [TransfersLoginTypes.VERIFY_SUCCESS](state, action) {
    mergeObjects(state, action);
    state.loadingUser = false;
    state.user = action.payload.user;
  },
  [TransfersLoginTypes.GET_USER](state) {
    state.loadingUser = true;
  },
  [TransfersLoginTypes.GET_USER_FAIL](state) {
    state.user = {};
    state.loadingUser = false;
  },
  [GUARANTEES_UNIFICATION.ENABLE_PAYMENT_SUCCESS](state, action) {
    mergeObjects(state, action);
  },
  [GUARANTEES_UNIFICATION.DISABLE_PAYMENT_SUCCESS](state, action) {
    mergeObjects(state, action);
  },
  [GUARANTEES_UNIFICATION.REJECT_PAYMENT_SUCCESS](state, action) {
    mergeObjects(state, action);
  },
  [LOG_OUT](state) {
    state.user = {};
    state.objects = { ...defaultObjects };
  },
  [TOGGLE_DRAWER](state) {
    state.drawerIsOpen = !state.drawerIsOpen;
  },
  [TOGGLE_ERROR](state) {
    state.error = false;
    state.errorMsg = !state.errorMsg;
  },
  [SET_ERROR](state, action) {
    state.error = true;
    state.errorMsg = action.msg;
  },
  [TOGGLE_SUCCESS](state) {
    state.success = false;
    state.successMsg = !state.successMsg;
  },
  [SET_SUCCESS](state, action) {
    state.success = true;
    state.successMsg = action.msg;
  },
  [SET_ALERT_DIALOG](state, action) {
    state.alertDialog = true;
    state.alertDialogMsg = action.payload.msg;
    state.alertDialogType = action.payload.typeAlert;
  },
  [CLOSE_ALERT_DIALOG](state, action) {
    state.alertDialog = false;
    state.alertDialogMsg = '';
    state.alertDialogType = '';
  },
  [SET_WARNING](state, action) {
    state.warning = true;
    state.warningMsg = action.msg;
  },
  [SET_GOTO_AUCTION](state, action) {
    state.gotoAuction = true;
    state.gotoAuctionMsg = action.msg;
  },
  [SET_EMAIL_REMINDER](state, action) {
    state.emailReminder = true;
    state.emailReminderMsg = action.msg;
  },
  [SET_GUARANTEE_MODAL](state, action) {
    state.guaranteeModal = true;
    state.guaranteeData = action.payload.data;
  },
  [SET_PAYMENT_MODAL](state, action) {
    state.paymentModal = true;
    state.paymentData = action.payload;
  },
  [TOGGLE_WARNING](state) {
    state.warning = false;
    state.warningMsg = !state.warningMsg;
  },
  [TOGGLE_GOTO_AUCTION](state) {
    state.gotoAuction = false;
    state.gotoAuctionMsg = !state.gotoAuctionMsg;
  },
  [TOGGLE_EMAIL_REMINDER](state) {
    state.emailReminder = false;
    state.emailReminder = !state.emailReminderMsg;
  },
  [TOGGLE_GUARANTEE_MODAL](state) {
    state.guaranteeModal = false;
    state.guaranteeData = !state.guaranteeData;
  },
  [TOGGLE_PAYMENT_MODAL](state) {
    state.paymentModal = false;
    state.paymentData = !state.paymentData;
  },
  [SET_LOADER](state, action) {
    state.loaders[action.id] = action.state;
  },

  [FORM_CONTROLS_CHANGED](state, action) {
    if (!Object.hasOwn(action.controls, 'areaId')) {
      state.formControls = {
        ...state.formControls,
        ...action.controls,
      };
    } else {
      state.formControls = {
        ...state.formControls,
        ...action.controls,

        areaId: action.controls.areaId === true ? 2 : 1,
      };
    }
  },
  [SEARCH_CONTROLS_CHANGED](state, action) {
    state.searchControls = {
      ...state.searchControls,
      ...action.controls,
    };
  },
  [TOGGLE_FORM](state, action) {
    state.contactLotId = null;
    state[action.modal] = !state[action.modal];
    state.formControls = initialState.formControls;
  },
  [APP.CREATE_CONTACT_MESSAGE](state) {
    state.formControls.loading = true;
  },
  [APP.CREATE_CONTACT_MESSAGE_FAIL](state) {
    state.formControls.loading = false;
  },
  [TOGGLE_CONTACT_FORM](state, action) {
    state.contactLotId = action.lotId;
    state['contactFormOpen'] = !state['contactFormOpen'];
    state.formControls = initialState.formControls;
  },
  [RESET_FILTERS](state) {
    state.searchControls = initialState.searchControls;
  },
  [APP.SEARCH_QUERY](state) {
    state.isLoading = true;
  },
  [TOGGLE_PARTICIPATE_MODAL](state) {
    state.participateModal = !state.participateModal;
  },
  [CLEAN_LOTS_CREATED](state) {
    state.objects.lotsCreated = {};
  },
  [SET_GLOBAL_BACKGROUND](state, action) {
    state.bgImage = action.image;
  },
  [APP.VIEWED_GLOBAL_DIALOG](state) {
    state.viewedGlobalDialog = true;
  },
  [APP.UPDATE_LAYOUT_STYLES](state, action) {
    state.layoutStyles = {
      ...state.layoutStyles,
      ...action.payload,
    };
  },
  [APP.SET_SOCKET_ID](state, action) {
    state.socketId = action.payload;
  },
  [APP.RESET_SOCKET_ID](state) {
    state.socketId = null;
  },
});

export default app;
