import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
// Material
import { CardContent, Snackbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// Project
import { composedComponent, getParameterByName, truthty } from 'utils/functions';
import Alert from '@mui/material/Alert';
import { appActions } from 'commons/reducer/commons.reducer';
import saga from './saga/resetPassword.saga';

import { resetPasswordActions } from './reducer/resetPassword.reducer';
import MailForm from './components/MailForm/MailForm';
import NewPasswordForm from './components/NewPasswordForm/NewPasswordForm';
import styles from './styles';

function ResetPassword(props) {
  const fields = {
    email: '',
    password: '',
    repeatPassword: '',
  };
  const [state, setState] = useState(fields);
  const [token, setToken] = useState('');

  useEffect(() => {
    const token = getParameterByName('token', props.location.search);
    setToken(token);
  }, [props.location.search]);

  useEffect(() => {
    if (props.controls.savePasswordSuccess) {
      props.actions.changeControls({ savePasswordSuccess: false });
      return props.history.push('/login');
    }
  }, [props.actions, props.controls.savePasswordSuccess, props.history]);

  function onChangeText(event) {
    if (event.persist) {
      event.persist();
    }
    setState(
      produce((draft) => {
        draft[event.target.id] = event.target.value;
      }),
    );
  }

  function onClickReset() {
    props.actions.sendEmail(state);
  }

  function onClickSave() {
    props.actions.savePassword({ ...props.controls, token });
  }

  function toggleError() {
    props.actions.toggleError();
  }

  function toggleSuccess() {
    props.actions.toggleSuccess();
  }

  return (
    <div style={styles.container}>
      <div style={styles.containerContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ margin: 0, height: '100vh' }}>
          <Grid item md={4} xs={12}>
            <Card>
              <CardContent>
                {truthty(token) ? (
                  <NewPasswordForm
                    controls={props.controls}
                    actions={props.actions}
                    onSubmit={onClickSave}
                    state={state}
                  />
                ) : (
                  <MailForm
                    controls={props.controls}
                    onChangeText={onChangeText}
                    onSubmit={onClickReset}
                    state={state}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {props.errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.success}
        autoHideDuration={3000}
        onClose={toggleSuccess}>
        <Alert onClose={toggleSuccess} severity="success">
          {props.successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withStyles(
  composedComponent(ResetPassword, saga, {
    saga: 'resetPasswordSaga',
    states: [
      'resetPassword.controls',
      'app.errorMsg',
      'app.error',
      'app.successMsg',
      'app.success',
    ],
    actions: [resetPasswordActions, appActions],
  }),
  styles,
);
