const base = '[POSTURES_MANAGER]';

export const PosturesManagerTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_FILTERS: `${base} CHANGE_FILTERS`,
  TOGGLE_POSTURES_DETAILS_DIALOG: `${base} TOGGLE_POSTURES_DETAILS_DIALOG`,
  FILTER_LOTS_BY_NAME: `${base} FILTER_LOTS_BY_NAME`,
  CHANGE_PAGINATION: `${base} CHANGE_PAGINAITON`,
  EXPORT_EXCEL: `${base} EXPORT_EXCEL`,
  EXPORT_EXCEL_SUCCESS: `${base} EXPORT_EXCEL_SUCCESS`,
  EXPORT_EXCEL_FAIL: `${base} EXPORT_EXCEL_FAIL`,
  EXPORT_PDF: `${base} EXPORT_PDF`,
  EXPORT_PDF_SUCCESS: `${base} EXPORT_PDF_SUCCESS`,
  EXPORT_PDF_FAIL: `${base} EXPORT_PDF_FAIL`,
};
