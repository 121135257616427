import { newReducer } from 'utils/reducer/utils.reducer';
import TransfersTypes from './Transfers.types';

const initialState = {
  loaders: {
    principal: false,
    creation: false,
  },
  filters: {
    name: '',
    state: '-1',
    userId: '',
    invoiceNumber: '',
  },
  controls: {
    loading: true,
    openCreateForm: false,
    directSaleForm: false,
    createUserForm: false,
    createDirectSale: false,
    total: 0,
  },
  data: {
    unusedLots: [],
    clients: [],
    transfers: [],
    images: [],
    unusedSaleLots: [],
  },
  creation: {
    ownerUserId: null,
    sellerId: null,
    buyerUserId: null,
    lotId: null,
    email: '',
    password: '',
    taxNumber: '',
    name: '',
    lastName: '',
    patent: '',
  },
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 30, 45, 99],
  },
};

const TransfersReducer = newReducer(initialState, {
  [TransfersTypes.OPEN_CREATE_DIALOG]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      openCreateForm: !state.controls.openCreateForm,
      directSaleForm: false,
      createUserForm: false,
    },
    creation: {
      ...initialState.creation,
    },
  }),
  [TransfersTypes.OPEN_CREATE_DIRECT_SALE_DIALOG]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      openCreateForm: !state.controls.openCreateForm,
      directSaleForm: !state.controls.directSaleForm,
      createDirectSale: false,
    },
    creation: {
      ...initialState.creation,
    },
  }),
  [TransfersTypes.TOGGLE_CREATE_USER]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      createUserForm: !state.controls.createUserForm,
    },
    creation: {
      ...initialState.creation,
    },
  }),
  [TransfersTypes.GET_UNUSED_LOTS]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: true,
    },
  }),
  [TransfersTypes.GET_UNUSED_LOTS_SUCCESS]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: false,
    },
    data: {
      ...state.data,
      unusedLots: action.payload.data.lots,
    },
  }),
  [TransfersTypes.GET_UNUSED_LOTS_SALE_SUCCESS]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: false,
    },
    data: {
      ...state.data,
      unusedSaleLots: action.payload.data.lots,
    },
  }),
  [TransfersTypes.GET_UNUSED_LOTS_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: false,
    },
  }),
  [TransfersTypes.GET_STATE_FROM_API]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      principal: true,
    },
    data: {
      ...initialState.data,
    },
  }),
  [TransfersTypes.GET_STATE_FROM_API_SUCCESS]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      principal: false,
    },
    data: {
      ...state.data,
      clients: action.payload.data.clients,
      transfers: action.payload.data.transfers,
      images: action.payload.data.images,
    },
    controls: {
      ...state.controls,
      total: action.payload.data.total,
    },
  }),
  [TransfersTypes.GET_STATE_FROM_API_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      principal: false,
    },
  }),
  [TransfersTypes.CHANGE_CREATION_CONTROLS]: (state, action) => ({
    ...state,
    creation: {
      ...state.creation,
      [action.payload.control]: action.payload.value,
    },
  }),
  [TransfersTypes.CREATE_TRANSFER]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: true,
    },
  }),
  [TransfersTypes.CREATE_TRANSFER_SUCCESS]: (state, action) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: false,
    },
    controls: {
      ...state.controls,
      openCreateForm: false,
      directSaleForm: false,
    },
    creation: {
      ownerUserId: null,
      buyerUserId: null,
      lotId: null,
    },
    data: {
      ...state.data,
      transfers: [...state.data.transfers, action.payload.data.transfer],
      images: [...state.data.images, action.payload.data.images],
    },
  }),
  [TransfersTypes.CREATE_TRANSFER_FAIL]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: false,
    },
    controls: {
      ...state.controls,
    },
  }),
  [TransfersTypes.CHANGE_FILTERS]: (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      [action.payload.name]: action.payload.value,
    },
  }),
  [TransfersTypes.RESET_FILTERS]: (state) => ({
    ...state,
    filters: {
      ...initialState.filters,
    },
  }),
  [TransfersTypes.CHANGE_PAGINATION]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...action.payload.pagination,
    },
  }),
  [TransfersTypes.CREATE_DIRECT_SALE_USER_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      createUserForm: false,
    },
    creation: {
      ...initialState.creation,
    },
    loaders: {
      ...state.loaders,
      creation: false,
    },
    data: {
      ...state.data,
    },
  }),
  [TransfersTypes.CREATE_DIRECT_SALE_USER]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: true,
    },
  }),
  [TransfersTypes.CREATE_DIRECT_SALE_USER_ERROR]: (state) => ({
    ...state,
    loaders: {
      ...state.loaders,
      creation: false,
    },
  }),
  [TransfersTypes.TOGGLE_CREATE_DIRECT_SALE]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      createDirectSale: !state.controls.createDirectSale,
    },
    creation: {
      ...initialState.creation,
    },
  }),
  [TransfersTypes.DO_NOTHING]: (state) => ({
    ...state,
  }),
});

export default TransfersReducer;
