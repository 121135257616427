const base = '[WORKSHEET_FORM]';

export const WorkSheetFormTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  RESET_FORM: `${base} RESET_FORM`,
  CREATE_WORKSHEET: `${base} CREATE_WORKSHEET`,
  CREATE_WORKSHEET_SUCCESS: `${base} CREATE_WORKSHEET_SUCCESS`,
  CREATE_WORKSHEET_FAIL: `${base} CREATE_WORKSHEET_FAIL`,
};

export default WorkSheetFormTypes;
