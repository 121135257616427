import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'mandatesLogin/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const MANDATES_LOGIN = {
  LOGIN_USER_SUCCESS: `${PATH}FETCH_LOGIN_USER_SUCCESS`,
  LOGIN_USER_FAIL: `${PATH}FETCH_LOGIN_USER_FAIL`,
  LOGIN_USER: `${PATH}FETCH_LOGIN_USER`,
  GET_USER_SUCCESS: `${PATH}GET_USER_SUCCESS`,
  GET_USER_FAIL: `${PATH}GET_USER_FAIL`,
  GET_USER: `${PATH}GET_USER`,
  VERIFY_SUCCESS: `${PATH}VERIFY_SUCCESS`,
  VERIFY_FAIL: `${PATH}VERIFY_FAIL`,
  VERIFY: `${PATH}VERIFY`,
  VERIFY_CAPTCHA: `${PATH}VERIFY_CAPTCHA`,
  VERIFY_CAPTCHA_SUCCESS: `${PATH}VERIFY_CAPTCHA_SUCCESS`,
  VERIFY_CAPTCHA_FAIL: `${PATH}VERIFY_CAPTCHA_FAIL`,
};

// actions
export const mandatesLoginActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  // api
  getUser: createAction(MANDATES_LOGIN.GET_USER),
  login: createAction(MANDATES_LOGIN.LOGIN_USER, 'controls', 'redirectUrl', 'isRol'),
  verify: createAction(MANDATES_LOGIN.VERIFY, 'token'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
};

const initialState = {
  controls: {
    loading: false,
    message: '',
    error: false,
    canLogin: true,
  },
};

const mandatesLogin = createReducer(initialState, {
  [`${DISABLE_ERRORS}`](state) {
    state.error = false;
  },
  // api
  [MANDATES_LOGIN.LOGIN_USER](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [MANDATES_LOGIN.LOGIN_USER_SUCCESS](state) {
    state.controls.loading = false;
  },
  [MANDATES_LOGIN.GET_USER_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = true;
    state.controls.message = action.payload.errorMsg;
  },
  [MANDATES_LOGIN.LOGIN_USER_FAIL](state) {
    state.controls.loading = false;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [MANDATES_LOGIN.VERIFY_CAPTCHA_SUCCESS](state, action) {
    state.controls.canLogin = action.payload.canLogin;
  },
});

export default mandatesLogin;
