const PATH = '[BRANDS_MANAGER]';

export const BrandsManagerActions = {
  TOGGLE_FORM: `${PATH} TOGGLE_FORM`,
  TOGGLE_CREATE_FORM: `${PATH} TOGGLE_CREATE_FORM`,
  TOGGLE_EDIT_FORM: `${PATH} TOGGLE_EDIT_FORM`,
  TOGGLE_DELETE: `${PATH} TOGGLE_DELETE`,
  GET_STATE_FROM_API: `${PATH} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${PATH} CHANGE_CONTROLS`,
  // create
  CREATE_BRAND: `${PATH} CREATE_BRAND`,
  CREATE_BRAND_SUCCESS: `${PATH} CREATE_BRAND_SUCCESS`,
  CREATE_BRAND_FAIL: `${PATH} CREATE_BRAND_FAIL`,
  // update
  EDIT_BRAND: `${PATH} EDIT_BRAND`,
  EDIT_BRAND_SUCCESS: `${PATH} EDIT_BRAND_SUCCESS`,
  EDIT_BRAND_FAIL: `${PATH} EDIT_BRAND_FAIL`,
  // delete
  DELETE_BRAND: `${PATH} DELETE_BRAND`,
  DELETE_BRAND_SUCCESS: `${PATH} DELETE_BRAND_SUCCESS`,
  DELETE_BRAND_FAIL: `${PATH} DELETE_BRAND_FAIL`,
};
