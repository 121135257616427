import { spawn, takeLatest, put } from 'redux-saga/effects';

/* Project */
import { appActions, SET_ERROR } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { get, update, post, destroy } from 'utils/api/api';
import { DirectSaleContactsManagerActions } from '../actions/DirectSaleContactsManager.actions';

function* initialQuery() {
  yield takeLatest(DirectSaleContactsManagerActions.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: DirectSaleContactsManagerActions.GET_STATE_FROM_API_FAIL });

    yield controlledCall(
      get,
      'api/v1/direct_sale_contacts',
      undefined,
      successFunction,
      failFunction,
    );

    yield put(appActions.setLoading(false));
  });
}

function* createContact() {
  yield takeLatest(DirectSaleContactsManagerActions.CREATE_CONTACT, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.CREATE_CONTACT_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.CREATE_CONTACT_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      'api/v1/direct_sale_contacts',
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* updateContact() {
  yield takeLatest(DirectSaleContactsManagerActions.EDIT_CONTACT, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.EDIT_CONTACT_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.EDIT_CONTACT_FAIL,
      payload,
    });

    const result = yield controlledCall(
      update,
      `api/v1/direct_sale_contacts/${body.id}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* deleteContact() {
  yield takeLatest(DirectSaleContactsManagerActions.DELETE_CONTACT, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.DELETE_CONTACT_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: DirectSaleContactsManagerActions.DELETE_CONTACT_FAIL,
      payload,
    });

    const result = yield controlledCall(
      destroy,
      `api/v1/direct_sale_contacts/${body.id}`,
      undefined,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* DirectSaleContactsManagerSaga() {
  yield spawn(initialQuery);
  yield spawn(createContact);
  yield spawn(updateContact);
  yield spawn(deleteContact);
}
