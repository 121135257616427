import camelize from 'camelcase-keys-deep';

export default class Payment {
  _isWeb = null;

  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  guarantee() {
    return this.env().Guarantee.getById(this.guaranteeId);
  }

  user() {
    return this.guarantee()?.user();
  }

  webTransaction() {
    return this.env().WebTransaction.findBy('paymentId', this.id);
  }

  receipt() {
    return this.env().Receipt.findBy('paymentId', this.id);
  }

  isWeb() {
    if (this._isWeb == null) {
      const webTransaction = this.webTransaction();
      this._isWeb = webTransaction != null;
    }
    return this._isWeb;
  }

  canUndo() {
    const guarantee = this.guarantee();
    const validateGuarantee = !guarantee.syncFlag && !guarantee.refundAt;
    const validatePayment =
      this.stateCode === 'COM' ||
      (this.stateCode === 'REC' &&
        guarantee.accountableAmount() + Number(this.amount) <= guarantee.totalAmount);
    return validateGuarantee && validatePayment;
  }

  showUndo() {
    const guarantee = this.guarantee();
    return this.stateCode !== 'PEN' && !this.isWeb() && !guarantee.syncFlag && !guarantee.refundAt;
  }
}
