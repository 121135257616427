import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import GavelIcon from '@mui/icons-material/Gavel';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SortIcon from '@mui/icons-material/Sort';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ApartmentIcon from '@mui/icons-material/Apartment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HistoryIcon from '@mui/icons-material/History';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CommuteIcon from '@mui/icons-material/Commute';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RssFeed from '@mui/icons-material/RssFeed';
import BackupIcon from '@mui/icons-material/Backup';
import BallotIcon from '@mui/icons-material/Ballot';
import AppsIcon from '@mui/icons-material/Apps';

import { basicUrlPagination, Roles } from 'utils/functions';
import { defaultQueries, externalLink } from 'utils/helpers/helper';
import Paths from './paths';

export const MenuTypes = Object.freeze({
  NONE: 0,
  ANONYMOUS: 1,
  PUBLIC: 2,
  PRIVATE: 3,
});

export const Menus = {
  [Paths.LOGIN]: {
    name: 'Iniciar Sesión',
    type: MenuTypes.ANONYMOUS,
    icon: AccountCircleIcon,
    permissionPath: 'login',
    areaID: 'all',
  },
  [Paths.REGISTER]: {
    name: 'Registrarse',
    type: MenuTypes.ANONYMOUS,
    icon: PersonAddIcon,
    permissionPath: 'register',
    areaID: 'all',
  },
  [Paths.PROFILE]: {
    name: 'Mi Perfil',
    type: MenuTypes.PRIVATE,
    icon: AccountCircleIcon,
    permissionPath: 'profile',
    areaID: 'all',
  },
  [Paths.TRANSFERS_LOTS]: {
    name: 'Transferencias Vehículos',
    type: MenuTypes.PRIVATE,
    icon: CommuteIcon,
    permissionPath: 'transfers',
    areaID: 'all',
  },
  [Paths.TRANSFERS_PENDING]: {
    name: 'Transferencias pendientes',
    type: MenuTypes.PRIVATE,
    icon: AssignmentIcon,
    permissionPath: 'transfers',
    areaID: 'all',
  },
  [Paths.BRANDS_MANAGER]: {
    name: 'Mantenedor de Marcas',
    type: MenuTypes.PRIVATE,
    icon: StorefrontIcon,
    permissionPath: 'brandsManager',
    areaID: 1,
  },
  [Paths.MODELS_MANAGER]: {
    name: 'Mantenedor de Modelos',
    type: MenuTypes.PRIVATE,
    icon: LocalMallIcon,
    permissionPath: 'modelsManager',
    areaID: 1,
  },
  [Paths.VEHICLE_TYPES_MANAGER]: {
    name: 'Mantenedor de tipos de vehiculos',
    type: MenuTypes.PRIVATE,
    icon: AirportShuttleIcon,
    permissionPath: 'vehicleTypes',
    areaID: 1,
  },
  [Paths.SELLER_MANAGER]: {
    name: 'Mantenedor de Mandantes',
    type: MenuTypes.PRIVATE,
    icon: BusinessCenterIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'maintainer',
  },
  [Paths.MANDATES_LOGIN]: {
    name: 'Portal Mandantes',
    type: MenuTypes.ANONYMOUS,
    icon: BallotIcon,
    permissionPath: 'mandatesLogin',
    extraText: ' (NUEVO)',
  },
  [Paths.USERS]: {
    name: 'Usuarios',
    type: MenuTypes.PRIVATE,
    icon: GroupIcon,
    auth: Roles.FINANCIAL,
    permissionPath: 'user',
    areaID: 'all',
  },
  [Paths.AUCTIONS + defaultQueries.fortinures.auctionsSidebar]: {
    name: 'Remates Bienes',
    icon: GavelIcon,
    permissionPath: 'auction',
    areaID: 'all',
  },
  [Paths.AUCTIONS + defaultQueries.properties.auctionsSidebar]: {
    name: 'Remates Propiedades',
    icon: GavelIcon,
    permissionPath: 'auction',
    areaID: 'all',
  },
  [`${Paths.LOTS}?areaId=1${basicUrlPagination}`]: {
    name: 'Lotes Bienes',
    icon: DriveEtaIcon,
    permissionPath: 'lot',
    areaID: 'all',
    displayMobile: 'restrict',
  },
  [`${Paths.LOTS}?areaId=2${basicUrlPagination}`]: {
    name: 'Lotes Propiedades',
    icon: ApartmentIcon,
    permissionPath: 'lot',
    areaID: 'all',
  },
  [Paths.SORT_LOTS]: {
    name: 'Orden de Lotes',
    type: MenuTypes.PRIVATE,
    icon: SortIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'orderLot',
    areaID: 'all',
  },
  [Paths.ENTITLED_LOTS]: {
    name: 'Lotes con Derecho',
    type: MenuTypes.PRIVATE,
    icon: GavelIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'orderLot',
    areaID: 'all',
  },
  [Paths.AUCTION_CATEGORIES]: {
    name: 'Categorías de Remates',
    type: MenuTypes.PRIVATE,
    icon: GavelIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'maintainer',
    areaID: 'all',
  },
  [Paths.LOT_CATEGORIES]: {
    name: 'Categorías de Lotes',
    type: MenuTypes.PRIVATE,
    icon: EmojiTransportationIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'maintainer',
  },
  [Paths.ADJUDICATIONS]: {
    name: 'Adjudicaciones',
    type: MenuTypes.PRIVATE,
    icon: AccountBalanceWalletIcon,
    permissionPath: 'adjudications',
  },
  [`${Paths.FINISHED_AUCTIONS.replace(':areaID', '1')}?&page=1&limit=10`]: {
    name: 'Remates bienes finalizados',
    icon: GavelIcon,
    permissionPath: 'auction',
    areaID: 'all',
  },
  [Paths.GUARANTEES_AND_PAYMENTS]: {
    name: 'Administrador de Garantías y Pagos',
    type: MenuTypes.PRIVATE,
    icon: MonetizationOnIcon,
    auth: Roles.FINANCIAL,
    permissionPath: 'guaranteeAndPayment',
    areaID: 'all',
  },
  [Paths.ADJUDICATIONS]: {
    name: 'Adjudicaciones',
    type: MenuTypes.PRIVATE,
    icon: AccountBalanceWalletIcon,
    permissionPath: 'adjudications',
  },
  [Paths.POSTURE_MANAGER]: {
    name: 'Posturas',
    type: MenuTypes.PRIVATE,
    icon: FormatAlignJustifyIcon,
    permissionPath: 'postureManager',
    areaID: 'all',
  },
  // [Paths.INTEGRATION_MANAGER]: {
  //   name: 'Integraciones',
  //   type: MenuTypes.PRIVATE,
  //   icon: ImportExportIcon,
  //   auth: Roles.FINANCIAL,
  //   permissionPath: 'integration',
  //   areaID: 'all',
  // },
  [Paths.GUARANTEES]: {
    name: 'Mis Garantias',
    type: MenuTypes.PRIVATE,
    icon: AttachMoneyIcon,
    permissionPath: 'guarantee',
    areaID: 'all',
  },
  [Paths.PAYMENTS]: {
    name: 'Mis Pagos',
    type: MenuTypes.PRIVATE,
    icon: AttachMoneyIcon,
    permissionPath: 'payment',
    areaID: 'all',
  },
  [Paths.FEATURED_LOTS_MANAGER]: {
    name: 'Destacados Venta Directa',
    type: MenuTypes.PRIVATE,
    icon: BeenhereIcon,
    permissionPath: 'featuredLotsManager',
    areaID: 1,
  },
  [Paths.CONTACTS_MANAGER]: {
    name: 'Contactos Venta Directa',
    type: MenuTypes.PRIVATE,
    icon: ContactPhoneIcon,
    permissionPath: 'contactsManager',
    areaID: 1,
  },
  [Paths.CURRENCY_MANAGER]: {
    name: 'Mantenedor de Monedas',
    type: MenuTypes.PRIVATE,
    icon: LocalAtmIcon,
    permissionPath: 'currencyManager',
    areaID: 'all',
  },
  [Paths.VIEW_REPORT]: {
    name: 'Reporte de visitas',
    type: MenuTypes.PRIVATE,
    icon: VisibilityIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'maintainer',
    areaID: 'all',
  },
  [Paths.SYSTEM_CHANGES]: {
    name: 'Historial de cambios',
    type: MenuTypes.PRIVATE,
    icon: HistoryIcon,
    permissionPath: 'systemChanges',
    areaID: 'all',
  },
  [Paths.FAQ]: {
    name: 'Preguntas Frecuentes',
    type: MenuTypes.PUBLIC,
    icon: HelpOutlineIcon,
    permissionPath: 'faq',
    areaID: 'all',
  },
  [Paths.CONTACT_MESSAGES]: {
    name: 'Mensajes de Contacto',
    type: MenuTypes.PRIVATE,
    icon: EmailIcon,
    auth: Roles.CONTACT,
    permissionPath: 'contactMessage',
    areaID: 'all',
  },
  /* [Paths.INSURANCE_INSTITUTIONS_MANAGER]: {
    name: 'Mantenedor de Institución aseguradora',
    type: MenuTypes.PRIVATE,
    icon: BusinessIcon,
    permissionPath: 'vehicleTypes',
    areaID: 1,
  }, */
};

export const ExternalMenu = (session, link) => ({
  FURNITURES: {
    name: 'Login Externo Bienes',
    Icon: DriveEtaIcon,
    path: `${externalLink(session, link)}&param2=${1}`,
  },
  PROPERTIES: {
    name: 'Login Externo Propiedades',
    Icon: ApartmentIcon,
    path: `${externalLink(session, link)}&param2=${2}`,
  },
});

export const ExternalPublicMenu = () => ({
  /*   BLOG: {
    name: 'Blog Tattersall',
    Icon: RssFeed,
    path: 'https://blog.tattersallgda.cl',
  }, */
});

export const MandatesMenu = {
  [Paths.HOME]: {
    name: 'Ir a Sitio Web',
    type: MenuTypes.PUBLIC,
    icon: AppsIcon,
    permissionPath: 'home',
  },
  [Paths.MANDATES_HOME]: {
    name: 'Inicio',
    type: MenuTypes.PRIVATE,
    icon: HomeIcon,
    auth: Roles.MANAGEMENT,
    permissionPath: 'mandates',
  },
  [Paths.MANDATES_BULK_LOAD]: {
    name: 'Carga Masiva de Liquidaciones',
    type: MenuTypes.PRIVATE,
    icon: BackupIcon,
    permissionPath: 'mandatesBulkLoad',
  },
};
