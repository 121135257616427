import SystemActions from 'utils/roles/SystemActions';

export default class ClientRole extends SystemActions {
  constructor(payload) {
    super();
    this.payload = payload;
    this.home.goToLive = true;

    this.profile.view = true;
    this.profile.update = true;
    this.profile.updatePassword = true;

    this.guarantee.view = true;
    this.guarantee.addPayment = true;

    this.payment.view = true;
    this.payment.newPayment = true;
    this.guaranteeManager.view = true;
    this.guaranteeManager.view = true;
    this.newPayment.view = true;
    this.transfers.view = true;

    this.mandatesLogin.view = true;
  }
}
