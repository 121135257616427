import React from 'react';

/* Material UI */
import {
  Grid,
  Fade,
  Grow,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import { CLOSE_ALERT_DIALOG } from 'commons/reducer/commons.reducer';
import alertDialogStyles from 'commons/components/AlertDialog/alertDialog.styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function AlertDialog() {
  const { alertDialog, alertDialogMsg, alertDialogType } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: CLOSE_ALERT_DIALOG });
  };

  const icon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircleOutlineIcon style={alertDialogStyles.success} />;
      case 'warning':
        return <ReportProblemOutlinedIcon style={alertDialogStyles.warning} />;
      case 'error':
        return <ErrorOutlineIcon style={alertDialogStyles.error} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Dialog
        open={alertDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <Grow in timeout={900}>
            <div style={alertDialogStyles.iconContainer}>{icon(alertDialogType)}</div>
          </Grow>
          {alertDialogMsg && alertDialogMsg !== '' && (
            <Fade in timeout={900}>
              <DialogTitle style={alertDialogStyles.title}>{alertDialogMsg}</DialogTitle>
            </Fade>
          )}
          <Grid item xs={12} style={alertDialogStyles.buttonContainer}>
            <Button onClick={handleClose} variant="contained" color="primary">
              ok
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
