import React from 'react';
import { formatCLP } from 'utils/functions';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import martillo from './Martillo-Fin_3.gif';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function ReceiptCreated(props) {
  const { data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(520));
  const payments = data?.payments;
  if (!payments || payments.length === 0) return null;

  const multi = payments.length > 1;
  const total = payments
    .map((payment) => Number(payment.amount))
    .reduce((acumulator, current) => acumulator + current, 0);

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: 2,
      }}>
      <Typography sx={{ color: '#074b93', fontWeight: '400px', fontSize: isMobile ? 22 : 26 }}>
        {multi ? `Comprobantes` : `Comprobante ${''}`}
        de pago recibido
      </Typography>
      <img src={martillo} width={isMobile ? 150 : 300} alt="martillito" />
      <Typography sx={{ fontSize: isMobile ? 12 : 16, pb: 1 }}>
        Hemos recibido {multi ? 'tus comprobantes' : 'tu comprobante'} de pago de garantía por un
        monto de {formatCLP(Number(total))}
        {multi
          ? ` (órdenes de compra ${payments.map(
              (payment) => ` #${payment.id.toString().padStart(8, '0')}`,
            )})`
          : ` (orden de compra #${payments[0].id.toString().padStart(8, '0')})`}
        . Te enviaremos un mail cuando sea validado. Podrás ver su estado en esta página.
        {/* <br /> */}
      </Typography>
      {data?.isProperty ? (
        <>
          <WarningAmberIcon sx={{ fontSize: 50, color: '#E60E00' }} />
          <Typography
            sx={{ fontSize: isMobile ? 12 : 20, pb: 2, fontWeight: 'bold', color: '#074b93' }}>
            ¡Recuerda que falta parte de la garantía por pagar, realiza tu deposito antes del día
            del remate!
          </Typography>
        </>
      ) : null}
    </Box>
  );
}

export default ReceiptCreated;
