import { getItemInStorage, truthty } from 'utils/functions';

const baseUrl = process.env.REACT_APP_BACKEND_ALB;
// @ts-nocheck
export const apiSuccess = (entity, payload) => ({
  type: entity,
  payload,
});

export const simpleFetch = async (path, options) => {
  const session = getItemInStorage('user');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${session && session.token}`,
  };

  const res = await fetch(`${baseUrl}/api/v1/${path}`, {
    // const res = await fetch(`${window.location.origin}${path}`, {
    ...options,
    headers,
  });

  const jsonBody = await res.json();
  return jsonBody;
};

export default function* apiRequest(
  url,
  options,
  // eslint-disable-next-line default-param-last
  useDefaultHeader = true,
  fileName,
  withoutFormat = false,
  directDownload = true,
) {
  const session = getItemInStorage('user');
  const authorizationHeader = { Authorization: `Bearer ${session && session.token}` };
  const defaultHeaders = { 'Content-Type': 'application/json' };

  const content = options ?? {};
  if (useDefaultHeader) {
    content.headers = { ...content.headers, ...defaultHeaders };
  }
  content.headers = { ...content.headers, ...authorizationHeader };

  return yield fetch(`${baseUrl}/${url}`, content)
    .then((response) => {
      if (response.ok) {
        if (fileName && directDownload) {
          return response.blob().then((blob) => {
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.click();
            return { ok: true };
          });
        }

        return response.json();
      }

      if (response.status === 401) {
        localStorage.clear();
        window.location.replace(window.location.origin);
      } else {
        if (withoutFormat) {
          return response.json().then((res) => ({
            ...res,
            error: true,
          }));
        }

        return response.text().then((errorMsg) => ({
          error: true,
          errorMsg,
        }));
      }

      return response;
    })
    .catch((response) => {
      console.error(response);
    });
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .filter((k) => truthty(obj[k]))
    .map((k) => {
      if (truthty(obj[k])) {
        return Array.isArray(obj[k])
          ? obj[k].map((o) => `${encodeURIComponent(k)}[]=${encodeURIComponent(o)}`).join('&')
          : `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      }
      return '';
    })
    .join('&');
}

export function* post(path, body, withoutFormat = false) {
  const options = {
    method: 'post',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options, true, null, withoutFormat);
}

export function* postForm(path, body) {
  const session = getItemInStorage('user');
  const options = {
    method: 'post',
    body,
    headers: {
      Authorization: `Bearer ${session && session.token}`,
    },
  };

  return yield apiRequest(path, options, false);
}

export function* putForm(path, body) {
  const session = getItemInStorage('user');
  const options = {
    method: 'put',
    body,
    headers: {
      Authorization: `Bearer ${session && session.token}`,
    },
  };

  return yield apiRequest(path, options, false);
}

export function* update(path, body) {
  const options = {
    method: 'put',
    body: JSON.stringify(body),
  };

  return yield apiRequest(path, options);
}

export function* destroy(path, query) {
  const options = {
    method: 'delete',
  };
  return yield apiRequest(`${path}${query ? `?${query}` : ''}`, options);
}

export function* get(path, query) {
  const options = {
    method: 'get',
  };
  return yield apiRequest(`${path}${query ? `?${query}` : ''}`, options);
}

export function getDocument(name, extension = 'xlsx', directDownload = false) {
  return function* (path, query) {
    const options = {
      method: 'get',
    };

    return yield apiRequest(
      `${path}${query ? `?${query}` : ''}`,
      options,
      true,
      `${name ?? 'reporte'}.${extension || 'xlsx'}`,
      false,
      directDownload,
    );
  };
}
