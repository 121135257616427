import camelize from 'camelcase-keys-deep';

export default class Person {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    return this.env().Entity.getById(this.entityId);
  }

  user() {
    return this.env().User.findBy('personId', this.id);
  }

  seller() {
    return this.env().Entity.getById(this.sellerId);
  }

  billingData() {
    return this.env().BillingData.getById(this.billingDataId);
  }

  fullName() {
    const entity = this.entity();
    return `${entity.name} ${this.lastName || ''} ${this.secondLastName || ''}`;
  }
}
