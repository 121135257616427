import React, { useState } from 'react';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller } from 'react-hook-form';

export default function TextFieldForm({
  control,
  defaultValue,
  id,
  label,
  name,
  rules,
  type,
  helperText,
  width,
  disabled,
  format = (v) => v, // Función de formato predeterminada
  unformat = (v) => v, // Función de desformato predeterminada
}) {
  const [showPassword, setShowPassword] = useState(false);

  const determineType = (t) => {
    if (t !== 'password') {
      return t;
    }
    if (showPassword) {
      return 'text';
    }
    return t;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} sx={{ width: width || null }}>
          <TextField
            {...field}
            error={error}
            helperText={error ? error.message : helperText}
            id={id}
            label={label}
            disabled={disabled || null}
            placeholder="Escribe aquí..."
            type={determineType(type)}
            value={format(field.value)}
            variant="standard"
            onChange={(o) => field.onChange(unformat(o.target.value))}
            InputLabelProps={{
              style: { fontSize: 16 }, // Cambia el tamaño del texto del label
            }}
            InputProps={{
              style: { fontSize: 16 }, // Cambia el tamaño del texto del label
              endAdornment:
                type === 'password' ? (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ mr: 0.1 }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                      edge="end">
                      {showPassword ? (
                        <Visibility sx={{ fontSize: 22 }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: 22 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
            sx={{
              backgroundColor: '#fff',
              outline: 'none',
              resize: 'none',
            }}
            FormHelperTextProps={{
              style: { fontSize: 11 }, // Aquí ajustas el tamaño del texto de ayuda/error
            }}
          />
        </FormControl>
      )}
    />
  );
}
