import camelize from 'camelcase-keys-deep';

export default class AccountType {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  billingData() {
    return this.env().BillingData.findAllBy('accountTypeId', this.id);
  }
}
