import camelize from 'camelcase-keys-deep';
import defaultImage from 'utils/images/default-image.png';

export default class Lot {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  lotCategory() {
    return this.env().LotCategory.getById(this.lotCategoryId);
  }

  location() {
    return this.env().Location.getById(this.locationId);
  }

  lotDocuments() {
    return this.env().LotDocument.findAllBy('lotId', this.id);
  }

  lotFiles() {
    return this.env().LotFile.findAllBy('lotId', this.id);
  }

  detailLines() {
    return this.env().DetailLine.findAllBy('lotId', this.id);
  }

  lotContacts() {
    return this.env().LotContact.findAllBy('lotId', this.id);
  }

  unit() {
    return this.env().Unit.getById(this.unitId);
  }

  minimumUnit() {
    return this.env().Unit.getById(this.minimumUnitId);
  }

  areaUnit() {
    return this.env().AreaUnit.getById(this.areaUnitId);
  }

  builtAreaUnit() {
    return this.env().AreaUnit.getById(this.builtAreaUnitId);
  }

  mandate() {
    return this.env().Mandate.getById(this.mandateId);
  }

  auction() {
    return this.mandateBase()?.mandate()?.auction();
  }

  lotState() {
    return this.env().LotState.getById(this.stateId);
  }

  patent() {
    const detailLines = this.detailLines();
    return detailLines?.find((line) => line.label === 'Patente');
  }

  image() {
    const lotDocuments = this.lotDocuments();
    const sortedLots = [...lotDocuments].sort((a, b) => (a.index > b.index ? 0 : -1))[0];
    return this.lotDocuments().length ? sortedLots.document().url : defaultImage;
  }

  images() {
    const lotDocuments = this.lotDocuments();
    return lotDocuments.length > 0
      ? lotDocuments
          .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0))
          .map((lotDocument) => lotDocument.document().url)
      : [defaultImage];
  }

  compressImages() {
    const lotDocuments = this.lotDocuments();
    return lotDocuments.length > 0
      ? lotDocuments
          .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0))
          .map((lotDocument) => lotDocument.document().compressUrl)
      : [defaultImage];
  }

  lotArea() {
    const lotCategory = this.lotCategory();
    const auctionCategory = lotCategory?.auctionCategory();
    return auctionCategory?.areaId;
  }

  isProperty() {
    const lotCategory = this.lotCategory();
    const auctionCategory = lotCategory?.auctionCategory();

    return auctionCategory && auctionCategory.areaId.toString() === '2';
  }

  linked() {
    return this.env().LinkedLot.findBy('currentId', this.id);
  }

  offers() {
    return this.env().Offer.findAllBy('lotId', this.id);
  }

  offer(userId = null) {
    if (userId) {
      return this.env()
        .Offer.all()
        .find((offer) => offer.guarantee().userId === userId && offer.lotId === this.id);
    }

    return this.env().Offer.findBy('lotId', this.id);
  }

  validOffer(userId = null) {
    if (userId) {
      return this.env()
        .Offer.all()
        .find((offer) => {
          const guarantee = offer.guarantee();
          return !guarantee.refundAt && guarantee.userId === userId && offer.lotId === this.id;
        });
    }

    return this.env()
      .Offer.all()
      .find((offer) => {
        const guarantee = offer.guarantee();
        return !guarantee.refundAt && offer.lotId === this.id;
      });
  }

  offerIsComplete(userId = null) {
    if (userId) {
      const offer = this.offer(userId);
      const guarantee = offer?.guarantee();
      return guarantee?.isComplete();
    }

    return false;
  }

  adjudication() {
    return this.env().Adjudication.findBy('lotId', this.id);
  }

  isAdjudicated() {
    return this.adjudication() != null;
  }

  mandateBase() {
    return this.env().MandateBase.getById(this.mandateBaseId);
  }
}
