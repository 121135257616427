import { spawn, takeLatest, put } from 'redux-saga/effects';

/* Project */
import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { get, update } from 'utils/api/api';
import { FEATURED_LOTS_MANAGER } from '../reducer/FeaturedLotsManager.reducer';

function* initialQuery() {
  yield takeLatest(FEATURED_LOTS_MANAGER.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: FEATURED_LOTS_MANAGER.GET_STATE_FROM_API_SUCCESS,
      payload,
    });
    const failFunction = () => ({ type: FEATURED_LOTS_MANAGER.GET_STATE_FROM_API_FAIL });

    yield controlledCall(get, 'api/v1/featured_lots', undefined, successFunction, failFunction);

    yield put(appActions.setLoading(false));
  });
}

function* toggleLotAsFeatured() {
  yield takeLatest(FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED, function* (action) {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED_SUCCESS,
      payload,
    });
    const failFunction = () => ({ type: FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED_FAIL });
    const id = Number(action.id);
    const result = yield controlledCall(
      update,
      `api/v1/featured_lots/${id}`,
      undefined,
      successFunction,
      failFunction,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha modificado exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

export default function* FeaturedLotsSaga() {
  yield spawn(initialQuery);
  yield spawn(toggleLotAsFeatured);
}
