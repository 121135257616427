import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, post } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { RecordCertificateFormTypes } from '../types/RecordCertificateForm.types';

function* getStateFromApi() {
  yield takeLatest(RecordCertificateFormTypes.GET_STATE_FROM_API, function* (action) {
    const successFunction = (payload) => ({
      type: RecordCertificateFormTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: RecordCertificateFormTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/record_certificate/${action.payload.transferId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* createRecordCertificate() {
  yield takeLatest(RecordCertificateFormTypes.CREATE_CERTIFICATE, function* (action) {
    const body = { ...action.payload.data };
    delete body.loading;
    const successFunction = (payload) => ({
      type: RecordCertificateFormTypes.CREATE_CERTIFICATE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: RecordCertificateFormTypes.CREATE_CERTIFICATE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      `api/v1/transfers/record-certificate/${action.payload.transferId}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    } else {
      history.push(`/transfers/lots/${action.payload.transferId}`);
    }
  });
}

function* aproveLimitation() {
  yield takeLatest(RecordCertificateFormTypes.APROVE_LIMITATION, function* (action) {
    const successFunction = (payload) => ({
      type: RecordCertificateFormTypes.APROVE_LIMITATION_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: RecordCertificateFormTypes.APROVE_LIMITATION_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/aprove-limitation/${action.payload.limitationId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* RecordCertificateFormSaga() {
  yield spawn(getStateFromApi);
  yield spawn(createRecordCertificate);
  yield spawn(aproveLimitation);
}
