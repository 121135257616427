import { alpha } from '@mui/material/styles';

const navbarStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  loginContainer: {
    '& .MuiGrid-item': {
      textAlign: 'center',
    },
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'],
  },
  searchBar: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 4,
  },
  itemMargin: {},
  navBar: {
    backgroundColor: '#FFFFFF',

    color: '#415364',
    zIndex: 4,
    [theme.breakpoints.down('lg')]: {
      height: '10vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '10vh',
      minHeight: '90px',
    },
    [theme.breakpoints.down('md')]: {
      height: '10vh',
      minHeight: '70px',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#afafaf', 0.15),
    '&:hover': {
      backgroundColor: alpha('#afafaf', 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  authShortcuts: {
    fontSize: 13,
    fontFamily: 'MyriadSemibold',
    color: '#485261',
  },
  navContainer: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '60px',
    },
  },
});

export default navbarStyles;
