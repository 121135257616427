import { createReducer } from 'utils/reducer/utils.reducer';
import { VehicleTypesManagerTypes } from '../types/VehicleTypesManager.types';

const initialState = {
  controls: {
    loading: false,
    name: '',
    code: '',
    filterName: '',
    active: true,
    openCreate: false,
    anchorEl: null,
    edit: false,
    openDelete: false,
    selectedVehicleType: [],
  },
  data: {
    vehicleTypes: [],
  },
};

const VehicleTypesManagerReducer = createReducer(initialState, {
  /* s */
  [`${VehicleTypesManagerTypes.OPEN_CREATE_DIALOG}`](state, action) {
    state.controls.openCreate = !state.controls.openCreate;
    state.controls.name = '';
    state.controls.code = '';
    state.controls.active = true;
    state.controls.edit = false;
  },
  [`${VehicleTypesManagerTypes.CHANGE_CONTROLS}`](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [`${VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE}`](state, action) {
    state.controls.loading = true;
  },
  [`${VehicleTypesManagerTypes.TOGGLE_EDIT_FORM}`](state, action) {
    state.controls.openCreate = true;
    state.controls.anchorEl = null;
    state.controls.edit = true;
    state.controls.name = state.controls.selectedVehicleType.name;
    state.controls.code = state.controls.selectedVehicleType.code;
    state.controls.active = state.controls.selectedVehicleType.active;
  },
  [`${VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE_SUCCESS}`](state, action) {
    state.controls.loading = false;
    state.controls.openCreate = false;
    state.controls.name = '';
    state.controls.code = '';
    state.controls.active = true;
  },
  [`${VehicleTypesManagerTypes.UPDATE_VEHICLE_TYPE_SUCCESS}`](state, action) {
    state.controls.loading = false;
    state.controls.openCreate = false;
    state.controls.name = '';
    state.controls.code = '';
    state.controls.active = true;
    state.controls.edit = false;
  },
  [`${VehicleTypesManagerTypes.TOGGLE_DELETE}`](state, action) {
    state.controls.openDelete = !state.controls.openDelete;
    state.controls.anchorEl = null;
  },
  [`${VehicleTypesManagerTypes.DELETE_VEHICLE_TYPE_SUCCESS}`](state, action) {
    state.controls.openDelete = false;
    state.controls.anchorEl = null;
    state.controls.selectedVehicleType = [];
  },
  [`${VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE_FAIL}`](state, action) {
    state.controls.loading = false;
    state.controls.openCreate = false;
    state.controls.name = '';
    state.controls.code = '';
    state.controls.active = true;
  },
});

export default VehicleTypesManagerReducer;
