import SystemActions from 'utils/roles/SystemActions';

export default class OperationalRole extends SystemActions {
  constructor(payload) {
    super();
    this.payload = payload;
    this.home.goToLive = true;

    this.profile.view = true;
    this.profile.update = true;
    this.profile.updatePassword = true;

    this.auction.view = true;
    this.auction.filter = true;
    this.auction.create = this.validateArea();
    this.auction.actions = this.validateArea();
    this.auction.massiveCopy = this.validateArea();
    this.auction.massiveCopyToSale = this.validateArea();

    this.auctionManager.view = true;
    this.auctionManager.create = this.validateArea();
    this.auctionManager.update = this.validateArea();

    this.lot.view = true;
    this.lot.filter = true;
    this.lot.massiveUpload = this.validateArea();
    this.lot.create = this.validateArea();
    this.lot.actions = this.validateArea();

    this.lotDetail.view = true;
    this.lotDetail.edit = this.validateArea();

    this.lotManager.view = this.validateArea();
    this.lotManager.history = this.validateArea();
    this.lotManager.update = this.validateArea();

    this.user.view = true;
    this.user.actions = this.validateUserActionArea();

    this.userEdition.view = false;
    this.userEdition.update = false;

    this.orderLot.view = this.validateArea();
    this.orderLot.update = this.validateArea();

    this.integration.view = true;
    this.integration.adjudications = true;
    this.integration.auction = true;
    this.integration.lots = true;
    this.integration.users = false;

    this.maintainer.view = true;
    this.maintainer.create = this.validateArea();
    this.maintainer.update = this.validateArea();
    this.maintainer.delete = false;

    this.payment.view = true;
    this.guarantee.view = true;
    this.guaranteeManager.view = true;
    this.newPayment.view = true;
    this.mandatesLogin.view = true;
  }

  validateArea() {
    return this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString();
  }

  validateUserActionArea() {
    return (
      this.payload?.areaId?.toString() === '' ||
      !this.payload?.areaId ||
      this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString()
    );
  }
}
