import { put, spawn, takeLatest } from 'redux-saga/effects';
import { get, getDocument } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions, SET_ERROR } from 'commons/reducer/commons.reducer';
import { PosturesManagerTypes } from '../types/posturesManager.types';

function* initialQuery() {
  yield takeLatest(PosturesManagerTypes.GET_STATE_FROM_API, function* (action) {
    appActions.setLoading(true);
    yield put(appActions.setLoader('filter-postures', true));
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    const successFunction = (payload) => ({
      type: PosturesManagerTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: PosturesManagerTypes.GET_STATE_FROM_API_FAIL });

    const result = yield controlledCall(
      get,
      `api/v1/postures?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });

    appActions.setLoading(false);
    yield put(appActions.setLoader('filter-postures', false));
  });
}

function* exportExcel() {
  yield takeLatest(PosturesManagerTypes.EXPORT_EXCEL, function* (action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: PosturesManagerTypes.EXPORT_EXCEL_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: PosturesManagerTypes.EXPORT_EXCEL_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('reportePosturas'),
      `api/v1/postures/xls?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* exportPdf() {
  yield takeLatest(PosturesManagerTypes.EXPORT_PDF, function* (action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: PosturesManagerTypes.EXPORT_PDF_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: PosturesManagerTypes.EXPORT_PDF_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('Posturas', 'pdf'),
      `api/v1/postures/pdf?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* PosturesSaga() {
  yield spawn(initialQuery);
  yield spawn(exportExcel);
  yield spawn(exportPdf);
}
