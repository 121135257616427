import { put, spawn, takeLatest } from 'redux-saga/effects';

import { destroy, get, post, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { LOT_CATEGORIES, lotCategoriesActions } from '../reducer/lotCategories.reducer';

function* initialQuery() {
  yield takeLatest(LOT_CATEGORIES.INITIAL_QUERY, function* () {
    yield controlledCall(
      get,
      'api/v1/lot_categories',
      null,
      lotCategoriesActions.initialQuerySuccess,
      lotCategoriesActions.initialQueryFail,
    );
  });
}

function* createLotCategory() {
  yield takeLatest(LOT_CATEGORIES.CREATE_LOT_CATEGORY, function* (action) {
    yield put(appActions.setLoader('create-edit-lot-category', true));

    const body = action.controls;
    const failFunction = () => ({ type: LOT_CATEGORIES.VERIFY_DUPLICATED_CATEGORY_FAIL });
    const result = yield controlledCall(
      post,
      'api/v1/lot_categories',
      body,
      lotCategoriesActions.createLotCategorySuccess,
      failFunction,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha creado la categoría exitosamente'));
      yield put(appActions.setLoader('create-edit-lot-category', false));
      return;
    }
    yield put(appActions.setError('La categoría ya se encuentra registrada'));
    yield put(appActions.setLoader('create-edit-lot-category', false));
  });
}

function* editLotCategory() {
  yield takeLatest(LOT_CATEGORIES.EDIT_LOT_CATEGORY, function* (action) {
    yield put(appActions.setLoader('create-edit-lot-category', true));

    const body = action.controls;
    const result = yield controlledCall(
      update,
      `api/v1/lot_categories/${action.controls.id}`,
      body,
      lotCategoriesActions.editLotCategorySuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha editado la categoría exitosamente'));
    }

    yield put(appActions.setLoader('create-edit-lot-category', false));
  });
}

function* deleteLotCategory() {
  yield takeLatest(LOT_CATEGORIES.DELETE_LOT_CATEGORY, function* (action) {
    yield put(appActions.setLoader('delete-lot-category', true));

    const result = yield controlledCall(
      destroy,
      `api/v1/lot_categories/${action.controls.lotCategory.id}`,
      undefined,
      lotCategoriesActions.deleteLotCategorySuccess,
    );
    if (!result?.error) {
      if (result.deleted === true && result.lotCount === 0) {
        yield put(appActions.setSuccess('Se ha eliminado la categoría exitosamente'));
      }
    }
    yield put(appActions.setLoader('delete-lot-category', false));
  });
}

export default function* lotCategoriesSaga() {
  yield spawn(initialQuery);
  yield spawn(createLotCategory);
  yield spawn(editLotCategory);
  yield spawn(deleteLotCategory);
}
