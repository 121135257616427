import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'lotCategories/';
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_DELETE_LOT_CATEGORY = `${PATH}TOGGLE_DELETE_LOT_CATEGORY`;
const TOGGLE_EDIT_LOT_CATEGORY = `${PATH}TOGGLE_EDIT_LOT_CATEGORY`;
const TOGGLE_CREATE_LOT_CATEGORY = `${PATH}TOGGLE_CREATE_LOT_CATEGORY`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const APPLY_FILTER = `${PATH}APPLY_FILTER`;
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const CLEAN_DELETE_DATA = `${PATH}CLEAN_DELETE_DATA`;
// Global constants for saga
export const LOT_CATEGORIES = {
  // gest state
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  // create
  CREATE_LOT_CATEGORY: `${PATH}CREATE_LOT_CATEGORY`,
  CREATE_LOT_CATEGORY_SUCCESS: `${PATH}CREATE_LOT_CATEGORY_SUCCESS`,
  CREATE_LOT_CATEGORY_FAIL: `${PATH}CREATE_LOT_CATEGORY_FAIL`,
  // delete
  DELETE_LOT_CATEGORY: `${PATH}DELETE_LOT_CATEGORY`,
  DELETE_LOT_CATEGORY_SUCCESS: `${PATH}DELETE_LOT_CATEGORY_SUCCESS`,
  DELETE_LOT_CATEGORY_FAIL: `${PATH}DELETE_LOT_CATEGORY_FAIL`,

  // edit
  EDIT_LOT_CATEGORY: `${PATH}EDIT_LOT_CATEGORY`,
  EDIT_LOT_CATEGORY_SUCCESS: `${PATH}EDIT_LOT_CATEGORY_SUCCESS`,
  EDIT_LOT_CATEGORY_FAIL: `${PATH}EDIT_LOT_CATEGORY_FAIL`,

  // Verify duplicated category
  VERIFY_DUPLICATED_CATEGORY_FAIL: `${PATH}VERIFY_DUPLICATED_CATEGORY_FAIL`,
};

// actions
export const lotCategoriesActions = {
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  toggleEditLotCategory: createAction(TOGGLE_EDIT_LOT_CATEGORY, 'LotCategory'),
  toggleCreateLotCategory: createAction(TOGGLE_CREATE_LOT_CATEGORY),
  toggleDelete: createAction(TOGGLE_DELETE),
  applyFilter: createAction(APPLY_FILTER, 'name', 'auctionCategoryId'),
  cleanDeleteData: createAction(CLEAN_DELETE_DATA),
  // api
  initialQuery: createAction(LOT_CATEGORIES.INITIAL_QUERY),
  createLotCategory: createAction(LOT_CATEGORIES.CREATE_LOT_CATEGORY, 'controls'),
  deleteLotCategory: createAction(LOT_CATEGORIES.DELETE_LOT_CATEGORY, 'controls'),
  editLotCategory: createAction(LOT_CATEGORIES.EDIT_LOT_CATEGORY, 'controls'),
  // success
  initialQuerySuccess: createAction(LOT_CATEGORIES.INITIAL_QUERY_SUCCESS, 'payload'),
  createLotCategorySuccess: createAction(LOT_CATEGORIES.CREATE_LOT_CATEGORY_SUCCESS, 'payload'),
  deleteLotCategorySuccess: createAction(LOT_CATEGORIES.DELETE_LOT_CATEGORY_SUCCESS, 'payload'),
  editLotCategorySuccess: createAction(LOT_CATEGORIES.EDIT_LOT_CATEGORY_SUCCESS, 'payload'),
  // fail
  initialQueryFail: createAction(LOT_CATEGORIES.INITIAL_QUERY_FAIL, 'payload'),
  createLotCategoryFail: createAction(LOT_CATEGORIES.CREATE_LOT_CATEGORY_FAIL, 'payload'),
  deleteLotCategoryFail: createAction(LOT_CATEGORIES.DELETE_LOT_CATEGORY_FAIL, 'payload'),
  editLotCategoryFail: createAction(LOT_CATEGORIES.EDIT_LOT_CATEGORY_FAIL, 'payload'),
};

const defaultInvalidControls = {
  name: false,
};

const initialState = {
  controls: {
    anchorEl: null,
    name: '',
    enabled: true,
    auctionCategoryId: '',
    formIsOpen: false,
    edit: false,
    filtered: false,
    toEditId: '',
    filterName: '',
    filterAuctionCategoryId: '0',
    lotCategory: {},
    lotCount: 0,
  },
  invalidControls: { ...defaultInvalidControls },
  toDeleteId: '',
  error: false,
  deleteIsOpen: false,
};

const auctionCategories = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
  },
  [LOT_CATEGORIES.VERIFY_DUPLICATED_CATEGORY_FAIL](state) {
    state.error = true;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
    state.controls.edit = false;
    state.controls.toEditId = '';
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.auctionCategoryId = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [APPLY_FILTER](state, action) {
    state.controls.filtered = true;
    state.controls.edit = false;
    state.controls.filterAuctionCategoryId = action.auctionCategoryId;
    state.controls.filterName = action.name.trim();
    state.controls.auctionCategoryId = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [TOGGLE_DELETE](state) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.controls.anchorEl = null;
  },
  [CLEAN_DELETE_DATA](state) {
    state.controls.lotCount = 0;
  },
  [TOGGLE_DELETE_LOT_CATEGORY](state) {
    state.toDeleteId = state.controls.lotCategory.id;
    state.controls.anchorEl = null;
  },
  [TOGGLE_EDIT_LOT_CATEGORY](state) {
    state.controls.formIsOpen = true;
    state.toDeleteId = '';
    state.controls.toEditId = state.controls.lotCategory.id;
    state.controls.name = state.controls.lotCategory.name;
    state.controls.enabled = state.controls.lotCategory.enabled;
    state.controls.auctionCategoryId = state.controls.lotCategory.auctionCategoryId;
    state.controls.edit = true;
    state.controls.anchorEl = null;
  },
  [TOGGLE_CREATE_LOT_CATEGORY](state) {
    state.controls.auctionCategoryId = '1';
    state.controls.formIsOpen = true;
    state.controls.edit = false;
  },
  // api
  [LOT_CATEGORIES.CREATE_LOT_CATEGORY_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.auctionCategoryId = '';
    state.controls.lotCategory = {};
    state.invalidControls = { ...defaultInvalidControls };
  },
  [LOT_CATEGORIES.DELETE_LOT_CATEGORY_SUCCESS](state, action) {
    // state.controls = initialState.controls;
    state.controls.anchorEl = null;
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.auctionCategoryId = '';
    state.controls.formIsOpen = false;
    state.controls.edit = false;
    state.controls.filtered = false;
    state.controls.toEditId = '';
    state.controls.filterName = '';
    state.controls.filterAuctionCategoryId = '0';
    state.controls.lotCategory = {};
    state.controls.lotCount = action.payload.lotCount;
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [LOT_CATEGORIES.EDIT_LOT_CATEGORY_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.auctionCategoryId = '';
    state.controls.lotCategory = {};
    state.toDeleteId = '';
    state.controls.toEditId = '';
    state.controls.edit = false;
  },
});

export default auctionCategories;
