/* eslint-disable */
import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'sellersManager/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const SET_EDIT = `${PATH}SET_EDIT`;
const CLEAN_DELETE_DATA = `${PATH}CLEAN_DELETE_DATA`;

// Global constants for saga
export const SELLERS_MANAGER = {
  FILTERS_CHANGED: `${PATH}FILTERS_CHANGED`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
  RESET_PAGINATION: `${PATH}RESET_PAGINATION`,
  GET_SELLERS_SUCCESS: `${PATH}GET_SELLERS_SUCCESS`,
  GET_SELLERS_FAIL: `${PATH}GET_SELLERS_FAIL`,
  GET_SELLERS: `${PATH}GET_SELLERS`,
  CREATE_SELLER_SUCCESS: `${PATH}CREATE_SELLER_SUCCESS`,
  CREATE_SELLER_FAIL: `${PATH}CREATE_SELLER_FAIL`,
  CREATE_SELLER: `${PATH}CREATE_SELLER`,
  EDIT_SELLER_SUCCESS: `${PATH}EDIT_SELLER_SUCCESS`,
  EDIT_SELLER_FAIL: `${PATH}EDIT_SELLER_FAIL`,
  EDIT_SELLER: `${PATH}EDIT_SELLER`,
  DELETE_SELLER_SUCCESS: `${PATH}DELETE_SELLER_SUCCESS`,
  DELETE_SELLER_FAIL: `${PATH}DELETE_SELLER_FAIL`,
  DELETE_SELLER: `${PATH}DELETE_SELLER`,
  TOGGLE_TRANSFORM_USER: `${PATH}TOGGLE_TRANSFORM_USER`,
  TRANSFORM_USER: `${PATH}TRANSFORM_USER`,
  TRANSFORM_USER_SUCCESS: `${PATH}TRANSFORM_USER_SUCCESS`,
  TRANSFORM_USER_FAIL: `${PATH}TRANSFORM_USER_FAIL`,
  SET_ERROR: `${PATH}SET_ERROR`,
  CLEAN_EMAIL_ERRORS: `${PATH}CLEAN_EMAIL_ERRORS`,
  CLEAN_PASSWORD_ERRORS: `${PATH}CLEAN_PASSWORD_ERRORS`,
  RESTORE_PASSWORD: `${PATH}RESTORE_PASSWORD`,
  RESTORE_PASSWORD_SUCCESS_OR_FAIL: `${PATH}RESTORE_PASSWORD_SUCCESS_OR_FAIL`,
};

// actions
export const sellersManagerActions = {
  changePagination: createAction(SELLERS_MANAGER.CHANGE_PAGINATION, 'payload'),
  resetPagination: createAction(SELLERS_MANAGER.RESET_PAGINATION),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  filtersChanged: createAction(SELLERS_MANAGER.FILTERS_CHANGED, 'filter'),
  toggleDelete: createAction(TOGGLE_DELETE, 'sellerID'),
  // get state from api
  getSellersSuccess: createAction(SELLERS_MANAGER.GET_SELLERS_SUCCESS, 'payload'),
  getSellersFail: createAction(SELLERS_MANAGER.GET_SELLERS_FAIL, 'payload'),
  getSellers: createAction(SELLERS_MANAGER.GET_SELLERS, 'payload'),
  // create Seller
  createSellerSuccess: createAction(SELLERS_MANAGER.CREATE_SELLER_SUCCESS, 'payload'),
  createSellerFail: createAction(SELLERS_MANAGER.CREATE_SELLER_FAIL, 'payload'),
  createSeller: createAction(SELLERS_MANAGER.CREATE_SELLER, 'controls'),
  // edit Seller
  editSellerSuccess: createAction(SELLERS_MANAGER.EDIT_SELLER_SUCCESS, 'payload'),
  editSellerFail: createAction(SELLERS_MANAGER.EDIT_SELLER_FAIL, 'payload'),
  editSeller: createAction(SELLERS_MANAGER.EDIT_SELLER, 'controls'),

  setEdit: createAction(SET_EDIT, 'sellerId'),
  cleanDeleteData: createAction(CLEAN_DELETE_DATA),
  deleteSellerSuccess: createAction(SELLERS_MANAGER.DELETE_SELLER_SUCCESS, 'payload'),
  deleteSellerFail: createAction(SELLERS_MANAGER.DELETE_SELLER_FAIL, 'payload'),
  deleteSeller: createAction(SELLERS_MANAGER.DELETE_SELLER, 'controls'),
  toggleTransformUser: createAction(SELLERS_MANAGER.TOGGLE_TRANSFORM_USER, 'sellerId'),
  transformUser: createAction(SELLERS_MANAGER.TRANSFORM_USER, 'controls'),
  transformUserSuccess: createAction(SELLERS_MANAGER.TRANSFORM_USER_SUCCESS, 'payload'),
  transformUserFail: createAction(SELLERS_MANAGER.TRANSFORM_USER_FAIL, 'payload'),
  setError: createAction(SELLERS_MANAGER.SET_ERROR, 'key', 'message'),
  cleanEmailErrors: createAction(SELLERS_MANAGER.CLEAN_EMAIL_ERRORS),
  cleanPasswordErrors: createAction(SELLERS_MANAGER.CLEAN_PASSWORD_ERRORS),
  restorePassword: createAction(SELLERS_MANAGER.RESTORE_PASSWORD, 'email'),
  restorePasswordSuccessOrFail: createAction(SELLERS_MANAGER.RESTORE_PASSWORD_SUCCESS_OR_FAIL),
  // errors
};
const defaultControls = {
  name: '',
  taxNumber: '',
  enabled: true,
  taxNumberValid: false,
  taxNumberUnique: true,
  sellerId: null,
  isLoading: false,
  loadingPage: false,
  sellerFormOpen: false,
  seller: {},
  entity: {},
  mandates: 0,
  associatedMandates: false,
  mandateRemoved: false,
  deleteIsOpen: false,
  email: '',
  confirmationEmail: '',
  password: '',
  openTransformUser: false,
  emailErrors: [],
  passwordErrors: [],
  isNaturalPerson: false,
};

const initialState = {
  sellersList: [],
  controls: defaultControls,
  filters: {
    generalFilter: null,
  },
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
  },
  totalPages: 0,
};

const sellersManager = createReducer(initialState, {
  [SELLERS_MANAGER.CHANGE_PAGINATION](state, action) {
    state.pagination.page = action.payload.page;
    state.pagination.offset = action.payload.offset;
  },
  [SELLERS_MANAGER.RESET_PAGINATION](state) {
    state.pagination = initialState.pagination;
  },
  [TOGGLE_FORM](state, action) {
    state.controls[action.modal] = !state.controls[action.modal];
    state.controls.seller = state.controls.sellerFormOpen ? state.controls.seller : {};
    if (!state.controls.sellerFormOpen) {
      state.controls.taxNumber = '';
      state.controls.sellerId = null;
      state.controls.name = '';
      state.controls.enabled = true;
      state.controls.email = '';
      state.controls.confirmationEmail = '';
    }
  },
  [TOGGLE_DELETE](state, action) {
    state.controls.deleteIsOpen = !state.controls.deleteIsOpen;
    state.controls.anchorEl = null;
    state.controls.sellerId = action.sellerID;
    state.controls.seller = {};
    state.controls.entity = {};
  },
  [SET_EDIT](state, action) {
    state.controls.anchorEl = null;
    state.controls.sellerFormOpen = !state.controls.sellerFormOpen;
    state.controls.name = state.controls.entity.name;
    state.controls.enabled = state.controls.seller.enabled;
    state.controls.taxNumber = state.controls.entity.taxNumber;
    state.controls.sellerId = action.sellerId;
    state.controls.isNaturalPerson = state.controls.seller.isNatural;
    state.controls.email = state.controls?.seller?.User?.email;
    state.controls.confirmationEmail = state.controls?.seller?.User?.email;
  },
  [CLEAN_DELETE_DATA](state) {
    state.controls.associatedMandates = false;
    state.controls.mandateRemoved = false;
    state.controls.mandates = 0;
  },
  [SELLERS_MANAGER.FILTERS_CHANGED](state, action) {
    state.filters[action.filter.name] = action.filter.value
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [SELLERS_MANAGER.EDIT_SELLER](state) {
    state.controls.isLoading = true;
  },
  [SELLERS_MANAGER.EDIT_SELLER_SUCCESS](state) {
    state.controls.isLoading = false;
  },
  [SELLERS_MANAGER.EDIT_SELLER_FAIL](state, action) {
    state.controls.isLoading = false;
  },
  [SELLERS_MANAGER.CREATE_SELLER](state) {
    state.controls.isLoading = true;
  },
  [SELLERS_MANAGER.CREATE_SELLER_SUCCESS](state, action) {
    state.sellersList = [...state.sellersList, action.payload];
    state.controls.isLoading = false;
    state.controls.sellerFormOpen = false;
    state.controls.name = '';
    state.controls.taxNumber = '';
    state.controls.enabled = true;
    state.controls.isNaturalPerson = false;
  },
  [SELLERS_MANAGER.CREATE_SELLER_FAIL](state, action) {
    state.controls.isLoading = false;
  },
  [SELLERS_MANAGER.GET_SELLERS](state) {
    state.controls.isLoading = true;
  },
  [SELLERS_MANAGER.GET_SELLERS_SUCCESS](state, action) {
    state.sellersList = action.payload.sellers;
    state.totalPages = action.payload.totalPages;
    state.controls.isLoading = false;
  },
  [SELLERS_MANAGER.GET_SELLERS_FAIL](state, action) {
    state.controls.isLoading = false;
  },
  [SELLERS_MANAGER.DELETE_SELLER_SUCCESS](state, action) {
    state.controls.isLoading = false;
    state.controls.mandates = action.payload.mandates;
    state.controls.associatedMandates = action.payload.associatedMandates;
    state.controls.sellerId = null;
    state.controls.mandateRemoved = action.payload.mandateRemoved;
    state.controls.deleteIsOpen = false;
  },
  [SELLERS_MANAGER.DELETE_SELLER](state) {
    state.controls.isLoading = true;
    state.controls.anchorEl = null;
  },
  [SELLERS_MANAGER.TOGGLE_TRANSFORM_USER](state, action) {
    state.controls.openTransformUser = !state.controls.openTransformUser;
    state.controls.isLoading = true;
    state.controls.anchorEl = null;
    state.controls.email = '';
    state.controls.password = '';
    state.controls.sellerId = action.sellerId;
  },
  [SELLERS_MANAGER.TRANSFORM_USER_SUCCESS](state, action) {
    state.controls.openTransformUser = !state.controls.openTransformUser;
    state.controls.isLoading = false;
    state.controls.anchorEl = null;
    state.controls.email = '';
    state.controls.password = '';
    state.controls.sellerId = '';
    state.controls.emailErrors = [];
    state.controls.passwordErrors = [];
  },
  [SELLERS_MANAGER.TRANSFORM_USER_FAIL](state, action) {
    state.controls.openTransformUser = !state.controls.openTransformUser;
    state.controls.isLoading = false;
    state.controls.anchorEl = null;
    state.controls.email = '';
    state.controls.password = '';
    state.controls.sellerId = '';
    state.controls.emailErrors = [];
    state.controls.passwordErrors = [];
  },
  [SELLERS_MANAGER.SET_ERROR](state, action) {
    state.controls[action.key] = [...state.controls[action.key], action.message];
  },
  [SELLERS_MANAGER.CLEAN_EMAIL_ERRORS](state, action) {
    state.controls.emailErrors = [];
  },
  [SELLERS_MANAGER.CLEAN_PASSWORD_ERRORS](state, action) {
    state.controls.passwordErrors = [];
  },
  [SELLERS_MANAGER.RESTORE_PASSWORD_SUCCESS_OR_FAIL](state, action) {
    state.controls.isLoading = false;
    state.controls.anchorEl = null;
  },
});

export default sellersManager;
