const alertDialogStyles = {
  success: {
    fontSize: 110,
    color: '#4caf50',
    paddingTop: '5px',
  },
  warning: {
    fontSize: 110,
    color: '#ff9800',
    paddingTop: '5px',
  },
  error: {
    fontSize: 110,
    color: '#f50057',
    paddingTop: '5px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    padding: 0,
    marginTop: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    padding: '10px',
  },
};

export default alertDialogStyles;
