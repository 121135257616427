import { createReducer, createAction } from 'utils/reducer/utils.reducer';
import { DirectSaleContactsManagerActions } from '../actions/DirectSaleContactsManager.actions';

export const contactManagerActions = {
  toggleForm: createAction(DirectSaleContactsManagerActions.TOGGLE_FORM, 'modal'),
  toggleCreateForm: createAction(DirectSaleContactsManagerActions.TOGGLE_CREATE_FORM),
  toggleEditForm: createAction(
    DirectSaleContactsManagerActions.TOGGLE_CREATE_FORM,
    'selectedContact',
  ),
  createContact: createAction(DirectSaleContactsManagerActions.CREATE_CONTACT, 'controls'),
  editContact: createAction(DirectSaleContactsManagerActions.EDIT_CONTACT, 'controls'),
};

const defaultInvalidControls = {
  name: false,
  contact: false,
  typeId: false,
};

const initialState = {
  controls: {
    name: '',
    contact: '',
    typeId: '-1',
    filterCategory: '',
    filterName: '',
    filterContact: '',
    edit: false,
    formIsOpen: false,
    toEditId: '',
    anchorEl: null,
    selectedContact: {},
    loading: false,
  },
  invalidControls: { ...defaultInvalidControls },
  toDeleteId: '',
  error: false,
  deleteIsOpen: false,
};

const directSaleContactsManagerReducer = createReducer(initialState, {
  [DirectSaleContactsManagerActions.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [DirectSaleContactsManagerActions.GET_STATE_FROM_API_SUCCESS](state) {
    state.controls.loading = false;
  },
  [DirectSaleContactsManagerActions.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [DirectSaleContactsManagerActions.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [DirectSaleContactsManagerActions.TOGGLE_CREATE_FORM](state) {
    state.controls.formIsOpen = true;
    state.controls.edit = false;
  },
  [DirectSaleContactsManagerActions.TOGGLE_EDIT_FORM](state) {
    state.controls.formIsOpen = true;
    state.toDeleteId = '';
    state.controls.toEditId = state.controls.selectedContact.id;
    state.controls.name = state.controls.selectedContact.name;
    state.controls.contact = state.controls.selectedContact.contact;
    state.controls.typeId = state.controls.selectedContact.type;
    state.controls.edit = true;
    state.controls.anchorEl = null;
  },
  [DirectSaleContactsManagerActions.TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
    state.controls.edit = false;
    state.controls.toEditId = '';
    state.controls.name = '';
    state.controls.contact = '';
    state.controls.typeId = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [DirectSaleContactsManagerActions.TOGGLE_DELETE](state) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.controls.anchorEl = null;
  },
  [DirectSaleContactsManagerActions.CREATE_CONTACT](state) {
    state.controls.loading = true;
  },
  [DirectSaleContactsManagerActions.DELETE_CONTACT](state) {
    state.controls.loading = true;
  },
  [DirectSaleContactsManagerActions.EDIT_CONTACT](state) {
    state.controls.loading = true;
  },
  [DirectSaleContactsManagerActions.CREATE_CONTACT_FAIL](state) {
    state.controls.loading = false;
  },
  [DirectSaleContactsManagerActions.DELETE_CONTACT_FAIL](state) {
    state.controls.loading = false;
  },
  [DirectSaleContactsManagerActions.EDIT_CONTACT_FAIL](state) {
    state.controls.loading = false;
  },
  [DirectSaleContactsManagerActions.EDIT_CONTACT_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.loading = false;
  },
  [DirectSaleContactsManagerActions.CREATE_CONTACT_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.loading = false;
    state.controls.name = '';
    state.controls.contact = '';
    state.controls.typeId = '-1';
  },
  [DirectSaleContactsManagerActions.DELETE_CONTACT_SUCCESS](state) {
    state.deleteIsOpen = false;
    state.controls.loading = false;
  },
});

export default directSaleContactsManagerReducer;
