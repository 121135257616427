import { createReducer } from 'utils/reducer/utils.reducer';
import { PosturesManagerTypes } from '../types/posturesManager.types';

const initialState = {
  filters: {
    auctionId: null,
    userId: null,
    postureDate: null,
  },
  controls: {
    loading: false,
    postureIds: [],
    lotIds: [],
    filteredLotIds: [],
    auctionIds: [],
    total: 0,
    selectedLotId: null,
    displayPostureDetail: false,
  },
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 30, 45, 99],
  },
};

const posturesManagerReducer = createReducer(initialState, {
  [PosturesManagerTypes.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [PosturesManagerTypes.CHANGE_FILTERS](state, action) {
    state.filters[action.payload.name] = action.payload.value;
  },
  [PosturesManagerTypes.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.postureIds = action.payload.postureIds;
    state.controls.lotIds = action.payload.lotIds;
    state.controls.userIds = action.payload.userIds;
    state.controls.auctionIds = action.payload.auctionIds;
    state.controls.total = action.payload.total;
  },
  [PosturesManagerTypes.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [PosturesManagerTypes.TOGGLE_POSTURES_DETAILS_DIALOG](state, action) {
    state.controls.selectedLotId = action.payload.id;
    state.controls.displayPostureDetail = !state.controls.displayPostureDetail;
  },
  [PosturesManagerTypes.FILTER_LOTS_BY_NAME](state, action) {
    state.controls.filteredLotIds = action.payload.filteredLots;
  },
  [PosturesManagerTypes.CHANGE_PAGINATION](state, action) {
    state.pagination = {
      ...state.pagination,
      ...action.payload.pagination,
    };
  },
});

export default posturesManagerReducer;
