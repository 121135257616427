import React from 'react';

export function SubmitForm({ children, onSubmit = null, ...rest }) {
  function handleSubmit(event) {
    event.preventDefault();
    if (onSubmit) onSubmit();
  }

  return (
    <form onSubmit={handleSubmit} {...rest}>
      {' '}
      {children}{' '}
    </form>
  );
}
