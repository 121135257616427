import { createAction, createReducer } from 'utils/reducer/utils.reducer';

const PATH = 'newPayment/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const RESET = `${PATH}RESET`;
const TOGGLE_INFO_MODAL = `${PATH}TOGGLE_INFO_MODAL`;

export const NEW_PAYMENT = {
  // gest state
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  DO_PAYMENT: `${PATH}DO_PAYMENT`,
  DO_PAYMENT_SUCCESS: `${PATH}DO_PAYMENT_SUCCESS`,
  DO_PAYMENT_FAIL: `${PATH}DO_PAYMENT_FAIL`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}GET_STATE_FROM_API_FAIL`,
};

// actions
export const newPaymentActions = {
  reset: createAction(RESET),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  toggleInfoModal: createAction(TOGGLE_INFO_MODAL),
  // api
  initialQuery: createAction(NEW_PAYMENT.INITIAL_QUERY, 'controls'),
  getStateFromApi: createAction(NEW_PAYMENT.GET_STATE_FROM_API, 'controls'),
  initialQuerySuccess: createAction(NEW_PAYMENT.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(NEW_PAYMENT.INITIAL_QUERY_FAIL, 'payload'),
  doPayment: createAction(
    NEW_PAYMENT.DO_PAYMENT,
    'controls',
    'guaranteeId',
    'biddingEmail',
    'isProperty',
    'isBidding',
  ),
  doPaymentSuccess: createAction(NEW_PAYMENT.DO_PAYMENT_SUCCESS, 'payload'),
  doPaymentFail: createAction(NEW_PAYMENT.DO_PAYMENT_FAIL, 'payload'),
};

const initialState = {
  controls: {
    guaranteeId: null,
    paymentMode: 'bank',
    availablePaymentModes: [],
    amount: 0,
    receipts: [],
    infoModal: false,
    loading: true,
    easyPayUrl: null,
    idTrx: 0,
  },
};

const newPayment = createReducer(initialState, {
  [RESET](state) {
    state.controls = { ...initialState.controls };
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = { ...state.controls, ...action.controls };
    state.invalidControls = { ...state.invalidControls, ...(action.invalidControls || {}) };
  },
  [NEW_PAYMENT.DO_PAYMENT_SUCCESS](state, action) {
    state.controls.easyPayUrl = action.payload.url;
    state.controls.idTrx = action.payload.idTrx;
  },
  [TOGGLE_INFO_MODAL](state) {
    state.controls.infoModal = !state.controls.infoModal;
  },
  [NEW_PAYMENT.INITIAL_QUERY](state) {
    state.controls.loading = true;
  },
  [NEW_PAYMENT.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [NEW_PAYMENT.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.availablePaymentModes = action.payload.objects.auction.PaymentMethods;
  },
  [NEW_PAYMENT.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
});

export default newPayment;
