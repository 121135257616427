import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'bulkLoad/';

export const BULK_LOAD = {
  REQUEST_FAILED: `${PATH}REQUEST_FAILED`,
  GET_PRESIGNED_URL: `${PATH}GET_PRESIGNED_URL`,
  CHANGE_MODAL: `${PATH}CHANGE_MODAL`,
  UPLOAD_FILES: `${PATH}UPLOAD_FILES`,
  DELETE_LAST_BULK_UPLOAD: `${PATH}DELETE_LAST_BULK_UPLOAD`,
  SET_URLS: `${PATH}SET_URLS`,
  SET_LOT_DOCUMENTS: `${PATH}SET_LOT_DOCUMENTS`,
  SAVE_RECORDS: `${PATH}SAVE_RECORDS`,
  CONTROLS_CHANGED: `${PATH}CONTROLS_CHANGED`,
};
// actions
export const bulkLoadActions = {
  changeModal: createAction(BULK_LOAD.CHANGE_MODAL, 'payload'),
  setPresignedUrls: createAction(BULK_LOAD.SET_URLS, 'presignedUrls'),
  setLotDocuments: createAction(BULK_LOAD.SET_LOT_DOCUMENTS, 'lotDocuments'),
  uploadFiles: createAction(BULK_LOAD.UPLOAD_FILES, 'files'),
  deleteLastBulkLoad: createAction(BULK_LOAD.DELETE_LAST_BULK_UPLOAD),
  saveRecord: createAction(BULK_LOAD.SAVE_RECORDS, 'records'),
  controlsChanged: createAction(BULK_LOAD.CONTROLS_CHANGED, 'controls'),
};

const initialState = {
  images: [],
  loading: false,
  error: false,
  openModal: false,
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
  },
  presignedUrls: [],
  records: [],
  remainingLotsToCertificate: [],
  controls: {
    isOpenModal: false,
    importedFile: null,
    uploadingFiles: false,
  },
};

const bulkLoad = createReducer(initialState, {
  [BULK_LOAD.CHANGE_MODAL](state, action) {
    state.openModal = action.payload;
  },
  [BULK_LOAD.SET_URLS](state, action) {
    console.log({ action });
    state.presignedUrls = action.presignedUrls;
  },
  [BULK_LOAD.SAVE_RECORDS](state, action) {
    state.records = action.records.fileResponses;
    state.remainingLotsToCertificate = action.records.pendingLots;
  },
  [BULK_LOAD.CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
});

export default bulkLoad;
