export default class SystemActions {
  constructor() {
    this.login = {
      view: false,
      update: false,
    };

    this.faq = {
      view: true,
    };

    this.register = {
      view: false,
      update: false,
    };

    this.home = {
      view: true,
      goToLive: false,
    };

    this.profile = {
      view: false,
      update: false,
      updatePassword: false,
    };

    this.contactMessage = {
      view: false,
      markAsRead: false,
      actions: false,
    };

    this.auction = {
      view: true,
      massiveCopy: false,
      create: false,
      filter: false,
      actions: false,
      massiveCopyToSale: false,
    };

    this.auctionManager = {
      view: false,
      create: false,
      update: false,
    };

    this.lot = {
      view: true,
      filter: false,
      massiveUpload: false,
      create: false,
      actions: false,
    };

    this.lotDetail = {
      view: true,
      edit: false,
    };

    this.lotManager = {
      view: false,
      history: false,
      update: false,
    };

    this.user = {
      view: false,
      actions: false,
    };

    this.userEdition = {
      view: false,
      update: false,
    };

    this.orderLot = {
      view: false,
      update: false,
    };

    this.guarantee = {
      view: false,
      update: false,
      report: false,
      massiveRefund: false,
      addPayment: false,
    };

    this.guaranteeAndPayment = {
      view: false,
      update: false,
      report: false,
      massiveRefund: false,
      addPayment: false,
    };

    this.payment = {
      view: false,
      update: false,
      newPayment: false,
    };

    this.newPayment = {
      view: false,
    };

    this.integration = {
      view: false,
      adjudications: false,
      guarantees: false,
      auction: false,
      lots: false,
      users: false,
      clear: false,
    };

    this.maintainer = {
      view: false,
      update: false,
      delete: false,
    };

    this.guaranteeManager = {
      view: false,
    };

    this.external = {
      view: true,
    };

    this.webpay = {
      view: true,
    };

    this.viewReport = {
      view: false,
      reports: false,
    };

    this.featuredLotsManager = {
      view: false,
    };

    this.contactsManager = {
      view: false,
    };

    this.brandsManager = {
      view: false,
    };

    this.modelsManager = {
      view: false,
    };

    this.adjudications = {
      view: false,
    };

    this.currencyManager = {
      view: false,
    };

    this.systemChanges = {
      view: false,
    };

    this.transfers = {
      view: false,
      update: false,
    };

    this.transferLots = {
      view: false,
      update: false,
      updatePassword: false,
    };

    this.vehicleTypes = {
      view: false,
    };

    this.insuranceInstitution = {
      view: false,
    };

    this.postureManager = {
      view: false,
    };
    this.mandates = {
      view: false,
      update: false,
    };
    this.mandatesLogin = {
      view: true,
    };
    this.mandatesBulkLoad = {
      view: false,
    };
  }
}
