import camelize from 'camelcase-keys-deep';

export default class Bank {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  billingData() {
    return this.env().BillingData.findAllBy('bankId', this.id);
  }
}
