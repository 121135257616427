const base = '[TRANSFER_MANAGER_LOTS]';

const TransfersTypes = {
  OPEN_CREATE_DIALOG: `${base} OPEN_CREATE_DIALOG`,
  OPEN_CREATE_DIRECT_SALE_DIALOG: `${base} OPEN_CREATE_DIRECT_SALE_DIALOG`,
  TOGGLE_CREATE_USER: `${base} TOGGLE_CREATE_USER`,
  CHANGE_CREATION_USER_CONTROLS: `${base} CHANGE_CREATION_USER_CONTROLS`,
  CREATE_DIRECT_SALE_USER: `${base} CREATE_DIRECT_SALE_USER`,
  CREATE_DIRECT_SALE_USER_SUCCESS: `${base} CREATE_DIRECT_SALE_USER_SUCCESS`,
  CREATE_DIRECT_SALE_USER_ERROR: `${base} CREATE_DIRECT_SALE_USER_ERROR`,
  GET_UNUSED_LOTS: `${base} GET_UNUSED_LOTS`,
  GET_UNUSED_LOTS_SUCCESS: `${base} GET_UNUSED_LOTS_SUCCESS`,
  GET_UNUSED_LOTS_FAIL: `${base} GET_UNUSED_LOTS_FAIL`,
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CREATION_CONTROLS: `${base} CHANGE_CREATION_CONTROLS`,
  CREATE_TRANSFER: `${base} CREATE_TRANSFER`,
  CREATE_TRANSFER_SUCCESS: `${base} CREATE_TRANSFER_SUCCESS`,
  CREATE_TRANSFER_FAIL: `${base} CREATE_TRANSFER_FAIL`,
  CHANGE_FILTERS: `${base} CHANGE_FILTERS`,
  RESET_FILTERS: `${base} RESET_FILTERS`,
  CHANGE_PAGINATION: `${base} CHANGE_PAGINATION`,
  GET_UNUSED_LOTS_SALE: `${base} GET_UNUSED_LOTS_SALE`,
  GET_UNUSED_LOTS_SALE_SUCCESS: `${base} GET_UNUSED_LOTS_SALE_SUCCESS`,
  GET_UNUSED_LOTS_SALE_FAIL: `${base} GET_UNUSED_LOTS_SALE_FAIL`,
  TOGGLE_CREATE_DIRECT_SALE: `${base} TOGGLE_CREATE_DIRECT_SALE`,
  DO_NOTHING: `${base} DO_NOTHING`,
};

export default TransfersTypes;
