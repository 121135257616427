import camelize from 'camelcase-keys-deep';

export default class TemplateLine {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  template() {
    return this.env().Template.getById(this.templateId);
  }

  auctionCategory() {
    return this.template().auctionCategory();
  }

  options() {
    return this.env().TemplateLineOption.findAllBy('templateLineId', this.id);
  }
}
