const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  contactButton: {
    zIndex: '9999999',
    position: 'fixed',
    top: '250px',
    right: '-42px',
    transform: 'rotate(-90deg)',
    '@media print': {
      display: 'none',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    width: '40vw',
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: '80%',
    margin: 4,
  },
  lateralButtons: {
    '@media print': {
      display: 'none',
    },
    // zIndex: '9999999',
    // position: 'fixed',
    // top: '40vh',
    // right: '-41px',
    transform: 'rotate(-90deg)',
    fontFamily: 'MyriadBold',
    height: 35,
    fontSize: 13,
    marginBottom: '6rem',
    // [theme.breakpoints.down('md')]: {
    //   top: '25vh',
    // right: '-30px',
    // }
  },
  lateralListItem: {
    color: '#FFF',
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#7AA9DF !important',
    },
  },
  contactSectionContainer: {
    marginBottom: '-64px',
    marginTop: '90px',
    display: 'flex',
    justifyContent: 'center',
    padding: '50px',
    [theme.breakpoints.only('sm')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '90px',
      marginBottom: '0px',
      padding: '30px',
    },
  },
  contactButtonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  contactButtonItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  contactSectionButton: {
    [theme.breakpoints.up('sm')]: {
      height: '50px',
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      height: '45px',
      fontSize: '12px',
    },
  },
});

export default styles;
