import React, { Fragment, Suspense, useCallback, useEffect, useMemo } from 'react';

/* Material UI */
import { StyledEngineProvider } from '@mui/material/styles';
/* Router */
import { Route } from 'react-router-dom';

/* Extra */
import socketIOClient, { io } from 'socket.io-client';

/* Project */
import PrivateRoute from 'utils/hooks/PrivateRoute';
import saga from 'commons/saga/commons.saga';
import PublicRoute from 'utils/hooks/PublicRoute';
import RenewSessionDialog from 'commons/components/RenewSessionDialog/RenewSessionDialog';
import { appActions } from 'commons/reducer/commons.reducer';
import { composedComponent, getItemInStorage, setInStorage } from 'utils/functions';
import Routes from 'config/routes';
import { checkTokenMinutesLeft } from 'utils/jwt';
import environments from 'config/environments';
import { loginActions } from '../modules/main/Login/reducer/login.reducer';

function mapRoute({ Component, user, path, options, withoutLayout = false }) {
  return (
    <Component
      key={path}
      path={path}
      exact={options?.exact ?? true}
      component={options.component}
      // auth={options.auth ?? Roles.ANY}
      permissionPath={options.permissionPath}
      pathAreaID={options.areaID}
      user={user}
      withoutLayout={withoutLayout}
      options={options}
    />
  );
}

function App({ user, actions, loadingUser }) {
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);

  const session = getItemInStorage('user');

  const authSocketPayload = useMemo(() => {
    const userToken = getItemInStorage('user')?.token;
    return { token: userToken };
  }, [user]);

  const webSocketInstance = useCallback(() => {
    const payload = {
      auth: { token: authSocketPayload.token },
      transports: ['websocket', 'polling'],
    };
    return environments.WS_ENDPOINT
      ? socketIOClient(environments.WS_ENDPOINT, payload)
      : io(payload);
  }, [authSocketPayload]);

  const socket = useMemo(() => {
    if (user && user.id) return webSocketInstance();
    return null;
  }, [user]);

  useEffect(() => {
    actions.initialQuery();

    const check = (currentSession) => {
      if (currentSession?.token) {
        const minutesLeft = checkTokenMinutesLeft(currentSession.token);
        if (minutesLeft > 0) {
          if (minutesLeft <= 15 && !openLoginDialog) {
            setOpenLoginDialog(true);
          } else if (openLoginDialog && minutesLeft > 0) {
            setOpenLoginDialog(false);
          }
        }
      }
    };
    check(session);

    const timer = setTimeout(() => {
      const currentSession = getItemInStorage('user');
      check(currentSession);
    }, 300000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('error', (err) => {
        console.error('Error client', err);
      });
      // [GuaranteeReport Notification]
      socket.on('[GuaranteeReport Notification]:progress', (data) => {
        actions.setSuccess(`progreso reporte ${data}%`);
      });

      socket.on('[GuaranteeReport Notification]:error', (err) => {
        actions.setLoader('export-guarantees', false);
        actions.setError('Error al generar el reporte');
        console.log('Error client', err);
      });
      socket.on('[GuaranteeReport Notification]', (data) => {
        actions.setLoader('export-guarantees', false);

        if (data.message === 'Job completed') {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.target = '_blank';
          document.body.appendChild(link);

          link.href = data.result;
          const [, fileName] = data.result.split('report/guarantee/')[1].split('?')[0];
          link.download = fileName;
          link.click();
          link.remove();
          actions.setSuccess(`Reporte generado`);
        } else {
          actions.setError('Ocurrió un error');
        }
      });

      // [PendingTransfersReport Notification]
      socket.on('[PendingTransfersReport Notification]:progress', (data) => {
        actions.setSuccess(`progreso reporte ${data}%`);
      });

      socket.on('[PendingTransfersReport Notification]:error', (err) => {
        actions.setLoader('export-pending-transfer', false);
        actions.setError('Error al generar el reporte');
        console.log('Error client', err);
      });
      socket.on('[PendingTransfersReport Notification]', (data) => {
        actions.setLoader('export-pending-transfer', false);
        if (data.message === 'Job completed') {
          console.log('link', data.result);
          const link = document.createElement('a', { key: 'link_PendingTransfersReport' });
          link.style.display = 'none';
          link.target = '_blank';
          document.body.appendChild(link);

          link.href = data.result;
          const [, fileName] = data.result.split('report/pending_transfer/')[1].split('?')[0];
          link.download = fileName;
          link.click();
          link.remove();
          actions.setSuccess(`Reporte generado`);
        } else {
          actions.setError('Ocurrió un error');
        }
      });

      // [Transfers Notification]
      socket.on('[Transfers Notification]:progress', (data) => {
        actions.setSuccess(`carga en progreso ${data}%`);
      });

      socket.on('[Transfers Notification]:error', (err) => {
        console.log('Error client', err);
      });
      socket.on('[Transfers Notification]', (data) => {
        if (data.result.error) {
          actions.getTransferSocketFail();
          actions.setError('Ocurrió un error, por favor vuelva a intentarlo');
          return;
        }
        actions.getTransferSocketSuccess(data.result);
        actions.setSuccess(`Datos cargados`);
      });

      // [Mandates Notification]
      socket.on('[Mandates Notification]:progress', (data) => {
        actions.setSuccess(`carga en progreso ${data}%`);
      });

      socket.on('[Mandates Notification]:error', (err) => {
        console.log('Error client', err);
      });
      socket.on('[Mandates Notification]', (data) => {
        console.log('------ SOCKET SUCCESS');
        // actions.getTransferSocketSuccess(data.result);
        actions.setSuccess(`Datos cargados`);
      });
      // -----------------------

      socket.on('connect', () => {
        console.log('[Socket] Connected', { socketId: socket.id });
        actions.setSocketId(socket.id);
        socket.emit('[APP] Suscribe');
      });

      socket.on('exception', (exception) => {
        console.log('[Socket] Exception', { exception });
      });

      socket.on('disconnect', () => {
        actions.resetSocketId();
        console.log('[Socket] Disconnected');
      });

      socket.on('[Notification]', (data) => {
        setInStorage('socketId', socket.id, 'session');
        console.log('notification', data);
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  const generalRoutes = useMemo(
    () =>
      Object.keys(Routes.general).map((path) =>
        mapRoute({
          Component: Route,
          user,
          path,
          options: Routes.general[path],
          requireSearchParams: Routes.general.requireSearchParams,
        }),
      ),
    [user],
  );

  const publicRoutes = useMemo(
    () =>
      Object.keys(Routes.public).map((path) =>
        mapRoute({
          Component: PublicRoute,
          user,
          path,
          options: Routes.public[path],
        }),
      ),
    [user],
  );

  const privateRoutes = useMemo(
    () =>
      Object.keys(Routes.private).map((path) =>
        mapRoute({
          Component: PrivateRoute,
          user,
          path,
          options: Routes.private[path],
        }),
      ),
    [user],
  );

  const transfersRoutes = useMemo(
    () =>
      Object.keys(Routes.transfers).map((path) =>
        mapRoute({
          Component: PrivateRoute,
          user,
          path,
          options: Routes.transfers[path],
          withoutLayout: true,
        }),
      ),
    [user],
  );

  const mandateRoutes = useMemo(
    () =>
      Object.keys(Routes.mandates).map((path) =>
        mapRoute({
          Component: PrivateRoute,
          user,
          path,
          options: Routes.mandates[path],
          withoutLayout: true,
        }),
      ),
    [user],
  );

  const othersRoutes = useMemo(
    () =>
      Object.keys(Routes.others).map((path) =>
        mapRoute({
          Component: Route,
          user,
          path,
          options: Routes.others[path],
        }),
      ),
    [user],
  );

  const allRoutes = useMemo(
    () =>
      [
        ...publicRoutes,
        ...privateRoutes,
        ...generalRoutes,
        ...transfersRoutes,
        ...mandateRoutes,
      ].map((route) => <Fragment key={`route-${route.key}`}>{route}</Fragment>),
    [generalRoutes, privateRoutes, publicRoutes, transfersRoutes, mandateRoutes],
  );

  const fallback = (
    <div className="loader-container">
      <span className="loader" />
    </div>
  );

  return (
    <StyledEngineProvider injectFirst>
      {loadingUser ? (
        <div />
      ) : (
        <Suspense fallback={fallback}>
          {allRoutes}
          <RenewSessionDialog open={openLoginDialog} setOpenLoginDialog={setOpenLoginDialog} />
        </Suspense>
      )}
    </StyledEngineProvider>
  );
}

export default composedComponent(App, saga, {
  saga: 'sagaApp',
  states: ['app.user', 'app.loadingUser'],
  actions: [appActions, loginActions],
});
