import { put, spawn, takeLatest } from 'redux-saga/effects';

import { get, post } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { LOT_DETAILS, lotDetailsActions } from '../reducer/lotDetails.reducer';

function* initialQuery() {
  yield takeLatest(LOT_DETAILS.INITIAL_QUERY, function* (action) {
    appActions.setLoading(true);
    yield controlledCall(
      get,
      `api/v1/lots/${action.lotId}`,
      undefined,
      lotDetailsActions.initialQuerySuccess,
      lotDetailsActions.initialQueryFail,
    );
    appActions.setLoading(false);
  });
}

function* createVisitForm() {
  yield takeLatest(LOT_DETAILS.CREATE_VISIT_FORM, function* (action) {
    // TODO: Replace with production URLs
    const body = {
      lotId: action.lotId,
      taxNumber: action.controls.taxNumber,
      name: action.controls.name,
      phone: action.controls.phone,
      email: action.controls.email,
      address: action.controls.address,
      notes: action.controls.notes,
    };
    const result = yield controlledCall(
      post,
      'api/v1/visit_forms',
      body,
      lotDetailsActions.createVisitFormSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha envíado el formulario de visita exitosamente'));
      yield put(
        appActions.setAlertDialog({
          msg: 'Orden de visita enviada, nos contactaremos contigo pronto.',
          typeAlert: 'success',
        }),
      );
    }
  });
}

export default function* lotDetailsSaga() {
  yield spawn(initialQuery);
  yield spawn(createVisitForm);
}
