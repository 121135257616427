import React from 'react';

// Material
import { Typography } from '@mui/material';
import tattersallLogo from 'utils/images/logo-tattersall.png';

import Grid from '@mui/material/Grid';
import { PasswordField } from 'commons/components/PasswordField/PasswordField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader/ButtonWithLoader';
import { useForm } from 'utils/hooks/useForm';
import { VALIDATIONS } from 'utils/validations';
// Project

function NewPasswordForm({ controls, actions, onSubmit }) {
  const {
    onChange,
    onSubmit: onFormSubmit,
    errors,
  } = useForm(controls, actions, {
    bailByDefault: true,
    prettyErrors: true,
    validations: {
      password: [VALIDATIONS.SIGNUP_PASSWORD],
      passwordConfirmation: [
        VALIDATIONS.SIGNUP_PASSWORD,
        { check: (value) => value === controls.password, message: 'Debe ser igual a contraseña' },
      ],
    },
  });

  function onClickSubmit() {
    onFormSubmit(() => onSubmit());
  }

  return (
    <Grid container spacing={3} justifyContent="center" style={{ width: '100%', margin: 'auto' }}>
      <Grid item xs={9}>
        <img src={tattersallLogo} style={{ width: '100%', marginTop: 20 }} alt="logo" />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={9}>
        <PasswordField
          error={errors.password}
          label="Contraseña"
          helperText={errors.password ?? 'Mínimo 6 carácteres, 1 mayúscula, 1 minúscula y 1 número'}
          id="password"
          value={controls.password}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={9}>
        <PasswordField
          error={errors.passwordConfirmation}
          helperText={errors.passwordConfirmation}
          label="Confirmar contraseña"
          id="passwordConfirmation"
          value={controls.passwordConfirmation}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={9}>
        <ButtonWithLoader
          buttonId="new-password"
          ButtonProps={{
            variant: 'contained',
            color: 'primary',
            onClick: onClickSubmit,
            style: { float: 'right' },
          }}>
          Guardar
        </ButtonWithLoader>
      </Grid>
      {controls.error && (
        <Grid item xs={12}>
          <Typography style={{ color: 'red' }} align="center">
            {controls.error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default NewPasswordForm;
