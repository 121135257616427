import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Material
import { CardContent, InputAdornment, Snackbar, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// Project
import { composedComponent, formatRut } from 'utils/functions';
import tattersallLogo from 'utils/images/logo-tattersall.png';
import Alert from '@mui/material/Alert';
import { appActions } from 'commons/reducer/commons.reducer';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useForm } from 'utils/hooks/useForm';
import { PasswordField } from 'commons/components/PasswordField/PasswordField';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader/ButtonWithLoader';
import { VALIDATIONS } from 'utils/validations';
import { SubmitForm } from 'commons/components/SubmitForm/SubmitForm';
import styles from './styles';
import { registerActions } from './reducer/register.reducer';
import saga from './saga/register.saga';
import FailModal from './components/FailModal/FailModal';

function Register({ classes, controls, actions, error, errorMsg, success, successMsg }) {
  const history = useHistory();
  const { onChange, onSubmit, errors } = useForm(controls, actions, {
    bailByDefault: true,
    prettyErrors: true,
    validations: {
      taxNumber: [VALIDATIONS.REQUIRED, VALIDATIONS.RUT, VALIDATIONS.LENGTH(7, 20)],
      email: [VALIDATIONS.REQUIRED, VALIDATIONS.EMAIL, VALIDATIONS.LENGTH(2, 50)],
      name: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(2, 30)],
      lastName: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(2, 20)],
      secondLastName: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(2, 20)],
      phone: [VALIDATIONS.REQUIRED, VALIDATIONS.LENGTH(7, 9)],
      password: [VALIDATIONS.SIGNUP_PASSWORD],
      passwordConfirmation: [
        VALIDATIONS.SIGNUP_PASSWORD,
        { check: (value) => value === controls.password, message: 'Debe ser igual a contraseña' },
      ],
    },
  });

  const onBlurTrim = useCallback(
    (event) => {
      event.target.value = event.target.value.trim();
      if (event.persist) {
        event.persist();
      }
      onChange(event);
    },
    [onChange],
  );

  const onBlurTaxNumber = useCallback(
    (event) => {
      event.target.value = formatRut(event.target.value, true);
      if (event.persist) {
        event.persist();
      }
      onChange(event);
    },
    [onChange],
  );

  function onRegister() {
    onSubmit(() => actions.register(controls));
  }

  function toggleError() {
    actions.toggleError();
  }

  function toggleSuccess() {
    actions.toggleSuccess();
  }

  function toggleFailModal() {
    actions.setFailModal();
  }

  const onSubmitForm = () => {
    if (controls.policiesChecked) {
      onRegister();
    }
  };

  const termsLabel = (
    <span>
      Al registrarme, declaro haber leído, comprendido y aceptado en su totalidad las&nbsp;
      <a
        href="https://plgonline.plataformagroup.cl/webtattersall/online/documentos/politicas_privacidad.pdf?_dc=1603294437749"
        target="_blank"
        rel="noopener noreferrer">
        políticas de privacidad
      </a>
      del sitio web
    </span>
  );

  const termsCheckbox = (
    <Checkbox
      checked={controls.policiesChecked}
      onChange={onChange}
      color="primary"
      id="policiesChecked"
    />
  );

  return (
    <div style={styles.container}>
      <div style={styles.containerContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ margin: 0, height: '100vh' }}>
          <FailModal isOpen={controls.error} controls={controls} onClose={toggleFailModal} />
          <Grid item md={6} xs={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  style={{ width: '80%', margin: 'auto' }}
                  component={SubmitForm}
                  onSubmit={onSubmitForm}>
                  <Grid item xs={7}>
                    <img
                      src={tattersallLogo}
                      style={{ width: '100%', marginTop: 20, cursor: 'pointer' }}
                      alt="logo"
                      onClick={() => history.push('/')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errors.taxNumber}
                      helperText={errors.taxNumber}
                      label="Rut"
                      id="taxNumber"
                      value={controls.taxNumber}
                      onBlur={onBlurTaxNumber}
                      onChange={onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errors.email}
                      helperText={errors.email}
                      label="Correo electrónico"
                      id="email"
                      value={controls.email}
                      onChange={onChange}
                      fullWidth
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errors.name}
                      helperText={errors.name}
                      label="Nombre"
                      id="name"
                      type="text"
                      value={controls.name}
                      onBlur={onBlurTrim}
                      onChange={onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errors.lastName}
                      helperText={errors.lastName}
                      label="Apellido paterno"
                      id="lastName"
                      value={controls.lastName}
                      onBlur={onBlurTrim}
                      onChange={onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errors.secondLastName}
                      helperText={errors.secondLastName}
                      label="Apellido materno"
                      id="secondLastName"
                      value={controls.secondLastName}
                      onBlur={onBlurTrim}
                      onChange={onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errors.phone}
                      helperText={errors.phone}
                      label="Teléfono"
                      id="phone"
                      value={controls.phone}
                      onChange={onChange}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PasswordField
                      error={errors.password}
                      label="Contraseña"
                      helperText={
                        errors.password ??
                        'Mínimo 6 carácteres, 1 mayúscula, 1 minúscula y 1 número'
                      }
                      id="password"
                      value={controls.password}
                      onChange={onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PasswordField
                      error={errors.passwordConfirmation}
                      helperText={errors.passwordConfirmation}
                      label="Confirmar contraseña"
                      id="passwordConfirmation"
                      value={controls.passwordConfirmation}
                      onChange={onChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: '16px 0px', textAlign: 'justify' }}>
                    <FormControlLabel control={termsCheckbox} label={termsLabel} />
                  </Grid>
                  <Grid item xs={12} className={classes.alignRight}>
                    <Typography variant="caption" color="primary" style={{ fontSize: '1rem' }}>
                      <Link
                        to="/login"
                        style={{ textDecoration: 'none', color: '#0d47a1' }}
                        variant="inherit"
                        color="primary">
                        ¿Tienes una cuenta? Iniciar sesión
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton edge="start" onClick={() => history.goBack()} size="large">
                      <ArrowBackIcon />
                    </IconButton>
                    <ButtonWithLoader
                      buttonId="register"
                      ButtonProps={{
                        variant: 'contained',
                        color: 'primary',
                        disabled: !controls.policiesChecked,
                        onClick: onRegister,
                        style: { float: 'right', marginTop: '5px' },
                      }}>
                      Registrarse
                    </ButtonWithLoader>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={success}
        autoHideDuration={3000}
        onClose={toggleSuccess}>
        <Alert onClose={toggleSuccess} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withStyles(
  composedComponent(Register, saga, {
    saga: 'sagaRegister',
    states: ['register.controls', 'app.errorMsg', 'app.error', 'app.successMsg', 'app.success'],
    actions: [registerActions, appActions],
  }),
  styles,
);
