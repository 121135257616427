/* eslint-disable */
import { createReducer } from 'utils/reducer/utils.reducer';

const PATH = 'viewReport';

export const VIEW_REPORT = {
  GET_SATE_FROM_API: `${PATH}/GET_SATE_FROM_API`,
  GET_SATE_FROM_API_SUCCESS: `${PATH}/GET_SATE_FROM_API_SUCCESS`,
  GET_SATE_FROM_API_FAIL: `${PATH}/GET_SATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${PATH}/CHANGE_CONTROLS`,
};

const initialState = {
  controls: {
    id: '',
    loading: false,
    communeId: '',
  },
  data: {
    auctionViews: [],
    lotViews: [],
    auctions: [],
    lots: [],
    communes: [],
    locations: [],
  },
};

const viewReportReducer = createReducer(initialState, {
  [VIEW_REPORT.GET_SATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [VIEW_REPORT.GET_SATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.data.auctionViews = action.payload.auctionViews;
    state.data.lotViews = action.payload.lotViews;
    state.data.auctions = action.payload.auctions;
    state.data.lots = action.payload.lots;
    state.data.communes = action.payload.communes;
    state.data.locations = action.payload.locations;
  },
  [VIEW_REPORT.GET_SATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [VIEW_REPORT.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
});

export default viewReportReducer;
