import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { USER_PROFILE, userProfileActions } from '../reducer/userProfile.reducer';

function* initialQuery() {
  yield takeLatest(USER_PROFILE.INITIAL_QUERY, function* () {
    yield put(appActions.setLoader('save-profile', true));
    yield controlledCall(get, 'api/v1/me', undefined, userProfileActions.initialQuerySuccess);
    yield put(appActions.setLoader('save-profile', false));
  });
}

function* submitForm() {
  yield takeLatest(USER_PROFILE.SUBMIT_FORM, function* (action) {
    yield put(appActions.setLoader('save-profile', true));
    let {
      taxNumber,
      name,
      lastName,
      secondLastName,
      phone,
      address,
      isBusiness,
      commercialBusiness,
      billingTaxNumber,
      billingName,
      billingAddress,
      countryId,
      communeId,
      city,
      province,
      refundTaxNumber,
      refundName,
      refundAccountNumber,
      bankId,
      accountTypeId,
      confirmationPassword,
      // businessTaxNumber,
      // businessName
    } = action.form;

    const fullName = `${name} ${lastName} ${secondLastName}`;
    if (!billingTaxNumber) {
      billingTaxNumber = taxNumber;
    }
    if (!billingName) {
      billingName = fullName;
    }
    if (!billingAddress) {
      billingAddress = address;
    }

    const body = {
      name,
      lastName,
      secondLastName,
      phone,
      address,
      isBusiness,
      commercialBusiness,
      billingAddress,
      countryId: parseInt(countryId),
      billingTaxNumber: billingTaxNumber?.replace(/[-.]/g, ''),
      billingName,
      communeId: countryId?.toString() === '1' ? parseInt(communeId) : undefined,
      city: countryId?.toString() === '2' ? city : undefined,
      province: countryId?.toString() === '2' ? province : undefined,
      refundTaxNumber: refundTaxNumber.replace(/[-.]/g, ''),
      refundName,
      refundAccountNumber,
      bankId: parseInt(bankId),
      accountTypeId: parseInt(accountTypeId),
      confirmationPassword,
      businessTaxNumber: billingTaxNumber?.replace(/[-.]/g, ''),
      businessName: billingName,
    };

    const result = yield controlledCall(
      update,
      'api/v1/me',
      body,
      userProfileActions.submitFormSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se actualizaron los datos de usuario exitosamente'));
    }

    yield put(appActions.setLoader('save-profile', false));
  });
}

function* changePassword() {
  yield takeLatest(USER_PROFILE.CHANGE_PASSWORD, function* (action) {
    yield put(appActions.setLoader('save-profile', true));
    const { confirmationPassword, newPassword, newPasswordConfirmation } = action.form;

    const body = { confirmationPassword, newPassword, newPasswordConfirmation };
    const result = yield controlledCall(
      update,
      'api/v1/me/password',
      body,
      userProfileActions.changePasswordSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se modificó la contraseña exitosamente'));
    }

    yield put(appActions.setLoader('save-profile', false));
  });
}

export default function* userProfileSaga() {
  yield spawn(initialQuery);
  yield spawn(submitForm);
  yield spawn(changePassword);
}
