const styles = () => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: 300,
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.3 },
  },
  headerGT: {
    animationName: '$blinker',
    animationDuration: '1.5s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  liveIcon: {
    color: '#f44336',
  },
});
export const listItemStyles = {
  paddingLeft: 16,
  paddingRight: 16,
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  width: '100%',
  display: 'flex',
  position: 'relative',
  boxSizing: 'border-box',
  textAlign: 'left',
  alignItems: 'center',
  paddingTop: 8,
  paddingBottom: 8,
  justifyContent: 'flex-start',
  textDecoration: 'none',
};

export const inlineText = {
  display: 'inline',
  fontSize: '1.06rem',
};

export default styles;
