import camelize from 'camelcase-keys-deep';

export default class User {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  person() {
    return this.env().Person.getById(this.personId);
  }

  entity() {
    return this.person().entity();
  }

  role() {
    return this.env().Role.getById(this.roleId);
  }

  area() {
    return this.env().Area.getById(this.areaId);
  }

  linked() {
    return this.env().LinkedUser.findBy('currentId', this.id);
  }

  fullName() {
    return this.person()?.fullName();
  }

  adjudications() {
    return this.env().Adjudication.findAllBy('userId', this.id);
  }

  guarantees() {
    return this.env().Guarantee.findAllBy('userId', this.id);
  }

  payments() {
    const allPayments = [];
    this.guarantees().forEach((guarantee) => {
      const guaranteePayments = guarantee.payments();
      if (guaranteePayments.length) {
        allPayments.push(guaranteePayments);
      }
    });

    return allPayments;
  }
}
