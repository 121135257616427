import { createAction, createReducer } from 'utils/reducer/utils.reducer';

const PATH = 'register/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

export const REGISTER = {
  REGISTER: `${PATH}REGISTER`,
  REGISTER_FAIL: `${PATH}REGISTER_FAIL`,
  REGISTER_SUCCESS: `${PATH}REGISTER_SUCCESS`,
  TOGGLE_FAIL_MODAL: `${PATH}TOGGLE_FAIL_MODAL`,
  SET_FAIL_MODAL: `${PATH}SET_FAIL_MODAL`,
};

export const registerActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  register: createAction(REGISTER.REGISTER, 'data'),
  registerFail: createAction(REGISTER.REGISTER_FAIL, 'payload'),
  registerSuccess: createAction(REGISTER.REGISTER_SUCCESS, 'payload'),
  toggleFailModal: createAction(REGISTER.TOGGLE_FAIL_MODAL, 'payload'),
  setFailModal: createAction(REGISTER.SET_FAIL_MODAL),
};

const initialState = {
  controls: {
    taxNumber: '',
    email: '',
    name: '',
    lastName: '',
    secondLastName: '',
    phone: '',
    password: '',
    passwordConfirmation: '',
    policiesChecked: false,
    errorMsg: '',
    error: false,
    errors: {},
  },
};

const register = createReducer(initialState, {
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    if (action.controls.email) {
      state.controls.email = state.controls.email.trim();
    }
  },
  [REGISTER.REGISTER_SUCCESS](state) {
    state.controls.errors = {};
  },
  [REGISTER.TOGGLE_FAIL_MODAL](state, action) {
    let errorMessage = '';

    if (action.payload.msg) {
      state.controls.errors = {};
      errorMessage = action.payload.msg;
    } else {
      if (action.payload.json.sameEmail) {
        state.controls.errors.sameEmail = true;
        errorMessage =
          'El Email que se ha ingresado está asociado a otra cuenta, si olvidaste tu contraseña haz click en el siguiente enlace :';
      }
      if (action.payload.json.sameRut) {
        state.controls.errors.sameRut = true;
        errorMessage =
          'El Rut que se ha ingresado está asociado a otra cuenta, si olvidaste tu contraseña haz click en el siguiente enlace :';
      }
    }

    state.controls.errorMsg = errorMessage;
    state.controls.error = action.payload.error;
  },
  [REGISTER.SET_FAIL_MODAL](state) {
    state.controls.error = false;
    state.controls.errorMsg = '';
  },
});

export default register;
