import { spawn, takeLatest, put } from 'redux-saga/effects';

/* Project */
import { appActions, SET_ERROR } from 'commons/reducer/commons.reducer';
import { get, update, post, destroy } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { BrandsManagerActions } from '../actions/BrandsManager.actions';

function* initialQuery() {
  yield takeLatest(BrandsManagerActions.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: BrandsManagerActions.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: BrandsManagerActions.GET_STATE_FROM_API_FAIL });

    yield controlledCall(get, 'api/v1/brands', undefined, successFunction, failFunction);

    yield put(appActions.setLoading(false));
  });
}

function* createBrand() {
  yield takeLatest(BrandsManagerActions.CREATE_BRAND, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: BrandsManagerActions.CREATE_BRAND_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: BrandsManagerActions.CREATE_BRAND_FAIL,
      payload,
    });

    const result = yield controlledCall(post, 'api/v1/brands', body, successFunction, failFunction);

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* updateBrand() {
  yield takeLatest(BrandsManagerActions.EDIT_BRAND, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: BrandsManagerActions.EDIT_BRAND_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: BrandsManagerActions.EDIT_BRAND_FAIL,
      payload,
    });

    const result = yield controlledCall(
      update,
      `api/v1/brands/${body.id}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* deleteBrand() {
  yield takeLatest(BrandsManagerActions.DELETE_BRAND, function* (action) {
    const body = action.controls;

    const successFunction = (payload) => ({
      type: BrandsManagerActions.DELETE_BRAND_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: BrandsManagerActions.DELETE_BRAND_FAIL,
      payload,
    });

    const result = yield controlledCall(
      destroy,
      `api/v1/brands/${body.id}`,
      undefined,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* BrandsManagerSaga() {
  yield spawn(initialQuery);
  yield spawn(createBrand);
  yield spawn(updateBrand);
  yield spawn(deleteBrand);
}
