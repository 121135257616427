import camelize from 'camelcase-keys-deep';

export default class LinkedSeller {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  current() {
    return this.env().Guarantee.getById(this.currentId);
  }
}
