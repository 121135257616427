const styles = () => ({
  root: {
    textAlign: 'center',
    padding: '10px !important',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageLogo: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '30%',
  },
  title: {
    fontSize: '19px',
    fontWeight: '400',
    margin: '20px 20px 20px 20px',
    fontFamily: 'Roboto, sans-serif',
    color: '#838383 !important',
  },
  text: {
    lineHeight: '1.6em !important',
    fontSize: '17px !important',
    color: '#838383 !important',
    padding: '0px!important',
    margin: '0px!important',
  },
  linkButton: {
    color: '#0A4199',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export default styles;
