import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, post } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { FormTwentyThreeTypes } from '../types/FormTwentyThree.types';

function* getStateFromApi() {
  yield takeLatest(FormTwentyThreeTypes.GET_STATE_FROM_API, function* (action) {
    const successFunction = (payload) => ({
      type: FormTwentyThreeTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: FormTwentyThreeTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/form-twenty-three/${action.payload.transferId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* createFormTwentythree() {
  yield takeLatest(FormTwentyThreeTypes.CREATE_FORM_TWENTYTHREE, function* (action) {
    const body = { ...action.payload.data };
    delete body.loading;
    const successFunction = (payload) => ({
      type: FormTwentyThreeTypes.CREATE_FORM_TWENTYTHREE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: FormTwentyThreeTypes.CREATE_FORM_TWENTYTHREE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      `api/v1/transfers/form-twenty-three/${action.payload.transferId}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    } else {
      history.push(`/transfers/lots/${action.payload.transferId}`);
    }
  });
}

export default function* FormTwentyThreeSaga() {
  yield spawn(getStateFromApi);
  yield spawn(createFormTwentythree);
}
