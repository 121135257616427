import controlledCall from 'utils/services/controlledSaga';
import { spawn, takeLatest } from 'redux-saga/effects';
import { get } from 'utils/api/api';
import { WEBPAY_FINAL, webpayFinalActions } from '../reducer/webpayFinal.reducer';

function* initialQuery() {
  yield takeLatest(WEBPAY_FINAL.INITIAL_QUERY, function* () {
    yield controlledCall(
      get,
      'api/v1/payments',
      undefined,
      webpayFinalActions.initialQuerySuccess,
      webpayFinalActions.initialQueryFail,
    );
  });
}

export default function* webpayFinalSaga() {
  yield spawn(initialQuery);
}
