import camelize from 'camelcase-keys-deep';

export default class Location {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  polygons() {
    return this.env().Polygon.findAllBy('locationId', this.id);
  }

  commune() {
    return this.env().Commune.getById(this.communeId);
  }

  region() {
    return this.env().Region.getById(this.commune()?.regionId);
  }
}
