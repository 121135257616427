import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Container, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
// Project

import './styles.scss';
import { composedComponent } from 'utils/functions';
import propertyOne from 'utils/images/Property/Prop3min.jpg';
import propertyTwo from 'utils/images/Property/Prop2min.jpg';
import propertyThree from 'utils/images/Property/Prop1min.jpg';
import furnitureOne from 'utils/images/Furniture/Bienes3min.jpg';
import furnitureTwo from 'utils/images/Furniture/Bienes1min.jpg';
import furnitureThree from 'utils/images/Furniture/Bienes2min.jpg';
import MobileLayout from './components/MobileLayout';
import DesktopLayout from './components/DesktopLayout';
import { loginActions } from '../Login/reducer/login.reducer';
import styles from './styles';
import saga from './saga/home.saga';

function Home(props) {
  const { globals, user } = props;
  const { propertiesIsLive, furnituresIsLive } = globals;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // sm
  const imagesOfProperties = [propertyOne, propertyTwo, propertyThree];
  const imagesOfFurniture = [furnitureOne, furnitureTwo, furnitureThree];

  const [propertyImg, setPropertyImg] = useState({ image: imagesOfProperties[0], index: 0 });
  const [furnitureImg, setFurnitureImg] = useState({ image: imagesOfFurniture[0], index: 0 });
  const [oldPropertyImg, setOldPropertyImg] = useState(imagesOfProperties[2]);
  const [oldFurnitureImg, setOldFurnitureImg] = useState(imagesOfFurniture[2]);
  const [isProperty, setIsProperty] = useState(true);

  function changeImage() {
    if (isProperty) {
      const newIndex =
        imagesOfProperties.length - 1 === propertyImg.index ? 0 : propertyImg.index + 1;
      setPropertyImg({ image: imagesOfProperties[newIndex], index: newIndex });
    } else {
      const newIndex =
        imagesOfFurniture.length - 1 === furnitureImg.index ? 0 : furnitureImg.index + 1;
      setFurnitureImg({ image: imagesOfFurniture[newIndex], index: newIndex });
    }
    setIsProperty(!isProperty);
  }

  useEffect(() => {
    if (isProperty) {
      setOldPropertyImg(imagesOfProperties[propertyImg.index]);
    } else {
      setOldFurnitureImg(imagesOfFurniture[furnitureImg.index]);
    }
    const interval = setInterval(() => {
      changeImage();
    }, 6000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isProperty]);

  const mobileBackground = (
    <Grid container className={props.classes.containerBack} style={{ position: 'absolute' }}>
      <Grid item container lg={12} xs={12} className={props.classes.backProperties}>
        <Grid
          item
          lg={12}
          xs={12}
          className={props.classes.backProperties}
          style={{
            backgroundImage: `url(${oldPropertyImg})`,
            display: 'block',
            zIndex: -1,
            position: 'absolute',
          }}
        />
        {imagesOfProperties.map((img, index) => (
          <Fade
            in={index === propertyImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === propertyImg.index ? { timeout: 1500 } : {})}>
            <Grid
              item
              lg={12}
              xs={12}
              className={props.classes.backProperties}
              style={{
                backgroundImage: `url(${img})`,
                zIndex: index === propertyImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}
            />
          </Fade>
        ))}
      </Grid>
      <Grid item container lg={12} xs={12} className={props.classes.backFurniture}>
        <Grid
          item
          lg={12}
          xs={12}
          className={props.classes.backFurniture}
          // justifyContent={'flex-end'}
          style={{
            backgroundImage: `url(${oldFurnitureImg})`,
            display: 'block',
            zIndex: -1,
            position: 'absolute',
          }}
        />
        {imagesOfFurniture.map((img, index) => (
          <Fade
            in={index === furnitureImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === furnitureImg.index ? { timeout: 1500 } : {})}>
            <Grid
              key={index}
              item
              lg={12}
              xs={12}
              className={props.classes.backFurniture}
              // justifyContent='flex-end'
              style={{
                backgroundImage: `url(${img})`,
                zIndex: index === furnitureImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}
            />
          </Fade>
        ))}
      </Grid>
    </Grid>
  );

  const desktopBackground = (
    <>
      <Grid container className={props.classes.containerFilterBack}>
        <Grid container style={{ zIndex: -1, position: 'absolute' }}>
          <Grid
            item
            lg={12}
            xs={12}
            className={props.classes.backProperties}
            style={{ backgroundImage: `url(${oldPropertyImg})`, display: 'block' }}
          />
        </Grid>
        {imagesOfProperties.map((img, index) => (
          <Fade
            in={index === propertyImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === propertyImg.index ? { timeout: 1500 } : {})}>
            <Grid
              container
              style={{
                zIndex: index === propertyImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}>
              <Grid
                item
                lg={12}
                xs={12}
                className={props.classes.backProperties}
                style={{ backgroundImage: `url(${img})` }}
              />
            </Grid>
          </Fade>
        ))}
      </Grid>
      <Grid container className={props.classes.containerBack} style={{ position: 'absolute' }}>
        <Grid container style={{ zIndex: -1, position: 'absolute' }}>
          <Grid
            item
            lg={12}
            xs={12}
            className={props.classes.backFurniture}
            style={{ backgroundImage: `url(${oldFurnitureImg})`, display: 'block' }}
          />
        </Grid>
        {imagesOfFurniture.map((img, index) => (
          <Fade
            in={index === furnitureImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === furnitureImg.index ? { timeout: 1500 } : {})}>
            <Grid
              container
              style={{
                zIndex: index === furnitureImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}>
              <Grid item lg={4} xs={4} />
              <Grid
                key={index}
                item
                lg={8}
                xs={8}
                className={props.classes.backFurniture}
                style={{ backgroundImage: `url(${img})` }}
              />
            </Grid>
          </Fade>
        ))}
      </Grid>
    </>
  );

  const mobileLayout = (
    <MobileLayout
      classes={props.classes}
      propertyLive={propertiesIsLive}
      furnitureLive={furnituresIsLive}
      globals={globals}
      user={user}
    />
  );

  const desktopLayout = (
    <DesktopLayout
      classes={props.classes}
      propertyLive={propertiesIsLive}
      furnitureLive={furnituresIsLive}
      globals={globals}
      user={user}
    />
  );

  return (
    <Container maxWidth="lg">
      {isMobile ? mobileBackground : desktopBackground}
      <Grid container className={props.classes.detailsContainer} alignItems="center">
        {isMobile ? mobileLayout : desktopLayout}
      </Grid>
    </Container>
  );
}

export default withStyles(
  composedComponent(Home, saga, {
    saga: 'sagaHome',
    states: ['app.objects', 'app.globals', 'app.user'],
    actions: [loginActions],
  }),
  styles,
);
