import React from 'react';

/* Material UI */
import { Button, Grid } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/* Router */
import { useParams } from 'react-router-dom';

/* Project */
import { furnitureLink, propertyLink } from 'utils/helpers/helper';

function HomeContactSection(props) {
  const { actions, classes, globals } = props;
  const { lotId } = useParams();
  const [open, setOpen] = React.useState(false);
  const { manualFurnitureLink, manualPropertyLink } = globals;
  const handleContactForm = () => {
    if (lotId) {
      actions.toggleContactForm(lotId);
    } else {
      actions.toggleForm('contactFormOpen');
    }
  };
  const contactButton = (
    <Grid item xs={12} md={8}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleContactForm}
        startIcon={<PhoneIcon style={{ fontSize: 20 }} />}
        className={classes.contactSectionButton}>
        Contacto
      </Button>
    </Grid>
  );

  const handleDrawer = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setOpen(!open);
  };
  const howParticipateButton = (
    <Grid item xs={12} md={8} className="dropdown" style={{ background: 'yellow' }}>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleDrawer}
        className={classes.contactSectionButton}
        startIcon={<HelpOutlineIcon style={{ fontSize: 20 }} />}>
        ¿Cómo participar?
      </Button>
      <div className="dropdown-content" style={{ display: open ? 'block' : 'none' }}>
        <a target="_blank" rel="noreferrer noopener" href={manualFurnitureLink}>
          Bienes
        </a>
        <a target="_blank" rel="noreferrer noopener" href={manualPropertyLink}>
          Propiedades
        </a>
      </div>
    </Grid>
  );
  const navSuperiorGrid = (
    <Grid item xs={12} className={classes.contactButtonsContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} className={classes.contactButtonItem}>
          {contactButton}
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classes.contactButtonItem}>
          {howParticipateButton}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.contactSectionContainer}>
      <Grid item style={{ width: '100%', display: 'flex' }}>
        {navSuperiorGrid}
      </Grid>
    </Grid>
  );
}

export default HomeContactSection;
