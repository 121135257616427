import camelize from 'camelcase-keys-deep';

export default class User {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  person() {
    return this.env().Person.getById(this.personId);
  }

  entity() {
    return this.person().entity()();
  }

  role() {
    return this.env().Role.getById(this.roleId);
  }

  isAdmin() {
    return this.roleId === 1;
  }

  isTattersall() {
    return this.roleId && this.areaId;
  }

  linked() {
    return this.env().LinkedUser.findBy('currentId', this.id);
  }
}
