import { createReducer } from 'utils/reducer/utils.reducer';

import { DirectSaleFeaturesActions } from '../actions/DirectSaleFeatures.actions';
import { DirectSaleActions } from '../../DirectSale/actions/DirectSale.actions';

const initialState = {
  controls: {
    categoryId: '',
    brandId: '',
    modelId: '',
    loading: false,
    filtersLoading: false,
    applyFilters: false,
  },
  pagination: {
    offset: 0,
    page: 1,
    limit: 10,
    total: 0,
    rowsPerPageOptions: [15, 30, 45, 99],
  },
  avaliableCategoryIds: [],
  filteredLots: [],
  validTypes: ['machineries', 'vehicles', 'others'],
};

const directSaleFeaturesReducer = createReducer(initialState, {
  [DirectSaleFeaturesActions.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
    state.controls = initialState.controls;
  },
  [DirectSaleFeaturesActions.GET_STATE_FROM_API_SUCCESS](state) {
    state.controls.loading = false;
  },
  [DirectSaleActions.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.avaliableCategoryIds = action.payload.extra.lotCategoryIds;
  },
  [DirectSaleFeaturesActions.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [DirectSaleFeaturesActions.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [DirectSaleFeaturesActions.MERGE_CONTROLS](state, action) {
    state.controls = {
      ...state.controls,
      ...action.payload.controls,
    };
    state.pagination = {
      ...state.pagination,
      ...action.payload.pagination,
    };
  },
  [DirectSaleFeaturesActions.FILTER_LOTS]({ controls }) {
    controls.filtersLoading = true;
  },
  [DirectSaleFeaturesActions.FILTER_LOTS_FAIL]({ controls }) {
    controls.filtersLoading = false;
  },
  [DirectSaleFeaturesActions.FILTER_LOTS_SUCCESS](state, action) {
    state.controls.filtersLoading = false;
    state.filteredLots = [...Object.values(action.payload.objects.lots)];
    state.pagination.total = action.payload.extra.total;
    state.avaliableCategoryIds = action.payload.extra.lotCategoryIds;
  },
});

export default directSaleFeaturesReducer;
