import { takeLatest, put, spawn } from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { LOTS_LIST } from '../reducer/lotsList.reducer';

function* getLotsList() {
  yield takeLatest(LOTS_LIST.FETCH_LOTS_LIST, function* fetchLotsListApi(action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    const response = yield apiRequest(
      `api/v1/mandates/lots?${new URLSearchParams(body)}`,
      { method: 'get' },
      true,
    );
    if (!response.error) {
      yield put(apiSuccess(LOTS_LIST.FETCH_LOTS_LIST_SUCCESS, response));
    } else {
      yield put(apiSuccess(LOTS_LIST.FETCH_LOTS_LIST_ERROR, response));
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

function* getMandateLotStatus() {
  yield takeLatest(LOTS_LIST.GET_MANDATE_LOT_STATUS, function* getLotStatusGenerator() {
    const response = yield apiRequest(`api/v1/mandates/lots/status`, { method: 'get' }, true);

    if (!response.error) {
      yield put(apiSuccess(LOTS_LIST.GET_MANDATE_LOT_STATUS_SUCCESS, response));
    } else {
      yield put(apiSuccess(LOTS_LIST.REQUEST_FAILED, response));
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

function* updateMandateLotStatus() {
  yield takeLatest(LOTS_LIST.UPDATE_MANDATE_LOT_STATUS, function* fetchLotsListApi(action) {
    const { lotId, statusId } = action.payload;

    const body = JSON.stringify({ statusId });

    const response = yield apiRequest(
      `api/v1/mandates/lots/${lotId}/status`,
      { method: 'put', body },
      true,
    );

    if (!response.error) {
      console.log('Response Front', response);
      yield put(apiSuccess(LOTS_LIST.UPDATE_MANDATE_LOT_STATUS_SUCCESS, response));
      yield put(appActions.setSuccess('Estado del lote actualizado correctamente.'));
    } else {
      yield put(apiSuccess(LOTS_LIST.REQUEST_FAILED, response));
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

export default function* lotsListSaga() {
  yield spawn(getLotsList);
  yield spawn(getMandateLotStatus);
  yield spawn(updateMandateLotStatus);
}
