import { createReducer } from 'utils/reducer/utils.reducer';
import { SystemChangesTypes } from '../types/SystemChanges.types';

const initialState = {
  filters: {
    startDate: null,
    endDate: null,
    userName: '',
    model: '',
    role: '',
  },
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 30, 45, 99],
  },
  controls: {
    loading: false,
    ids: [],
    total: 0,
    userId: '',
    displayChangeDetail: false,
    systemChangeId: '',
    detailData: null,
  },
};

const systemChangesReducer = createReducer(initialState, {
  [SystemChangesTypes.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [SystemChangesTypes.CHANGE_FILTERS](state, action) {
    state.filters[action.payload.name] = action.payload.value;
  },
  [SystemChangesTypes.CHANGE_PAGINATION](state, action) {
    state.pagination = {
      ...state.pagination,
      ...action.payload.pagination,
    };
  },
  [SystemChangesTypes.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.ids = action.payload.ids;
    state.controls.total = action.payload.total;
  },
  [SystemChangesTypes.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [SystemChangesTypes.TOGGLE_SYSTEM_CHANGES_DETAILS_DIALOG](state, action) {
    state.controls.displayChangeDetail = !state.controls.displayChangeDetail;
    state.controls.systemChangeId = action.payload.id;
    state.controls.detailData = action.payload.detailData;
  },
});

export default systemChangesReducer;
