import React from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 1,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const typeChannels = [
  'LLamada Telefonica',
  'WhatsApp',
  'Correo',
  'Telefono Fijo',
  'Sin canal de preferencia',
  'Movil',
  'Telefono',
];

function getStyles(channel, allChannels, theme) {
  return {
    fontWeight:
      allChannels.indexOf(channel) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
  const { controls, onChange, id } = props;
  const { classes } = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">Canales de interes</InputLabel>
      <Select
        name={id}
        labelId="typeChannel"
        multiple
        value={controls.typeChannel}
        onChange={onChange}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}>
        {typeChannels.map((channel) => (
          <MenuItem key={channel} value={channel}>
            <Checkbox checked={controls.typeChannel.indexOf(channel) > -1} />
            <ListItemText primary={channel} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
