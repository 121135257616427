const base = '[VEHICLE_TYPES_MANAGER]';

export const VehicleTypesManagerTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  OPEN_CREATE_DIALOG: `${base} OPEN_CREATE_DIALOG`,
  TOGGLE_EDIT_FORM: `${base} TOGGLE_EDIT_FORM`,
  CREATE_VEHICLE_TYPE: `${base} CREATE_VEHICLE_TYPE`,
  CREATE_VEHICLE_TYPE_SUCCESS: `${base} CREATE_VEHICLE_TYPE_SUCCESS`,
  CREATE_VEHICLE_TYPE_FAIL: `${base} CREATE_VEHICLE_TYPE_FAIL`,
  UPDATE_VEHICLE_TYPE: `${base} UPDATE_VEHICLE_TYPE`,
  UPDATE_VEHICLE_TYPE_SUCCESS: `${base} UPDATE_VEHICLE_TYPE_SUCCESS`,
  UPDATE_VEHICLE_TYPE_FAIL: `${base} UPDATE_VEHICLE_TYPE_FAIL`,
  TOGGLE_DELETE: `${base} TOGGLE_DELETE`,
  DELETE_VEHICLE_TYPE: `${base} DELETE_VEHICLE_TYPE`,
  DELETE_VEHICLE_TYPE_SUCCESS: `${base} DELETE_VEHICLE_TYPE_SUCCESS`,
  DELETE_VEHICLE_TYPE_FAIL: `${base} DELETE_VEHICLE_TYPE_FAIL`,
};

export default VehicleTypesManagerTypes;
