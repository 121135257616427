import { newReducer } from 'utils/reducer/utils.reducer';
import { MandatePreviewTypes } from '../types/MandatePreview.types';

const initialState = {
  controls: {
    loading: false,
    openEditForm: false,
    mandateDate: null,
    agreementDate: null,
    signatureDate: null,
    mandateName: '',
    mandateRut: '',
    representativeName: '',
    representativeRut: '',
    notary: '',
    patent: '',
    brand: '',
    vehicleType: '',
    model: '',
    bill: '',
    invoiceNumber: '',
    isNaturalPerson: false,
  },
  data: {
    mandate: [],
    brands: [],
    models: [],
    transferOwner: [],
    vehicleTypes: [],
    transferMandate: [],
  },
};

const MandatePreviewReducer = newReducer(initialState, {
  [MandatePreviewTypes.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.name]: action.payload.value,
    },
  }),
  [MandatePreviewTypes.CREATE_MANDATE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: true,
    },
  }),
  [MandatePreviewTypes.TOGGLE_EDIT_FORM]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      openEditForm: !state.controls.openEditForm,
    },
  }),
  [MandatePreviewTypes.GET_STATE_FROM_API_SUCCESS]: (state, action) => ({
    data: {
      ...action.payload.data,
    },
    controls: {
      ...state.controls,
      mandateDate: action.payload.data.transferMandate.mandateDate,
      signatureDate: action.payload.data.transferMandate.signatureDate,
      agreementDate: action.payload.data.transferMandate.agreementDate,
      notary: action.payload.data.transferMandate.notary,
      mandateName: action.payload.data.transferMandate.mandateName,
      mandateRut: action.payload.data.transferMandate.mandateRut,
      representativeName: action.payload.data.transferMandate.representativeName,
      representativeRut: action.payload.data.transferMandate.representativeRut,
      patent: action.payload.data.mandate.patent,
      brand: action.payload.data.brandId,
      vehicleType: action.payload.data.vehicleTypeId,
      model: action.payload.data.modelId,
      invoiceNumber: action.payload.data.mandate.invoice,
      isNaturalPerson: action.payload.data.isNaturalPerson,
    },
  }),
  [MandatePreviewTypes.CREATE_MANDATE_SUCCESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      mandate: action.payload.data.mandate,
      transferMandate: action.payload.data.transferMandate,
    },
    controls: {
      ...state.controls,
      loading: false,
      openEditForm: false,
      mandateDate: action.payload.data.transferMandate.mandateDate,
      signatureDate: action.payload.data.transferMandate.signatureDate,
      agreementDate: action.payload.data.transferMandate.agreementDate,
      notary: action.payload.data.transferMandate.notary,
      mandateName: action.payload.data.transferMandate.mandateName,
      mandateRut: action.payload.data.transferMandate.mandateRut,
      representativeName: action.payload.data.transferMandate.representativeName,
      representativeRut: action.payload.data.transferMandate.representativeRut,
      patent: action.payload.data.mandate.patent,
      brand: action.payload.data.brandId,
      vehicleType: action.payload.data.vehicleTypeId,
      model: action.payload.data.modelId,
    },
  }),
  [MandatePreviewTypes.TOGGLE_NATURAL_PERSON_FORM]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      isNaturalPerson: action.payload.value,
    },
  }),
  [MandatePreviewTypes.GET_STATE_FROM_API]: (state, action) => ({
    ...state,
    controls: {
      ...initialState.controls,
    },
  }),
});

export default MandatePreviewReducer;
