import { createReducer } from 'utils/reducer/utils.reducer';
import { TransfersLoginTypes } from '../types/transfersLogin.types';

// // actions
// export const loginActions = {
//   disableErrors: createAction(DISABLE_ERRORS),
//   // api
//   getUser: createAction(LOGIN.GET_USER),
//   login: createAction(LOGIN.LOGIN_USER, 'controls', 'redirectUrl'),
//   verify: createAction(LOGIN.VERIFY, 'token'),
//   controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
// };

const initialState = {
  controls: {
    loading: false,
    message: '',
    error: false,
    canLogin: null,
  },
};

const transfersLogin = createReducer(initialState, {
  [`${TransfersLoginTypes.DISABLE_ERRORS}`](state) {
    state.error = false;
  },
  // api
  [TransfersLoginTypes.LOGIN_USER](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [TransfersLoginTypes.LOGIN_USER_SUCCESS](state) {
    state.controls.loading = false;
  },
  [TransfersLoginTypes.GET_USER_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = true;
    state.controls.message = action.payload.errorMsg;
  },
  [TransfersLoginTypes.LOGIN_USER_FAIL](state) {
    state.controls.loading = false;
  },
  [TransfersLoginTypes.CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [TransfersLoginTypes.VERIFY_CAPTCHA_SUCCESS](state, action) {
    state.controls.canLogin = action.payload.canLogin;
  },
});

export default transfersLogin;
