const base = '[TRANSFERS_LOGIN]';

export const TransfersLoginTypes = {
  LOGIN_USER_SUCCESS: `${base}FETCH_LOGIN_USER_SUCCESS`,
  LOGIN_USER_FAIL: `${base}FETCH_LOGIN_USER_FAIL`,
  LOGIN_USER: `${base}FETCH_LOGIN_USER`,
  GET_USER_SUCCESS: `${base}GET_USER_SUCCESS`,
  GET_USER_FAIL: `${base}GET_USER_FAIL`,
  GET_USER: `${base}GET_USER`,
  VERIFY_SUCCESS: `${base}VERIFY_SUCCESS`,
  VERIFY_FAIL: `${base}VERIFY_FAIL`,
  VERIFY: `${base}VERIFY`,
  CONTROLS_CHANGED: `${base}CONTROLS_CHANGED`,
  DISABLE_ERRORS: `${base}DISABLE_ERRORS`,
  VERIFY_CAPTCHA: `${base}VERIFY_CAPTCHA`,
  VERIFY_CAPTCHA_SUCCESS: `${base}VERIFY_CAPTCHA_SUCCESS`,
  VERIFY_CAPTCHA_ERROR: `${base}VERIFY_CAPTCHA_ERROR`,
};

export default TransfersLoginTypes;
