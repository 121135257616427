import React from 'react';

/* Material UI */
import {
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Dialog,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';

/* Project */
import { truthty } from 'utils/functions';
import colors from 'utils/colors';
import ButtonWithLoader from '../ButtonWithLoader/ButtonWithLoader';

function FormsContainer(props) {
  const {
    createAction,
    createButton,
    editAction,
    // fullScreen,
    noActions,
    maxWidth,
    disabled,
    onCancel,
    buttonId,
    title,
    text,
    open,
    id,
    acceptLoading,
    children,
    ...rest
  } = props;

  let submitFunction = null;
  if (truthty(editAction) || truthty(createAction)) {
    submitFunction = id ? editAction : createAction;
  }

  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreenDialog}
      maxWidth={maxWidth || 'md'}
      onClose={onCancel}
      open={open}
      disableScrollLock>
      <DialogTitle style={{ color: colors.primary }}>{title}</DialogTitle>
      <DialogContent {...rest}>
        <DialogContentText>{text}</DialogContentText>
        <div style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </div>
      </DialogContent>
      {!noActions && (
        <DialogActions>
          <Button onClick={onCancel}>Cancelar</Button>
          {submitFunction && (
            <ButtonWithLoader
              buttonId={buttonId}
              ButtonProps={{
                onClick: submitFunction,
                disabled: acceptLoading || disabled,
              }}>
              {createButton || (id ? 'Guardar' : 'Agregar')}
            </ButtonWithLoader>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default FormsContainer;
