export function unformatRut(rut) {
  return rut.toString().replace(/\./g, '').replace(/-/g, '');
}
export function formatRut(rut) {
  const rutClean = unformatRut(rut);

  const rutPart = rutClean.slice(0, -1);
  const rutDv = rutClean.slice(-1);

  const formatedRut = rutPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + rutDv;
  return formatedRut;
}

export function formatMoney(amount) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
