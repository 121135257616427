import SystemActions from 'utils/roles/SystemActions';

export default class CommercialRole extends SystemActions {
  constructor(payload) {
    super();
    this.payload = payload;
    this.home.goToLive = true;

    this.profile.view = true;
    this.profile.update = true;
    this.profile.updatePassword = true;

    this.contactMessage.actions = true;
    this.contactMessage.markAsRead = true;
    this.contactMessage.view = true;

    this.auction.view = true;
    this.auction.filter = true;
    this.auction.create = this.validateArea();
    this.auction.actions = this.validateArea();
    this.auction.massiveCopy = this.validateArea();
    this.auction.massiveCopyToSale = this.validateArea();

    this.auctionManager.view = this.validateArea();
    this.auctionManager.create = this.validateArea();
    this.auctionManager.update = this.validateArea();

    this.lot.view = this.validateArea();
    this.lot.filter = this.validateArea();
    this.lot.massiveUpload = this.validateArea();
    this.lot.create = this.validateArea();
    this.lot.actions = this.validateArea();

    this.lotManager.view = this.validateArea();
    this.lotManager.history = this.validateArea();
    this.lotManager.update = this.validateArea();

    this.lotDetail.view = true;
    this.lotDetail.edit = this.validateArea();

    // this.orderLot.view = this.validateArea();
    // this.orderLot.update = this.validateArea();

    this.payment.view = true;
    this.guarantee.view = true;
    this.guaranteeManager.view = true;
    this.newPayment.view = true;

    this.mandatesLogin.view = true;
  }

  validateArea() {
    return this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString();
  }
}
