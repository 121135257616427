import moment from 'moment';

/* "Now" comparison functions */
export function isNow(date, granularity = null) {
  const now = moment();
  const other = moment(date);
  return now.isSame(other, granularity);
}

export function isNowOrAfter(date, granularity = null) {
  const now = moment();
  const other = moment(date);
  return other.isSameOrAfter(now, granularity);
}

export function isAfterNow(date, granularity = null) {
  const now = moment();
  const other = moment(date);
  return other.isAfter(now, granularity);
}

export function isNowOrBefore(date, granularity = null) {
  const now = moment();
  const other = moment(date);
  return other.isSameOrBefore(now, granularity);
}

export function isBeforeNow(date, granularity = null) {
  const now = moment();
  const other = moment(date);
  return other.isBefore(now, granularity);
}

export function isBeforeFromNow(date, amount, unit) {
  const now = moment();
  const nowTimeAgo = now.clone().subtract(amount, unit);
  const momentDate = moment(date);

  return momentDate.isBefore(nowTimeAgo);
}

export function isAfterFromNow(date, amount, unit) {
  const now = moment();
  const nowTimeFrom = now.clone().add(amount, unit);
  const momentDate = moment(date);

  return momentDate.isAfter(nowTimeFrom);
}

export function isSameOrBeforeFromNow(date, amount, unit) {
  const now = moment();
  const nowTimeAgo = now.clone().subtract(amount, unit);
  const momentDate = moment(date);

  return momentDate.isSameOrBefore(nowTimeAgo);
}

export function isSameOrAfterFromNow(date, amount, unit) {
  const now = moment();
  const nowTimeFrom = now.clone().add(amount, unit);
  const momentDate = moment(date);

  return momentDate.isSameOrAfter(nowTimeFrom);
}

/* Two dates comparison functions */
export function isSame(firstDate, secondDate, granularity = null) {
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isSame(other, granularity);
}

export function isSameOrAfter(firstDate, secondDate, granularity = null) {
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isSameOrAfter(other, granularity);
}

export function isAfter(firstDate, secondDate, granularity = null) {
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isAfter(other, granularity);
}

export function isSameOrBefore(firstDate, secondDate, granularity = null) {
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isSameOrBefore(other, granularity);
}

export function isBefore(firstDate, secondDate, granularity = null) {
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isBefore(other, granularity);
}

export function inRange(date, firstRange, secondRange, inclusivity = '[]') {
  const momentDate = moment(date);

  switch (inclusivity) {
    case '(]':
      return momentDate.isAfter(firstRange) && momentDate.isSameOrBefore(secondRange);
    case '()':
      return momentDate.isAfter(firstRange) && momentDate.isBefore(secondRange);
    case '[)':
      return momentDate.isSameOrAfter(firstRange) && momentDate.isBefore(secondRange);
    case '[]':
      return momentDate.isSameOrAfter(firstRange) && momentDate.isSameOrBefore(secondRange);
    case ')(':
      return momentDate.isBefore(firstRange) || momentDate.isAfter(secondRange);
    case ')[':
      return momentDate.isBefore(firstRange) || momentDate.isSameOrAfter(secondRange);
    case '](':
      return momentDate.isSameOrBefore(firstRange) || momentDate.isAfter(secondRange);
    case '][':
      return momentDate.isSameOrBefore(firstRange) || momentDate.isSameOrAfter(secondRange);
    default:
      throw new Error('Invalid inclusivity option');
  }
}

/* Range functions */
export function isAgoFromNow(date, amount, unit, inclusivity = '[]') {
  const now = moment();
  const nowTimeAgo = now.clone().subtract(amount, unit);

  return inRange(date, nowTimeAgo, now, inclusivity);
}

export function isTimeFromNow(date, amount, unit, inclusivity = '[]') {
  const now = moment();
  const nowTimeFrom = now.clone().add(amount, unit);

  return inRange(date, now, nowTimeFrom, inclusivity);
}

/* Selection functions */
export function pickGreater(firstDate, secondDate) {
  if (firstDate == null) {
    return secondDate;
  }

  if (secondDate == null) {
    return firstDate;
  }
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isAfter(other) ? firstDate : secondDate;
}

export function pickLesser(firstDate, secondDate) {
  const date = moment(firstDate);
  const other = moment(secondDate);
  return date.isBefore(other) ? firstDate : secondDate;
}

export function selectGreater(selector) {
  return (previous, current) => pickGreater(selector(previous), selector(current));
}

export function selectLesser(selector) {
  return (previous, current) => pickLesser(selector(previous), selector(current));
}

/* Format functions */
export function formatDatetime(date) {
  return moment(date).format('DD/MM/YYYY HH:mm');
}

export function formatDate(date) {
  return moment(date).format('DD/MM/YYYY');
}

export function formatDatePicker(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatTime(date) {
  return moment(date).format('HH:mm');
}

export function formatLocalDateTime(date) {
  return moment(date).local().format('DD/MM/YYYY HH:mm');
}

export function formatMonthDate(date) {
  return moment(date).format('DD [de] MMMM');
}

export function formatLongDate(date) {
  return moment(date).local().format('dddd, D [de] MMMM [de] YYYY - h:mm');
}

export function daysFromNow(oldDate) {
  const now = moment(new Date());
  const duration = moment.duration(now.diff(oldDate));
  return duration.asDays();
}

export function formatDateWord(date) {
  return moment(date).local().format('D [de] MMMM YYYY');
}

export function formatDateTwoWord(date) {
  return moment(date).local().format('D [de] MMMM [de] YYYY');
}
