import { createAction, createReducer } from 'utils/reducer/utils.reducer';

const PATH = 'lotDetails/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;

export const LOT_DETAILS = {
  CREATE_VISIT_FORM_SUCCESS: `${PATH}CREATE_VISIT_FORM_SUCCESS`,
  CREATE_VISIT_FORM_FAIL: `${PATH}CREATE_VISIT_FORM_FAIL`,
  CREATE_VISIT_FORM: `${PATH}CREATE_VISIT_FORM`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  TOGGLE_VIDEO: `${PATH}TOGGLE_VIDEO`,
};

export const lotDetailsActions = {
  toggleForm: createAction(TOGGLE_FORM, 'controls'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  initialQuerySuccess: createAction(LOT_DETAILS.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(LOT_DETAILS.INITIAL_QUERY_FAIL, 'payload'),
  initialQuery: createAction(LOT_DETAILS.INITIAL_QUERY, 'lotId'),
  createVisitFormSuccess: createAction(LOT_DETAILS.CREATE_VISIT_FORM_SUCCESS, 'payload'),
  createVisitFormFail: createAction(LOT_DETAILS.CREATE_VISIT_FORM_FAIL, 'payload'),
  createVisitForm: createAction(LOT_DETAILS.CREATE_VISIT_FORM, 'controls', 'lotId'),
  toggleVideo: createAction(LOT_DETAILS.TOGGLE_VIDEO),
};

const defaultInvalidControls = {
  notes: false,
  name: false,
  email: false,
  taxNumber: false,
  phone: false,
};

const initialState = {
  initialQuery: true,
  printIsOpen: false,
  invalidControls: defaultInvalidControls,
  controls: {
    notes: '',
    name: '',
    email: '',
    taxNumber: '',
    phone: '',
    address: '',
    displayVideo: false,
  },
};

const lotDetails = createReducer(initialState, {
  [LOT_DETAILS.INITIAL_QUERY_SUCCESS](state) {
    state.initialQuery = false;
  },
  [LOT_DETAILS.INITIAL_QUERY](state) {
    state.initialQuery = true;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = { ...state.controls, ...action.controls };
    state.invalidControls = { ...state.invalidControls, ...(action.invalidControls || {}) };
  },
  [TOGGLE_FORM](state, action) {
    state[action.controls] = !state[action.controls];
  },
  [LOT_DETAILS.TOGGLE_VIDEO](state, action) {
    state.controls.displayVideo = !state.controls.displayVideo;
  },
});

export default lotDetails;
