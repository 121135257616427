import camelize from 'camelcase-keys-deep';

export default class Entity {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  person() {
    return this.env().Person.findBy('entityId', this.id);
  }

  user() {
    return this.person().user();
  }

  linked() {
    return this.env().LinkedSeller.findBy('currentId', this.id);
  }
}
