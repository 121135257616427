import { createReducer, createAction } from 'utils/reducer/utils.reducer';
import { CurrencyManagerActions } from '../actions/CurrencyManager.actions';

export const currencyManagerActions = {
  editCurrency: createAction(CurrencyManagerActions.EDIT_CURRENCY, 'controls'),
};

const initialState = {
  controls: {
    filterName: '',
    filterSort: '-1',
    filterDate: null,
    name: '',
    symbol: '',
    code: '',
    factor: '',
    loading: false,
    formIsOpen: false,
    toEditId: '',
    selectedCurrency: {},
    edit: false,
  },
  isOpen: false,
};

const currencyManagerReducer = createReducer(initialState, {
  [CurrencyManagerActions.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [CurrencyManagerActions.GET_STATE_FROM_API_SUCCESS](state) {
    state.controls.loading = false;
  },
  [CurrencyManagerActions.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [CurrencyManagerActions.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [CurrencyManagerActions.TOGGLE_EDIT_FORM](state) {
    state.controls.formIsOpen = true;
    state.controls.toEditId = state.controls.selectedCurrency.id;
    state.controls.name = state.controls.selectedCurrency.name;
    state.controls.symbol = state.controls.selectedCurrency.symbol;
    state.controls.code = state.controls.selectedCurrency.code;
    state.controls.factor = state.controls.selectedCurrency.factor;
    state.controls.edit = true;
  },
  [CurrencyManagerActions.TOGGLE_FORM](state) {
    state.controls.formIsOpen = !state.controls.formIsOpen;
    state.controls.toEditId = '';
    state.controls.name = '';
    state.controls.symbol = '';
    state.controls.code = '';
    state.controls.factor = '';
    state.controls.edit = false;
  },
  [CurrencyManagerActions.EDIT_CURRENCY](state) {
    state.controls.loading = true;
  },
  [CurrencyManagerActions.CREATE_CURRENCY_SUCCESS](state) {
    state.controls.loading = false;
    state.controls.formIsOpen = false;
    state.controls.name = '';
    state.controls.symbol = '';
    state.controls.code = '';
    state.controls.factor = '';
  },
  [CurrencyManagerActions.EDIT_CURRENCY_SUCCESS](state) {
    state.controls.loading = false;
    state.controls.formIsOpen = false;
  },
});

export default currencyManagerReducer;
