// @ts-nocheck
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_REDUX_DEV_TOOLS === 'false') {
  middlewares.push(createLogger({ collapsed: true }));
}

export default applyMiddleware(...middlewares);
