import camelize from 'camelcase-keys-deep';

export default class Seller {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    return this.env().Entity.getById(this.entityId);
  }
}
