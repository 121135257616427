import moment from 'moment';

/**
 * Decode a JSON WEB TOKEN
 * @param token
 * @returns { Object | null }
 */
export const decodeJWT = (token) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

/**
 * Return hours and minutes left for JWT
 * @param { Object } tokenPayload
 * @returns { { hours: null, minutes: null } | { hours: number, minutes: number } }
 */
export const retrieveExpiration = (tokenPayload) => {
  if (tokenPayload) {
    const expiration = tokenPayload.exp;
    const time = expiration * 1000;
    const duration = moment.duration(moment(time).diff(new Date().getTime()));
    const hours = duration.asHours();
    const minutes = duration.asMinutes();

    return { hours, minutes };
  }

  return { hours: null, minutes: null };
};

/**
 * @param {String} token
 * @returns {number}
 */
export const checkTokenMinutesLeft = (token) => {
  if (token) {
    const tokenPayload = decodeJWT(token);

    if (tokenPayload) {
      const { minutes } = retrieveExpiration(tokenPayload);
      return minutes;
    }
  }

  return 0;
};
