import camelize from 'camelcase-keys-deep';

export default class Area {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  auctionCategories() {
    return this.env().AuctionCategory.findAllBy('areaId', this.id);
  }

  lotCategories() {
    const auctionCategories = this.auctionCategories();
    const lotCategories = auctionCategories.reduce(
      (prev, current) => [...prev, ...current.lotCategories()],
      [],
    );

    return lotCategories;
  }
}
