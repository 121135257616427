import { createAction, createReducer } from 'utils/reducer/utils.reducer';

const PATH = 'payments/';
const TOGGLE_DETAILS = `${PATH}TOGGLE_DETAILS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const APPLY_FILTER = `${PATH}APPLY_FILTER`;
const CLEAR_FILTER = `${PATH}CLEAR_FILTER`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const FILTERS_CHANGED = `${PATH}FILTERS_CHANGED`;
const PAGINATION_CHANGED = `${PATH}PAGINATION_CHANGED`;
const SHOW_GUARANTEE_SELECTION = `${PATH}SHOW_GUARANTEE_SELECTION`;
const SET_GUARANTEE = `${PATH}SET_GUARANTEE`;
const SET_EXPANDED_USER_ID = `${PATH}SET_EXPANDED_USER_ID`;

export const PAYMENTS = {
  RESET: `${PATH}RESET`,
  // gest state
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  ACCEPT_RECEIPT: `${PATH}ACCEPT_RECEIPT`,
  ACCEPT_RECEIPT_SUCCESS: `${PATH}ACCEPT_RECEIPT_SUCCESS`,
  ACCEPT_RECEIPT_FAIL: `${PATH}ACCEPT_RECEIPT_FAIL`,
  REJECT_RECEIPT: `${PATH}REJECT_RECEIPT`,
  REJECT_RECEIPT_SUCCESS: `${PATH}REJECT_RECEIPT_SUCCESS`,
  REJECT_RECEIPT_FAIL: `${PATH}REJECT_RECEIPT_FAIL`,
  EDIT_AMOUNT: `${PATH}EDIT_AMOUNT`,
  EDIT_AMOUNT_SUCCESS: `${PATH}EDIT_AMOUNT_SUCCESS`,
  EDIT_AMOUNT_FAIL: `${PATH}EDIT_AMOUNT_FAIL`,
  RETRY_PAYMENT: `${PATH}RETRY_PAYMENT`,
  RETRY_PAYMENT_SUCCESS: `${PATH}RETRY_PAYMENT_SUCCESS`,
  RETRY_PAYMENT_FAIL: `${PATH}RETRY_PAYMENT_FAIL`,
  UNDO_PAYMENT: `${PATH}UNDO_PAYMENT`,
  UNDO_PAYMENT_SUCCESS: `${PATH}UNDO_PAYMENT_SUCCESS`,
  UNDO_PAYMENT_FAIL: `${PATH}UNDO_PAYMENT_FAIL`,
};

// actions
export const paymentsActions = {
  reset: createAction(PAYMENTS.RESET),
  toggleDetails: createAction(TOGGLE_DETAILS, 'payment'),
  setExpandedUserId: createAction(SET_EXPANDED_USER_ID, 'id'),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  showGuaranteeSelection: createAction(SHOW_GUARANTEE_SELECTION, 'show'),
  setGuarantee: createAction(SET_GUARANTEE, 'value'),
  applyFilter: createAction(APPLY_FILTER),
  clearFilter: createAction(CLEAR_FILTER),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  filtersChanged: createAction(FILTERS_CHANGED, 'filters'),
  paginationChanged: createAction(PAGINATION_CHANGED, 'options'),
  // api
  initialQuery: createAction(PAYMENTS.INITIAL_QUERY, 'filters'),
  acceptReceipt: createAction(PAYMENTS.ACCEPT_RECEIPT, 'paymentId'),
  rejectReceipt: createAction(PAYMENTS.REJECT_RECEIPT, 'paymentId', 'notes'),
  editAmount: createAction(PAYMENTS.EDIT_AMOUNT, 'paymentId', 'amount'),
  retryPayment: createAction(PAYMENTS.RETRY_PAYMENT, 'id', 'guaranteeId'),
  undoPayment: createAction(PAYMENTS.UNDO_PAYMENT, 'id'),
  // success
  initialQuerySuccess: createAction(PAYMENTS.INITIAL_QUERY_SUCCESS, 'payload'),
  acceptReceiptSuccess: createAction(PAYMENTS.ACCEPT_RECEIPT_SUCCESS, 'payload'),
  rejectReceiptSuccess: createAction(PAYMENTS.REJECT_RECEIPT_SUCCESS, 'payload'),
  editAmountSuccess: createAction(PAYMENTS.EDIT_AMOUNT_SUCCESS, 'payload'),
  retryPaymentSuccess: createAction(PAYMENTS.RETRY_PAYMENT_SUCCESS, 'payload'),
  undoPaymentSuccess: createAction(PAYMENTS.UNDO_PAYMENT_SUCCESS, 'payload'),
  // fail
  initialQueryFail: createAction(PAYMENTS.INITIAL_QUERY_FAIL, 'payload'),
  acceptReceiptFail: createAction(PAYMENTS.ACCEPT_RECEIPT_SUCCESS, 'payload'),
  rejectReceiptFail: createAction(PAYMENTS.REJECT_RECEIPT_SUCCESS, 'payload'),
  editAmountFail: createAction(PAYMENTS.EDIT_AMOUNT_FAIL, 'payload'),
  retryPaymentFail: createAction(PAYMENTS.RETRY_PAYMENT_FAIL, 'payload'),
  undoPaymentFail: createAction(PAYMENTS.UNDO_PAYMENT_FAIL, 'payload'),
};

const defaultControls = {
  paymentGuaranteeId: null,
  receiptAmount: null,
  observations: '',
};

const defaultInvalidControls = {
  receiptAmount: false,
};

const initialState = {
  controls: {
    doQuery: true,
    paymentsCount: 0,
    expandedUserId: null,
    groupByUser: false,
    activeOnly: true,
    showDetails: false,
    anchorEl: null,
    filterState: '-1',
    filterPaymentType: '-1',
    filterAuctionId: '-1',
    filterMinDate: null,
    filterMaxDate: null,
    guaranteeId: null,
    formIsOpen: false,
    filtered: false,
    payment: null,
    editedAmount: '',
    ...defaultControls,
  },
  filters: {
    state: '-1',
    paymentType: '-1',
    auctionId: '-1',
    minDate: '',
    maxDate: '',
    paymentId: null,
    guaranteeId: null,
    groupByUser: false,
    activeOnly: true,
    hidePastAuctions: false,
    userSearch: '',
  },
  ids: {
    payments: [],
  },
  pagination: {
    page: 0,
    count: 10,
  },
  error: false,
  invalidControls: defaultInvalidControls,
};

const payments = createReducer(initialState, {
  [SET_EXPANDED_USER_ID](state, action) {
    state.controls.expandedUserId = action.id ?? null;
  },
  [TOGGLE_DETAILS](state, action) {
    state.controls.showDetails = !state.controls.showDetails;
    state.controls.payment = action.payment;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = { ...state.controls, ...action.controls };
    state.invalidControls = { ...state.invalidControls, ...(action.invalidControls || {}) };
  },
  [PAYMENTS.ACCEPT_RECEIPT](state) {
    state.controls = { ...state.controls, ...defaultControls };
  },
  [PAYMENTS.REJECT_RECEIPT](state) {
    state.controls = { ...state.controls, ...defaultControls };
  },
  [SHOW_GUARANTEE_SELECTION](state, actions) {
    state.controls.guaranteeId = null;
    state.controls.showGuaranteeSelection = actions.show;
  },
  [SET_GUARANTEE](state, action) {
    state.controls.paymentGuaranteeId = action.value;
  },
  [APPLY_FILTER](state) {
    state.pagination.page = 0;
    state.controls.doQuery = true;
  },
  [CLEAR_FILTER](state) {
    state.filters = initialState.filters;
    state.pagination = initialState.pagination;
    state.controls.doQuery = true;
  },
  [FILTERS_CHANGED](state, action) {
    state.filters = { ...state.filters, ...action.filters };
  },
  [PAYMENTS.INITIAL_QUERY](state) {
    state.controls.doQuery = false;
  },
  [PAYMENTS.INITIAL_QUERY_SUCCESS](state, action) {
    state.ids.payments = action.payload.ids.payments;
    state.controls.paymentsCount = action.payload.count.payments;
    state.controls.doQuery = false;
  },
  [PAGINATION_CHANGED](state, action) {
    if (action.options.page) {
      state.pagination.page = action.options.page;
    }
    if (action.options.count) {
      state.pagination.count = action.options.count;
    }
  },
  [PAYMENTS.RESET](state) {
    state.controls = initialState.controls;
    state.ids = initialState.ids;
    state.filters = initialState.filters;
    state.pagination = initialState.pagination;
    state.error = initialState.error;
  },
});

export default payments;
