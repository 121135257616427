import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, post, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { asQuery, unformatParse } from 'utils/functions';
import { history } from 'utils/history';
import { GUARANTEE_MANAGER, guaranteeManagerActions } from '../reducer/guaranteeManager.reducer';

function* initialQuery() {
  yield takeLatest(GUARANTEE_MANAGER.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('guarantees-next', true));
    yield controlledCall(
      get,
      `api/v1/guarantees?${asQuery({
        auctionId: action.auctionId,
        auctionLotId: action.lotId,
        fromManager: true,
      })}`,
      undefined,
      guaranteeManagerActions.initialQuerySuccess,
      guaranteeManagerActions.initialQueryFail,
    );
    yield put(appActions.setLoader('guarantees-next', false));
  });
}

function* createGuarantee() {
  yield takeLatest(GUARANTEE_MANAGER.CREATE_GUARANTEE, function* (action) {
    yield put(appActions.setLoader('accept-bases', true));
    yield put(appActions.setLoader('guarantees-next', true));
    const {
      lotsOffered: lots,
      offerts: amounts,
      offerAmount: amount,
      isProperty,
    } = action.controls;
    const body = {};
    if (isProperty) {
      body.lots = lots
        .filter((id) => amounts[id] != null && amounts[id].toString().trim().length > 0)
        .map((id) => ({ id, amount: unformatParse(amounts[id]) }));
    } else {
      body.furnitureAuctionId = action.furnitureAuctionId;
      body.amount = unformatParse(amount);
    }
    const data = body;
    const result = yield controlledCall(
      post,
      'api/v1/guarantees',
      body,
      guaranteeManagerActions.createGuaranteeSuccess,
    );

    if (!result?.error) {
      data.createdGuarantee = result.objects.guarantees;
      data.createdGuaranteeAuctionType = result.objects.createdGuaranteeAuctionType;
      data.isProperty = isProperty;
      yield put(appActions.setSuccess('Se ha creado la garantía exitosamente'));
      yield put(appActions.setGuaranteeModal({ data }));
      const guaranteeId = Object.values(result.objects.guarantees)[0].id;
      history.push(`/payments?preselect=${guaranteeId}`);
    }

    yield put(appActions.setLoader('accept-bases', false));
    yield put(appActions.setLoader('guarantees-next', false));
  });
}

function* createOrUpdateBillingInfo() {
  yield takeLatest(GUARANTEE_MANAGER.CREATE_OR_UPDATE_BILLING_INFO, function* (action) {
    const { controls, billingInfoId } = action;
    yield put(appActions.setLoader('guarantees-next', true));

    let billingTaxNumber = controls.billingTaxNumber || controls.taxNumber;
    billingTaxNumber = billingTaxNumber?.replace(/[-.]/g, '');
    const fullName = `${controls.name} ${controls.lastName} ${controls.secondLastName}`;
    const billingName = controls.billingName || fullName;
    const address = controls.address || controls.personalAddress;

    const refundTaxNumber = controls.titularTaxNumber?.replace(/[-.]/g, '');

    const body = {
      lastName: controls.lastName,
      secondLastName: controls.secondLastName,
      commercialBusiness: controls.commercialBusiness,
      isBusiness: controls.isBusiness,
      billingTaxNumber,
      billingName,
      address,
      countryId: controls.countryId,
      refundTaxNumber,
      refundName: controls.titularName,
      bankId: controls.bankId,
      accountTypeId: controls.accountTypeId,
      accountNumber: controls.accountNumber,
      businessTaxNumber: billingTaxNumber,
      businessName: billingName,
    };

    if (controls.countryId.toString() === '1') {
      body.communeId = controls.communeId;
    } else {
      body.province = controls.regionId;
      body.city = controls.communeId;
    }

    let result;
    if (billingInfoId) {
      result = yield controlledCall(
        update,
        `api/v1/billing_data/${billingInfoId}`,
        body,
        guaranteeManagerActions.createOrUpdateBillingInfoSuccess,
      );
    } else {
      result = yield controlledCall(
        post,
        'api/v1/billing_data',
        body,
        guaranteeManagerActions.createOrUpdateBillingInfoSuccess,
      );
    }
    if (!result?.error) {
      yield put(appActions.setSuccess('Se han actualizado los datos de usuario'));
    }
    yield put(appActions.setLoader('guarantees-next', false));
  });
}

function* validateSSIData() {
  yield takeLatest(GUARANTEE_MANAGER.VALIDATE_SSI_DATA, function* (action) {
    const {
      payload: { callback, controls },
    } = action;
    yield put(appActions.setLoader('guarantees-next', true));

    const successDispatch = () => ({ type: GUARANTEE_MANAGER.VALIDATE_SSI_DATA_SUCCESS });

    yield put(successDispatch());
    yield put(callback());
    // código comentado para no mostrar error de validación del SII
    // const isBusiness = controls.isBusiness;
    // const billingTaxNumber = controls.billingTaxNumber?.replace(/[-.]/g, '');
    // const businessTaxNumber = controls.businessTaxNumber?.replace(/[-.]/g, '');
    // const billingName = controls.billingName;
    // const businessName = controls.businessName;

    // let data = {
    //   commercialBusiness: controls.commercialBusiness,
    //   taxNumber: isBusiness ? businessTaxNumber : billingTaxNumber,
    //   businessName: isBusiness ? businessName : billingName,
    // };

    // const result = yield controlledCall(
    //   get,
    //   `api/v1/validateBillingData?taxNumber=${data.taxNumber}&businessName=${data.businessName}&commercialBusiness=${data.commercialBusiness}`,
    //   undefined,
    //   successDispatch,
    // );

    // if (result?.error || !result?.matchName || !result?.correctCommercialBusiness) {
    //   // yield put({ type: GUARANTEE_MANAGER.VALIDATE_SSI_DATA_FAIL, payload: { callback, result } });
    // } else {
    //   yield put(callback());
    // }

    yield put(appActions.setLoader('guarantees-next', false));
  });
}

export default function* guaranteeManagerSaga() {
  yield spawn(createOrUpdateBillingInfo);
  yield spawn(initialQuery);
  yield spawn(createGuarantee);
  yield spawn(validateSSIData);
}
