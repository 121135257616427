/* eslint-disable */
import React from 'react';
import { Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
// Utils
import configureStore from './config/configureStore';
import 'fontsource-roboto';
// components
import App from './App/App';
// navbarStyles
import './commons/assets/styles.scss';
import { history } from 'utils/history';
import { configMoment } from 'utils/configMoment';
import ScrollToTop from 'commons/components/ScrollToTop/ScrollToTop';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider, adaptV4Theme } from '@mui/material/styles';
import { blue, teal } from '@mui/material/colors';
const store = configureStore();
const persistor = persistStore(store);

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: blue[900],
      },
      secondary: {
        main: teal[900],
      },
    },
  }),
);

const AppWithProfile = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <ScrollToTop />
              <App />
            </Router>
          </PersistGate>
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
};

const renderApp = () => render(<AppWithProfile />, document.querySelector('#root'));

renderApp();
console.log(`Environment loaded:${process.env.ENV}`);
if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    module.hot.accept('./App/App', () => {
      renderApp();
    });
  }
}
