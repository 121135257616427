import camelize from 'camelcase-keys-deep';
import { isBefore } from 'utils/moment';
import moment from 'moment';

export default class Auction {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  auctionState() {
    return this.env().AuctionState.getById(this.stateId);
  }

  mandates() {
    return this.env().Mandate.findAllBy('auctionId', this.id);
  }

  bases() {
    return this.mandates().reduce((acum, ma) => {
      acum = [...acum, ...ma.allBases()];
      return acum;
    }, []);
  }

  lots() {
    const lots = this.bases().reduce((acum, base) => {
      acum = [...acum, ...base.lots()];
      return acum;
    }, []);
    return lots.filter((lot) => lot.stateId !== 1);
  }

  mandatesDocuments() {
    return this.mandates().reduce((acum, ma) => {
      acum = [...acum, ma.document()];
      return acum;
    }, []);
  }

  image() {
    if (!this.documentId) {
      return null;
    }
    return this.env().Document.getById(this.documentId);
  }

  linked() {
    return this.env().LinkedAuction.findBy('currentId', this.id);
  }

  guarantees() {
    const lots = this.lots();
    const offers = lots.reduce((result, lot) => [...result, ...lot.offers()], []);
    const uniqueGuarantees = offers.reduce((result, offer) => {
      const guarantee = offer.guarantee();
      if (result[guarantee.id] != null) {
        return result;
      }
      return { ...result, [guarantee.id]: guarantee };
    }, {});
    return Object.values(uniqueGuarantees);
  }

  isProperty() {
    return this.areaId?.toString() === '2';
  }

  linkedGuarantees() {
    return this.env().LinkedGuarantee.findAllBy('auctionId', this.id);
  }

  syncStatus() {
    const lots = this.lots().filter((lot) => lot.stateId.toString() === '2' && lot.enabled);
    const linkedAuction = this.linked();
    const linkedLots = lots.map((lot) => lot.linked()).filter((linked) => linked != null);

    const auctionIsUnsync =
      linkedAuction != null && isBefore(linkedAuction.updatedAt, this.updatedAt);
    const lotsIsUnsync =
      linkedLots != null &&
      linkedLots.length > 0 &&
      linkedLots.some((linked) => {
        const lot = lots.find((lot) => lot.id.toString() === linked.currentId.toString());
        return moment(linked.updatedAt).isBefore(moment(lot.updatedAt));
      });
    if (auctionIsUnsync) {
      return 4;
    }
    if (linkedAuction == null) {
      return 3;
    }
    if (linkedLots.length !== lots.length || lotsIsUnsync) {
      return 2;
    }
    return 1;
  }

  auctionType() {
    return this.env().AuctionType.findBy('id', this.auctionTypeId);
  }
}
