import { newReducer } from 'utils/reducer/utils.reducer';
import { WorkSheetFormTypes } from '../types/WorkSheetForm.types';

const initialState = {
  controls: {
    loading: false,
    patent: '',
    vehicleType: '',
    brand: '',
    year: '',
    model: '',
    engine: '',
    chasis: '',
    series: '',
    vin: '',
    color: '',
    sellerLastname: '',
    sellerName: '',
    sellerRut: '',
    sellerAddress: '',
    sellerCommune: '',
    buyerLastname: '',
    buyerName: '',
    buyerRut: '',
    buyerAddress: '',
    buyerCommune: '',
    invoice: '',
    price: '',
    date: null,
    auctioneerBusiness: '',
    auctioneerPhone: '',
    auctioneerCommune: '',
    auctioneerRut: '',
    civilRegistrationPrice: '',
  },
  data: {
    vehicleTypes: [],
    models: [],
    brands: [],
    communes: [],
    auctioneer: [],
  },
};

const WorkSheetFormReducer = newReducer(initialState, {
  [WorkSheetFormTypes.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.name]: action.payload.value,
    },
  }),
  [WorkSheetFormTypes.GET_STATE_FROM_API_SUCCESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      vehicleTypes: action.payload.data.vehicleTypes,
      models: action.payload.data.models,
      brands: action.payload.data.brands,
      communes: action.payload.data.communes,
      auctioneer: action.payload.data.auctioneer,
    },
    controls: {
      ...state.controls,
      patent: action.payload.data.transferredVehicle?.patent,
      vehicleType: action.payload.data.transferredVehicle?.vehicleTypeId,
      brand: action.payload.data.brand,
      year: action.payload.data.transferredVehicle?.year,
      model: action.payload.data.brandModel,
      engine: action.payload.data.transferredVehicle?.engineNumber,
      chasis: action.payload.data.transferredVehicle?.chassisNumber,
      series: action.payload.data.transferredVehicle?.serie,
      vin: action.payload.data.transferredVehicle?.vin,
      color: action.payload.data.transferredVehicle?.color,
      sellerLastname: action.payload.data.transferOwner?.businessName,
      sellerName: action.payload.data.transferOwner?.names,
      sellerRut: action.payload.data.transferOwner?.rut,
      sellerAddress: action.payload.data.transferOwner?.address,
      sellerCommune: action.payload.data.transferOwner?.communeId,
      buyerLastname: action.payload.data.transferBuyer?.businessName,
      buyerName: action.payload.data.transferBuyer?.names,
      buyerRut: action.payload.data.transferBuyer?.rut,
      buyerAddress: action.payload.data.transferBuyer?.address,
      buyerCommune: action.payload.data.transferBuyer?.communeId,
      invoice: action.payload.data.transferMiscellaneous?.invoiceNumber,
      price: action.payload.data.transferWorksheet?.price,
      date: action.payload.data.transferWorksheet?.date,
      auctioneerBusiness: action.payload.data.auctioneer.business,
      auctioneerPhone: action.payload.data.auctioneer.phone,
      auctioneerCommune: action.payload.data.auctioneer.communeId,
      auctioneerRut: action.payload.data.auctioneer.rut,
      civilRegistrationPrice: action.payload.data.transferWorksheet?.civilRegistrationPrice,
    },
  }),
  [WorkSheetFormTypes.RESET_FORM]: () => ({
    controls: {
      ...initialState.controls,
    },
    data: {
      ...initialState.data,
    },
  }),
});

export default WorkSheetFormReducer;
