import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'resetPassword/';
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const RESET_PASSWORD = {
  SEND_EMAIL_SUCCESS: `${PATH}SEND_EMAIL_SUCCESS`,
  SEND_EMAIL_FAIL: `${PATH}SEND_EMAIL_FAIL`,
  SEND_EMAIL: `${PATH}SEND_EMAIL`,
  SAVE_PASSWORD_SUCCESS: `${PATH}SAVE_PASSWORD_SUCCESS`,
  SAVE_PASSWORD_FAIL: `${PATH}SAVE_PASSWORD_FAIL`,
  SAVE_PASSWORD: `${PATH}SAVE_PASSWORD`,
};

// actions
export const resetPasswordActions = {
  controlsChanged: createAction(CHANGE_CONTROLS, 'controls'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),

  sendEmailSuccess: createAction(RESET_PASSWORD.SEND_EMAIL_SUCCESS, 'payload'),
  sendEmailFail: createAction(RESET_PASSWORD.SEND_EMAIL_FAIL, 'payload'),
  sendEmail: createAction(RESET_PASSWORD.SEND_EMAIL, 'controls'),
  savePasswordSuccess: createAction(RESET_PASSWORD.SAVE_PASSWORD_SUCCESS, 'payload'),
  savePasswordFail: createAction(RESET_PASSWORD.SAVE_PASSWORD_FAIL, 'payload'),
  savePassword: createAction(RESET_PASSWORD.SAVE_PASSWORD, 'controls'),
};

const initialState = {
  controls: {
    loading: false,
    error: '',
    savePasswordSuccess: false,
    password: '',
    passwordConfirmation: '',
  },
};

const register = createReducer(initialState, {
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [CHANGE_CONTROLS](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [RESET_PASSWORD.SEND_EMAIL](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [RESET_PASSWORD.SEND_EMAIL_SUCCESS](state) {
    state.controls.loading = false;
  },
  [RESET_PASSWORD.SEND_EMAIL_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = action.payload;
  },
  [RESET_PASSWORD.SAVE_PASSWORD](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [RESET_PASSWORD.SAVE_PASSWORD_SUCCESS](state) {
    state.controls.loading = false;
    state.controls.savePasswordSuccess = true;
  },
  [RESET_PASSWORD.SAVE_PASSWORD_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = action.payload;
  },
});

export default register;
