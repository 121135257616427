import { spawn, takeLatest, put } from 'redux-saga/effects';

import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { get, objectToQueryString } from 'utils/api/api';
import { VIEW_REPORT } from '../reducer/viewReport.reducer';

function* getReport() {
  yield takeLatest(VIEW_REPORT.GET_SATE_FROM_API, function* (action) {
    yield put(appActions.setLoader('view-report', true));

    const successFunction = (payload) => ({
      type: VIEW_REPORT.GET_SATE_FROM_API_SUCCESS,
      payload,
    });
    const failFunction = () => ({ type: VIEW_REPORT.GET_SATE_FROM_API_FAIL });

    const result = yield controlledCall(
      get,
      'api/v1/pageViewReport',
      objectToQueryString(action.payload),
      successFunction,
      failFunction,
      false,
      true,
    );

    if (result?.error) {
      yield put(appActions.setError(result?.msg || 'Error interno'));
    }

    yield put(appActions.setLoader('view-report', false));
  });
}

export default function* ViewReportSaga() {
  yield spawn(getReport);
}
