import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Link from '@mui/material/Link';
import { formatCLP } from '../../../../utils/functions';
import { formatDate, formatTime } from '../../../../utils/moment';

function PaymentSuccess(props) {
  const { classes, data, objects, onClose, externalLogin } = props;
  const history = useHistory();

  const guarantee = objects?.guarantees[data.guaranteeId];
  const payment = objects?.payments[data.paymentId];
  const now = Date.now();
  const date = formatDate(now);
  const hour = formatTime(now);
  const [modalData, setModalData] = useState({
    confirmedAmount: null,
    isProperty: null,
    ratio: null,
    remaining: null,
    showRemaining: null,
    showAdvanced: null,
    paymentAmount: null,
    guaranteeAmount: null,
    showPropertyCompleted: null,
    showFurnitureCompleted: null,
    auctionId: null,
    orderBuy: null,
    source: null,
  });

  useEffect(() => {
    if (guarantee !== undefined && payment !== undefined) {
      const objectPayments = { ...objects.payments };
      const payments = Object.values(objectPayments).filter(
        (item) => item.guaranteeId === guarantee.id,
      );
      const confirmedAmount = payments
        .filter((payment) => payment.stateCode === 'COM')
        .reduce((result, payment) => result + Number(payment.amount), 0);
      const isProperty = guarantee.offers() != null && guarantee.offers().length > 0;
      const ratio = confirmedAmount / guarantee.totalAmount;
      const remaining = guarantee.totalAmount - confirmedAmount;
      const showRemaining = remaining > 0;
      const showAdvanced = ratio < 1;
      const paymentAmount = formatCLP(payment.amount);
      const guaranteeAmount = formatCLP(guarantee.totalAmount);
      const showPropertyCompleted = ratio >= 1 && isProperty;
      const showFurnitureCompleted = ratio >= 1 && !isProperty;
      const { auctionId } = guarantee;
      const orderBuy = payment.id.toString().padStart(8, '0');
      const source = payment?.webTransaction()?.source;

      setModalData({
        ...modalData,
        confirmedAmount,
        payments,
        isProperty,
        ratio,
        remaining,
        showRemaining,
        showAdvanced,
        paymentAmount,
        guaranteeAmount,
        showPropertyCompleted,
        showFurnitureCompleted,
        auctionId,
        orderBuy,
        source,
      });
    }
    // eslint-disable-next-line
  }, [guarantee, payment]);

  const redirectGuarantee = () => {
    onClose();
    history.push(`/login?redirectUrl=/guarantees?guaranteeId=${data.guaranteeId}`);
  };
  const redirectAuction = () => {
    onClose();
    window.open(externalLogin, '_blank', 'noopener');
  };

  return (
    <div>
      {payment !== undefined && (
        <div className={classes.root}>
          <h6 className={classes.title}>Pago por {modalData.source} completado</h6>
          <p className={classes.text}>
            Tu pago por {modalData.source} realizado el {date} a las {hour} ha sido confirmado. El
            pago completado es de {modalData.paymentAmount}.- (Orden de compra #{modalData.orderBuy}{' '}
            )
            <br />
            {modalData.showAdvanced &&
              ` Tienes garantía hoy por la suma de ${formatCLP(
                modalData.confirmedAmount,
              )} sobre un monto total a depositar de
              ${modalData.guaranteeAmount}`}
            {modalData.showRemaining &&
              `, te falta ${formatCLP(modalData.remaining)} para poder participar en el
              remate.`}
          </p>
          {modalData.showPropertyCompleted && (
            <>
              <p className={classes.text}>
                Tu garantía ha sido constituída por un monto total de {modalData.guaranteeAmount}.
                Para ingresar al remate podras hacerlo mediante
              </p>
              <Link color="inherit" className={classes.linkButton} onClick={redirectAuction}>
                este enlace
              </Link>
            </>
          )}
          {modalData.showFurnitureCompleted && (
            <>
              <p className={classes.text}>
                Tu garantía ha sido constituída por un monto total de {modalData.guaranteeAmount} y
                el monto ha sido sumado a tu saldo. Puedes participar en el remate! Para ingresar
                hazlo por
              </p>
              <Link color="inherit" className={classes.linkButton} onClick={redirectAuction}>
                este enlace
              </Link>
            </>
          )}
          <p className={classes.text}>
            Puede ver el detalle de su garantía y pagos ingresando al siguiente enlace:
          </p>
          <Link color="inherit" className={classes.linkButton} onClick={redirectGuarantee}>
            Ver detalle de garantía
          </Link>
        </div>
      )}
    </div>
  );
}

export default PaymentSuccess;
