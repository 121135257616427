import camelize from 'camelcase-keys-deep';

export default class LotCategory {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  auctionCategory() {
    return this.env().AuctionCategory.getById(this.auctionCategoryId);
  }

  area() {
    return this.auctionCategory()?.area();
  }

  lots() {
    return this.env().Lot.findAllBy('lotCategoryId', this.id);
  }
}
