/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react';

/* Material UI */
import {
  Snackbar,
  Box,
  Button,
  Grid,
  Link,
  ListItem,
  ListItemText,
  Hidden,
  useMediaQuery,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import MuiAlert from '@mui/material/Alert';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTheme } from '@mui/material/styles';

/* Router */
import { useHistory } from 'react-router-dom';

/* Project */
import { composedComponent, onChangeFnc } from 'utils/functions';
import { appActions } from 'commons/reducer/commons.reducer';
import { lotsActions } from 'modules/main/Lots/reducer/lots.reducer';
import { boxStyle, finishedBoxStyles } from 'commons/containers/Layout/layout.functions';
import ContactMessageForm from 'commons/components/ContactMessageForm/ContactMessageForm';
import saga from 'commons/saga/commons.saga';
import Footer from 'commons/components/Footer/Footer';
import GuaranteeConfirmationModal from 'commons/components/GuaranteeConfirmationModal/GuaranteeConfirmationModal';
import PaymentConfirmationModal from 'commons/components/PaymentConfirmationModal/PaymentConfirmationModal';
import SearchNav from 'commons/containers/Layout/components/SearchNav';
import SideBar from 'commons/components/SideBar/SideBar';
import NavBar from 'commons/components/NavBar/Navbar';
import HomeContactSection from 'commons/components/HomeContactSection/HomeContactSection';
import NoticeDialog from 'commons/components/NoticeDialog/NoticeDialog';
import AlertDialog from 'commons/components/AlertDialog/AlertDialog';
import styles from './styles';
import FormsContainer from '../FormsContainer/FormsContainer';
import { sendForm } from '../../components/ContactMessageForm/FormSalesForce';

function Alert(props) {
  const { children } = props;
  return (
    <MuiAlert elevation={6} variant="filled" {...props}>
      {children || ''}
    </MuiAlert>
  );
}

function Layout(props) {
  const {
    user,
    formControls,
    classes,
    searchControls,
    globals,
    bgImage,
    viewedGlobalDialog,
    layoutStyles,
    actions,
    contactLotId,
    objects,
    contactFormOpen,
    warning,
    warningMsg,
    gotoAuction,
    guaranteeModal,
    guaranteeData,
    paymentModal,
    paymentData,
    error,
    errorMsg,
    success,
    successMsg,
    gotoAuctionMsg,
    emailReminder,
    emailReminderMsg,
    children,
  } = props;

  const { propertiesIsLive, furnituresIsLive } = globals;

  const history = useHistory();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openFinished, setOpenFinished] = React.useState(false);

  let appStyle = {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const isRoot = window.location.pathname === '/';

  const fullUrl = window.location.href;
  const url = new URL(fullUrl);
  const areaId = url.searchParams.get('areaId');

  if (bgImage) {
    appStyle = {
      backgroundSize: 'cover',
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    };
  }

  const handleDrawer = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setOpen(!open);
  };

  const handleDrawerFinished = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setOpenFinished(!openFinished);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenFinished(false);
  };

  const logOut = useCallback(() => {
    localStorage.clear();
    actions.setUser(null);
  }, []);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
    // props.actions.toggleDrawer();
  };

  function toggleError() {
    actions.toggleError();
  }

  function toggleSuccess() {
    actions.toggleSuccess();
  }

  function toggleWarning() {
    actions.toggleWarning();
  }

  function toggleGotoAuction() {
    actions.toggleGotoAuction();
  }

  function toggleEmailReminder() {
    actions.toggleEmailReminder();
  }

  function toggleGuaranteeModal() {
    actions.toggleGuaranteeModal();
  }

  function togglePaymentModal() {
    actions.togglePaymentModal();
  }

  const handleItemClick = useCallback((module) => {
    history.push(module);
    toggleDrawer();
  }, []);

  const controlsChanged = useCallback((event, date) => {
    onChangeFnc(actions.formControlsChanged, event, date);
  }, []);

  const emailValid = (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value);

  function valueIsNotEmpty(value) {
    return value.toString().trim().length > 0;
  }

  function arrayIsNotEmpty(value) {
    return Array.isArray(value) && value.length > 0;
  }

  const contactMessageisValid = useMemo(() => {
    if (!(formControls.optIn && formControls.captcha)) {
      return false;
    }

    if (formControls.areaId === 1) {
      return (
        valueIsNotEmpty(formControls.areaId) &&
        valueIsNotEmpty(formControls.name) &&
        valueIsNotEmpty(formControls.email) &&
        emailValid(formControls.email) &&
        valueIsNotEmpty(formControls.phone) &&
        valueIsNotEmpty(formControls.message) &&
        formControls.phone.toString().trim().length === 9
      );
    }
    if (formControls.areaId === 2 && formControls.typePerson === 2) {
      return (
        valueIsNotEmpty(formControls.company) &&
        valueIsNotEmpty(formControls.lastName) &&
        valueIsNotEmpty(formControls.areaId) &&
        valueIsNotEmpty(formControls.name) &&
        valueIsNotEmpty(formControls.email) &&
        emailValid(formControls.email) &&
        valueIsNotEmpty(formControls.phone) &&
        valueIsNotEmpty(formControls.message) &&
        arrayIsNotEmpty(formControls.typeChannel) &&
        formControls.phone.toString().trim().length === 9
      );
    }
    return (
      valueIsNotEmpty(formControls.lastName) &&
      valueIsNotEmpty(formControls.areaId) &&
      valueIsNotEmpty(formControls.name) &&
      valueIsNotEmpty(formControls.email) &&
      emailValid(formControls.email) &&
      valueIsNotEmpty(formControls.phone) &&
      valueIsNotEmpty(formControls.message) &&
      arrayIsNotEmpty(formControls.typeChannel) &&
      formControls.phone.toString().trim().length === 9
    );
  }, [
    formControls.name,
    formControls.typePerson,
    formControls.email,
    formControls.phone,
    formControls.areaId,
    formControls.message,
    formControls.lastName,
    formControls.company,
    formControls.rut,
    formControls.typeBusiness,
    formControls.typeInterest,
    formControls.typeChannel,
    formControls.optIn,
    formControls.captcha,
  ]);
  const searchNav = (
    <SearchNav
      classes={classes}
      searchControls={searchControls}
      actions={actions}
      history={history}
    />
  );

  const boxStyles = boxStyle(open);
  const finishedStyles = finishedBoxStyles(openFinished);

  const splitUrl = url.pathname.split('/');
  const lotExist = splitUrl[1] === 'lots';
  const lotId = splitUrl[2] ? Number(splitUrl[2]) : null;

  const handleContactButton = () => {
    if (lotExist && lotId) {
      actions.toggleContactForm(lotId);
    } else {
      actions.toggleForm('contactFormOpen');
    }
  };

  const furnitureRedirect = () =>
    window.open(globals.manualFurnitureLink, '_blank', 'noopener,noreferrer');

  const propertyRedirect = () =>
    window.open(globals.manualPropertyLink, '_blank', 'noopener,noreferrer');

  const redirectToFinished = (areaID) =>
    history.push(`/${areaID}/finished_auctions?page=1&limit=10`);

  const contactButton = (
    <Box
      style={{
        ...boxStyles,
        width: 34,
        marginBottom: '1rem',
        height: 'auto',
      }}
      // onClick={() => props.actions.toggleForm('contactFormOpen')}
      onClick={handleContactButton}>
      <div className="lateral-wrapper" style={{ minHeight: 75 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PhoneIcon style={{ fontSize: 12 }} />}
          // className={classes.lateralButtons}
        >
          Contacto
        </Button>
      </div>
    </Box>
  );

  const howParticipateButton = (
    <Box style={boxStyles} onClick={handleDrawer}>
      <div className="lateral-wrapper">
        <Button>¿Cómo participar?</Button>
        <hr />
        <div className="lateral-content">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: '100%' }}>
            <Grid item xs={12} style={{}}>
              <ListItem
                button
                key="lateral-shorcut-furniture"
                className={classes.lateralListItem}
                component="a"
                onClick={furnitureRedirect}
                href={globals.manualFurnitureLink}
                target="_blank">
                <ListItemText primary="Bienes" />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <ListItem
                button
                key="lateral-shorcut-property"
                className={classes.lateralListItem}
                component="a"
                onClick={propertyRedirect}
                href={globals.manualPropertyLink}
                target="_blank">
                <ListItemText primary="Propiedades" />
              </ListItem>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );

  const finishedAuctions = (
    <Box style={finishedStyles} onClick={handleDrawerFinished}>
      <div className="lateral-wrapper">
        <Button>Remates finalizados</Button>
        <hr />
        <div className="lateral-content">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: '100%' }}>
            <Grid item xs={12} style={{}}>
              <ListItem
                button
                key="lateral-shorcut-furniture"
                className={classes.lateralListItem}
                component="a"
                onClick={() => redirectToFinished(1)}
                href="/1/finished_auctions?page=1&limit=10">
                <ListItemText primary="Bienes" />
              </ListItem>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );

  const lateralGrid = (
    <div
      style={{
        position: 'fixed',
        top: '20vh',
        zIndex: 99,
        right: 0,
        width: 17,
      }}>
      {contactButton}
      {howParticipateButton}
      {finishedAuctions}
    </div>
  );

  const handleCreate = () => {
    if (formControls.areaId === 2) {
      sendForm(formControls);
      // eslint-disable-next-line
      // if (formControls.areaId === 2){

      // }
      gtag('event', 'contact_form_prop', {
        area: 'Properties',
        user: user?.id,
      });
    }
    actions.createContactMessage({
      ...formControls,
      lotId: contactLotId,
    });
  };

  const contactForm = (
    <FormsContainer
      acceptLoading={formControls.loading}
      disabled={!contactMessageisValid}
      title="CONTACTO"
      createAction={handleCreate}
      onCancel={() => actions.toggleForm('contactFormOpen')}
      text={
        contactLotId
          ? 'Por favor llena tus datos e ingresa un mensaje'
          : 'Por favor llena tus datos, selecciona el área y categoría de lotes relacionada e ingresa un mensaje'
      }
      open={contactFormOpen}
      createButton="Enviar">
      <ContactMessageForm
        controlsChanged={controlsChanged}
        controls={formControls}
        objects={objects}
        actions={actions}
        contactLotId={contactLotId}
        areaId={areaId}
        disableSubmit={contactMessageisValid}
        handleCreate={handleCreate}
        allowSubmit
      />
    </FormsContainer>
  );

  return (
    // eslint-disable-next-line
    <div onClick={handleDrawerClose}>
      <NavBar
        {...props}
        logOut={logOut}
        user={user}
        objects={objects}
        toggleDrawer={toggleDrawer}
        actions={actions}
        SearchNav={searchNav}
      />
      <SideBar
        {...props}
        isOpen={openDrawer}
        toggleDrawer={toggleDrawer}
        globals={globals}
        handleItemClick={handleItemClick}
        actions={actions}
        balance={user?.balance}
        propertyBalance={user?.propertyBalance}
        propertiesIsLive={propertiesIsLive}
        furnituresIsLive={furnituresIsLive}
      />
      <div className="content-container" style={appStyle}>
        <Grid container justifyContent="center">
          <Grid
            container
            item
            md={10}
            sm={12}
            style={{
              minHeight: layoutStyles.minHeight,
              width: layoutStyles.width,
            }}>
            {children}
          </Grid>
          {isMobile && isRoot && (
            <HomeContactSection actions={actions} classes={classes} globals={globals} />
          )}
          <Footer />
        </Grid>
      </div>
      {globals.noticeTitle && <NoticeDialog globals={globals} viewed={viewedGlobalDialog} />}
      {guaranteeModal ? (
        <GuaranteeConfirmationModal
          open={!!guaranteeModal}
          onClose={() => toggleGuaranteeModal()}
          data={guaranteeData}
          objects={objects}
          globals={globals}
        />
      ) : null}

      <PaymentConfirmationModal
        open={paymentModal}
        onClose={() => togglePaymentModal()}
        data={paymentData}
        objects={objects}
        externalLogin={globals.externalLogin}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={error}
        autoHideDuration={3000}
        onClose={() => toggleError()}>
        <div>
          <Alert onClose={() => toggleError()} severity="error">
            {errorMsg || ''}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={success}
        autoHideDuration={3000}
        onClose={() => toggleSuccess()}>
        <div>
          <Alert onClose={() => toggleSuccess()} severity="success">
            {successMsg || ''}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={warning}
        autoHideDuration={3000}
        onClose={() => toggleWarning()}>
        <div>
          <Alert onClose={() => toggleWarning()} severity="warning">
            {warningMsg}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={gotoAuction}
        onClose={() => toggleGotoAuction()}
        action={
          <>
            <Button style={{ color: 'white' }} onClick={() => toggleGotoAuction()}>
              Cerrar
            </Button>
            <Button
              style={{ color: 'white' }}
              component={Link}
              href={`${globals.rolExternalLink}/auctionList`}
              target="_blank"
              rel="noopener noreferrer">
              Ir a remate
            </Button>
          </>
        }>
        <div>
          <Alert severity="info" onClose={() => toggleGotoAuction()}>
            {gotoAuctionMsg}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={emailReminder}
        onClose={() => toggleEmailReminder()}
        style={{ marginTop: '60px' }}
        action={
          <Button style={{ color: 'white' }} onClick={() => toggleEmailReminder()}>
            Cerrar
          </Button>
        }>
        <div>
          <Alert severity="success" onClose={() => toggleEmailReminder()}>
            {emailReminderMsg}
          </Alert>
        </div>
      </Snackbar>
      <AlertDialog />
      {contactForm}
      <Hidden xlDown>{lateralGrid}</Hidden>
    </div>
  );
}

export default withStyles(
  composedComponent(Layout, saga, {
    saga: 'sagaApp',
    states: [
      'app.drawerIsOpen',
      'app.deviceError',
      'app.device',
      'app.errorMsg',
      'app.error',
      'app.successMsg',
      'app.success',
      'app.warningMsg',
      'app.warning',
      'app.gotoAuctionMsg',
      'app.emailReminderMsg',
      'app.guaranteeModal',
      'app.guaranteeData',
      'app.participateModal',
      'app.paymentModal',
      'app.paymentData',
      'app.gotoAuction',
      'app.emailReminder',
      'app.user',
      'app.objects',
      'app.formControls',
      'app.contactFormOpen',
      'app.contactLotId',
      'app.searchControls',
      'app.isLoading',
      'app.globals',
      'app.bgImage',
      'app.viewedGlobalDialog',
      'app.layoutStyles',
    ],
    actions: [
      {
        ...appActions,
        resetFilters: lotsActions.resetFilters,
        controlsChanged: lotsActions.controlsChanged,
      },
    ],
  }),
  styles,
);
