import { spawn, takeLatest, put } from 'redux-saga/effects';
import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { get, objectToQueryString } from 'utils/api/api';
import { DirectSaleFeaturesActions } from '../actions/DirectSaleFeatures.actions';

function* initialQuery() {
  yield takeLatest(DirectSaleFeaturesActions.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));
    const successFunction = (payload) => ({
      type: DirectSaleFeaturesActions.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: DirectSaleFeaturesActions.GET_STATE_FROM_API_FAIL });

    yield controlledCall(get, 'api/v1/direct_sale', undefined, successFunction, failFunction);
    yield put(appActions.setLoading(false));
  });
}

function* filterLots() {
  yield takeLatest(DirectSaleFeaturesActions.FILTER_LOTS, function* (action) {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: DirectSaleFeaturesActions.FILTER_LOTS_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: DirectSaleFeaturesActions.FILTER_LOTS_FAIL });

    const { offset, limit, categoryId, brandId, modelId, category } = action.payload;
    const params = {
      offset,
      limit,
      categoryId,
      brandId,
      brandModelId: modelId,
    };

    yield controlledCall(
      get,
      `api/v1/direct_sale/${category}?${objectToQueryString(params)}`,
      undefined,
      successFunction,
      failFunction,
    );
    yield put(appActions.setLoading(false));
  });
}

export default function* DirectSaleFeaturesSaga() {
  yield spawn(initialQuery);
  yield spawn(filterLots);
}
