import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

/* Redux */
import { useDispatch } from 'react-redux';

/* Router */
import { useHistory, useLocation } from 'react-router-dom';

/* Project */
import { LOG_OUT } from 'commons/reducer/commons.reducer';

const excludePaths = ['/login', '/register'];

function RenewSessionDialog(props) {
  const { open, setOpenLoginDialog } = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  if (excludePaths.includes(location.pathname)) {
    return <></>;
  }

  const redirectToLogin = () => {
    setOpenLoginDialog(false);

    dispatch({ type: LOG_OUT });

    localStorage.clear();

    const encoded = encodeURIComponent(location.pathname + location.search);
    const fullPath = `/login?redirectUrl=${encoded}`;

    history.push(fullPath);
  };

  const close = () => setOpenLoginDialog(false);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={close}>
      <DialogTitle>
        <Typography>Advertencia</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Esta sesión está por caducar, se recomienda guardar cambios e iniciar sesión nuevamente.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={redirectToLogin}>
          Ir a login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RenewSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenLoginDialog: PropTypes.func.isRequired,
};

export default RenewSessionDialog;
