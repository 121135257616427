import { validateTaxNumber, validateEmail } from './functions';

const passwordRegex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;

export const VALIDATE = {
  // eslint-disable-next-line
  REQUIRED: (value) => value != null && value.toString().trim().length > 0,
  MIN_LENGTH: (value, length) =>
    VALIDATE.REQUIRED(value) && value.toString().trim().length >= length,
  MAX_LENGTH: (value, length) =>
    VALIDATE.REQUIRED(value) && value.toString().trim().length <= length,
  PASSWORD: (value) => VALIDATE.MIN_LENGTH(value, 6) && VALIDATE.MAX_LENGTH(value, 20),
  SIGNUP_PASSWORD: (value) => {
    const reg = passwordRegex.test(value);
    return VALIDATE.MIN_LENGTH(value, 6) && VALIDATE.MAX_LENGTH(value, 20) && reg;
  },
  LENGTH: (value, min, max) => VALIDATE.MIN_LENGTH(value, min) && VALIDATE.MAX_LENGTH(value, max),
  RUT: (rut) =>
    validateTaxNumber(rut) &&
    VALIDATE.MIN_LENGTH(rut?.toString().replace(/[-.]/g, '').trim() ?? '', 7),
  EMAIL: validateEmail,
};

export const VALIDATIONS = {
  REQUIRED: {
    check: VALIDATE.REQUIRED,
    message: 'Campo requerido',
  },
  PASSWORD: {
    check: VALIDATE.PASSWORD,
    message: 'Campo requerido, entre 6 y 20 caracteres',
  },
  SIGNUP_PASSWORD: {
    check: VALIDATE.SIGNUP_PASSWORD,
    message: 'Campo requerido, entre 6 y 20 caracteres. Min. 1 mayúscula, minúscula y número',
  },
  MIN_LENGTH: (length) => ({
    check: (value) => VALIDATE.MIN_LENGTH(value, length),
    message: `Mínimo ${length} caracteres`,
  }),
  MAX_LENGTH: (length) => ({
    check: (value) => VALIDATE.MAX_LENGTH(value, length),
    message: `Máximo ${length} caracteres`,
  }),
  LENGTH: (min, max) => ({
    check: (value) => VALIDATE.LENGTH(value, min, max),
    message: `Campo debe tener entre ${min} y ${max} caracteres`,
  }),
  RUT: {
    check: VALIDATE.RUT,
    message: 'Debe ser un RUT válido, mínimo 6 dígitos',
  },
  EMAIL: {
    check: VALIDATE.EMAIL,
    message: 'Debe ser un email válido',
  },
};
