import { put, spawn, takeLatest } from 'redux-saga/effects';

import controlledCall from 'utils/services/controlledSaga';
import { destroy, get, post, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { USERS, usersActions } from '../reducer/users.reducer';

function* initialQuery() {
  yield takeLatest(USERS.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('filter-users', true));
    const { roleId, userSearch, sellerSearch } = action.filters;
    let filters = {
      ...action.filters,
      roleId: roleId === '-1' ? undefined : roleId,
      userSearch: userSearch.trim(),
      sellerSearch: sellerSearch.trim(),
    };
    filters = Object.keys(filters).reduce((result, filter) => {
      if (filters[filter] == null) {
        return result;
      }
      result[filter] = filters[filter];
      return result;
    }, {});
    yield controlledCall(
      get,
      `api/v1/users?${new URLSearchParams(filters)}`,
      undefined,
      usersActions.initialQuerySuccess,
      usersActions.initialQueryFail,
    );
    yield put(appActions.setLoader('filter-users', false));
  });
}

function* deleteUser() {
  yield takeLatest(USERS.DELETE_USER, function* (action) {
    yield controlledCall(
      destroy,
      `api/v1/users/${action.controls.user.id}`,
      undefined,
      usersActions.deleteUserSuccess,
    );
  });
}

function* roleAssigment() {
  yield takeLatest(USERS.ROLE_ASSIGMENT, function* (action) {
    const { areaId, roleId } = action.controls;
    let userAreaId = areaId;

    if (userAreaId === '-1' && roleId === '-1') {
      userAreaId = null;
    } else if (areaId === '-1' && roleId > 1) {
      userAreaId = 1;
    }

    const controls = {
      ...action.controls,
      roleId: roleId === '-1' ? null : roleId,
      areaId: userAreaId,
    };

    const result = yield controlledCall(
      update,
      `api/v1/users/${action.userId}/assignRole`,
      controls,
      usersActions.roleAssigmentSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se cambió el rol del usuario exitosamente'));
    }
  });
}

function* saveSeller() {
  yield takeLatest(USERS.SAVE_SELLER, function* (action) {
    yield controlledCall(
      post,
      `api/v1/users/${action.userId}/seller`,
      action.controls,
      usersActions.saveSellerSuccess,
      usersActions.saveSellerFail,
    );
  });
}

function* resetPasswordMail() {
  yield takeLatest(USERS.RESET_PASSWORD_MAIL, function* (action) {
    const result = yield controlledCall(
      post,
      'api/v1/users/admin_reset_password',
      { email: action.email },
      usersActions.resetPasswordMailSuccess,
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Se envió correo exitosamente'));
    }
  });
}

function* toggleEnableUser() {
  yield takeLatest(USERS.HANDLE_ENABLE_USER, function* (action) {
    const { id } = action.payload;

    const successFunction = (payload) => ({ type: USERS.HANDLE_ENABLE_USER_SUCCESS, payload });

    const result = yield controlledCall(
      update,
      `api/v1/users/${id}/toggle_enable`,
      undefined,
      successFunction,
    );

    if (!result.error) {
      yield put(appActions.setSuccess('Se actualizó el usuario'));
    }
  });
}

function* getResetPasswordLink() {
  yield takeLatest(USERS.RESET_PASSWORD_LINK, function* (action) {
    const { id } = action.payload;

    const successFunction = (payload) => ({ type: USERS.RESET_PASSWORD_LINK_SUCCESS, payload });

    const result = yield controlledCall(
      get,
      `api/v1/users/${id}/reset_password_link`,
      undefined,
      successFunction,
    );

    if (!result.error) {
      yield put({ type: USERS.TOGGLE_RESET_LINK_DIALOG, payload: { path: result.path } });
    }
  });
}

function* getUserAdjudications() {
  yield takeLatest(USERS.GET_USER_ADJUDICATIONS, function* (action) {
    const { id } = action.payload;

    const successFunction = (payload) => ({ type: USERS.GET_USER_ADJUDICATIONS_SUCCESS, payload });
    const failFunction = (payload) => ({ type: USERS.GET_USER_ADJUDICATIONS_FAIL, payload });

    yield controlledCall(
      get,
      `api/v1/users/${id}/adjudications`,
      undefined,
      successFunction,
      failFunction,
    );
  });
}

export default function* usersSaga() {
  yield spawn(resetPasswordMail);
  yield spawn(initialQuery);
  yield spawn(roleAssigment);
  yield spawn(deleteUser);
  yield spawn(saveSeller);
  yield spawn(toggleEnableUser);
  yield spawn(getResetPasswordLink);
  yield spawn(getUserAdjudications);
}
