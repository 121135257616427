import camelize from 'camelcase-keys-deep';

export default class Brand {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  lotCategory() {
    return this.env().LotCategory.getById(this.lotCategoryId);
  }

  models() {
    return this.env().BrandModel.findAllBy('brandId', this.id);
  }

  lots() {
    return this.models().map((model) => model.lots());
  }
}
