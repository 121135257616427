import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'orderLots/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const RESET_CONTROLS = `${PATH}RESET_CONTROLS`;
const ADD_LOT_OFFSET = `${PATH}ADD_LOT_OFFSET`;

// Global constants for saga
export const ORDER_LOTS = {
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  GET_AUCTION_LOTS_FROM_API_SUCCESS: `${PATH}GET_AUCTION_LOTS_FROM_API_SUCCESS`,
  GET_AUCTION_LOTS_FROM_API_FAIL: `${PATH}GET_AUCTION_LOTS_FROM_API_FAIL`,
  GET_AUCTION_LOTS_FROM_API: `${PATH}GET_AUCTION_LOTS_FROM_API`,
  UPDATE_COLUMN_INDEX: `${PATH}UPDATE_COLUMN_INDEX`,
  SORT_LOTS: `${PATH}SORT_LOTS`,
  CREATE_LOTS_WITH_RIGHTS_FAIL: `${PATH}CREATE_LOTS_WITH_RIGHTS_FAIL`,
  CREATE_LOTS_WITH_RIGHTS_SUCCESS: `${PATH}CREATE_LOTS_WITH_RIGHTS_SUCCESS`,
};

// actions
export const orderLotsActions = {
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  resetControls: createAction(RESET_CONTROLS),
  addLotOffset: createAction(ADD_LOT_OFFSET, 'lotId', 'offSet'),
  // get state from api
  initialQuerySuccess: createAction(ORDER_LOTS.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(ORDER_LOTS.INITIAL_QUERY_FAIL, 'payload'),
  initialQuery: createAction(ORDER_LOTS.INITIAL_QUERY, 'controls'),
  // get state from api
  getAuctionLotsFromApiSuccess: createAction(
    ORDER_LOTS.GET_AUCTION_LOTS_FROM_API_SUCCESS,
    'payload',
  ),
  getAuctionLotsFromApiFail: createAction(ORDER_LOTS.GET_AUCTION_LOTS_FROM_API_FAIL, 'payload'),
  getAuctionLotsFromApi: createAction(ORDER_LOTS.GET_AUCTION_LOTS_FROM_API, 'controls'),
  sortLots: createAction(ORDER_LOTS.SORT_LOTS, 'lots'),
};
const mainColumn = { name: '', items: [], mainColumn: true };
const defaultControler = {
  test: '',
  auctionId: '',
  columns: [mainColumn],
  getAuctionLots: 1,
  idsGroupsRemoved: [],
  message: '',
  error: false,
  notification: false,
  initialQuery: false,
  columnDialogOpen: false,
  columnIndexUpdate: null,
  columnImages: {},
  infoDialogOpen: false,
  infoDialogError: null,
  infoColumn: {},
};
const initialState = {
  controls: defaultControler,
};

const orderLots = createReducer(initialState, {
  [ADD_LOT_OFFSET](state, action) {
    const index = state.controls.columns[0].items.findIndex((item) => item.id === action.lotId);
    state.controls.columns[0].items[index].offSet = action.offSet;
  },
  [RESET_CONTROLS](state) {
    state.controls = defaultControler;
  },
  [TOGGLE_FORM](state, action) {
    state.controls[action.modal] = !state.controls[action.modal];
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [ORDER_LOTS.INITIAL_QUERY_SUCCESS](state) {
    state.controls = {
      ...state.controls,
      initialQuery: true,
    };
  },
  [ORDER_LOTS.GET_AUCTION_LOTS_FROM_API_SUCCESS](state) {
    state.controls = {
      ...state.controls,
      getAuctionLots: state.controls.getAuctionLots + 1,
    };
  },
  [ORDER_LOTS.CREATE_LOTS_WITH_RIGHTS_SUCCESS](state) {
    state.controls = {
      ...state.controls,
      notification: true,
      message: 'Lotes ordenados y lotes con derecho creados',
    };
  },
  [ORDER_LOTS.CREATE_LOTS_WITH_RIGHTS_FAIL](state) {
    state.controls = {
      ...state.controls,
      notification: true,
      message: 'Ocurrio un error inesperado',
    };
  },
  [ORDER_LOTS.UPDATE_COLUMN_INDEX](state, action) {
    state.controls = {
      ...state.controls,
      columns: action.columns,
      columnDialogOpen: false,
      columnIndexUpdate: null,
    };
  },
});

export default orderLots;
