import { put, spawn, takeLatest, select } from 'redux-saga/effects';

import { apiSuccess, get, objectToQueryString, post, update } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { getItemInStorage, unformatParse } from 'utils/functions';
import { appActions } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { GUARANTEES_UNIFICATION, guaranteesActions } from '../reducer/newGuarantee.reducer';

function* initialQuery() {
  yield takeLatest(GUARANTEES_UNIFICATION.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('filter-guarantees', true));
    let filters = {
      ...action.filters,
      minAmount: unformatParse(action.filters.minAmount),
      maxAmount: unformatParse(action.filters.maxAmount),
    };
    filters = Object.keys(filters).reduce((result, filter) => {
      if (filters[filter] == null) {
        return result;
      }
      // eslint-disable-next-line no-param-reassign
      result[filter] = filters[filter];
      return result;
    }, {});
    yield controlledCall(
      get,
      `api/v1/guarantees?${new URLSearchParams(filters)}`,
      undefined,
      guaranteesActions.initialQuerySuccess,
      guaranteesActions.initialQueryFail,
    );
    yield put(appActions.setLoader('filter-guarantees', false));
  });
}

function* acknowledgeRefund() {
  yield takeLatest(GUARANTEES_UNIFICATION.ACKNOWLEDGE_REFUND, function* (action) {
    yield put(appActions.setLoading(true));

    const result = yield controlledCall(
      post,
      `api/v1/guarantees/${action.id}/refund/acknowledge?undo=${action.undo}`,
      undefined,
      guaranteesActions.acknowledgeRefundSuccess,
    );

    if (!result?.error) {
      // eslint-disable-next-line no-unused-expressions
      action.undo
        ? yield put(appActions.setSuccess('Se deshizo la devolución de la garantía exitosamente'))
        : yield put(appActions.setSuccess('Se marcó la garantía como "devuelta" exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* changeAmount() {
  yield takeLatest(GUARANTEES_UNIFICATION.CHANGE_AMOUNT, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      post,
      `api/v1/guarantees/${action.id}/changeAmount`,
      { amount: action.amount },
      guaranteesActions.changeAmountSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se modificó el monto de la garantía exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* setFlag() {
  yield takeLatest(GUARANTEES_UNIFICATION.SET_FLAG, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      post,
      `api/v1/guarantees/${action.id}/setSyncFlag`,
      { syncFlag: action.syncFlag },
      guaranteesActions.setFlagSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se marcó la garantía exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* unsetFlag() {
  yield takeLatest(GUARANTEES_UNIFICATION.UNSET_FLAG, function* (action) {
    yield put(appActions.setLoading(true));
    const result = yield controlledCall(
      update,
      `api/v1/guarantees/${action.id}/unsetSyncFlag`,
      {},
      guaranteesActions.unsetFlagSuccess,
      guaranteesActions.unsetFlagFail,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se desmarcó la garantía exitosamente'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* getReport() {
  yield takeLatest(GUARANTEES_UNIFICATION.GET_REPORT, function* (action) {
    yield put(appActions.setLoading(true));
    yield put(appActions.setLoader('export-guarantees', true));
    const socketId = yield select((state) => state.app.socketId);
    const filters = objectToQueryString({ ...action.payload, socketId });
    const result = yield controlledCall(
      get,
      `api/v1/guarantees/getReport?${filters}`,
      undefined,
      guaranteesActions.doNothing,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Generando reporte'));
    } else {
      yield put(appActions.setLoader('export-guarantees', false));
      yield put(appActions.setError('Error al generar el reporte'));
    }
    yield put(appActions.setLoading(false));
  });
}

function* acceptReceipt() {
  yield takeLatest(GUARANTEES_UNIFICATION.ACCEPT_RECEIPT, function* (action) {
    yield put(appActions.setLoader('process-payment', true));
    const result = yield controlledCall(
      post,
      `api/v1/receipts/${action.paymentId}/confirm`,
      { paymentsUrl: `${window.location.origin}/payments` },
      guaranteesActions.acceptReceiptSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha aprobado exitosamente el comprobante'));
    }
    yield put(appActions.setLoader('process-payment', false));
  });
}

function* rejectReceipt() {
  yield takeLatest(GUARANTEES_UNIFICATION.REJECT_RECEIPT, function* (action) {
    yield put(appActions.setLoader('process-payment', true));
    const body = {
      paymentsUrl: `${window.location.origin}/payments`,
      observations: action.notes,
    };

    const result = yield controlledCall(
      post,
      `api/v1/receipts/${action.paymentId}/reject`,
      body,
      guaranteesActions.rejectReceiptSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha rechazado exitosamente el comprobante'));
    }
    yield put(appActions.setLoader('process-payment', false));
  });
}

function* editAmount() {
  yield takeLatest(GUARANTEES_UNIFICATION.EDIT_AMOUNT, function* (action) {
    yield put(appActions.setLoader('process-payment', true));
    const result = yield controlledCall(
      update,
      `api/v1/receipts/${action.paymentId}`,
      { amount: unformatParse(action.amount) },
      guaranteesActions.editAmountSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha editado exitosamente el monto comprobante'));
    }
    yield put(appActions.setLoader('process-payment', false));
  });
}

function* retryPayment() {
  yield takeLatest(GUARANTEES_UNIFICATION.RETRY_PAYMENT, function* (action) {
    const result = yield controlledCall(
      post,
      `api/v1/payments/${action.id}/retry`,
      undefined,
      guaranteesActions.retryPaymentSuccess,
    );

    if (!result?.error) {
      history.push(`/payments/new?guaranteeId=${action.guaranteeId}`);
    }
  });
}

function* undoPayment() {
  yield takeLatest(GUARANTEES_UNIFICATION.UNDO_PAYMENT, function* (action) {
    const result = yield controlledCall(
      post,
      `api/v1/payments/${action.id}/undo`,
      undefined,
      guaranteesActions.undoPaymentSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se deshizo la aprobación o rechazo del pago'));
    } else {
      yield put(
        appActions.setSuccess('Ocurrió un error al deshacer la aprobación o rechazo del pago'),
      );
    }
  });
}

function* enablePayment() {
  yield takeLatest(GUARANTEES_UNIFICATION.ENABLE_PAYMENT, function* (action) {
    const result = yield post(`api/v1/payments/enable/${action.id}`, undefined);

    if (!result?.error) {
      yield put(apiSuccess(GUARANTEES_UNIFICATION.ENABLE_PAYMENT_SUCCESS, result));
      yield put(appActions.setSuccess('Se habilitó el pago de forma manual'));
    } else {
      yield put(apiSuccess(GUARANTEES_UNIFICATION.ENABLE_PAYMENT_FAIL, result));
      yield put(appActions.setError(result.errorMsg));
    }
  });
}

function* disablePayment() {
  yield takeLatest(GUARANTEES_UNIFICATION.DISABLE_PAYMENT, function* (action) {
    const result = yield post(`api/v1/payments/disable/${action.id}`, undefined);

    if (!result?.error) {
      yield put(apiSuccess(GUARANTEES_UNIFICATION.DISABLE_PAYMENT_SUCCESS, result));
      yield put(appActions.setSuccess('Se deshabilitó el pago de forma manual'));
    } else {
      yield put(apiSuccess(GUARANTEES_UNIFICATION.DISABLE_PAYMENT_FAIL, result));
      yield put(appActions.setSuccess('Ocurrió un error al intentar deshabilitar el pago'));
    }
  });
}

function* rejectPayment() {
  yield takeLatest(GUARANTEES_UNIFICATION.REJECT_PAYMENT, function* (action) {
    const result = yield post(`api/v1/payments/reject/${action.id}`, undefined);

    if (!result?.error) {
      yield put(apiSuccess(GUARANTEES_UNIFICATION.REJECT_PAYMENT_SUCCESS, result));
      yield put(appActions.setSuccess('Se deshabilitó el pago de forma manual'));
    } else {
      yield put(apiSuccess(GUARANTEES_UNIFICATION.REJECT_PAYMENT_FAIL, result));
      yield put(appActions.setSuccess('Ocurrió un error al intentar deshabilitar el pago'));
    }
  });
}

export default function* newGuaranteeSaga() {
  yield spawn(initialQuery);
  yield spawn(acknowledgeRefund);
  yield spawn(changeAmount);
  yield spawn(setFlag);
  yield spawn(unsetFlag);
  yield spawn(getReport);
  yield spawn(acceptReceipt);
  yield spawn(rejectReceipt);
  yield spawn(editAmount);
  yield spawn(retryPayment);
  yield spawn(undoPayment);
  yield spawn(enablePayment);
  yield spawn(disablePayment);
  yield spawn(rejectPayment);
}
