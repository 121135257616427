import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { formatDate, formatTime } from 'utils/moment';
import { formatCLP } from 'utils/functions';
import styles from './styles';
import tattersallLogo from '../../../utils/images/logo-tattersall.png';

function GuaranteeConfirmationModal(props) {
  // TODO: Refactorizar codigo como en la modal de pago success
  const useStyles = makeStyles()(styles);
  const { open, onClose, data, objects, globals } = props;
  const { classes } = useStyles();
  const [modalData, setmodalData] = useState({
    lotId: null,
    lotData: null,
    now: null,
    date: null,
    hour: null,
    guaranteeData: null,
    amount: null,
    biddingEmail: null,
    isBidding: null,
    area: null,
  });

  useEffect(() => {
    if (data !== undefined) {
      const lotId = data && data.isProperty && data.lots[0].id;
      const lotData = lotId && objects.lots[lotId];
      const now = Date.now();
      const date = formatDate(now);
      const hour = formatTime(now);
      const guaranteeData = data?.createdGuarantee && Object.values(data?.createdGuarantee);
      const amount = guaranteeData && formatCLP(guaranteeData[0].totalAmount);
      const biddingEmail = lotData?.biddingEmail
        ? lotData?.biddingEmail
        : globals.defaultPropertyBiddingEmail;
      const isBidding = data && data.createdGuaranteeAuctionType === 'BID';
      const area = data && data.isProperty ? 'propiedades' : 'bienes muebles';
      setmodalData({
        ...modalData,
        lotId,
        lotData,
        now,
        date,
        hour,
        guaranteeData,
        amount,
        biddingEmail,
        isBidding,
        area,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="simple-dialog-title" open={!!open}>
      <DialogContent>
        <Grid container spacing={2}>
          <div className={classes.root}>
            <h6 className={classes.title}>Garantía por pagar</h6>
            {data && (
              <p className={classes.text}>
                Bienvenido y gracias por inscribir tu garantía para {modalData.area} el{' '}
                {modalData.date} a las {modalData.hour}.
                <br />
                La garantía que has inscrito es por un monto total de {modalData.amount}. Ahora,
                para participar del remate, debes pagarla, ya sea por Webpay, Transferencia o
                Depósito.
                <br />
                {modalData.isBidding &&
                  data.isProperty &&
                  `Una vez realizados los pagos, enviar la oferta al siguiente correo notarial : ${modalData.biddingEmail}`}
              </p>
            )}
          </div>
          <div className={classes.imageContainer}>
            <img className={classes.imageLogo} src={tattersallLogo} alt="tattersall-logo" />
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GuaranteeConfirmationModal;
