import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { Link } from 'react-router-dom';

const iconStyle = {
  fontSize: 110,
  color: '#ff9800',
  paddingTop: '15px',
};
const iconContainer = {
  display: 'flex',
  justifyContent: 'center',
};
const title = {
  textAlign: 'center',
  padding: 0,
};
const sub = {
  textAlign: 'center',
  fontWeight: 500,
};
const dialogContent = {
  textAlign: 'center',
};

function FailModal(props) {
  const { controls } = props;
  const showForgetPassword = controls.errors.sameEmail || controls.errors.sameRut;

  return (
    <Dialog
      fullWidth
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}>
      <div style={iconContainer}>
        <WarningIcon style={iconStyle} />
      </div>
      <DialogTitle style={title}>Error al registrar usuario</DialogTitle>
      <DialogContent style={dialogContent}>
        <Typography style={sub}>{controls.errorMsg} </Typography>
        {showForgetPassword && (
          <Typography variant="caption" color="primary">
            <Link
              to="/reset_password"
              style={{ textDecoration: 'none', color: '#0d47a1', fontSize: '1rem' }}
              variant="inherit"
              color="primary">
              restablecer contraseña
            </Link>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FailModal;
