import camelize from 'camelcase-keys-deep';

export default class Commune {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  billingData() {
    return this.env().BillingData.findAllBy('communeId', this.id);
  }

  locations() {
    return this.env().Location.findAllBy('communeId', this.id);
  }

  region() {
    return this.env().Region.getById(this.regionId);
  }
}
