// import { min } from 'moment';
// import furnitureImg from 'utils/images/Furniture.png';
// import propertiesImg from 'utils/images/Properties.png';
const heightSm = '100vh';
const widthSm = '50vw';
const heightXs = 'calc(80vh - 81px)';
const widthXs = '100vw';
const white = '#FFFFFF';
const blue = '#002F77';
const hoverBlue = '#002f77cf';
const lightBlue = '#6AAAE4';
const hoverLightBlue = '#73aae4d4';
const grayButtons = '#A9A7B0';
const grayHoverButtons = '#a9a7b0e0';
const styles = (theme) => ({
  buttonContainer: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '4vh',
    },
  },
  detailsLeftButton: {
    paddingRight: theme.spacing(1),
    '&>button': {
      minHeight: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: theme.spacing(1),
    },
  },
  detailsRightButton: {
    paddingLeft: theme.spacing(1),
    '&>button': {
      minHeight: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  onLiveButton: {
    color: blue,
    backgroundColor: white,
    height: '4vh',
    width: '12vw',
    paddingTop: 8,
    paddingRight: 12,
    paddingBottom: 8,
    paddingLeft: 11,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4vh',
      marginTop: 0,
      width: '100%',
    },
    marginTop: '1.5vh',
    verticalAlign: 'top',
    borderRadius: '10px',
    border: `solid ${blue} 2.3px`,
    '&:hover': {
      backgroundColor: '#ffffffcf',
    },
  },
  detailsTitle: {
    color: '#FFFFFF',
    fontSize: '5.0vh',
    fontFamily: ['Roboto'],
    fontWeight: '700',
    display: 'inline-block',
    textShadow: `0px 0px 4px ${grayButtons}`,
    [theme.breakpoints.down('lg')]: {
      fontWeight: '330',
      fontSize: '3.6vh',
    },
  },
  detailsCol: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  detailsHeader: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    height: heightXs,
    minHeight: '450px',
    [theme.breakpoints.up('xl')]: {
      minHeight: '648px',
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '450px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '43vh',
      minHeight: '245px',
      paddingLeft: theme.spacing(18),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(18),
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(42vh - 8px)',
      minHeight: '245px',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(1),
    },
  },
  detailsContainer: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '84vh',
    marginTop: '10vh',
    zIndex: '3',
    color: 'white',
    fontFamily: ['Roboto'],
    fontWeight: '200',
    [theme.breakpoints.down('lg')]: {
      marginTop: '64px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 'max(10vh,90px)',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '70px',
    },
  },
  overlayBlue: {
    position: 'relative',
    '&::after': {
      backgroundColor: '#002F87',
      width: '100%',
      height: '84vh',
      top: '0',
      left: '0',
      position: 'absolute',
      opacity: '0.8',
    },
  },
  areaImage: {
    paddingTop: 4,
    paddingBottom: 4,
    width: '5.2vw',
    height: '2.7vw',
    objectFit: 'contain',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      top: 10,
      width: '2.9vh',
      height: 'auto',
      position: 'absolute',
    },
  },
  areaText: {
    fontFamily: 'MyriadSemibold',
  },
  customImage: {
    width: '2.9vw',
    height: '2.9vw',
    objectFit: 'contain',
    [theme.breakpoints.down('lg')]: {
      width: '3.5vh',
      height: 'auto',
    },
  },
  containerBack: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    maxHeight: '100vh',
    zIndex: 2,
    // overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      marginTop: '10vh',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 'max(10vh, 90px)',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '70px',
    },
  },
  containerFilterBack: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '84vh',
    zIndex: 3,
    [theme.breakpoints.down('lg')]: {
      marginTop: '8vh',
    },
  },
  sideContainer: {
    padding: '2vw',
    [theme.breakpoints.down('lg')]: {
      padding: '3vw',
      paddingLeft: '5vw',
      paddingRight: '5vw',
    },
  },
  backFurniture: {
    backgroundRepeat: 'no-repeat',
    // backgroundSize: 'auto 100vh',
    backgroundSize: 'cover',
    backgroundColor: '#EDEDED',
    width: '60vw',
    height: heightSm,
    minHeight: '500px',
    [theme.breakpoints.up('xl')]: {
      minHeight: '725px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '90vh',
      minHeight: '491px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '43vh',
      width: widthXs,
      minHeight: '245px',
      backgroundSize: 'cover',
      '-webkit-clip-path': '',
      'clip-path': '',
    },
    [theme.breakpoints.down('md')]: {
      height: '47vh',
      backgroundSize: 'auto 100vh',
    },
  },
  backProperties: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: widthSm,
    height: heightSm,
    backgroundColor: '#EDEDED',
    minHeight: '500px',
    [theme.breakpoints.up('xl')]: {
      minHeight: '725px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '92vh',
      minHeight: '500px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '43vh',
      minHeight: '245px',
      width: widthXs,
      backgroundSize: 'cover',
      '-webkit-clip-path': '',
      'clip-path': '',
    },
    [theme.breakpoints.down('md')]: {
      height: '42vh',
      // minHeight: 0,
      backgroundSize: 'auto 100vh',
    },
    [theme.breakpoints.up('md')]: {
      '-webkit-clip-path': 'polygon(0 0, 53% 0, 47% 100%, 0 100%)',
      'clip-path': 'polygon(0 0, 53% 0, 47% 100%, 0 100%)',
    },
  },
  furnitureFilterColor: {
    backgroundColor: '#6396C3',
    opacity: 0.6,
    width: widthSm,
    height: heightSm,
    [theme.breakpoints.down('lg')]: {
      width: widthXs,
      height: heightXs,
    },
  },
  PropertiesfilterColor: {
    backgroundColor: '#002F87',
    opacity: 0.6,
    width: widthSm,
    height: heightSm,
    [theme.breakpoints.down('lg')]: {
      width: widthXs,
      height: heightXs,
    },
  },
  marginTop: {
    marginTop: '3.5vh',
    [theme.breakpoints.down('lg')]: {
      marginTop: '1.4vh',
    },
  },
  bigButton: {
    color: white,
    backgroundColor: blue,
    borderRadius: '6px',
    fontSize: '1.9vh',
    padding: 6,
    paddingTop: 12,
    paddingBottom: 12,
    fontWeight: '600',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: 3,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '1.9vh',
      width: '100%',
    },
    [theme.breakpoints.only('lg')]: {
      height: '60px',
      fontSize: '1.4vh',
    },
    [theme.breakpoints.down('md')]: {
      padding: 3,
      paddingTop: 4,
      paddingBottom: 4,
      fontSize: '1.7vh',
      width: '100%',
    },
    '&:hover': {
      backgroundColor: hoverBlue,
    },
  },
  bigLightBlueButton: {
    color: white,
    backgroundColor: lightBlue,
    borderRadius: '6px',
    fontSize: '1.8vh',
    padding: 6,
    paddingTop: 12,
    paddingBottom: 12,
    fontWeight: '600',
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      padding: 3,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '1.7vh',
      width: '100%',
    },
    [theme.breakpoints.only('lg')]: {
      height: '60px',
      fontSize: '1.4vh',
    },
    [theme.breakpoints.down('md')]: {
      padding: 3,
      paddingTop: 4,
      paddingBottom: 4,
      fontSize: '1.7vh',
      width: '100%',
    },
    '&:hover': {
      backgroundColor: hoverLightBlue,
    },
  },
  shortcutButton: {
    color: blue,
    backgroundColor: grayHoverButtons,
    border: '0px',
    opacity: 0.85,
    fontSize: '2.3vh',
    fontFamily: ['Roboto'],
    fontWeight: '400',
    borderRadius: '6px',
    '-webkit-box-shadow': '-2px 0px 22px -7px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '-2px 0px 22px -7px rgba(0,0,0,0.75)',
    'box-shadow': '-2px 0px 22px -7px rgba(0,0,0,0.75)',
    justifyContent: 'space-between',
    paddingLeft: '6%',
    paddingRight: '6%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: grayButtons,
      opacity: 0.95,
    },
  },
  gavelIcon: {
    [theme.breakpoints.down('lg')]: {
      width: '3.8vw',
      height: 'auto',
    },
  },
  furnitureMargin: {
    [theme.breakpoints.down('lg')]: {
      marginTop: 35,
    },
  },
  spacingContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0),
    },
  },
  liveText: {
    fontWeight: 600,
    fontSize: 14,
  },
});

export default styles;
