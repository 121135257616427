import { createReducer, createAction } from 'utils/reducer/utils.reducer';
import { DirectSaleModelsManagerActions } from '../actions/DirectSaleModelsManager.actions';

export const brandManagerActions = {
  toggleForm: createAction(DirectSaleModelsManagerActions.TOGGLE_FORM, 'modal'),
  toggleCreateForm: createAction(DirectSaleModelsManagerActions.TOGGLE_CREATE_FORM),
  toggleEditForm: createAction(DirectSaleModelsManagerActions.TOGGLE_CREATE_FORM, 'selectedModel'),
  createModels: createAction(DirectSaleModelsManagerActions.CREATE_MODELS, 'controls'),
  editModels: createAction(DirectSaleModelsManagerActions.EDIT_MODELS, 'controls'),
};

const defaultInvalidControls = {
  name: false,
  categoryId: false,
};

const initialState = {
  controls: {
    name: '',
    brandId: '-1',
    year: '',
    filterName: '',
    filterBrand: '-1',
    edit: false,
    formIsOpen: false,
    toEditId: '',
    anchorEl: null,
    selectedModel: {},
  },
  invalidControls: { ...defaultInvalidControls },
  toDeleteId: '',
  error: false,
  deleteIsOpen: false,
};

const directSaleModelsManagerReducer = createReducer(initialState, {
  [DirectSaleModelsManagerActions.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [DirectSaleModelsManagerActions.GET_STATE_FROM_API_SUCCESS](state) {
    state.controls.loading = false;
  },
  [DirectSaleModelsManagerActions.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [DirectSaleModelsManagerActions.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [DirectSaleModelsManagerActions.TOGGLE_CREATE_FORM](state) {
    state.controls.formIsOpen = true;
    state.controls.edit = false;
  },
  [DirectSaleModelsManagerActions.TOGGLE_EDIT_FORM](state) {
    state.controls.formIsOpen = true;
    state.toDeleteId = '';
    state.controls.toEditId = state.controls.selectedModel.id;
    state.controls.name = state.controls.selectedModel.name;
    state.controls.brandId = state.controls.selectedModel.brandId;
    state.controls.year = state.controls.selectedModel.year || '';
    state.controls.edit = true;
    state.controls.anchorEl = null;
  },
  [DirectSaleModelsManagerActions.TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
    state.controls.edit = false;
    state.controls.toEditId = '';
    state.controls.name = '';
    state.controls.brandId = '';
    state.controls.year = '';
    state.invalidControls = { ...defaultInvalidControls };
    state.controls.selectedModel = {};
  },
  [DirectSaleModelsManagerActions.TOGGLE_DELETE](state) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.controls.anchorEl = null;
  },
  [DirectSaleModelsManagerActions.CREATE_MODELS_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.loading = false;
    state.controls.name = '';
    state.controls.brandId = '-1';
    state.controls.year = '';
  },
  [DirectSaleModelsManagerActions.EDIT_MODELS_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.loading = false;
  },
  [DirectSaleModelsManagerActions.DELETE_MODELS_SUCCESS](state) {
    state.deleteIsOpen = false;
    state.controls.loading = false;
  },
});

export default directSaleModelsManagerReducer;
