const PATH = '[DIRECT_SALE_MODELS_MANAGER]';

export const DirectSaleModelsManagerActions = {
  TOGGLE_FORM: `${PATH} TOGGLE_FORM`,
  TOGGLE_CREATE_FORM: `${PATH} TOGGLE_CREATE_FORM`,
  TOGGLE_EDIT_FORM: `${PATH} TOGGLE_EDIT_FORM`,
  TOGGLE_DELETE: `${PATH} TOGGLE_DELETE`,
  GET_STATE_FROM_API: `${PATH} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${PATH} CHANGE_CONTROLS`,
  // create
  CREATE_MODELS: `${PATH} CREATE_MODELS`,
  CREATE_MODELS_SUCCESS: `${PATH} CREATE_MODELS_SUCCESS`,
  CREATE_MODELS_FAIL: `${PATH} CREATE_MODELS_FAIL`,
  // update
  EDIT_MODELS: `${PATH} EDIT_MODELS`,
  EDIT_MODELS_SUCCESS: `${PATH} EDIT_MODELS_SUCCESS`,
  EDIT_MODELS_FAIL: `${PATH} EDIT_MODELS_FAIL`,
  // delete
  DELETE_MODELS: `${PATH} DELETE_MODELS`,
  DELETE_MODELS_SUCCESS: `${PATH} DELETE_MODELS_SUCCESS`,
  DELETE_MODELS_FAIL: `${PATH} DELETE_MODELS_FAIL`,
};
