import { put, spawn, takeLatest } from 'redux-saga/effects';
import { getDocument, postForm } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { asQuery } from 'utils/functions';

/* Project */
import {
  TEMPLATE_IMPORT_MODAL,
  templateImportModalActions,
} from '../reducer/templateImportModal.reducer';

function* downloadTemplate() {
  yield takeLatest(TEMPLATE_IMPORT_MODAL.DOWNLOAD_TEMPLATE, function* (action) {
    const { url, params } = action;

    yield put(appActions.setLoader('download-template', true));
    const result = yield controlledCall(
      getDocument('template', 'xlsx', true),
      url,
      asQuery(params),
      templateImportModalActions.downloadTemplateSuccess,
    );

    if (!result.error) {
      // Handle success
    }

    yield put(appActions.setLoader('download-template', false));
  });
}

function* importTemplate() {
  yield takeLatest(TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE, function* (action) {
    const { url, file, params } = action;

    const form = new FormData();
    form.append('file', file);
    form.append('params', params ? JSON.stringify(params) : '');

    yield put(appActions.setLoader('import-template', true));
    const result = yield controlledCall(
      postForm,
      url,
      form,
      templateImportModalActions.importTemplateSuccess,
    );

    if (!result?.error) {
      // Handle success
    } else {
      yield put({ type: TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE_FAIL, payload: {} });
    }

    yield put(appActions.setLoader('import-template', false));
  });
}

export default function* templateImportModalSaga() {
  yield spawn(downloadTemplate);
  yield spawn(importTemplate);
}
