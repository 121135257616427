import ilustration from 'utils/images/ilustration.png';

const styles = {
  alignRight: {
    textAlign: 'right',
  },
  container: {
    background:
      'linear-gradient(0deg, rgba(7,42,104,1) 0%, rgba(0,23,64,1) 100%, rgba(32,75,154,1) 100%)',
  },
  containerContent: {
    backgroundImage: `url("${ilustration}")`,
    minHeight: '100%',
    backgroundSize: 'contained',
    backgroundPositionX: '50%',
    // backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    left: 0,
    top: 0,
  },
};

export default styles;
