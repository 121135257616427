import { put, spawn, takeLatest } from 'redux-saga/effects';

import { get, post, update } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { asQuery, unformatParse } from 'utils/functions';
import { history } from 'utils/history';
import { PAYMENTS, paymentsActions } from '../reducer/payments.reducer';

function* initialQuery() {
  yield takeLatest(PAYMENTS.INITIAL_QUERY, function* (action) {
    // const { page, count, state, paymentType, auctionId, minDate, maxDate, paymentId, guaranteeId } = action.filters;
    const filters = { ...action.filters };

    yield put(appActions.setLoader('payments', true));
    yield controlledCall(
      get,
      `api/v1/payments?${asQuery(filters, true)}`,
      undefined,
      paymentsActions.initialQuerySuccess,
    );
    yield put(appActions.setLoader('payments', false));
  });
}

function* acceptReceipt() {
  yield takeLatest(PAYMENTS.ACCEPT_RECEIPT, function* (action) {
    yield put(appActions.setLoader('process-payment', true));
    const result = yield controlledCall(
      post,
      `api/v1/receipts/${action.paymentId}/confirm`,
      { paymentsUrl: `${window.location.origin}/payments` },
      paymentsActions.acceptReceiptSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha aprobado exitosamente el comprobante'));
    }
    yield put(appActions.setLoader('process-payment', false));
  });
}

function* rejectReceipt() {
  yield takeLatest(PAYMENTS.REJECT_RECEIPT, function* (action) {
    yield put(appActions.setLoader('process-payment', true));
    const body = {
      paymentsUrl: `${window.location.origin}/payments`,
      observations: action.notes,
    };

    const result = yield controlledCall(
      post,
      `api/v1/receipts/${action.paymentId}/reject`,
      body,
      paymentsActions.rejectReceiptSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha rechazado exitosamente el comprobante'));
    }
    yield put(appActions.setLoader('process-payment', false));
  });
}

function* editAmount() {
  yield takeLatest(PAYMENTS.EDIT_AMOUNT, function* (action) {
    yield put(appActions.setLoader('process-payment', true));
    const result = yield controlledCall(
      update,
      `api/v1/receipts/${action.paymentId}`,
      { amount: unformatParse(action.amount) },
      paymentsActions.editAmountSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha editado exitosamente el monto comprobante'));
    }
    yield put(appActions.setLoader('process-payment', false));
  });
}

function* retryPayment() {
  yield takeLatest(PAYMENTS.RETRY_PAYMENT, function* (action) {
    const result = yield controlledCall(
      post,
      `api/v1/payments/${action.id}/retry`,
      undefined,
      paymentsActions.retryPaymentSuccess,
    );

    if (!result?.error) {
      history.push(`/payments/new?guaranteeId=${action.guaranteeId}`);
    }
  });
}

function* undoPayment() {
  yield takeLatest(PAYMENTS.UNDO_PAYMENT, function* (action) {
    const result = yield controlledCall(
      post,
      `api/v1/payments/${action.id}/undo`,
      undefined,
      paymentsActions.undoPaymentSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se deshizo la aprobación o rechazo del pago'));
    }
  });
}

export default function* paymentsSaga() {
  yield spawn(initialQuery);
  yield spawn(acceptReceipt);
  yield spawn(rejectReceipt);
  yield spawn(editAmount);
  yield spawn(retryPayment);
  yield spawn(undoPayment);
}
