import { put, spawn, takeLatest } from 'redux-saga/effects';

import { post } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { unformat } from 'utils/functions';
import { appActions } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { REGISTER, registerActions } from '../reducer/register.reducer';

function* register() {
  yield takeLatest(REGISTER.REGISTER, function* (action) {
    yield put(appActions.setLoader('register', true));
    const body = {
      ...action.data,
      taxNumber: unformat(action.data.taxNumber),
      phone: `+56${action.data.phone}`,
    };
    const result = yield controlledCall(
      post,
      'api/v1/users/sign_up',
      body,
      registerActions.registerSuccess,
      registerActions.registerFail,
    );
    if (!result?.error) {
      history.push('/login');
    } else {
      try {
        const jsonObject = JSON.parse(result.errorMsg);
        yield put(registerActions.toggleFailModal({ error: result.error, json: jsonObject }));
      } catch (e) {
        yield put(registerActions.toggleFailModal({ error: result.error, msg: result.errorMsg }));
      }
    }
    yield put(appActions.setLoader('register', false));
  });
}

export default function* registerSaga() {
  yield spawn(register);
}
