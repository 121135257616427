import React, { useEffect, useMemo } from 'react';

/* Material UI */
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';

/* Project */
import SelectField from 'commons/components/SelectField/SelectField';
import colors from 'utils/colors';
import { makeStyles } from 'tss-react/mui';

import { Checkbox, FormControlLabel } from '@mui/material';
import MultipleSelect from './MultipleSelect';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 55,
    height: 36,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#002F77' : '#002F77',
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.main === 'main' ? '#aab4be' : '#6AAAE4',
          width: 25,
          height: 31,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.main === 'main' ? '#aab4be' : '#002F77',
      width: 25,
      height: 31,
    },
    '& .MuiSwitch-track': {
      width: 70,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#6AAAE4' : '#6AAAE4',
      borderRadius: 20 / 2,
    },
  },
}));

function AreaSwitch() {
  const { classes } = useStyles();
  return <Switch className={classes.root} />;
}

function ContactMessageForm(props) {
  const {
    controls,
    controlsChanged,
    objects,
    actions,
    areaId,
    handleCreate,
    allowSubmit,
    disableSubmit,
    contactLotId,
    viewOnly,
  } = props;

  const lot = useMemo(
    () => (contactLotId ? objects.lots[contactLotId] : null),
    [contactLotId, objects.lots],
  );
  const [captcha, setCaptcha] = React.useState();

  useEffect(() => {
    if (lot) {
      let currentLotAreaId;
      const lotCategory = lot.lotCategory()?.auctionCategory()?.name;
      const isLand = lotCategory === 'Campos' || lot.lease;
      if (isLand) {
        currentLotAreaId = 3;
      } else if (lot.isProperty() && !isLand) {
        currentLotAreaId = 2;
      } else {
        currentLotAreaId = 1;
      }
      actions.formControlsChanged({ areaId: currentLotAreaId });
    }
  }, [lot, actions]);

  useEffect(() => {
    if (areaId !== null) {
      actions.formControlsChanged({ areaId });
    }
  }, [areaId, actions]);

  const onKeyDown = (event) => {
    if (allowSubmit && disableSubmit) {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        event.preventDefault();
        handleCreate();
      }
    }
  };

  const emailValid = (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(value);
  // const rutValid = (value) =>   ;

  function valueIsNotEmpty(value) {
    if (value === undefined) {
      value = '';
    } else return value.toString().trim().length > 0;
  }

  const validPhone = (phone) => phone.toString().trim().length === 9;
  return (
    <>
      {lot && (
        <Grid item container xs={12}>
          <Grid item container md={12} xs={12}>
            <Typography color="primary" variant="h6" component="span">
              Datos de lote:
            </Typography>
          </Grid>
          <Grid item container md={12} xs={12} style={{ marginTop: '5px' }}>
            <Typography color="primary" variant="body1" component="span">
              <b>Nombre:</b>
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              component="span"
              style={{ marginLeft: '5px' }}>
              {lot.name}
            </Typography>
          </Grid>

          <Grid item container md={12} xs={12} style={{ marginTop: '2px' }}>
            <Typography color="primary" variant="body1" component="span">
              <b>Descripción:</b>
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              component="span"
              style={{ marginLeft: '5px' }}>
              {lot.description}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Bienes</Grid>
            <Grid item>
              <AreaSwitch
                id="areaId"
                checked={controls.areaId !== 1}
                onChange={controlsChanged}
                name="areaId"
              />
            </Grid>
            <Grid item>Propiedades</Grid>
          </Grid>
        </Typography>
      </Grid>

      {controls.areaId === 2 ? (
        <Grid item xs={6}>
          <SelectField
            native
            onChange={controlsChanged}
            value={controls.typePerson || 0}
            title="Tipo de persona"
            id="typePerson"
            disabled={!!lot}
            items={[
              <option key="typePerson-0" value={0}>
                Seleccionar
              </option>,
              <option key="typePerson-1" value={1}>
                Natural
              </option>,
              <option key="typePerson-2" value={2}>
                Juridica
              </option>,
            ]}
          />
        </Grid>
      ) : null}
      {/* {(controls.typePerson === '1' && controls.areaId === 2) ||
      (controls.typePerson === '2' && controls.areaId === 2) ? (
        <Grid item xs={6}>
          <TextField
            disabled={viewOnly}
            fullWidth
            id="rut"
            label="RUT"
            value={controls.rut}
            onChange={controlsChanged}
            onKeyDown={onKeyDown}
            helperText={!valueIsNotEmpty(controls.rut) && 'Rut requerido'}
            FormHelperTextProps={{
              error: true,
              style: { color: colors.helper },
            }}
          />
        </Grid>
      ) : null} */}

      {controls.typePerson === '2' && controls.areaId === 2 ? (
        <Grid item xs={6}>
          <TextField
            disabled={viewOnly}
            fullWidth
            id="company"
            label="Compañia"
            value={controls.company}
            onChange={controlsChanged}
            onKeyDown={onKeyDown}
            helperText={!valueIsNotEmpty(controls.company) && 'Compañia requerida'}
            FormHelperTextProps={{
              error: true,
              style: { color: colors.helper },
            }}
          />
        </Grid>
      ) : null}

      <Grid item xs={6}>
        <TextField
          disabled={viewOnly}
          fullWidth
          id="name"
          label="Nombre"
          value={controls.name}
          onChange={controlsChanged}
          onKeyDown={onKeyDown}
          helperText={!valueIsNotEmpty(controls.name) && 'Nombre requerido'}
          FormHelperTextProps={{
            error: true,
            style: { color: colors.helper },
          }}
        />
      </Grid>

      {controls.typePerson === '1' || controls.typePerson === '2' ? (
        <Grid item xs={6}>
          <TextField
            disabled={viewOnly}
            fullWidth
            id="lastName"
            label="Apellidos"
            value={controls.lastName}
            onChange={controlsChanged}
            onKeyDown={onKeyDown}
            helperText={!valueIsNotEmpty(controls.lastName) && 'Apellido requerido'}
            FormHelperTextProps={{
              error: true,
              style: { color: colors.helper },
            }}
          />
        </Grid>
      ) : null}

      <Grid item xs={6}>
        <TextField
          disabled={viewOnly}
          fullWidth
          id="email"
          label="Correo"
          value={controls.email}
          onChange={controlsChanged}
          onKeyDown={onKeyDown}
          helperText={!emailValid(controls.email) && 'Email requerido'}
          FormHelperTextProps={{
            error: true,
            style: { color: colors.helper },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={viewOnly}
          fullWidth
          id="phone"
          label="Teléfono"
          value={controls.phone}
          onChange={controlsChanged}
          onKeyDown={onKeyDown}
          helperText={!validPhone(controls.phone) && 'Teléfono requerido'}
          FormHelperTextProps={{
            error: true,
            style: { color: colors.helper },
          }}
        />
      </Grid>

      {controls.areaId === 2 ? (
        <Grid item xs={6}>
          <SelectField
            native
            onChange={controlsChanged}
            value={controls.typeBusiness || 0}
            title="Tipo de negocio"
            id="typeBusiness"
            disabled={!!lot}
            items={[
              <option key="typeBusiness-1" value={1}>
                Venta/Arriendo
              </option>,
              <option key="typeBusiness-2" value={2}>
                Compra/Arriendo
              </option>,
            ]}
          />
        </Grid>
      ) : null}

      {controls.areaId === 2 ? (
        <Grid item xs={6}>
          <SelectField
            native
            onChange={controlsChanged}
            value={controls.typeInterest || 0}
            title="Interes"
            id="typeInterest"
            disabled={!!lot}
            items={[
              <option key="interest-0" value={0}>
                Propiedades Urbanas
              </option>,
              <option key="interest-1" value={1}>
                Propiedades Rurales
              </option>,
              <option key="interest-2" value={2}>
                Arriendo de Propiedades
              </option>,
              <option key="interest-3" value={3}>
                Administracion de Propiedades
              </option>,
              <option key="interest-4" value={4}>
                Tasacion
              </option>,
            ]}
          />
        </Grid>
      ) : null}

      {controls.areaId === 2 ? (
        <Grid item xs={6}>
          <MultipleSelect id="typeChannel" controls={controls} onChange={controlsChanged} />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <TextField
          disabled={viewOnly}
          fullWidth
          id="message"
          label="Mensaje"
          value={controls.message}
          onChange={controlsChanged}
          onKeyDown={onKeyDown}
          minRows={4}
          helperText={!valueIsNotEmpty(controls.message) && 'Mensaje requerido'}
          FormHelperTextProps={{
            error: true,
            style: { color: colors.helper },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              id="optIn"
              checked={controls.optIn}
              onChange={controlsChanged}
              color="primary"
            />
          }
          label="Desea recibir informacion mediante correo electronico"
        />
      </Grid>

      <ReCAPTCHA
        id="captcha"
        sitekey="6LfK1k0pAAAAAFRNaMH2HDXbgJaxCx7-fCOIgtgh"
        onChange={(val) => controlsChanged({ target: { id: 'captcha', value: val } })}
      />
    </>
  );
}
export default ContactMessageForm;
