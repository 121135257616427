const base = '[MANDATE_PREVIEW]';

export const MandatePreviewTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  TOGGLE_EDIT_FORM: `${base} TOGGLE_EDIT_FORM`,
  CREATE_MANDATE: `${base} CREATE_MANDATE`,
  CREATE_MANDATE_SUCCESS: `${base} CREATE_MANDATE_SUCCESS`,
  CREATE_MANDATE_FAIL: `${base} CREATE_MANDATE_FAIL`,
  EXPORT_PDF: `${base} EXPORT_PDF`,
  EXPORT_PDF_SUCCESS: `${base} EXPORT_PDF_SUCCESS`,
  EXPORT_PDF_FAIL: `${base} EXPORT_PDF_FAIL`,
  TOGGLE_NATURAL_PERSON_FORM: `${base} TOGGLE_NATURAL_PERSON_FORM`,
};

export default MandatePreviewTypes;
