import React from 'react';

/* Material UI */
import { Typography } from '@mui/material';

function StyledTypography(props) {
  const {
    variant = 'body1',
    component = null,
    color = 'initial',
    className = null,
    size = null,
    customColor = null,
    weight = null,
    lineHeight = null,
    letterSpacing = null,
    white = null,
    textAlign = null,
    display = null,
    mb = null,
    mt = null,
    ml = null,
    mr = null,
    style = null,
    children,
  } = props;

  const styles = {
    fontSize: size,
    color: white ? 'white' : customColor,
    fontWeight: weight,
    lineHeight,
    letterSpacing,
    textAlign,
    marginBottom: mb,
    marginTop: mt,
    marginLeft: ml,
    marginRight: mr,
    whiteSpace: 'pre-line',
    display: display ?? undefined,
    ...style,
  };

  return (
    <Typography
      variant={variant}
      component={component}
      color={color}
      className={className}
      style={styles}>
      {children}
    </Typography>
  );
}

export default StyledTypography;
