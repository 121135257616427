import { put, spawn, takeLatest, select } from 'redux-saga/effects';
import apiRequest, { apiSuccess } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { LOTS_LIST } from 'modules/mandates-tracking/LotsList/reducer/lotsList.reducer';
import { enqueueSnackbar } from 'notistack';
import { BULK_LOAD, bulkLoadActions } from '../reducer/bulkLoad.reducer';

function* getPresignedURL() {
  yield takeLatest(BULK_LOAD.GET_PRESIGNED_URL, function* (action) {
    const { files } = action.payload;

    const mappedFiles = Object.values(files)?.map((file) => ({
      name: file.name,
      type: file.type,
    }));

    const body = JSON.stringify({ filesData: mappedFiles });

    // Obtiene las urls firmadas.
    const response = yield apiRequest(
      `api/v1/mandates/lots/presigned_url`,
      { method: 'POST', body },
      true,
    );

    if (!response.error) {
      const { urls } = response;

      yield put(bulkLoadActions.setPresignedUrls(urls));
      enqueueSnackbar('URLS generadas exitosamente.', { variant: 'success' });
    } else {
      yield put(apiSuccess(LOTS_LIST.REQUEST_FAILED, response));
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

function* uploadFiles() {
  yield takeLatest(BULK_LOAD.UPLOAD_FILES, function* (action) {
    const mappedFiles = Object.values(action.files)?.map((file) => ({
      name: file.name,
      type: file.type,
    }));

    const response = yield apiRequest(
      `api/v1/mandates/lots/bulk_load`,
      {
        method: 'POST',
        body: JSON.stringify({ files: mappedFiles }),
      },
      true,
    );

    if (!response.error) {
      yield put(bulkLoadActions.saveRecord(response));
      /* yield put(appActions.setSuccess('Carga masiva de documentos realizada exitosamente.')); */
      enqueueSnackbar('Carga masiva de documentos realizada exitosamente.', { variant: 'success' });
    } else {
      yield put(apiSuccess(LOTS_LIST.REQUEST_FAILED, response));
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

function* deleteLastBulkLoad() {
  yield takeLatest(BULK_LOAD.DELETE_LAST_BULK_UPLOAD, function* (action) {
    const response = yield apiRequest(
      `api/v1/mandates/lots/bulk_load/delete`,
      {
        method: 'PUT',
      },
      true,
    );

    if (!response.error) {
      // yield put(bulkLoadActions.saveRecord(response));
      yield put(appActions.setSuccess('Eliminación exitosa de la última carga masiva realizada.'));
    } else {
      // yield put(apiSuccess(LOTS_LIST.REQUEST_FAILED, response));
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

export default function* bulkLoadSaga() {
  yield spawn(getPresignedURL);
  yield spawn(uploadFiles);
  yield spawn(deleteLastBulkLoad);
}
