const PATH = '[DIRECT_SALE_FEATURES]';

export const DirectSaleFeaturesActions = {
  GET_STATE_FROM_API: `${PATH} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${PATH} CHANGE_CONTROLS`,
  MERGE_CONTROLS: `${PATH} MERGE_CONTROLS`,
  FILTER_LOTS: `${PATH} FILTER_LOTS`,
  FILTER_LOTS_SUCCESS: `${PATH} FILTER_LOTS_SUCCESS`,
  FILTER_LOTS_FAIL: `${PATH} FILTER_LOTS_FAIL`,
};
