import { put, spawn, takeLatest } from 'redux-saga/effects';
import { appActions } from 'commons/reducer/commons.reducer';
import apiRequest, { get, objectToQueryString } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { ORDER_LOTS, orderLotsActions } from '../reducer/entitledLots.reducer';

function* initialQuery() {
  yield takeLatest(ORDER_LOTS.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('order-lots', true));
    yield controlledCall(
      get,
      'api/v1/order_lot',
      objectToQueryString(action.controls),
      orderLotsActions.initialQuerySuccess,
      orderLotsActions.initialQueryFail,
    );
    yield put(appActions.setLoader('order-lots', false));
  });
}

function* getAuctionlotsFromApi() {
  yield takeLatest(ORDER_LOTS.GET_AUCTION_LOTS_FROM_API, function* (action) {
    yield put(appActions.setLoader('order-lots', true));
    yield controlledCall(
      get,
      'api/v1/order_lot/auctionLots',
      objectToQueryString(action.controls),
      orderLotsActions.getAuctionLotsFromApiSuccess,
      orderLotsActions.getAuctionLotsFromApiFail,
    );
    yield put(appActions.setLoader('order-lots', false));
  });
}

const arrayToBody = (body, array, key) => {
  array.forEach((item) => body.append([key], JSON.stringify(item)));
};

function* createLotsWithRights() {
  yield takeLatest(ORDER_LOTS.CREATE_LOTS_WITH_RIGHTS, function* (action) {
    yield put(appActions.setLoader('order-lots', true));

    const body = new FormData();

    Object.entries(action.images).forEach((val) => {
      const fileExtension = val[1].name.split('.').pop();
      body.append(`image_${val[0]}`, val[1], `image_${val[0]}.${fileExtension}`);
    });

    const lots = action.lots.map((lot) => ({
      id: lot.id,
      lotWithRightId: lot.lotWithRightId,
    }));
    const lotsWithRights = action.lotsWithRights.map((lotWR) => ({
      id: lotWR.id || null,
      index: lotWR.groupIndex,
      name: lotWR.name,
      items: lotWR.items.map((lot) => lot.id),
    }));

    arrayToBody(body, lots, 'lots[]');
    arrayToBody(body, lotsWithRights, 'entitledLots[]');
    arrayToBody(body, action.idsGroupsRemoved, 'idsRemoved[]');

    const response = yield apiRequest(
      'api/v1/order_lot/',
      {
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );
    yield put(appActions.setLoader('order-lots', false));
    if (!response.error) {
      yield put(appActions.setSuccess('Lotes con derecho actualizados.'));
    } else {
      yield put(appActions.setError('Error al actualizar lotes con derecho'));
    }
  });
}

export default function* entitledLotsSaga() {
  yield spawn(initialQuery);
  yield spawn(getAuctionlotsFromApi);
  yield spawn(createLotsWithRights);
}
