import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, CircularProgress } from '@mui/material';

/* Router */
import { withRouter } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Project */
import { selectState } from 'utils/reduxActions';
import StyledBox from './../../../utils/styledComponent/styledBox';

function ButtonWithLoader(props) {
  const {
    buttonId,
    children,
    loaders,
    isLoading,
    className,
    styled,
    ButtonProps,
    width,
    height,
    fontSize,

    // ...rest
  } = props;
  const { startIcon: icon, color, variant, disabled, ...rest } = ButtonProps;

  const loading = loaders[buttonId] || isLoading;
  const startIcon = loading ? <CircularProgress size={15} /> : icon;
  return (
    <Button
      {...rest}
      sx={{
        width: width ?? null,
        height: height ?? null,
        fontSize: fontSize ?? null,
      }}
      className={styled && className}
      color={color || 'primary'}
      variant={variant || 'contained'}
      disabled={disabled || loading}
      startIcon={startIcon}>
      {children}
    </Button>
  );
}

ButtonWithLoader.propTypes = {
  isLoading: PropTypes.bool,
  styled: PropTypes.bool,
  className: PropTypes.string,
  buttonId: PropTypes.string,
  loaders: PropTypes.object,
  ButtonProps: PropTypes.object,
};

ButtonWithLoader.defaultProps = {
  ButtonProps: {},
};

const withConnect = connect(selectState('app.loaders'), null, null);

export default compose(withRouter, withConnect)(ButtonWithLoader);
