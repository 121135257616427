import React from 'react';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Icon } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';

import { Environment } from 'model-environment';

import { getItemInStorage, onlyKeysWithValue } from 'utils/functions';

import PublicRole from 'utils/roles/PublicRole';
import ClientRole from 'utils/roles/ClientRole';
import AdminRole from 'utils/roles/AdminRole';
import OperationalRole from 'utils/roles/OperationalRole';
import FinancialRole from 'utils/roles/FinancialRole';
import CommercialRole from 'utils/roles/CommercialRole';
import DirectSaleRole from 'utils/roles/DirectSaleRole';
import SuperAdminRole from 'utils/roles/SuperAdminRole';
import TransferenceRole from 'utils/roles/TransferenceRole';
import MandateRole from 'utils/roles/MandateRole';

// Icons
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import Terrain from '../../commons/assets/icons/Icono-terrenos.png';
import Car from '../../commons/assets/icons/Icono-auto.png';
import Equipment from '../../commons/assets/icons/Icono-equipos.png';
import Machinery from '../../commons/assets/icons/Icono-Maquinaria.png';
import Truck from '../../commons/assets/icons/Icono-camion.png';
import FarmingTruck from '../../commons/assets/icons/Icono-Tractor.png';
import Farming from '../../commons/assets/icons/Icono-agricola.png';
import Commerce from '../../commons/assets/icons/Icono-comercial.png';
import House from '../../commons/assets/icons/Icono-casa.png';
import Building from '../../commons/assets/icons/Icono-edificio.png';
import WaterRights from '../../commons/assets/icons/Icono-derechos-de-agua.png';
import Industry from '../../commons/assets/icons/Icono-industria.png';
import * as Models from '../../models';

export const syncFlags = [
  {
    id: '10',
    name: 'No constituida',
  },
  {
    id: '11',
    name: 'Cliente',
  },
  {
    id: '12',
    name: 'Mandante',
  },
  {
    id: '13',
    name: 'Interno',
  },
  {
    id: '14',
    name: 'Observador',
  },
];

export const tattersallBillingData = [
  [
    'TATTERSALL GESTION DE ACTIVOS S.A.',
    '96.775.780-2',
    'Banco de Chile',
    'Cuenta corriente',
    '11690-04',
    'kvial@tattersall.cl',
  ],
  [
    'TATTERSALL GESTION DE ACTIVOS S.A.',
    '96.775.780-2',
    'BCI',
    'Cuenta corriente',
    '10634347',
    'depositosgda@tattersall.cl',
  ],
];

export const defaultCopyLinkMsg = 'Copiar enlace al portapepeles.';

export const defaultCurreny = 2;

export const defaultQueries = {
  fortinures: {
    auctions: '?areaId=1&auctionCode=AUC',
    auctionsSidebar: '?areaId=1&auctionCode=AUC',
    auctionTender: '?areaId=1&auctionCode=AUC/BID',
    all: '?areaId=1&auctionCode=ALL',
  },
  properties: {
    auctions: '?areaId=2&auctionCode=ALL',
    auctionsSidebar: '?areaId=2&auctionCode=ALL',
  },
};

export const guaranteeStatusOptions = () =>
  // TODO: Add "used" status filter on backend
  // if (showUsed) { states.push({ label: 'Utilizada', value: '7' }); }
  [
    {
      label: 'Todos',
      value: '-1',
    },
    {
      label: 'Sin pagos',
      value: '1',
    },
    {
      label: 'Por pagar',
      value: '2',
    },
    {
      label: 'Por validar',
      value: '3',
    },
    {
      label: 'Constituída',
      value: '4',
    },
    {
      label: 'Devuelta',
      value: '5',
    },
    {
      label: 'Marcada',
      value: '6',
    },
  ];

export const paymentStates = [
  {
    id: 'PEN',
    name: 'Pendiente',
  },
  {
    id: 'COM',
    name: 'Completado',
  },
  {
    id: 'REC',
    name: 'Rechazado',
  },
];

export const paymentTypes = [
  {
    id: 'webpay',
    name: 'Webpay',
  },
  {
    id: 'receipt',
    name: 'Comprobante de pago',
  },
];

export const paymentFilters = [
  {
    id: 'date-asc',
    name: 'Fecha ascendente',
  },
  {
    id: 'date-dsc',
    name: 'Fecha descendente',
  },
  {
    id: 'amount-asc',
    name: 'Monto total ascendente',
  },
  {
    id: 'amount-dsc',
    name: 'Monto total descendente',
  },
];

export const userSortFilters = [
  {
    id: 'name-asc',
    name: 'Nombre ascendente',
  },
  {
    id: 'name-dsc',
    name: 'Nombre descendente',
  },
  {
    id: 'date-asc',
    name: 'Fecha creación ascendente',
  },
  {
    id: 'date-dsc',
    name: 'Fecha creación descendente',
  },
];

export const currencySortFilters = [
  {
    id: 'name-asc',
    name: 'Nombre ascendente',
  },
  {
    id: 'name-dsc',
    name: 'Nombre descendente',
  },
  {
    id: 'date-asc',
    name: 'Última actualización ascendente',
  },
  {
    id: 'date-dsc',
    name: 'Última actualización descendente',
  },
];

export const auctionIcon = (lot) => {
  if (lot.sale) {
    return (
      <MonetizationOnIcon
        style={{
          fontSize: 30,
          color: '#415363',
        }}
      />
    );
  }

  switch (lot.auction()?.auctionType()?.code) {
    case 'BID':
      return (
        <Icon>
          <img
            style={{
              width: 30,
              height: 30,
              color: '#415363',
            }}
            src="./images/black-icon.svg"
            alt=""
          />
        </Icon>
      );
    default:
      return (
        <GavelIcon
          style={{
            fontSize: 30,
            color: '#415363',
          }}
        />
      );
  }
};

export const lotCategoryIcon = (id) => {
  const iconStyle = {
    width: 30,
    height: 30,
  };
  switch (true) {
    case id === 1 || id === 37 || id === 43 || id === 2 || id === 36: // autos, vehiculos
      return <img style={iconStyle} src={Car} alt="icono" />;
    case id === 32 || id === 33 || id === 39 || id === 49 || id === 50 || id === 51:
      return <img style={iconStyle} src={Equipment} alt="icono" />;
    case id === 40 ||
      id === 4 ||
      id === 41 ||
      id === 38 ||
      id === 3 ||
      id === 48 ||
      id === 52 ||
      id === 53 ||
      id === 54:
      return <img style={iconStyle} src={Machinery} alt="icono" />;
    case id === 34 || id === 35 || id === 44 || id === 45 || id === 46:
      return <img style={iconStyle} src={Truck} alt="icono" />;
    case id === 42:
      return <img style={iconStyle} src={FarmingTruck} alt="icono" />;
    case id === 5 || id === 6 || id === 21:
      return <img style={iconStyle} src={Farming} alt="icono" />;
    case id === 9 || id === 10 || id === 11 || id === 22:
      return <img style={iconStyle} src={Terrain} alt="icono" />;
    case id === 12 || id === 14 || id === 25:
      return <img style={iconStyle} src={Commerce} alt="icono" />;
    case id === 7 || id === 31:
      return <img style={iconStyle} src={House} alt="icono" />;
    case id === 8 || id === 13 || id === 23 || id === 24:
      return <img style={iconStyle} src={Building} alt="icono" />;
    case id === 19:
      return <img style={iconStyle} src={Car} alt="icono" />;
    case id === 30:
      return <img style={iconStyle} src={WaterRights} alt="icono" />;
    case id === 16 ||
      id === 17 ||
      id === 18 ||
      id === 49 ||
      id === 50 ||
      id === 20 ||
      id === 26 ||
      id === 27:
      return <img style={iconStyle} src={Industry} alt="icono" />;
    default:
      return (
        <EmojiTransportationIcon
          style={{
            fontSize: 30,
            color: '#415363',
          }}
        />
      );
  }
};

/**
 * Function to transform role code in class with specific permissions
 * @param roleCode
 * @param payload
 * @returns {MandateRole|ClientRole|PublicRole|CommercialRole|OperationalRole|FinancialRole|DirectSaleRole|TransferenceRole|SuperAdminRole|AdminRole}
 */
const transformUserCode = (roleCode, payload) => {
  switch (roleCode) {
    case 'ADM':
      return new AdminRole(payload);
    case 'OPE':
      return new OperationalRole(payload);
    case 'FIN':
      return new FinancialRole(payload);
    case 'COM':
      return new CommercialRole(payload);
    case 'SAL':
      return new DirectSaleRole(payload);
    case 'SUA':
      return new SuperAdminRole(payload);
    case 'TRA':
      return new TransferenceRole(payload);
    case 'CLI':
      return new ClientRole(payload);
    case 'MDT':
      return new MandateRole(payload);
    default:
      return new PublicRole(payload);
  }
};

/**
 * Function to validate user permissions for action
 * @param {string} roleCode
 * @param {string} actionWithRoute
 * @param {Object?} payload
 * @returns {boolean}
 */
export const can = (roleCode, actionWithRoute, payload) => {
  const [route, action] = actionWithRoute.split(':');
  const roleClass = transformUserCode(roleCode, payload);
  // console.log({ route, action, userCode, roleClass, can: Boolean(roleClass[route][action]) });
  return Boolean(roleClass[route][action]);
};

export const checkViewPermissions = (userCode, path, fullUser = null, areaID = null) =>
  can(userCode, `${path}:view`, {
    user: fullUser,
    areaId: areaID,
  });

export const externalLink = (session, link) => `${link}?param1=${session?.token}`;
export const propertyLink = process.env.REACT_APP_MANUAL_PROPERTIES_PDF_LINK;
export const furnitureLink = process.env.REACT_APP_MANUAL_FURNITURE_PDF_LINK;

export const defaultAreas = {
  PROPERTY: 2,
  FURNITURE: 1,
};
export const getExtension = (fileName) => fileName.split('.').pop().toLowerCase();

/**
 * @param {Array} objArray
 * @param {string, Function} property
 * @param {boolean} asc
 * @returns {*[]}
 * @constructor
 */
export const SortObjectArray = (objArray, property = 'name', asc = true) => {
  if (!objArray || !objArray.length) return [];

  return [...objArray].sort((a, b) => {
    if (typeof property === 'function') {
      if (property(a) > property(b)) {
        return asc ? 1 : -1;
      }

      if (property(a) < property(b)) {
        return asc ? -1 : 1;
      }
    } else {
      if (a[property] > b[property]) {
        return asc ? 1 : -1;
      }

      if (a[property] < b[property]) {
        return asc ? -1 : 1;
      }
    }

    return 0;
  });
};

/**
 * @param {object} history
 * @param {string} path
 * @returns {JSX.Element}
 */
export const handleHomeRedirection = (history, path = '/') => {
  history.push(path);
  return <div>Wrong data...</div>;
};

export const defaultDirectSalePagination = {
  page: 1,
  offset: 0,
  limit: 15,
};

/**
 * @param {Object} history
 * @param {string} category
 * @param {{ categoryId, brandId, modelId }} currentControls
 * @param {Object} newControls
 * @param {Object} pagination
 * @param {Boolean} push
 * @param {Boolean} force
 */
export const updatingDirectSalePageQuery = (
  history,
  category,
  currentControls,
  newControls = {},
  pagination = {},
  push = false,
  force = false,
) => {
  const controls = currentControls;

  const currentUrlControls = {
    categoryId: controls.categoryId,
    brandId: controls.brandId,
    modelId: controls.modelId,
    ...newControls,
    ...pagination,
  };

  if (push) {
    const newSearch = new URLSearchParams(onlyKeysWithValue(currentUrlControls)).toString();
    history.push({
      pathname: `/direct_sale/${category}/lots`,
      search: `?${newSearch}`,
    });
  }

  if (force) {
    // const urlSearch = onlyKeysWithValue(urlParams);
    // actions.searchQuery(urlSearch);
  }
};

/**
 * @param {Object} objects
 * @returns {Object}
 */
export const reselectObjects = (objects) => new Environment({ objects }, Models).parseDB().objects;

export const rolRedirection = (rolLink, areaId) =>
  `${externalLink(getItemInStorage('user'), rolLink)}&param2=${areaId}`;

export const openUrl = ({ url, target, fileName, download }) => {
  const link = document.createElement('a');
  link.href = url;

  if (download) {
    link.download = fileName;
  }

  link.target = target || '_blank';

  link.click();
};
