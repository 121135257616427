import camelize from 'camelcase-keys-deep';

export default class LinkedGuarantee {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  current() {
    return this.env().Guarantee.getById(this.currentId);
  }

  auction() {
    return this.env().Auction.getById(this.auctionId);
  }

  user() {
    return this.env().User.getById(this.userId);
  }
}
