import SystemActions from 'utils/roles/SystemActions';

export default class MandateRole extends SystemActions {
  constructor() {
    super();

    this.mandates.view = true;
    this.mandatesLogin.view = true;
    this.mandates.updateLotStatus = false;
    this.mandatesBulkLoad.view = false;

    this.profile.view = true;
    this.profile.update = true;
    this.profile.updatePassword = true;
  }
}
