import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'contactMessages/';
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const FORM_CONTROLS_CHANGED = `${PATH}FORM_CONTROLS_CHANGED`;
const FILTERS_CHANGED = `${PATH}FILTERS_CHANGED`;
const APPLY_FILTER = `${PATH}APPLY_FILTER`;
const CLEAR_FILTER = `${PATH}CLEAR_FILTER`;
const PAGINATION_CHANGED = `${PATH}PAGINATION_CHANGED`;

// Global constants for saga
export const CONTACT_MESSAGES = {
  RESET: `${PATH}RESET`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  ASSIGN_USER: `${PATH}ASSIGN_USER`,
  ASSIGN_USER_SUCCESS: `${PATH}ASSIGN_USER_SUCCESS`,
  ASSIGN_USER_FAIL: `${PATH}ASSIGN_USER_FAIL`,
  DELETE_USER: `${PATH}DELETE_USER`,
  DELETE_USER_SUCCESS: `${PATH}DELETE_USER_SUCCESS`,
  DELETE_USER_FAIL: `${PATH}DELETE_USER_FAIL`,
};

// actions
export const contactMessagesActions = {
  paginationChanged: createAction(PAGINATION_CHANGED, 'options'),
  reset: createAction(CONTACT_MESSAGES.RESET),
  filtersChanged: createAction(FILTERS_CHANGED, 'filters'),
  applyFilter: createAction(APPLY_FILTER),
  clearFilter: createAction(CLEAR_FILTER),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  formControlsChanged: createAction(FORM_CONTROLS_CHANGED, 'controls'),
  initialQuery: createAction(CONTACT_MESSAGES.INITIAL_QUERY, 'filters'),
  initialQueryFail: createAction(CONTACT_MESSAGES.INITIAL_QUERY_FAIL, 'payload'),
  initialQuerySuccess: createAction(CONTACT_MESSAGES.INITIAL_QUERY_SUCCESS, 'payload'),
  assignUser: createAction(CONTACT_MESSAGES.ASSIGN_USER, 'messageId'),
  assignUserFail: createAction(CONTACT_MESSAGES.ASSIGN_USER_FAIL, 'payload'),
  assignUserSuccess: createAction(CONTACT_MESSAGES.ASSIGN_USER_SUCCESS, 'payload'),
};

const initialState = {
  controls: {
    doQuery: true,
    anchorEl: null,
    loading: 0,
    areaId: null,
    lotCategoryId: null,
    contactMessagesCount: 0,
    assignMessage: false,
  },
  filters: {
    areaId: '-1',
    nameSearch: '',
    assignedSearch: '',
    ids: [],
  },
  pagination: {
    page: 0,
    count: 10,
  },
};

const contactMessages = createReducer(initialState, {
  [TOGGLE_FORM](state, action) {
    // const seller = state.controls.user && state.controls.user.entity().person().seller();
    state.controls[action.modal] = !state.controls[action.modal];
    // state.formControls.name = seller && seller.name;
    // state.formControls.description = seller && seller.description;
    state.formControls.roleId = state.controls.user && state.controls.user.roleId;
    state.controls.anchorEl = null;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [FORM_CONTROLS_CHANGED](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
  },
  [CONTACT_MESSAGES.INITIAL_QUERY](state) {
    state.controls.doQuery = false;
  },
  [CONTACT_MESSAGES.INITIAL_QUERY_SUCCESS](state, action) {
    state.filters.ids = action.payload.ids.contactMessages;
    state.controls.contactMessagesCount = action.payload.count.contactMessages;
  },
  [PAGINATION_CHANGED](state, action) {
    if (action.options.page) {
      state.pagination.page = action.options.page;
    }
    if (action.options.count) {
      state.pagination.count = action.options.count;
    }
  },
  [APPLY_FILTER](state) {
    state.pagination.page = 0;
    state.controls.doQuery = true;
  },
  [CLEAR_FILTER](state) {
    state.filters = initialState.filters;
    state.pagination = initialState.pagination;
    state.controls.doQuery = true;
  },
  [CONTACT_MESSAGES.RESET](state) {
    state.controls = initialState.controls;
    state.filters = initialState.filters;
    state.pagination = initialState.pagination;
    state.formControls = initialState.formControls;
  },
  [FILTERS_CHANGED](state, action) {
    state.filters = { ...state.filters, ...action.filters };
  },
});

export default contactMessages;
