import React, { Fragment } from 'react';

/* Material UI */
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LocationOnIcon from '@mui/icons-material/LocationOn';

/* Router */
import { useLocation } from 'react-router-dom';

/* Project */
import tattersallLogo from 'utils/images/tattersall-white.png';
import footerStyles from 'commons/components/Footer/footer.styles';
import Instagram from '../../assets/icons/instagram.png';
import Facebook from '../../assets/icons/facebook.png';
import Youtube from '../../assets/icons/youtube.png';
import Email from '../../assets/icons/mail.png';
import Paper from '../../assets/icons/paper.png';
import Phone from '../../assets/icons/phone.png';

const useStyles = makeStyles()(footerStyles);

const Footer = React.memo((props) => {
  const { directSale } = props;

  const { classes: styles } = useStyles();
  const location = useLocation();

  const marginTop = '';

  if (location.pathname === '/') {
    // marginTop = '29vh';
  }

  const logoGrid = (
    <Grid item xs={12}>
      <img src={tattersallLogo} className={styles.logo} alt="Tattersall white logo" />
    </Grid>
  );

  const findUsGrid = (
    <Grid item xs={12} md={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={styles.footerTitle}>Encuéntranos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.titleDivider} />
        </Grid>
        <Grid item xs={12} className={styles.headContainer}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <IconButton
                className={styles.socialButton}
                component="a"
                target="_blank"
                href="https://www.instagram.com/tattersallgda/?hl=es-la"
                size="large">
                <img style={{ width: 35, height: 35 }} src={Instagram} alt="instagram-logo" />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={styles.socialButton}
                component="a"
                target="_blank"
                href="https://www.facebook.com/tattersall.gestiondeactivos/"
                size="large">
                <img style={{ width: 35, height: 35 }} src={Facebook} alt="facebook-logo" />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={styles.socialButton}
                component="a"
                target="_blank"
                href="https://www.youtube.com/channel/UCl2XqcXhbtiFOFsOYzmhMYQ"
                size="large">
                <img style={{ width: 35, height: 35 }} src={Youtube} alt="youtube-logo" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <img style={{ width: 35, height: 23 }} src={Email} alt="mail-logo" />
            </Grid>
            <Grid item className={styles.centerGrid}>
              <Typography
                className={styles.simpleText}
                component="a"
                href="mailto:contactogda@tattersall.cl"
                style={{ marginLeft: '13px' }}>
                contactogda@tattersall.cl
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <img
                style={{ width: 'auto', height: 23, marginLeft: '8px' }}
                src={Paper}
                alt="paper-logo"
              />
            </Grid>
            <Grid item className={styles.centerGrid}>
              <Typography
                className={styles.simpleText}
                component="a"
                rel="noopener noreferrer"
                href="https://www.tattersall.cl/index.php/canal-de-denuncias"
                target="_blank"
                style={{ marginLeft: '20px' }}>
                Canal de denuncia
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const branchOfficesGrid = (
    <Grid item xs={12} md={4}>
      <Grid container spacing={2}>
        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Grid>
            <LocationOnIcon fontSize="large" style={{ padding: '5px' }} />
          </Grid>
          <Grid>
            <Typography className={styles.footerTitle}>Sucursales</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider className={styles.titleDivider} />
        </Grid>
        {!directSale && (
          <Grid item xs={12} className={styles.headContainer}>
            <Typography className={styles.subHeader} component="span">
              Antofagasta
              <br />
              <Typography className={styles.simpleText}>
                El Lapizlazuli 588, manzana n° 19, sitio 2
              </Typography>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className={styles.subHeader} component="span">
            Santiago
            <br />
            <Typography className={styles.simpleText}>
              Isidora Goyenechea 3600, Piso 5, Las Condes
              <br />
              Camino longitudinal ruta 5 sur 02535, Buin
            </Typography>
          </Typography>
        </Grid>
        {!directSale && (
          <>
            <Grid item xs={12}>
              <Typography className={styles.subHeader} component="span">
                Los Ángeles
                <br />
                <Typography className={styles.simpleText}>Km. 4 Camino a Nacimiento</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={styles.subHeader} component="span">
                Puerto Varas
                <br />
                <Typography className={styles.simpleText}>Gramados S/N</Typography>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );

  const contactGrid = (
    <Grid item xs={12} md={4}>
      <Grid container spacing={2}>
        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Grid>
            <img style={{ width: 21, height: 21, padding: '8px' }} src={Phone} alt="mail-logo" />
          </Grid>
          <Grid>
            <Typography className={styles.footerTitle}>Contacto</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.titleDivider} />
        </Grid>
        {!directSale && (
          <>
            <Grid item xs={12} className={styles.headContainer}>
              <Typography className={styles.subHeader} component="span">
                Propiedades Urbanas / Industriales / Campos
                <br />
                <Typography className={styles.simpleText}>(+562) 2362 30 90</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={styles.subHeader} component="span">
                Arriendos
                <br />
                <Typography className={styles.simpleText}>(+569) 4986 73 74</Typography>
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography className={styles.subHeader} component="span">
            Vehículos / Maquinaria / Equipos
            <br />
            <Typography className={styles.simpleText}>800 123 990</Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const copyrightGrid = (
    <Grid container item xs={12} className={styles.copyright}>
      <Divider className={styles.copyrightDivider} />
      <Grid item xs={12} className={styles.copyrightInner}>
        <Typography className={styles.copyrightText} variant="subtitle2">
          {`  Copyright 2024 | Todos los derechos reservados | `}
          <a
            style={{ color: 'white' }}
            href="https://www.tattersall.cl"
            target="_blank"
            rel="noopener noreferrer">
            Empresas Tattersall S.A.
          </a>
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={styles.container} style={{ marginTop, paddingBottom: 0 }}>
      <Grid container style={{ width: '87%', margin: 'auto' }} spacing={5}>
        {logoGrid}
        {findUsGrid}
        {branchOfficesGrid}
        {contactGrid}
        {copyrightGrid}
      </Grid>
    </Grid>
  );
});

export default Footer;
