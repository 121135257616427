import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, { destroy, get, getDocument } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { asQuery } from 'utils/functions';
import { openUrl } from 'utils/helpers/helper';
import { AUCTIONS, auctionsActions } from '../reducer/auctions.reducer';

function* initialQuery() {
  yield takeLatest(AUCTIONS.INITIAL_QUERY, function* (action) {
    appActions.setLoading(true);
    yield put(appActions.setLoader('filter-auctions', true));
    yield controlledCall(
      get,
      `api/v1/auctions?${asQuery(action.filters)}`,
      undefined,
      auctionsActions.initialQuerySuccess,
      auctionsActions.initialQueryFail,
    );
    appActions.setLoading(false);
    yield put(appActions.setLoader('filter-auctions', false));
  });
}

function* deleteAuction() {
  yield takeLatest(AUCTIONS.DELETE_AUCTION, function* (action) {
    appActions.setLoading(true);
    const result = yield controlledCall(
      destroy,
      `api/v1/auctions/${action.controls.auction.id}`,
      undefined,
      auctionsActions.deleteAuctionSuccess,
    );
    if (!result?.error) {
      yield put(
        appActions.setSuccess(
          `Se ha eliminado el remate exitosamente, Se eliminaron ${result?.disabledLots?.length} lotes.`,
        ),
      );
    }
    appActions.setLoading(false);
  });
}

function* getMediaReport() {
  yield takeLatest(AUCTIONS.GET_MEDIA_REPORT, function* (action) {
    const result = yield controlledCall(
      getDocument('reporteMultimedia', 'xlsx', true),
      `api/v1/auctions/${action.auctionId}/media_report`,
      undefined,
      undefined,
      undefined,
      true,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Descargando reporte'));
    }
  });
}

function* getAuctionReport() {
  yield takeLatest(AUCTIONS.GET_AUCTION_REPORT, function* (action) {
    const result = yield controlledCall(
      getDocument('reporteRemate', 'xlsx', true),
      `api/v1/auctions/${action.auctionId}/auction_report`,
      undefined,
      undefined,
      undefined,
      true,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Descargando reporte'));
    }
  });
}

function* getResultsReport() {
  yield takeLatest(AUCTIONS.GET_RESULTS_REPORT, function* (action) {
    const result = yield controlledCall(
      getDocument('reporteResultados', 'xlsx', true),
      `api/v1/auctions/${action.auctionId}/results_report`,
      undefined,
      undefined,
      undefined,
      true,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Descargando reporte'));
    }
  });
}

function* getMinuteReport() {
  yield takeLatest(AUCTIONS.GET_MINUTE_REPORT, function* (action) {
    const result = yield controlledCall(
      getDocument('reporteActa', 'xlsx', true),
      `api/v1/auctions/${action.auctionId}/minute_report`,
      undefined,
      undefined,
      undefined,
      true,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Descargando reporte'));
    }
  });
}

function* getPublicResultsReport() {
  yield takeLatest(AUCTIONS.DOWNLOAD_PUBLIC_REPORT, function* (action) {
    const result = yield controlledCall(
      getDocument('reporteRematesFinalizados', 'pdf'),
      `api/v1/auctions/${action.auctionID}/public_results_report?download=false`,
      undefined,
      undefined,
      undefined,
      true,
    );

    if (!result?.error) {
      yield put({ type: AUCTIONS.DOWNLOAD_PUBLIC_REPORT_SUCCESS, payload: { ...result } });
      yield put(appActions.setSuccess('Abriendo reporte...'));
    }

    if (!action.isMobile) {
      openUrl({ url: result.url });
    }
  });
}

function* generateTemplate() {
  yield takeLatest(AUCTIONS.GENERATE_TEMPLATE_1, function* (action) {
    yield put(appActions.setLoader('generate-template-id', true));

    const response = yield controlledCall(
      getDocument('template', 'xlsx', true),
      `api/v1/mandates/auctions/${action.auctionId}/generate-template`,
      undefined,
      undefined,
      undefined,
      true,
    );

    if (!response?.error) {
      yield put(appActions.setSuccess('Plantilla descargada'));
    }

    yield put(appActions.setLoader('generate-template-id', false));
  });
}

function* uploadTemplate() {
  yield takeLatest(AUCTIONS.UPLOAD_TEMPLATE, function* (action) {
    const { controls } = action;

    yield put(appActions.setLoader('data-masive-upload', true));

    const body = new FormData();
    body.append('file', controls.importedFile);
    body.append('name', 'Archivo de prueba');

    const response = yield apiRequest(
      `api/v1/mandates/lots/upload-template`,
      {
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );

    yield put(appActions.setLoader('data-masive-upload', false));
    yield put(auctionsActions.uploadTemplateFail());

    console.log('response', response);

    if (!response.error) {
      yield put(appActions.setSuccess(response.message));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

export default function* auctionsSaga() {
  yield spawn(getMediaReport);
  yield spawn(initialQuery);
  yield spawn(deleteAuction);
  yield spawn(getAuctionReport);
  yield spawn(getResultsReport);
  yield spawn(getMinuteReport);
  yield spawn(getPublicResultsReport);
  yield spawn(generateTemplate);
  yield spawn(uploadTemplate);
}
