import React, { useCallback, useState } from 'react';

function deconstructEvent(event, date) {
  if (event.persist) {
    event.persist();
  }
  let value;
  if (event.target.type === 'file') {
    value = event.target.files[0];
  } else if (event.target.type === 'checkbox') {
    value = event.target.checked;
  } else {
    value =
      event.target.type === 'date' || event.target.type === 'time' ? date : event.target.value;
  }
  const id = event.target.type ? event.target.id : event.currentTarget.id;
  return { id, value };
}

export const useForm = (controls, actions, options, callback = null) => {
  const [errors, setErrors] = useState({});

  if (actions?.controlsChanged == null && options?.onChange == null && callback === null) {
    throw new Error('Parameters are missing change callback');
  }

  const isValid = useCallback(
    (id, value) => {
      return (
        options.validations[id] == null ||
        options.validations[id].every((validation) => validation.check(value))
      );
    },
    [options],
  );

  const validate = useCallback(
    (id, value) => {
      let isValid = true;
      let newErrors = [];

      for (const validation of options.validations[id]) {
        if (validation.check(value)) {
          continue;
        }
        isValid = false;
        if (newErrors[id] == null) {
          newErrors[id] = [];
        }
        if (options.prettyErrors == null || options.prettyErrors) {
          newErrors[id].push(
            <span
              style={{
                display: 'block',
              }}>
              {validation.message ?? 'Campo inválido'}
            </span>,
          );
        } else {
          newErrors[id].push(validation.message ?? 'Campo inválido');
        }
        if (options?.bailByDefault || validation.bail) {
          break;
        }
      }
      setErrors((state) => ({ ...state, [id]: newErrors.length === 0 ? undefined : newErrors }));

      return isValid;
      // eslint-disable-next-line
    },
    [
      options.bailByDefault,
      options.prettyErrors,
      options.validations,
      // styles.prettyError,
      callback,
    ],
  );

  const validateAll = useCallback(() => {
    const ids = Object.keys(options.validations);
    let isValid = true;
    let newErrors = {};

    for (const id of ids) {
      const value = controls[id];
      for (const validation of options.validations[id]) {
        if (validation.check(value)) {
          continue;
        }
        isValid = false;
        if (newErrors[id] == null) {
          newErrors[id] = [];
        }
        if (options.prettyErrors == null || options.prettyErrors) {
          newErrors[id].push(
            <span key={`error-${id}`}>{validation.message ?? 'Campo inválido'}</span>,
          );
        } else {
          newErrors[id].push(validation.message ?? 'Campo inválido');
        }
        if (options?.bailByDefault || validation.bail) {
          break;
        }
      }
    }
    setErrors(newErrors);
    return isValid;
  }, [
    options.validations,
    options.prettyErrors,
    options.bailByDefault,
    controls,
    // styles.prettyError,
  ]);

  const onChange = useCallback(
    (event) => {
      const hasControlsChanged = actions && 'controlsChanged' in actions;
      const { id, value } = deconstructEvent(event, null); // TODO: Pass date

      if (id == null || id.length === 0) {
        throw new Error('Input is missing id');
      }

      // Either validate on change or clear errors if it is valid
      if (options?.validateOnChange) {
        validate(id, value);
      } else if (options?.clearOnValid && isValid(id, value)) {
        setErrors((errors) => ({ ...errors, [id]: undefined }));
      }

      if (callback) {
        callback({ [id]: value });
      } else {
        if (hasControlsChanged) {
          actions.controlsChanged({ [id]: value });
        } else {
          options.onChange(id, value);
        }
      }
      // eslint-disable-next-line
    },
    [options, actions, isValid, validate],
  );

  const onSubmit = useCallback(
    (callback) => {
      const valid = validateAll();
      if (valid) {
        callback();
      }
    },
    [validateAll],
  );

  return { errors, onChange, onSubmit, validateAll };
};
