import camelize from 'camelcase-keys-deep';

export default class ContactMessage {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  area() {
    return this.env().Area.getById(this.areaId);
  }

  lot() {
    return this.env().Lot.getById(this.lotId);
  }

  assigned() {
    return this.env().User.getById(this.assignedId);
  }
}
