import { createReducer } from 'utils/reducer/utils.reducer';
import { PendingTransferencesTypes } from '../types/PendingTransferences.types';

const initialState = {
  controls: {
    loading: false,
    patent: '',
    step: '',
    subStep: '',
    initDate: null,
    endDate: null,
    total: 0,
  },
  data: {
    transfers: [],
  },
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 30, 45, 99],
  },
};

const PendingTransferencesReducer = createReducer(initialState, {
  [`${PendingTransferencesTypes.GET_STATE_FROM_API_SUCCESS}`](state, action) {
    state.data.transfers = action.payload.data.transfers;
    state.controls.total = action.payload.data.total;
  },
  [`${PendingTransferencesTypes.CHANGE_CONTROLS}`](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [`${PendingTransferencesTypes.CLEAN_FILTERS}`](state, action) {
    state.controls = {
      ...initialState.controls,
    };
  },
  [`${PendingTransferencesTypes.CHANGE_PAGINATION}`](state, action) {
    state.pagination = {
      ...state.pagination,
      ...action.payload.pagination,
    };
  },
});

export default PendingTransferencesReducer;
