import camelize from 'camelcase-keys-deep';

export default class Region {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  communes() {
    return this.env().Commune.findAllBy('regionId', this.id);
  }
}
