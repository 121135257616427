/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import Layout from 'commons/containers/Layout/Layout';
import { checkViewPermissions } from 'utils/helpers/helper';

function PrivateRoute(props) {
  const { component: Component, user, permissionPath, pathAreaID, withoutLayout, ...rest } = props;

  function ComponentContainer(containerProps) {
    return withoutLayout ? (
      <Component {...containerProps} />
    ) : (
      <Layout>
        <Component {...containerProps} />
      </Layout>
    );
  }

  return (
    <Route
      {...rest}
      component={(componentProps) => {
        if (checkViewPermissions(user?.roleCode, permissionPath, user, pathAreaID)) {
          return <ComponentContainer props={componentProps} />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
}

export default PrivateRoute;
