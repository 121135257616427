import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'guaranteeManager/';
const RESET = `${PATH}RESET`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const STEP_ONE_CONTROLS_CHANGED = `${PATH}STEP_ONE_CONTROLS_CHANGED`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT = `${PATH}TOGGLE_EDIT`;
const SET_LOTS = `${PATH}SET_LOTS`;
const OPEN_LETTER = `${PATH}OPEN_LETTER`;
const SET_DEFAULT_INFORMATION = `${PATH}SET_DEFAULT_INFORMATION`;

// Global constants for saga
export const GUARANTEE_MANAGER = {
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  CREATE_GUARANTEE: `${PATH}CREATE_GUARANTEE`,
  CREATE_GUARANTEE_SUCCESS: `${PATH}CREATE_GUARANTEE_SUCCESS`,
  CREATE_GUARANTEE_FAIL: `${PATH}CREATE_GUARANTEE_FAIL`,
  CREATE_OR_UPDATE_BILLING_INFO: `${PATH}CREATE_OR_UPDATE_BILLING_INFO`,
  CREATE_OR_UPDATE_BILLING_INFO_SUCCESS: `${PATH}CREATE_OR_UPDATE_BILLING_INFO_SUCCESS`,
  CREATE_OR_UPDATE_BILLING_INFO_FAIL: `${PATH}CREATE_OR_UPDATE_BILLING_INFO_FAIL`,
  VALIDATE_SSI_DATA: `${PATH}VALIDATE_SSI_DATA`,
  VALIDATE_SSI_DATA_SUCCESS: `${PATH}VALIDATE_SSI_DATA_SUCCESS`,
  VALIDATE_SSI_DATA_FAIL: `${PATH}VALIDATE_SSI_DATA_FAIL`,
  VIEW_CONTROLS_CHANGED: CONTROLS_CHANGED,
  CREATE_PAYMENT_SUCCESS: `${PATH}_CREATE_PAYMENT_SUCCESS`,
};

// actions
export const guaranteeManagerActions = {
  setDefaultInformation: createAction(
    SET_DEFAULT_INFORMATION,
    'lot',
    'personalInfo',
    'lots',
    'mandatesDocuments',
    'isProperty',
    'minimumFurniture',
  ),
  setLots: createAction(SET_LOTS, 'lots'),
  toggleForm: createAction(TOGGLE_FORM, 'controls'),
  stepOneControlsChanged: createAction(STEP_ONE_CONTROLS_CHANGED, 'controls', 'invalidControls'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  toggleDelete: createAction(TOGGLE_DELETE),
  toggleEdit: createAction(TOGGLE_EDIT, 'Auction'),
  openLetter: createAction(OPEN_LETTER, 'value', 'symbol'),
  reset: createAction(RESET),
  // api
  initialQuery: createAction(GUARANTEE_MANAGER.INITIAL_QUERY, 'auctionId', 'lotId'),
  createGuarantee: createAction(
    GUARANTEE_MANAGER.CREATE_GUARANTEE,
    'controls',
    'furnitureAuctionId',
  ),
  createOrUpdateBillingInfo: createAction(
    GUARANTEE_MANAGER.CREATE_OR_UPDATE_BILLING_INFO,
    'controls',
    'billingInfoId',
  ),
  // success
  initialQuerySuccess: createAction(GUARANTEE_MANAGER.INITIAL_QUERY_SUCCESS, 'payload'),
  createGuaranteeSuccess: createAction(GUARANTEE_MANAGER.CREATE_GUARANTEE_SUCCESS, 'payload'),
  createOrUpdateBillingInfoSuccess: createAction(
    GUARANTEE_MANAGER.CREATE_OR_UPDATE_BILLING_INFO_SUCCESS,
    'payload',
  ),
  // fail
  initialQueryFail: createAction(GUARANTEE_MANAGER.INITIAL_QUERY_FAIL, 'payload'),
  createGuaranteeFail: createAction(GUARANTEE_MANAGER.CREATE_GUARANTEE_FAIL, 'payload'),
  createOrUpdateBillingInfoFail: createAction(
    GUARANTEE_MANAGER.CREATE_OR_UPDATE_BILLING_INFO_FAIL,
    'payload',
  ),
};
const defaultOne = {
  // personal info
  businessName: '',
  name: '',
  lastName: '',
  secondLastName: '',
  phone: '',
  taxNumber: '',
  email: '',
  isBusiness: false,
  commercialBusiness: 'Particular',
  businessTaxNumber: '',
  personalAddress: '',
  // address info
  billingTaxNumber: '',
  billingName: '',
  address: '',
  countryId: '1',
  regionId: '',
  communeId: '',
  city: '',
  province: '',
  // account info
  titularTaxNumber: '',
  titularName: '',
  bankId: '',
  accountTypeId: '',
  accountNumber: '',
};

const defaultStepOneInvalidControls = {
  // personal info
  businessName: false,
  commercialBusiness: false,
  businessTaxNumber: false,
  // address info
  address: false,
  countryId: false,
  regionId: false,
  communeId: false,
  // account info
  titularName: false,
  bankId: false,
  accountTypeId: false,
  accountNumber: false,
};

const defaultControls = {
  activeStep: 0,
  initialQuery: false,
  guaranteeId: null,
  webpayToken: null,
  webpayUrl: null,
  payMode: null,
  offerAmount: 0,
  amountSimulator: 0,
  lotsOffered: [], // Ids array
  images: [],
  offerts: {},
  amount: null,
  currentValue: '',
  isProperty: null,
  confirmBases: false,
  displaySSIDialog: false,
  ssiCallback: () => {},
};

const defaultRest = {
  lots: [],
  mandatesDocuments: [],
  modalIsOpen: false,
  letterIsOpen: false,
  modalIsChecked: false,
  initialQuery: false,
};

const defaultInitialState = {
  ...defaultRest,
  stepOne: { ...defaultOne },
  stepOneInvalidControls: { ...defaultStepOneInvalidControls },
  controls: { ...defaultControls },
  ssi: {
    matchName: false,
    correctCommercialBusiness: false,
  },
};

const initialState = { ...defaultInitialState };

const guaranteeManager = createReducer(initialState, {
  [RESET](state) {
    state.controls = { ...defaultControls };
    state.stepOne = { ...defaultOne };
    state.stepOneInvalidControls = { ...defaultStepOneInvalidControls };
    state.lots = defaultRest.lots;
    state.mandatesDocuments = defaultRest.mandatesDocuments;
    state.modalIsOpen = defaultRest.modalIsOpen;
    state.letterIsOpen = defaultRest.letterIsOpen;
    state.modalIsChecked = defaultRest.modalIsChecked;
    state.initialQuery = defaultRest.initialQuery;
  },
  [SET_DEFAULT_INFORMATION](state, action) {
    if (!action.isProperty && action.minimumFurniture) {
      state.controls.offerAmount = Number(action.minimumFurniture);
    }
    if (action.lot != null) {
      state.controls.lotsOffered = [action.lot.id];
      state.controls.offerts = { [action.lot.id]: action.lot.price };
      state.controls.isProperty = action.isProperty;
    }
    state.lots = action.lots;
    state.mandatesDocuments = action.mandatesDocuments;
    state.stepOne = {
      ...state.stepOne,
      ...action.personalInfo,
    };
  },
  [SET_LOTS](state, action) {
    state.lots = action.lots;
  },
  [GUARANTEE_MANAGER.CREATE_PAYMENT_SUCCESS](state, action) {
    state.controls.webpayToken = action.payload.token;
    state.controls.webpayUrl = action.payload.url;
  },
  [GUARANTEE_MANAGER.CREATE_OR_UPDATE_BILLING_INFO_SUCCESS](state) {
    state.controls.activeStep = 1;
  },
  [STEP_ONE_CONTROLS_CHANGED](state, action) {
    state.stepOne = {
      ...state.stepOne,
      ...action.controls,
    };
    state.stepOneInvalidControls = {
      ...state.stepOneInvalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [GUARANTEE_MANAGER.INITIAL_QUERY_SUCCESS](state) {
    state.initialQuery = true;
    state.controls.initialQuery = true;
  },
  [TOGGLE_DELETE](state) {
    state.controls.anchorEl = null;
  },
  [TOGGLE_EDIT](state) {
    state.controls.edit = true;
  },
  [TOGGLE_FORM](state, action) {
    state[action.controls] = !state[action.controls];
  },
  [OPEN_LETTER](state, action) {
    state.letterIsOpen = true;
    state.controls.currentValue = action.value;
    state.controls.currentSymbol = action.symbol;
  },
  [GUARANTEE_MANAGER.VALIDATE_SSI_DATA_SUCCESS](state) {
    state.controls.displaySSIDialog = false;
    state.controls.ssiCallback = () => {};
  },
  [GUARANTEE_MANAGER.VALIDATE_SSI_DATA_FAIL](state, action) {
    state.controls.displaySSIDialog = true;
    state.controls.ssiCallback = action.payload.callback;
  },
});

export default guaranteeManager;
