import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import moment from 'moment';

const PATH = 'guarantees/';
const TOGGLE_DETAILS = `${PATH}TOGGLE_DETAILS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const APPLY_FILTER = `${PATH}APPLY_FILTER`;
const CLEAR_FILTER = `${PATH}CLEAR_FILTER`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const FILTERS_CHANGED = `${PATH}FILTERS_CHANGED`;
const SET_IDS = `${PATH}SET_IDS`;
const PAGINATION_CHANGED = `${PATH}PAGINATION_CHANGED`;
// Bulk refund modal
export const SHOW_BULK_REFUND_MODAL = `${PATH}SHOW_BULK_REFUND_MODAL`;
export const HIDE_BULK_REFUND_MODAL = `${PATH}HIDE_BULK_REFUND_MODAL`;
export const TOGGLE_BULK_REFUND_MODAL = `${PATH}TOGGLE_BULK_REFUND_MODAL`;

export const GUARANTEES = {
  // gest state
  RESET: `${PATH}RESET`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  ACKNOWLEDGE_REFUND: `${PATH}ACKNOWLEDGE_REFUND`,
  ACKNOWLEDGE_REFUND_SUCCESS: `${PATH}ACKNOWLEDGE_REFUND_SUCCESS`,
  ACKNOWLEDGE_REFUND_FAIL: `${PATH}ACKNOWLEDGE_REFUND_FAIL`,
  CHANGE_AMOUNT: `${PATH}CHANGE_AMOUNT`,
  CHANGE_AMOUNT_SUCCESS: `${PATH}CHANGE_AMOUNT_SUCCESS`,
  CHANGE_AMOUNT_FAIL: `${PATH}CHANGE_AMOUNT_FAIL`,
  SET_FLAG: `${PATH}SET_FLAG`,
  SET_FLAG_SUCCESS: `${PATH}SET_FLAG_SUCCESS`,
  SET_FLAG_FAIL: `${PATH}SET_FLAG_FAIL`,
  GET_REPORT: `${PATH}GET_REPORT`,
};

// actions
export const guaranteesActions = {
  reset: createAction(GUARANTEES.RESET),
  toggleDetails: createAction(TOGGLE_DETAILS, 'guarantee'),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  applyFilter: createAction(APPLY_FILTER),
  clearFilter: createAction(CLEAR_FILTER),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  filtersChanged: createAction(FILTERS_CHANGED, 'filters'),
  setIds: createAction(SET_IDS, 'ids'),
  paginationChanged: createAction(PAGINATION_CHANGED, 'options'),
  // API
  initialQuery: createAction(GUARANTEES.INITIAL_QUERY, 'filters', 'pagination'),
  initialQuerySuccess: createAction(GUARANTEES.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(GUARANTEES.INITIAL_QUERY_FAIL, 'payload'),
  acknowledgeRefund: createAction(GUARANTEES.ACKNOWLEDGE_REFUND, 'id'),
  acknowledgeRefundSuccess: createAction(GUARANTEES.ACKNOWLEDGE_REFUND_SUCCESS, 'payload'),
  acknowledgeRefundFail: createAction(GUARANTEES.ACKNOWLEDGE_REFUND_FAIL, 'payload'),
  changeAmount: createAction(GUARANTEES.CHANGE_AMOUNT, 'id', 'amount'),
  changeAmountSuccess: createAction(GUARANTEES.CHANGE_AMOUNT_SUCCESS, 'payload'),
  changeAmountFail: createAction(GUARANTEES.CHANGE_AMOUNT_FAIL, 'payload'),
  setFlag: createAction(GUARANTEES.SET_FLAG, 'id', 'syncFlag'),
  setFlagSuccess: createAction(GUARANTEES.SET_FLAG_SUCCESS, 'payload'),
  setFlagFail: createAction(GUARANTEES.SET_FLAG_FAIL, 'payload'),
  getReport: createAction(GUARANTEES.GET_REPORT, 'filters'),
  // Bulk refund
  showBulkRefundModal: createAction(SHOW_BULK_REFUND_MODAL),
  hideBulkRefundModal: createAction(HIDE_BULK_REFUND_MODAL),
  toggleBulkRefundModal: createAction(TOGGLE_BULK_REFUND_MODAL),
};

const defaultControls = {
  receiptAmount: null,
  observations: null,
};

const defaultInvalidControls = {
  receiptAmount: false,
};

const tomorrow = moment().add(1, 'day').startOf('day');

const initialState = {
  controls: {
    showBulkRefundModal: false,
    doQuery: true,
    showDetails: false,
    anchorEl: null,
    guaranteeId: null,
    formIsOpen: false,
    filtered: false,
    guaranteesCount: 0,
    newAmount: null,
    syncFlag: '10',
    ...defaultControls,
  },
  filters: {
    minAmount: null,
    maxAmount: null,
    minDate: null,
    maxDate: tomorrow.toISOString(),
    userSearch: '',
    status: '-1',
    ids: [],
  },
  pagination: {
    page: 0,
    count: 10,
  },
  error: false,
  invalidControls: defaultInvalidControls,
};

const guarantees = createReducer(initialState, {
  [GUARANTEES.RESET](state) {
    state.controls = initialState.controls;
    state.filters = initialState.filters;
    state.pagination = initialState.pagination;
    state.error = initialState.error;
  },
  [GUARANTEES.INITIAL_QUERY](state) {
    state.controls.doQuery = false;
  },
  [GUARANTEES.INITIAL_QUERY_SUCCESS](state, action) {
    state.filters.ids = action.payload.ids.guarantees;
    state.controls.guaranteesCount = action.payload.controls.guaranteesCount;
    state.controls.doQuery = false;
  },
  [PAGINATION_CHANGED](state, action) {
    if (action.options.page) {
      state.pagination.page = action.options.page;
    }
    if (action.options.count) {
      state.pagination.count = action.options.count;
    }
  },
  [TOGGLE_DETAILS](state, action) {
    state.controls.showDetails = !state.controls.showDetails;
    state.controls.guarantee = action.guarantee;
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
  },
  [APPLY_FILTER](state) {
    state.pagination.page = 0;
    state.controls.doQuery = true;
  },
  [CLEAR_FILTER](state) {
    state.filters = initialState.filters;
    state.pagination = initialState.pagination;
    state.controls.doQuery = true;
  },
  [GUARANTEES.CHANGE_AMOUNT](state) {
    state.controls.newAmount = null;
  },
  [GUARANTEES.SET_FLAG](state) {
    state.controls.syncFlag = '10';
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = { ...state.controls, ...action.controls };
    state.invalidControls = { ...state.invalidControls, ...(action.invalidControls || {}) };
  },
  [FILTERS_CHANGED](state, action) {
    state.filters = { ...state.filters, ...action.filters };
  },
  [SET_IDS](state, action) {
    state.filters.ids = action.ids;
  },
  // Bulk refund modal
  [SHOW_BULK_REFUND_MODAL](state) {
    state.controls.showBulkRefundModal = true;
  },
  [HIDE_BULK_REFUND_MODAL](state) {
    state.controls.showBulkRefundModal = false;
  },
  [TOGGLE_BULK_REFUND_MODAL](state) {
    state.controls.showBulkRefundModal = !state.controls.showBulkRefundModal;
  },
});

export default guarantees;
