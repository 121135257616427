import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, getDocument } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { AdjudicationsTypes } from '../types/adjudications.types';

function* getStateFromApi() {
  yield takeLatest(AdjudicationsTypes.GET_STATE_FROM_API, function* (action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: AdjudicationsTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: AdjudicationsTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/adjudications?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* exportExcel() {
  yield takeLatest(AdjudicationsTypes.EXPORT_EXCEL, function* (action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: AdjudicationsTypes.EXPORT_EXCEL_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: AdjudicationsTypes.EXPORT_EXCEL_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('Adjudications', 'xlsx', true),
      `api/v1/adjudications/xls?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* exportPdf() {
  yield takeLatest(AdjudicationsTypes.EXPORT_PDF, function* (action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: AdjudicationsTypes.EXPORT_PDF_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: AdjudicationsTypes.EXPORT_PDF_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('Adjudications', 'pdf', true),
      `api/v1/adjudications/pdf?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* AdjudicationsSaga() {
  yield spawn(getStateFromApi);
  yield spawn(exportExcel);
  yield spawn(exportPdf);
}
