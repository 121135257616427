import { put, spawn, takeLatest } from 'redux-saga/effects';

import { post } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { RESET_PASSWORD, resetPasswordActions } from '../reducer/resetPassword.reducer';

function* register() {
  yield takeLatest(RESET_PASSWORD.SEND_EMAIL, function* (action) {
    const body = action.controls;
    yield controlledCall(
      post,
      'api/v1/users/reset_password',
      body,
      resetPasswordActions.sendEmailSuccess,
      resetPasswordActions.sendEmailFail,
    );
  });
  yield takeLatest(RESET_PASSWORD.SAVE_PASSWORD, function* (action) {
    yield put(appActions.setLoader('new-password', true));
    const body = {
      password: action.controls.password,
      passwordConfirmation: action.controls.passwordConfirmation,
      token: action.controls.token,
    };
    yield controlledCall(
      post,
      'api/v1/users/change_password',
      body,
      resetPasswordActions.savePasswordSuccess,
      resetPasswordActions.savePasswordFail,
    );
    yield put(appActions.setLoader('new-password', false));
  });
}

export default function* resetPasswordSaga() {
  yield spawn(register);
}
