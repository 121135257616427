import { createReducer, createAction } from 'utils/reducer/utils.reducer';
import { BrandsManagerActions } from '../actions/BrandsManager.actions';

export const brandManagerActions = {
  toggleForm: createAction(BrandsManagerActions.TOGGLE_FORM, 'modal'),
  toggleCreateForm: createAction(BrandsManagerActions.TOGGLE_CREATE_FORM),
  toggleEditForm: createAction(BrandsManagerActions.TOGGLE_CREATE_FORM, 'selectedContact'),
  createBrand: createAction(BrandsManagerActions.CREATE_BRAND, 'controls'),
  editBrand: createAction(BrandsManagerActions.EDIT_BRAND, 'controls'),
};

const defaultInvalidControls = {
  name: false,
  lotCategoryId: false,
};

const initialState = {
  controls: {
    name: '',
    lotCategoryId: '-1',
    filterCategory: '-1',
    filterName: '',
    edit: false,
    formIsOpen: false,
    toEditId: '',
    anchorEl: null,
    selectedBrand: {},
  },
  invalidControls: { ...defaultInvalidControls },
  toDeleteId: '',
  error: false,
  deleteIsOpen: false,
};

const brandsManagerReducer = createReducer(initialState, {
  [BrandsManagerActions.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [BrandsManagerActions.GET_STATE_FROM_API_SUCCESS](state) {
    state.controls.loading = false;
  },
  [BrandsManagerActions.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [BrandsManagerActions.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [BrandsManagerActions.TOGGLE_CREATE_FORM](state) {
    state.controls.formIsOpen = true;
    state.controls.edit = false;
  },
  [BrandsManagerActions.TOGGLE_EDIT_FORM](state) {
    state.controls.formIsOpen = true;
    state.toDeleteId = '';
    state.controls.toEditId = state.controls.selectedBrand.id;
    state.controls.name = state.controls.selectedBrand.name;
    state.controls.lotCategoryId = state.controls.selectedBrand.lotCategoryId;
    state.controls.edit = true;
    state.controls.anchorEl = null;
  },
  [BrandsManagerActions.TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
    state.controls.edit = false;
    state.controls.toEditId = '';
    state.controls.name = '';
    state.controls.lotCategoryId = '-1';
    state.invalidControls = { ...defaultInvalidControls };
    state.controls.selectedBrand = {};
  },
  [BrandsManagerActions.TOGGLE_DELETE](state) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.controls.anchorEl = null;
  },
  [BrandsManagerActions.CREATE_BRAND_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.loading = false;
    state.controls.name = '';
    state.controls.lotCategoryId = '-1';
  },
  [BrandsManagerActions.EDIT_BRAND_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.loading = false;
  },
  [BrandsManagerActions.DELETE_BRAND_SUCCESS](state) {
    state.deleteIsOpen = false;
    state.controls.loading = false;
  },
  [BrandsManagerActions.DELETE_BRAND_FAIL](state) {
    state.deleteIsOpen = false;
    state.controls.loading = false;
  },
});

export default brandsManagerReducer;
