import { formatRut } from 'utils/functions';
import { createAction, createReducer } from 'utils/reducer/utils.reducer';

const PATH = 'userProfile/';

export const USER_PROFILE = {
  FORM_CHANGED: `${PATH}FORM_CHANGED`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  SUBMIT_FORM: `${PATH}SUBMIT_FORM`,
  SUBMIT_FORM_SUCCESS: `${PATH}SUBMIT_FORM_SUCCESS`,
  SUBMIT_FORM_FAIL: `${PATH}SUBMIT_FORM_FAIL`,
  CHANGE_PASSWORD: `${PATH}CHANGE_PASSWORD`,
  CHANGE_PASSWORD_SUCCESS: `${PATH}CHANGE_PASSWORD_SUCCESS`,
  CHANGE_PASSWORD_FAIL: `${PATH}CHANGE_PASSWORD_FAIL`,
};

export const userProfileActions = {
  formChanged: createAction(USER_PROFILE.FORM_CHANGED, 'form'),
  initialQuery: createAction(USER_PROFILE.INITIAL_QUERY),
  initialQuerySuccess: createAction(USER_PROFILE.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(USER_PROFILE.INITIAL_QUERY_FAIL, 'payload'),
  submitForm: createAction(USER_PROFILE.SUBMIT_FORM, 'form', 'copyUserInfo'),
  submitFormSuccess: createAction(USER_PROFILE.SUBMIT_FORM_SUCCESS, 'payload'),
  submitFormFail: createAction(USER_PROFILE.SUBMIT_FORM_FAIL, 'payload'),
  changePassword: createAction(USER_PROFILE.CHANGE_PASSWORD, 'form'),
  changePasswordSuccess: createAction(USER_PROFILE.CHANGE_PASSWORD_SUCCESS, 'payload'),
  changePasswordFail: createAction(USER_PROFILE.CHANGE_PASSWORD_FAIL, 'payload'),
};

const initialState = {
  form: {
    // User
    taxNumber: '',
    email: '',
    name: '',
    lastName: '',
    secondLastName: '',
    phone: '',
    address: '',
    // Business and billing
    isBusiness: false,
    commercialBusiness: '',
    billingTaxNumber: '',
    billingName: '',
    billingAddress: '',
    countryId: '',
    communeId: '',
    regionId: '',
    province: '',
    city: '',
    // Refunding
    refundTaxNumber: '',
    refundName: '',
    bankId: '',
    accountTypeId: '',
    refundAccountNumber: '',
    // Password
    confirmationPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    copyUserInfo: false,
  },
  controls: {},
};

const userProfile = createReducer(initialState, {
  [USER_PROFILE.INITIAL_QUERY_SUCCESS](state, action) {
    updateReducerProfile(state, action);
  },
  [USER_PROFILE.SUBMIT_FORM_SUCCESS](state, action) {
    updateReducerProfile(state, action);
  },
  [USER_PROFILE.FORM_CHANGED](state, action) {
    state.form = { ...state.form, ...action.form };
  },
  [USER_PROFILE.SUBMIT_FORM](state) {
    state.form.confirmationPassword = '';
  },
  [USER_PROFILE.CHANGE_PASSWORD](state) {
    state.form.confirmationPassword = '';
    state.form.newPassword = '';
    state.form.newPasswordConfirmation = '';
  },
});

const updateReducerProfile = (state, action) => {
  const { users, people, entities, billingData, communes } = action.payload.objects;

  const user = Object.values(users)[0];
  const person = Object.values(people)[0];
  const entity = entities[person.entityId];
  const userBillingData = person.billingDataId ? billingData[person.billingDataId] : null;
  const region = userBillingData?.communeId ? communes[userBillingData?.communeId]?.regionId : null;

  const isLocal = userBillingData?.countryId == null || userBillingData.countryId === 1;
  const data = {
    confirmationPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    taxNumber: formatRut(entity.taxNumber, true),
    email: user.email,
    name: entity.name,
    lastName: person.lastName,
    secondLastName: person.secondLastName,
    phone: person.phone,
    address: person.address,
    isBusiness: userBillingData?.isBusiness ?? false,
    businessName: userBillingData?.businessName ?? '',
    commercialBusiness:
      userBillingData?.commercialBusiness == null ||
      userBillingData?.commercialBusiness.trim().length === 0
        ? 'Particular'
        : userBillingData?.commercialBusiness,
    businessTaxNumber:
      userBillingData?.businessTaxNumber == null
        ? ''
        : formatRut(userBillingData.businessTaxNumber, true),
    billingTaxNumber:
      userBillingData?.billingTaxNumber == null
        ? ''
        : formatRut(userBillingData.billingTaxNumber, true),
    billingName: userBillingData?.billingName ?? '',
    billingAddress: userBillingData?.billingAddress ?? '',
    countryId: userBillingData?.countryId.toString() ?? '1',
    communeId: isLocal ? userBillingData?.communeId ?? '' : '',
    regionId: isLocal ? region ?? '' : '',
    province: !isLocal ? userBillingData?.province ?? '' : '',
    city: !isLocal ? userBillingData?.city ?? '' : '',
    refundTaxNumber:
      userBillingData?.refundTaxNumber == null
        ? ''
        : formatRut(userBillingData.refundTaxNumber, true),
    refundName: userBillingData?.refundName ?? '',
    bankId: userBillingData?.bankId.toString() ?? '',
    accountTypeId: userBillingData?.accountTypeId.toString() ?? '',
    refundAccountNumber: userBillingData?.accountNumber ?? '',
  };

  state.form = { ...state.form, ...data };
};

export default userProfile;
