import { put, spawn, takeLatest } from 'redux-saga/effects';

/* Project */
import controlledCall from 'utils/services/controlledSaga';
import apiRequest, { getDocument, get, update, post, destroy } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { asQuery } from 'utils/functions';
import { TransfersLotDetailsTypes } from '../types/TransferLotDetails.types';

function* initialQuery() {
  yield takeLatest(
    TransfersLotDetailsTypes.GET_STATE_FROM_API,
    function* fetchInitialQuery(action) {
      const successFunction = (payload) => ({
        type: TransfersLotDetailsTypes.GET_STATE_FROM_API_SUCCESS,
        payload,
      });

      const failFunction = (payload) => ({
        type: TransfersLotDetailsTypes.GET_STATE_FROM_API_FAIL,
        payload,
      });

      const result = yield controlledCall(
        get,
        `api/v1/transfers/${action.payload.transferId}`,
        null,
        successFunction,
        failFunction,
      );

      if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
    },
  );
}

function* invoiceTmplate() {
  yield takeLatest(TransfersLotDetailsTypes.GET_INVOICE_TEMPLATE, function* fetchInvoice(action) {
    const successFunction = (payload) => ({
      type: TransfersLotDetailsTypes.GET_INVOICE_TEMPLATE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersLotDetailsTypes.GET_INVOICE_TEMPLATE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('PlantillaFactura', 'xlsx', true),
      `api/v1/transfers/invoice/template`,
      asQuery(action.payload),
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* uploadRecordCertificate() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_RECORD_CERTIFICATE,
    function* fetchUploadRecord(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        'api/v1/transfers/record-certificate-upload/',
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_RECORD_CERTIFICATE_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_RECORD_CERTIFICATE_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* uploadInvoiceTmplate() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE,
    function* fetchUploadInvoice(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        `api/v1/transfers/invoice/upload`,
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_INVOICE_TEMPLATE_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* uploadInvoiceDocument() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_INVOICE_DOCUMENT,
    function* fetchUploadInvoiceDocument(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        'api/v1/transfers/invoice/upload-document',
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_INVOICE_DOCUMENT_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_INVOICE_DOCUMENT_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* completeStep() {
  yield takeLatest(TransfersLotDetailsTypes.COMPLETE_STEP, function* fetchCompleteStep(action) {
    const body = { ...action.payload };
    const successFunction = (payload) => ({
      type: TransfersLotDetailsTypes.COMPLETE_STEP_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersLotDetailsTypes.COMPLETE_STEP_FAIL,
      payload,
    });

    const result = yield controlledCall(
      update,
      `api/v1/transfers/complete-step/${action.payload.transferId}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* getWorksheet() {
  yield takeLatest(TransfersLotDetailsTypes.GET_WORKSHEET, function* fetchWorkSheet(action) {
    const successFunction = (payload) => ({
      type: TransfersLotDetailsTypes.GET_WORKSHEET_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersLotDetailsTypes.GET_WORKSHEET_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('HojadeTrabajo', 'pdf', true),
      `api/v1/transfers/worksheet/document`,
      asQuery(action.payload),
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* uploadFormtwentythree() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_TWENTYTHREE_DOC,
    function* fetchUploadFormtwentythree(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        'api/v1/transfers/form-twenty-three/upload/document',
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_TWENTYTHREE_DOC_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_TWENTYTHREE_DOC_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* uploadSignedFormtwentythree() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_SIGNED_TWENTYTHREE_DOC,
    function* fetchUploadSignedFormtwentythree(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        'api/v1/transfers/signed-form-twenty-three',
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_SIGNED_TWENTYTHREE_DOC_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_SIGNED_TWENTYTHREE_DOC_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* uploadCivilRegistrationDoc() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_CIVIL_REGISTRATION_DOC,
    function* fetchUploadCivilRegistrationDoc(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        'api/v1/transfers/civil-registration',
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_CIVIL_REGISTRATION_DOC_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_CIVIL_REGISTRATION_DOC_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* createRequestNumber() {
  yield takeLatest(
    TransfersLotDetailsTypes.CREATE_REQUEST_NUMBER,
    function* fetchCreateRequestNumber(action) {
      const body = { ...action.payload.data };

      const successFunction = (payload) => ({
        type: TransfersLotDetailsTypes.CREATE_REQUEST_NUMBER_SUCCESS,
        payload,
      });

      const failFunction = (payload) => ({
        type: TransfersLotDetailsTypes.CREATE_REQUEST_NUMBER_FAIL,
        payload,
      });

      const result = yield controlledCall(
        update,
        `api/v1/transfers/request-number/${action.payload.transferId}`,
        body,
        successFunction,
        failFunction,
      );

      if (result.error) {
        yield put({
          type: SET_ERROR,
          msg: result.errorMsg,
        });
      }
    },
  );
}

function* uploadRegistrationEntryDoc() {
  yield takeLatest(
    TransfersLotDetailsTypes.UPLOAD_REGISTRATION_ENTRY_DOC,
    function* fetchUploadRegistrationEntryDoc(action) {
      const { file, transferId, typeOfUpload } = action.payload;

      const data = new FormData();
      data.append('files', file);
      data.append('transferId', transferId);
      data.append('typeOfUpload', typeOfUpload);

      const response = yield apiRequest(
        'api/v1/transfers/registration-entry',
        {
          method: 'post',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        },
        false,
      );

      if (response.error) {
        yield put({
          type: SET_ERROR,
          msg: response.errorMsg,
        });
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_REGISTRATION_ENTRY_DOC_FAIL,
          payload: response,
        });
      } else {
        yield put({
          type: TransfersLotDetailsTypes.UPLOAD_REGISTRATION_ENTRY_DOC_SUCCESS,
          payload: response,
        });
      }
    },
  );
}

function* sendRejectMessage() {
  yield takeLatest(
    TransfersLotDetailsTypes.SEND_REJECT_TRANSFER,
    function* fetchSendRejectMessage(action) {
      const body = { ...action.payload };
      const successFunction = (payload) => ({
        type: TransfersLotDetailsTypes.SEND_REJECT_TRANSFER_SUCCESS,
        payload,
      });

      const failFunction = (payload) => ({
        type: TransfersLotDetailsTypes.SEND_REJECT_TRANSFER_FAIL,
        payload,
      });

      const result = yield controlledCall(
        post,
        `api/v1/transfers/reject-message`,
        body,
        successFunction,
        failFunction,
      );

      if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
    },
  );
}

function* rejectTransfer() {
  yield takeLatest(TransfersLotDetailsTypes.REJECT_TRANSFER, function* fetchRejectTransfer(action) {
    const body = { ...action.payload };
    const successFunction = (payload) => ({
      type: TransfersLotDetailsTypes.REJECT_TRANSFER_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersLotDetailsTypes.REJECT_TRANSFER_FAIL,
      payload,
    });

    const result = yield controlledCall(
      update,
      `api/v1/transfers/reject-transfer/${action.payload.transferId}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* deleteTransfer() {
  yield takeLatest(TransfersLotDetailsTypes.DELETE_TRANSFER, function* fetchDeleteTransfer(action) {
    const successFunction = (payload) => ({
      type: TransfersLotDetailsTypes.DELETE_TRANSFER_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersLotDetailsTypes.DELETE_TRANSFER_FAIL,
      payload,
    });

    const result = yield controlledCall(
      destroy,
      `api/v1/transfers/${action.payload.transferId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({ type: SET_ERROR, msg: result.errorMsg });
    } else {
      window.location.replace('/transfers/lots');
    }
  });
}

export default function* TransferLotDetailsSaga() {
  yield spawn(initialQuery);
  yield spawn(invoiceTmplate);
  yield spawn(uploadRecordCertificate);
  yield spawn(uploadInvoiceTmplate);
  yield spawn(uploadInvoiceDocument);
  yield spawn(completeStep);
  yield spawn(getWorksheet);
  yield spawn(uploadFormtwentythree);
  yield spawn(uploadSignedFormtwentythree);
  yield spawn(uploadCivilRegistrationDoc);
  yield spawn(createRequestNumber);
  yield spawn(uploadRegistrationEntryDoc);
  yield spawn(sendRejectMessage);
  yield spawn(rejectTransfer);
  yield spawn(deleteTransfer);
}
