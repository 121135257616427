/**
 * @param {Boolean} open
 * @returns {Object}
 */
export const boxStyle = (open) => ({
  '@media print': {
    display: 'none',
  },
  // zIndex: '9999999',
  // position: 'fixed',
  // top: isMobile ? '40vh' : '55vh',
  right: 0,
  fontFamily: 'MyriadBold',
  height: 165,
  fontSize: 13,
  width: open ? 220 : 34,
  backgroundColor: '#0d47a1',
  transition: 'width 0.5s',
  float: 'right',
  cursor: 'pointer',
  // transform: 'rotate(-90deg)',
});

/**
 * @param {Boolean} open
 * @returns {Object}
 */
export const finishedBoxStyles = (open) => ({
  '@media print': {
    display: 'none',
  },
  // zIndex: '9999999',
  // position: 'fixed',
  // top: isMobile ? '40vh' : '55vh',
  marginTop: 12,
  right: 0,
  fontFamily: 'MyriadBold',
  height: 165,
  fontSize: 13,
  width: open ? 220 : 34,
  backgroundColor: '#0d47a1',
  transition: 'width 0.5s',
  float: 'right',
  cursor: 'pointer',
  // transform: 'rotate(-90deg)',
});
