import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import { APP_SEARCH_QUERY_SUCCESS, GLOBAL_FILTER_RESET } from 'utils/actions/global.actions';

// Local constants
const PATH = 'lots/';
export const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const SET_DEFAULT = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
export const RESET_FILTERS = `${PATH}RESET_FILTERS`;
const CLONE_CONTROLS_CHANGED = `${PATH}CLONE_CONTROLS_CHANGED`;

// Global constants for saga
export const LOTS = {
  // get state from api
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  DELETE_LOT_SUCCESS: `${PATH}DELETE_LOT_SUCCESS`,
  DELETE_LOT_FAIL: `${PATH}DELETE_LOT_FAIL`,
  DELETE_LOT: `${PATH}DELETE_LOT`,
  MASS_UPLOAD_LOTS: `${PATH}MASS_UPLOAD_LOTS`,
  MASS_UPLOAD_LOTS_SUCCESS: `${PATH}MASS_UPLOAD_LOTS_SUCCESS`,
  MASS_UPLOAD_LOTS_FAIL: `${PATH}MASS_UPLOAD_LOTS_FAIL`,
  CLONE_LOTS: `${PATH}CLONE_LOTS`,
  CLONE_LOTS_SUCCESS: `${PATH}CLONE_LOTS_SUCCESS`,
  CLONE_LOTS_FAIL: `${PATH}CLONE_LOTS_FAIL`,
  GET_CLONE_INFO: `${PATH}GET_CLONE_INFO`,
  GET_CLONE_INFO_SUCCESS: `${PATH}GET_CLONE_INFO_SUCCESS`,
  GET_CLONE_INFO_FAIL: `${PATH}GET_CLONE_INFO_FAIL`,
  GENERATE_TEMPLATE: `${PATH}GENERATE_TEMPLATE`,
  GENERATE_TEMPLATE_SUCCESS: `${PATH}GENERATE_TEMPLATE_SUCCESS`,
  GENERATE_TEMPLATE_FAIL: `${PATH}GENERATE_TEMPLATE_FAIL`,
  // get basic info catalog report
  GET_INFO_CATALOG: `${PATH}GET_INFO_CATALOG`, /// :id/get_info_catalog
  GET_INFO_CATALOG_SUCCESS: `${PATH}GET_INFO_CATALOG_SUCCESS`,
  GET_INFO_CATALOG_FAIL: `${PATH}GET_INFO_CATALOG_FAIL`,
  FORCE_RELOAD: `${PATH}FORCE_RELOAD`,
  FILTER_FROM_URL: `${PATH}FILTER_FROM_URL`,
  COPY_TO_SALE: `${PATH}COPY_TO_SALE`,
  COPY_TO_SALE_SUCCESS: `${PATH}COPY_TO_SALE_SUCCESS`,
  COPY_TO_SALE_FAIL: `${PATH}COPY_TO_SALE_FAIL`,
};

// actions
export const lotsActions = {
  toggleForm: createAction(TOGGLE_FORM, 'controls'),
  toggleDelete: createAction(TOGGLE_DELETE),
  setDefault: createAction(SET_DEFAULT),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  cloneControlsChanged: createAction(CLONE_CONTROLS_CHANGED, 'controls'),
  resetFilters: createAction(RESET_FILTERS),
  // get state
  initialQuerySuccess: createAction(LOTS.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(LOTS.INITIAL_QUERY_FAIL, 'payload'),
  initialQuery: createAction(LOTS.INITIAL_QUERY, 'controls'),
  // delete lot
  deleteLotSuccess: createAction(LOTS.DELETE_LOT_SUCCESS, 'payload'),
  deleteLotFail: createAction(LOTS.DELETE_LOT_FAIL, 'payload'),
  deleteLot: createAction(LOTS.DELETE_LOT, 'id'),
  // mass upload of lots
  massUploadLotsSuccess: createAction(LOTS.MASS_UPLOAD_LOTS_SUCCESS, 'payload'),
  massUploadLotsFail: createAction(LOTS.MASS_UPLOAD_LOTS_FAIL, 'payload'),
  massUploadLots: createAction(LOTS.MASS_UPLOAD_LOTS, 'controls'),
  // clone lots - historial
  cloneLotsSuccess: createAction(LOTS.CLONE_LOTS_SUCCESS, 'payload'),
  cloneLotsFail: createAction(LOTS.CLONE_LOTS_FAIL, 'payload'),
  cloneLots: createAction(LOTS.CLONE_LOTS, 'controls'),
  // get basic clone info
  getCloneInfoSuccess: createAction(LOTS.GET_CLONE_INFO_SUCCESS, 'payload'),
  getCloneInfoFail: createAction(LOTS.GET_CLONE_INFO_FAIL, 'payload'),
  getCloneInfo: createAction(LOTS.GET_CLONE_INFO),
  // Geneate dinamyc template
  generateTemplateSuccess: createAction(LOTS.GENERATE_TEMPLATE_SUCCESS, 'payload'),
  generateTemplateFail: createAction(LOTS.GENERATE_TEMPLATE_FAIL, 'payload'),
  generateTemplate: createAction(LOTS.GENERATE_TEMPLATE, 'controls'),
  // get basic info catalog report
  getInfoCatalogSuccess: createAction(LOTS.GET_INFO_CATALOG_SUCCESS, 'payload'),
  getInfoCatalogFail: createAction(LOTS.GET_INFO_CATALOG_FAIL, 'payload'),
  getInfoCatalog: createAction(LOTS.GET_INFO_CATALOG, 'controls'),
  filterFromUrl: createAction(LOTS.FILTER_FROM_URL),
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const dafaultFilters = {
  lotType: urlParams.get('lotType') || '',
  auctionId: urlParams.get('auctionId') || '',
  auctionCategoryId: '',
  lotCategoryId: '',
  stateId: '0',
  minArea: '',
  maxArea: '',
  minUtilArea: '',
  maxUtilArea: '',
  regionId: '',
  communeId: '',
  minRange: '',
  maxRange: '',
  unitId: '',
};

const defaultCloneControls = {
  auctionId: null,
  mandateId: null,
  validDestiny: true,
  message: '',
  basicInfoLoaded: false,
  auctionsIds: [],
};
export const initialState = {
  controls: {
    applyFilter: false,
    viewList: false,
    deleteIsOpen: false,
    isSale: false,
    isAdmin: false,
    isArea: false,
    printIsOpen: false,
    isMobile: false,
    areaColor: '',
    importIsOpen: false,
    importedLotsFile: null,
    lotsCreated: null,
    wrongLots: null,
    formatValidation: null,
    ...dafaultFilters,
    urlParams: false,
    oldLot: false,
    cloneControls: defaultCloneControls,
    cloneIsOpen: false,
    auctionImportId: null,
    areaId: urlParams.get('areaId') || null,
    searchParaments: urlParams.get('searchParaments') || '',
    forceReload: false,
    filterFromUrl: false,
  },
  filteredLotsIds: [],
  loading: false,
  queryLoading: true,
};

const lots = createReducer(initialState, {
  [RESET_FILTERS](state) {
    state.controls = {
      ...state.controls,
      searchParaments: '',
      ...dafaultFilters,
    };
  },
  [GLOBAL_FILTER_RESET](state) {
    state.controls = {
      ...state.controls,
      ...dafaultFilters,
      lotType: '',
      auctionId: '',
      searchParaments: '',
    };
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    if (action.controls.lotType === 'sale') {
      state.controls.auctionId = '';
    }
  },
  [CLONE_CONTROLS_CHANGED](state, action) {
    state.controls.cloneControls = {
      ...state.controls.cloneControls,
      ...action.controls,
    };
  },
  [TOGGLE_DELETE](state) {
    state.controls.deleteIsOpen = !state.controls.deleteIsOpen;
    state.controls.anchorEl = null;
  },
  [LOTS.DELETE_LOT_SUCCESS](state) {
    state.controls.deleteIsOpen = false;
  },
  [TOGGLE_FORM](state, action) {
    state.controls[action.controls] = !state.controls[action.controls];
    if (!state.controls.cloneIsOpen) {
      state.controls.cloneControls = defaultCloneControls;
    }
    if (!state.controls.importIsOpen) {
      state.controls.auctionImportId = null;
      state.controls.importedLotsFile = null;
      state.controls.wrongLots = null;
      state.controls.formatValidation = null;
      state.controls.areaId = null;
    }
  },
  [LOTS.INITIAL_QUERY_SUCCESS](state, action) {
    state.filteredLotsIds = action.payload.filteredLotsIds;
    state.queryLoading = false;
    state.controls.forceReload = false;
    state.controls.filterFromUrl = false;
  },
  [LOTS.INITIAL_QUERY](state) {
    state.queryLoading = true;
  },
  [LOTS.MASS_UPLOAD_LOTS_SUCCESS](state, action) {
    state.controls.wrongLots = action.payload.validations.wrongLots;
    state.controls.formatValidation = action.payload.validations.headersValition;
    state.controls.areaId = action.payload.validations.areaId;
    // state.controls.wrongLots = [1, 2, 3, 4];
    // state.controls.headersValition = true;
  },
  [LOTS.CLONE_LOTS_SUCCESS](state, action) {
    state.controls.cloneControls.validDestiny = action.payload.validDestiny;
    state.controls.cloneControls.message = action.payload.message;
    state.loading = false;
  },
  [LOTS.CLONE_LOTS](state) {
    state.loading = true;
  },
  [LOTS.GET_CLONE_INFO_SUCCESS](state, action) {
    state.controls.cloneControls.basicInfoLoaded = true;
    state.controls.cloneControls.auctionsIds = action.payload.auctionsIds;
  },
  [LOTS.GET_INFO_CATALOG_SUCCESS](state, action) {
    state.controls.printIsOpen = true;
    state.controls.lotsIds = action.payload.lotsIds;
  },
  [LOTS.FILTER_FROM_URL](state) {
    state.controls.filterFromUrl = true;
  },
  [APP_SEARCH_QUERY_SUCCESS](state) {
    state.queryLoading = false;
  },
  [LOTS.COPY_TO_SALE](state) {
    state.controls.anchorEl = null;
  },
});

export default lots;
