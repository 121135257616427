import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'webpayFinal/';

// Global constants for saga
export const WEBPAY_FINAL = {
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
};

// actions
export const webpayFinalActions = {
  initialQuerySuccess: createAction(WEBPAY_FINAL.INITIAL_QUERY_SUCCESS, 'payload'),
  initialQueryFail: createAction(WEBPAY_FINAL.INITIAL_QUERY_FAIL, 'payload'),
  initialQuery: createAction(WEBPAY_FINAL.INITIAL_QUERY),
};

const initialState = {};

const webpay = createReducer(initialState, {});

export default webpay;
