import React, { useState, useEffect } from 'react';

// Material ui
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';

// Project
import logo from 'utils/images/mini-normal.png';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { APP } from 'commons/reducer/commons.reducer';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function NoticeDialog(props) {
  const [open, setOpen] = useState(false);
  const { globals, viewed } = props;
  const { noticeContent, noticeExpiration, noticePage, noticeTitle } = globals;
  const path = window.location.pathname;

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: APP.VIEWED_GLOBAL_DIALOG });
  };

  useEffect(() => {
    if (!viewed) {
      if (noticeExpiration && noticeExpiration !== '') {
        const expirationDate = new Date(noticeExpiration);
        const dateDiff = moment(Date.now()).isBefore(expirationDate);
        if (path === noticePage && dateDiff) {
          setOpen(true);
        }
      }
    }
  }, [noticeExpiration, noticePage, path, viewed]);

  const parseContent = (text) => {
    if (!text) return '<div />';

    const getLinks = text.match(/<(.*?)>/g)?.map((value) => value.substring(1, value.length - 1));

    if (getLinks) {
      return text.replace(/<(.*?)>/g, (url) => {
        let urlCopy = url.replace(/</g, '');
        urlCopy = urlCopy.replace(/>/g, '');
        const [tag, link] = urlCopy.split(',');
        return `<a href='${link || tag}' target='_blank'>${tag}</a>`;
      });
    }

    return text;
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <DialogTitle id="alert-dialog-slide-title">{noticeTitle}</DialogTitle>
          <img src={logo} width="auto" height="35px" alt="logo" style={{ padding: ' 16px 24px' }} />
        </Grid>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            dangerouslySetInnerHTML={{ __html: parseContent(noticeContent) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NoticeDialog;
