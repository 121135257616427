import { put, spawn, takeLatest } from 'redux-saga/effects';

import { history } from 'utils/history';
import controlledCall from 'utils/services/controlledSaga';
import { get, post } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { WorkSheetFormTypes } from '../types/WorkSheetForm.types';

function* getStateFromApi() {
  yield takeLatest(WorkSheetFormTypes.GET_STATE_FROM_API, function* fetchState(action) {
    const successFunction = (payload) => ({
      type: WorkSheetFormTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: WorkSheetFormTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/worksheet/${action.payload.transferId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* createWorksheet() {
  yield takeLatest(WorkSheetFormTypes.CREATE_WORKSHEET, function* generateSheet(action) {
    const body = { ...action.payload.data };
    delete body.loading;
    const successFunction = (payload) => ({
      type: WorkSheetFormTypes.CREATE_WORKSHEET_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: WorkSheetFormTypes.CREATE_WORKSHEET_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      `api/v1/transfers/worksheet/${action.payload.transferId}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    } else {
      history.push(`/transfers/lots/${action.payload.transferId}`);
    }
  });
}

export default function* WorkSheetFormSaga() {
  yield spawn(getStateFromApi);
  yield spawn(createWorksheet);
}
