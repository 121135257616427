import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { EDIT_USER, editUserActions } from '../reducer/editUser.reducer';

function* initialQuery() {
  yield takeLatest(EDIT_USER.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('save-profile', true));
    yield controlledCall(
      get,
      `api/v1/users/${action.id}`,
      undefined,
      editUserActions.initialQuerySuccess,
    );
    yield put(appActions.setLoader('save-profile', false));
  });
}

function* submitForm() {
  yield takeLatest(EDIT_USER.SUBMIT_FORM, function* (action) {
    yield put(appActions.setLoader('save-profile', true));
    const {
      name,
      lastName,
      secondLastName,
      phone,
      address,
      isBusiness,
      commercialBusiness,
      billingAddress,
      email,
      countryId,
      communeId,
      city,
      province,
      billingName,
      billingTaxNumber,
      refundTaxNumber,
      refundName,
      refundAccountNumber,
      bankId,
      accountTypeId,
      confirmationPassword,
      businessName,
      businessTaxNumber,
    } = action.form;

    const body = {
      name,
      lastName,
      secondLastName,
      phone,
      address,
      isBusiness,
      commercialBusiness,
      billingAddress,
      email,
      countryId: parseInt(countryId),
      billingTaxNumber: billingTaxNumber?.replace(/[-.]/g, ''),
      communeId: countryId?.toString() === '1' ? parseInt(communeId) : undefined,
      city: countryId?.toString() === '2' ? city : undefined,
      province: countryId?.toString() === '2' ? province : undefined,
      refundTaxNumber: refundTaxNumber.replace(/[-.]/g, ''),
      refundName,
      refundAccountNumber,
      billingName,
      bankId: parseInt(bankId),
      accountTypeId: parseInt(accountTypeId),
      confirmationPassword,
      businessTaxNumber: businessTaxNumber?.replace(/[-.]/g, ''),
      businessName,
    };
    const result = yield controlledCall(
      update,
      `api/v1/users/${action.id}/edit`,
      body,
      editUserActions.submitFormSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se actualizaron los datos de usuario exitosamente'));
    }

    yield put(appActions.setLoader('save-profile', false));
  });
}

export default function* editUserSaga() {
  yield spawn(initialQuery);
  yield spawn(submitForm);
}
