import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, { apiSuccess, destroy, get, post, update } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { SELLERS_MANAGER, sellersManagerActions } from '../reducer/sellersManager.reducer';

function* getSellers() {
  yield takeLatest(SELLERS_MANAGER.GET_SELLERS, function* (action) {
    const body = {
      filter: action.payload.filter,
      ...action.payload.pagination,
    };

    yield controlledCall(
      get,
      `api/v1/sellers?${new URLSearchParams(body)}`,
      undefined,
      sellersManagerActions.getSellersSuccess,
      sellersManagerActions.getSellersFail,
    );
  });
}

function* createSeller() {
  yield takeLatest(SELLERS_MANAGER.CREATE_SELLER, function* (action) {
    const { controls } = action.controls;

    const body = {
      taxNumber: controls?.taxNumber?.trim(),
      name: controls?.name?.trim(),
      enabled: controls.enabled,
      isNaturalPerson: controls?.isNaturalPerson,
      email: controls?.email?.trim(),
    };

    yield put(appActions.setLoader('sellers-manager', true));

    const response = yield controlledCall(
      post,
      `api/v1/sellers`,
      body,
      sellersManagerActions.createSellerSuccess,
      sellersManagerActions.createSellerFail,
    );

    if (response.error) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(appActions.setSuccess('Mandante creado exitosamente.'));
      yield put(
        sellersManagerActions.getSellers({
          filter: null,
          pagination: {
            page: 1,
            offset: 0,
            rowsPerPage: 15,
          },
        }),
      );
    }

    yield put(appActions.setLoader('sellers-manager', false));
  });
}

function* updateSeller() {
  yield takeLatest(SELLERS_MANAGER.EDIT_SELLER, function* (action) {
    const { controls, sellerId } = action.controls;

    const body = {
      taxNumber: controls?.taxNumber?.trim(),
      name: controls?.name?.trim(),
      enabled: controls.enabled,
      isNaturalPerson: controls?.isNaturalPerson,
      email: controls?.email?.trim(),
    };

    yield put(appActions.setLoader('sellers-manager', true));
    const response = yield controlledCall(
      update,
      `api/v1/sellers/${sellerId}`,
      body,
      sellersManagerActions.createSellerSuccess,
      sellersManagerActions.createSellerFail,
    );

    if (response.error) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(appActions.setSuccess('Mandante actualizado exitosamente.'));
      yield put(
        sellersManagerActions.getSellers({
          filter: null,
          pagination: {
            page: 1,
            offset: 0,
            rowsPerPage: 15,
          },
        }),
      );
    }

    yield put(appActions.setLoader('sellers-manager', false));
  });
}

function* deleteSeller() {
  yield takeLatest(SELLERS_MANAGER.DELETE_SELLER, function* (action) {
    yield put(appActions.setLoader('sellers-manager', true));
    yield controlledCall(
      destroy,
      `api/v1/sellers/${action.controls.sellerId}`,
      undefined,
      sellersManagerActions.deleteSellerSuccess,
      sellersManagerActions.deleteSellerFail,
    );
    yield put(appActions.setLoader('sellers-manager', false));
  });
}

function* transformSellerToUser() {
  yield takeLatest(SELLERS_MANAGER.TRANSFORM_USER, function* (action) {
    const controls = {
      email: action.controls.email,
      password: action.controls.password,
    };

    yield put(appActions.setLoader('sellers-manager', true));

    const result = yield controlledCall(
      update,
      `api/v1/sellers/transform-to-user/${action.controls.sellerId}`,
      controls,
      sellersManagerActions.transformUserSuccess,
      sellersManagerActions.transformUserFail,
    );

    if (result.error) {
      yield put(appActions.setError(result.errorMsg));
    } else {
      yield put(appActions.setSuccess('Usuario para mandante creado exitosamente'));
    }

    yield put(appActions.setLoader('sellers-manager', false));
  });
}

function* restorePassword() {
  yield takeLatest(SELLERS_MANAGER.RESTORE_PASSWORD, function* (action) {
    const body = { email: action.email };

    const response = yield controlledCall(
      post,
      `api/v1/sellers/restore-password`,
      body,
      sellersManagerActions.restorePasswordSuccessOrFail,
      sellersManagerActions.restorePasswordSuccessOrFail,
    );

    if (response.error) {
      yield put(appActions.setError(response.errorMsg));
    } else {
      yield put(
        appActions.setSuccess('Se ha enviado correctamente la solicitud de cambio de contraseña'),
      );
    }
  });
}

export default function* sellersManagerSaga() {
  yield spawn(getSellers);
  yield spawn(createSeller);
  yield spawn(updateSeller);
  yield spawn(deleteSeller);
  yield spawn(transformSellerToUser);
  yield spawn(restorePassword);
}
