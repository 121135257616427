import { newReducer } from 'utils/reducer/utils.reducer';
import { FinishedAuctionsTypes } from '../types/FinishedAuctions.types';
import { AUCTIONS } from '../../Auctions/reducer/auctions.reducer';

const initialState = {
  pagination: {
    page: 1,
    offset: 0,
    limit: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
  },
  controls: {
    loading: false,
    ids: [],
    total: 0,
  },
  downloadUrl: null,
  dowloading: null,
};

const FinishedAuctionsReducer = newReducer(initialState, {
  [FinishedAuctionsTypes.GET_STATE_FROM_API]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: true,
    },
    pagination: {
      ...state.pagination,
      ...action.payload.pagination,
    },
  }),
  [FinishedAuctionsTypes.GET_STATE_FROM_API_SUCCESS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: false,
      ids: action.payload.ids,
      total: action.payload.total,
    },
  }),
  [FinishedAuctionsTypes.GET_STATE_FROM_API_FAIL]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      loading: false,
    },
  }),
  [FinishedAuctionsTypes.CHANGE_PAGINATION]: (state, action) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...action.payload.pagination,
    },
  }),
  [AUCTIONS.DOWNLOAD_PUBLIC_REPORT]: (state) => ({
    ...state,
    downloadUrl: null,
    dowloading: true,
  }),
  [AUCTIONS.DOWNLOAD_PUBLIC_REPORT_SUCCESS]: (state, action) => ({
    ...state,
    downloadUrl: action.payload?.url,
    dowloading: false,
  }),
  [FinishedAuctionsTypes.CLEAR_URL]: (state) => ({
    ...state,
    downloadUrl: null,
  }),
});

export default FinishedAuctionsReducer;
