import io from 'socket.io-client';

/* Saga */
import { eventChannel } from 'redux-saga';
import { call, put, spawn, take, takeLatest } from 'redux-saga/effects';

/* Project */
import { APP, appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { truthty } from 'utils/functions';
import { history } from 'utils/history';
import apiRequest, { apiSuccess, get, objectToQueryString, post } from 'utils/api/api';
import { LOGIN } from '../../modules/main/Login/reducer/login.reducer';
import { lotsActions } from '../../modules/main/Lots/reducer/lots.reducer';

const loader = {
  setup() {
    this.cancel();
    this.timeoutID = window.setTimeout(() => {
      document.getElementById('loader-container').hidden = true;
      document.getElementById('root').hidden = false;
    }, 1000);
  },
  cancel() {
    if (typeof this.timeoutID === 'number') {
      window.clearTimeout(this.timeoutID);
      delete this.timeoutID;
    }
  },
};

function* initialQuery() {
  yield takeLatest(APP.INITIAL_QUERY, function* () {
    yield controlledCall(
      get,
      'api/v1/',
      undefined,
      appActions.initialQuerySuccess,
      appActions.initialQueryFail,
    );
  });

  loader.setup();
}

function* createContactMessage() {
  yield takeLatest(APP.CREATE_CONTACT_MESSAGE, function* (action) {
    const result = yield controlledCall(
      post,
      'api/v1/public_contact_messages/',
      action.controls,
      appActions.createContactMessageSuccess,
      appActions.createContactMessageFail,
    );

    if (!result?.error) {
      yield put(
        appActions.setAlertDialog({
          msg: 'Mensaje de contacto recibido, nos contactaremos contigo pronto.',
          typeAlert: 'success',
        }),
      );
    }
  });
}

function connect() {
  // TCT: TODO Debug why websockets transport is not working
  const socket = io();
  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });
}

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('objects', (e) => {
      if (truthty(e.objects)) {
        emit(appActions.objectsFromSocketReceived({ objects: e.objects }));
      }
      if (truthty(e.ids)) {
        emit(appActions.deleteObjectsFromSocketReceived({ response: { ids: e.ids } }));
      }
    });
    socket.on('onTest', (e) => {
      emit(appActions.mergeControls(e));
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* flow() {
  // if (process.env.NODE_ENV === 'production') {
  while (true) {
    yield take(APP.ACTIVATE);
    // TCT: TODO: Connect at loguin
    const socket = yield call(connect);
    localStorage.setItem('socketId', socket.id);
    // yield put(appActions.connectSocketSuccess(socket));
    // socket.emit('login', { username: payload.username });

    yield spawn(read, socket);

    // let action = yield take(`4321`);
    // yield cancel(task);
    // socket.emit('logout');
  }
}

function* getUser() {
  yield takeLatest(LOGIN.GET_USER, function* () {
    const response = yield apiRequest('api/v1/users/user', { method: 'get' });
    if (truthty(response)) {
      yield put(apiSuccess(LOGIN.GET_USER_SUCCESS, response));
    } else {
      yield put(apiSuccess(LOGIN.GET_USER_FAIL));
    }
  });
}

function* searchQuery() {
  yield takeLatest(APP.SEARCH_QUERY, function* (action) {
    yield controlledCall(
      get,
      'api/v1/lots/search',
      objectToQueryString(action.controls),
      appActions.searchQuerySuccess,
      appActions.searchQueryFail,
    );
    yield put(appActions.setLoading(false));
    const { pathname } = history.location;
    appActions.searchControlsChanged({ manualAreaChanged: false });
    lotsActions.controlsChanged({ searchParaments: action.controls.searchParaments });

    if (pathname !== '/lots') {
      history.push('/lots');
    } else {
      lotsActions.resetFilters();
    }

    yield put(appActions.setLoader('get-lots', false));
  });
}

function* searchAuction() {
  yield takeLatest(APP.AUTO_SEARCH_AUCTIONS, function* (action) {
    yield controlledCall(
      get,
      `api/v1/auctions/search_auction?${new URLSearchParams(action.controls)}`,
      undefined,
      appActions.autoSearchAuctionsSuccess,
      appActions.autoSearchAuctionsFail,
    );
  });
}

function* fetchBalance() {
  yield takeLatest(APP.FETCH_BALANCE, function* () {
    yield controlledCall(get, 'api/v1/me/balance', undefined, appActions.fetchBalanceSuccess);
  });
}

export default function* commonsSaga() {
  yield spawn(createContactMessage);
  yield spawn(initialQuery);
  yield spawn(getUser);
  yield spawn(flow);
  yield spawn(searchQuery);
  yield spawn(searchAuction);
  yield spawn(fetchBalance);
}
