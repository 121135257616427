import { createAction, newReducer } from 'utils/reducer/utils.reducer';
import moment from 'moment';
import { GLOBAL_FILTER_RESET } from 'utils/actions/global.actions';

// Local constants
const PATH = 'auctions/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TEMPLATE_CONTROLS_CHANGED = `${PATH}TEMPLATE_CONTROLS_CHANGED`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT = `${PATH}TOGGLE_EDIT`;
const TOGGLE_DOWNLOAD_TEMPLATE = `${PATH}TOGGLE_DOWNLOAD_TEMPLATE`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const PAGINATION_CHANGED = `${PATH}PAGINATION_CHANGED`;
const RESET_FILTERS = `${PATH}RESET_FILTERS`;
// Bulk clone modal
const SHOW_BULK_CLONE_MODAL = `${PATH}SHOW_BULK_CLONE_MODAL`;
export const HIDE_BULK_CLONE_MODAL = `${PATH}HIDE_BULK_CLONE_MODAL`;
const TOGGLE_BULK_CLONE_MODAL = `${PATH}TOGGLE_BULK_CLONE_MODAL`;

// Global constants for saga
export const AUCTIONS = {
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  DELETE_AUCTION: `${PATH}DELETE_AUCTION`,
  DELETE_AUCTION_SUCCESS: `${PATH}DELETE_AUCTION_SUCCESS`,
  DELETE_AUCTION_FAIL: `${PATH}DELETE_AUCTION_FAIL`,
  GET_STATE_IMG_VID_REPORT: `${PATH}GET_STATE_IMG_VID_REPORT`,
  GET_STATE_IMG_VID_REPORT_SUCCESS: `${PATH}GET_STATE_IMG_VID_REPORT_SUCCESS`,
  GET_STATE_IMG_VID_REPORT_FAIL: `${PATH}GET_STATE_IMG_VID_REPORT_FAIL`,
  GET_STATE_TOTAL_REPORT: `${PATH}GET_STATE_TOTAL_REPORT`,
  GET_STATE_TOTAL_REPORT_SUCCESS: `${PATH}GET_STATE_TOTAL_REPORT_SUCCESS`,
  GET_STATE_TOTAL_REPORT_FAIL: `${PATH}GET_STATE_TOTAL_REPORT_FAIL`,
  GET_MEDIA_REPORT: `${PATH}GET_MEDIA_REPORT`,
  GET_AUCTION_REPORT: `${PATH}GET_AUCTION_REPORT`,
  GET_RESULTS_REPORT: `${PATH}GET_RESULTS_REPORT`,
  GET_MINUTE_REPORT: `${PATH}GET_MINUTE_REPORT`,
  DOWNLOAD_PUBLIC_REPORT: `${PATH}DOWNLOAD_PUBLIC_REPORT`,
  DOWNLOAD_PUBLIC_REPORT_SUCCESS: `${PATH}DOWNLOAD_PUBLIC_REPORT_SUCCESS`,
  GENERATE_TEMPLATE_1: `${PATH}GENERATE_TEMPLATE_1`,
  UPLOAD_TEMPLATE: `${PATH}UPLOAD_TEMPLATE`,
  UPLOAD_TEMPLATE_FAIL: `${PATH}UPLOAD_TEMPLATE_FAIL`,
  UPLOAD_FILES: `${PATH}UPLOAD_FILES`,
  CLEAR_BULK_LOAD: `${PATH}CLEAR_BULK_LOAD`,
};

// actions
export const auctionsActions = {
  resetFilters: createAction(RESET_FILTERS),
  toggleForm: createAction(TOGGLE_FORM, 'controls'),
  paginationChanged: createAction(PAGINATION_CHANGED, 'options'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  toggleDelete: createAction(TOGGLE_DELETE),
  toggleEdit: createAction(TOGGLE_EDIT, 'Auction'),
  // api
  initialQuery: createAction(AUCTIONS.INITIAL_QUERY, 'filters'),
  deleteAuction: createAction(AUCTIONS.DELETE_AUCTION, 'controls'),
  getStateImgVidReport: createAction(AUCTIONS.GET_STATE_IMG_VID_REPORT, 'id'),
  getStateTotalReport: createAction(AUCTIONS.GET_STATE_TOTAL_REPORT, 'id'),
  // success
  initialQuerySuccess: createAction(AUCTIONS.INITIAL_QUERY_SUCCESS, 'payload'),
  deleteAuctionSuccess: createAction(AUCTIONS.DELETE_AUCTION_SUCCESS, 'payload'),
  getStateImgVidReportSuccess: createAction(AUCTIONS.GET_STATE_IMG_VID_REPORT_SUCCESS, 'payload'),
  getStateTotalReportSuccess: createAction(AUCTIONS.GET_STATE_TOTAL_REPORT_SUCCESS, 'payload'),
  // fail
  initialQueryFail: createAction(AUCTIONS.INITIAL_QUERY_FAIL, 'payload'),
  deleteAuctionFail: createAction(AUCTIONS.DELETE_AUCTION_FAIL, 'payload'),
  getStateImgVidReportFail: createAction(AUCTIONS.GET_STATE_IMG_VID_REPORT_FAIL, 'payload'),
  getStateTotalReportFail: createAction(AUCTIONS.GET_STATE_TOTAL_REPORT_FAIL, 'payload'),
  getMediaReport: createAction(AUCTIONS.GET_MEDIA_REPORT, 'auctionId'),
  getAuctionReport: createAction(AUCTIONS.GET_AUCTION_REPORT, 'auctionId'),
  getResultsReport: createAction(AUCTIONS.GET_RESULTS_REPORT, 'auctionId'),
  getMinuteReport: createAction(AUCTIONS.GET_MINUTE_REPORT, 'auctionId'),
  // Bulk refund
  showBulkCloneModal: createAction(SHOW_BULK_CLONE_MODAL),
  hideBulkCloneModal: createAction(HIDE_BULK_CLONE_MODAL),
  toggleBulkCloneModal: createAction(TOGGLE_BULK_CLONE_MODAL),
  downloadPublicReport: createAction(AUCTIONS.DOWNLOAD_PUBLIC_REPORT, 'auctionID'),
  // Template
  templateControlsChanged: createAction(TEMPLATE_CONTROLS_CHANGED, 'controls'),
  toggleDownloadTemplate: createAction(TOGGLE_DOWNLOAD_TEMPLATE, 'Auction'),
  generateTemplate1: createAction(AUCTIONS.GENERATE_TEMPLATE_1, 'auctionId'),
  uploadTemplate: createAction(AUCTIONS.UPLOAD_TEMPLATE, 'controls'),
  uploadTemplateFail: createAction(AUCTIONS.UPLOAD_TEMPLATE_FAIL),
  clearBulkLoad: createAction(AUCTIONS.CLEAR_BULK_LOAD),
  uploadFiles: createAction(AUCTIONS.UPLOAD_FILES, 'files'),
};
const defaultInfo = {
  auctions: {},
  mandates: {},
};

const initialState = {
  controls: {
    anchorEl: null,
    viewList: true,
    // startDate: moment().startOf('month').subtract(1, 'M').format('YYYY-MM-DD'),
    // endDate: moment().startOf('month').subtract(1, 'M').add(1, 'y').format('YYYY-MM-DD'),
    startDate: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'd').add(1, 'y').format('YYYY-MM-DD'),
    stateId: '0',
    auction: null,
    edit: false,
    set: true,
    printIsOpen: false,
    isAdmin: false,
    isArea: false,
    isMobile: false,
    applyFilter: false,
    auctionsCount: 0,
    onScreen: true,
    showBulkCloneModal: false,
    originAuctionId: null,
    destinationAuctionId: null,
    auctionCode: 'ALL',
    saleClone: false,
  },
  ids: {
    auctions: [],
  },
  pagination: {
    areaId: null,
    page: 0,
    rowsPerPage: 10,
  },
  imgVideoInfo: defaultInfo,
  totalInfo: defaultInfo,
  deleteIsOpen: false,
  auctionsIds: [],
  loading: false,
  templateControls: {
    auctionId: null,
    isOpenModal: false,
    importedFile: null,
  },
};

const auctions = newReducer(initialState, {
  [RESET_FILTERS]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      ...initialState.controls,
    },
    pagination: initialState.pagination,
  }),
  [GLOBAL_FILTER_RESET]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      ...initialState.controls,
    },
    pagination: initialState.pagination,
  }),
  [CONTROLS_CHANGED]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      ...action.controls,
    },
  }),
  [TOGGLE_DELETE]: (state) => ({
    ...state,
    deleteIsOpen: !state.deleteIsOpen,
    controls: {
      ...state.controls,
      anchorEl: null,
    },
  }),
  [AUCTIONS.DELETE_AUCTION_SUCCESS]: (state) => ({
    ...state,
    deleteIsOpen: false,
    controls: {
      ...state.controls,
      anchorEl: null,
    },
  }),
  [TOGGLE_EDIT]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      edit: true,
    },
  }),
  [TOGGLE_FORM]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.controls]: !state.controls[action.controls],
    },
  }),
  [AUCTIONS.INITIAL_QUERY]: (state) => ({
    ...state,
    loading: true,
  }),
  [AUCTIONS.INITIAL_QUERY_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    ids: {
      ...state.ids,
      auctions: action.payload.ids.auctions,
    },
    controls: {
      ...state.controls,
      applyFilter: false,
      auctionsCount: action.payload.count.auctions,
    },
  }),
  [PAGINATION_CHANGED]: (state, action) => ({
    ...state,
    // if (action.options.page) { state.pagination.page = action.options.page; }
    // if (action.options.count) { state.pagination.count = action.options.count; }
    pagination: { ...state.pagination, ...action.options },
  }),
  // Bulk clone modal
  [SHOW_BULK_CLONE_MODAL]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      showBulkCloneModal: true,
    },
  }),
  [HIDE_BULK_CLONE_MODAL]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      originAuctionId: null,
      destinationAuctionId: null,
      showBulkCloneModal: false,
    },
  }),
  [TOGGLE_BULK_CLONE_MODAL]: (state) => ({
    ...state,
    controls: {
      ...state.controls,
      showBulkCloneModal: !state.controls.showBulkCloneModal,
    },
  }),
  [TEMPLATE_CONTROLS_CHANGED]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      anchorEl: null,
    },
    templateControls: {
      ...state.templateControls,
      ...action.controls,
    },
  }),
  [AUCTIONS.UPLOAD_TEMPLATE_FAIL]: (state, action) => ({
    ...state,
    templateControls: {
      ...state.templateControls,
      importedFile: null,
    },
  }),
  [AUCTIONS.CLEAR_BULK_LOAD]: (state, action) => ({
    ...state,
    templateControls: {
      auctionId: null,
      isOpenModal: false,
      importedFile: null,
    },
  }),
});

export default auctions;
