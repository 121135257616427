import { createReducer } from 'utils/reducer/utils.reducer';
import { RecordCertificateFormTypes } from '../types/RecordCertificateForm.types';

const initialState = {
  controls: {
    loading: false,
    openForm: false,
    showLimitationsForm: false,
    invoice: '',
    verificationCode: '',
    inscription: '',
    vehicleType: '',
    year: '',
    brand: '',
    model: '',
    engineNumber: '',
    chasisNumber: '',
    color: '',
    fuel: '',
    pbv: '',
    insuranceInstitution: '',
    policyNumber: '',
    expirationPolicyDate: null,
    ownerName: '',
    rut: '',
    acquisitionDate: null,
    repertoire: '',
    number: '',
    date: null,
    dateOfIssue: null,
    merePossession: '',
    limitationsRut: '',
    documentType: '',
    natureAct: '',
    docOrRolNumber: '',
    place: '',
    limitationsDate: null,
    authorizingAgent: '',
    limitationsRepertoire: '',
    limitationsNumber: '',
    observations: '',
    valuePayed: null,
    regionTaxes: '',
    toggleCreateTitle: false,
    toggleListTitle: false,
    limitationsTitle: '',
    limitationsTitleId: '',
    brandCategory: '',
    inputCategory: '',
    inputBrand: '',
    inputModel: '',
    patent: '',
  },
  data: {
    entryCertificate: [],
    transferredVehicle: [],
    transferOwner: [],
    domainRestriction: [],
    transfer: [],
    brands: [],
    models: [],
    selectedModel: [],
    vehicleTypes: [],
    domainLimitations: [],
  },
};

const RecordCertificateFormReducer = createReducer(initialState, {
  [RecordCertificateFormTypes.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.name]: action.payload.value,
    },
  }),
  [RecordCertificateFormTypes.OPEN_DOMAIN_DIALOG]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      openForm: !state.controls.openForm,
    },
  }),
  [RecordCertificateFormTypes.OPEN_DOMAIN_FORM]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      showLimitationsForm: !state.controls.showLimitationsForm,
      merePossession: '',
      limitationsRut: '',
      documentType: '',
      natureAct: '',
      docOrRolNumber: '',
      place: '',
      limitationsDate: null,
      authorizingAgent: '',
      limitationsRepertoire: '',
      limitationsNumber: '',
      observations: '',
      valuePayed: null,
      regionTaxes: '',
      limitationsTitle: '',
      limitationsTitleId: '',
    },
  }),
  [RecordCertificateFormTypes.CREATE_CERTIFICATE_SUCCESS]: (state, action) => ({
    controls: {
      ...initialState.controls,
    },
    data: {
      ...initialState.data,
    },
  }),
  [RecordCertificateFormTypes.RESET_FORM]: (state, action) => ({
    controls: {
      ...initialState.controls,
    },
    data: {
      ...initialState.data,
    },
  }),
  [RecordCertificateFormTypes.GET_STATE_FROM_API_SUCCESS]: (state, action) => ({
    data: {
      ...action.payload.data,
    },
    controls: {
      invoice: action.payload.data.entryCertificate.folio,
      verificationCode: action.payload.data.entryCertificate.verificationCode,
      inscription: action.payload.data.entryCertificate.inscription,
      vehicleType: action.payload.data.transferredVehicle.vehicleTypeId,
      year: action.payload.data.transferredVehicle.year,
      brand: action.payload.data.selectedBrand,
      model: action.payload.data.transferredVehicle.modelId,
      engineNumber: action.payload.data.transferredVehicle.engineNumber,
      chasisNumber: action.payload.data.transferredVehicle.chassisNumber,
      color: action.payload.data.transferredVehicle.color,
      fuel: action.payload.data.transferredVehicle.fuel,
      pbv: action.payload.data.transferredVehicle.pbv,
      insuranceInstitution: action.payload.data.transferredVehicle.insuranceInstitutionId,
      policyNumber: action.payload.data.transferredVehicle.insuranceNumber,
      expirationPolicyDate: action.payload.data.transferredVehicle.insuranceExpiration,
      ownerName: action.payload.data.transferOwner.names,
      rut: action.payload.data.transferOwner.rut,
      acquisitionDate: action.payload.data.entryCertificate.purchaseDate,
      repertoire: action.payload.data.entryCertificate.repertoire,
      number: action.payload.data.entryCertificate.propertyNumber,
      date: action.payload.data.entryCertificate.date,
      dateOfIssue: action.payload.data.entryCertificate.releaseDate,
      brandCategory: action.payload.data.category[0]?.id,
      patent: action.payload.data.transferredVehicle.patent,
    },
  }),
  [RecordCertificateFormTypes.TOGGLE_CREATE_LIMITATION_TITLE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toggleCreateTitle: !state.controls.toggleCreateTitle,
      toggleListTitle: false,
      limitationsTitleId: '',
    },
  }),
  [RecordCertificateFormTypes.TOGGLE_LIST_LIMITATION_TITLE]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      toggleListTitle: !state.controls.toggleListTitle,
      toggleCreateTitle: false,
      limitationsTitle: '',
    },
  }),
});

export default RecordCertificateFormReducer;
