const base = '[TRANSFER_MANAGER_LOT_DETAILS]';

export const TransfersLotDetailsTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  GET_INVOICE_TEMPLATE: `${base} GET_INVOICE_TEMPLATE`,
  GET_INVOICE_TEMPLATE_SUCCESS: `${base} GET_INVOICE_TEMPLATE_SUCCESS`,
  GET_INVOICE_TEMPLATE_FAIL: `${base} GET_INVOICE_TEMPLATE_FAIL`,
  OPEN_RECORD_CERTIFICATE: `${base} OPEN_RECORD_CERTIFICATE`,
  OPEN_INVOICE: `${base} OPEN_INVOICE`,
  OPEN_VERSIONS_CERTIFICATE: `${base} OPEN_VERSIONS_CERTIFICATE`,
  OPEN_UPLOAD_INVOICE_TEMPLATE: `${base} OPEN_UPLOAD_INVOICE_TEMPLATE`,
  OPEN_UPLOAD_INVOICE_DOCUMENT: `${base} OPEN_UPLOAD_INVOICE_DOCUMENT`,
  OPEN_UPLOAD_CERTIFICATE: `${base} OPEN_UPLOAD_CERTIFICATE`,
  OPEN_UPLOAD_TWENTYTHREE_FORM: `${base} OPEN_UPLOAD_TWENTYTHREE_FORM`,
  OPEN_UPLOAD_SIGNED_TWENTYTHREE_FORM: `${base} OPEN_UPLOAD_SIGNED_TWENTYTHREE_FORM`,
  OPEN_UPLOAD_CIVIL_REGISTRATION: `${base} OPEN_UPLOAD_CIVIL_REGISTRATION`,
  OPEN_UPLOAD_REGISTRATION_ENTRY: `${base} OPEN_UPLOAD_REGISTRATION_ENTRY`,
  CLOSE_CERTIFICATE_DIALOG: `${base} CLOSE_CERTIFICATE_DIALOG`,
  HANDLE_CHANGE: `${base} HANDLE_CHANGE`,
  OPEN_WORKSHEET: `${base} OPEN_WORKSHEET`,
  OPEN_FORM_TWENTY_THREE: `${base} OPEN_FORM_TWENTY_THREE`,
  OPEN_SIGNED_FORM: `${base} OPEN_SIGNED_FORM`,
  OPEN_INSCRIPTION_CERTIFICATE: `${base} OPEN_INSCRIPTION_CERTIFICATE`,
  OPEN_INVOICE_TEMPLATE: `${base} OPEN_INVOICE_TEMPLATE`,
  OPEN_REQUEST_NUMBER: `${base} OPEN_REQUEST_NUMBER`,
  OPEN_REGISTRATION_ENTRY: `${base} OPEN_REGISTRATION_ENTRY`,
  HANDLE_DOC: `${base} HANDLE_DOC`,
  HANDLE_INVOICE: `${base} HANDLE_INVOICE`,
  HANDLE_INVOICE_DOC: `${base} HANDLE_INVOICE_DOC`,
  HANDLE_TWENTYTHREE_DOC: `${base} HANDLE_TWENTYTHREE_DOC`,
  HANDLE_SIGNED_TWENTYTHREE_DOC: `${base} HANDLE_SIGNED_TWENTYTHREE_DOC`,
  HANDLE_CIVIL_REGISTRATION_DOC: `${base} HANDLE_CIVIL_REGISTRATION_DOC`,
  HANDLE_REGISTRATION_ENTRY_DOC: `${base} HANDLE_REGISTRATION_ENTRY_DOC`,
  UPLOAD_RECORD_CERTIFICATE: `${base} UPLOAD_RECORD_CERTIFICATE`,
  UPLOAD_RECORD_CERTIFICATE_SUCCESS: `${base} UPLOAD_RECORD_CERTIFICATE_SUCCESS`,
  UPLOAD_RECORD_CERTIFICATE_FAIL: `${base} UPLOAD_RECORD_CERTIFICATE_FAIL`,
  UPLOAD_INVOICE_TEMPLATE: `${base} UPLOAD_INVOICE_TEMPLATE`,
  UPLOAD_INVOICE_TEMPLATE_SUCCESS: `${base} UPLOAD_INVOICE_TEMPLATE_SUCCESS`,
  UPLOAD_INVOICE_TEMPLATE_FAIL: `${base} UPLOAD_INVOICE_TEMPLATE_FAIL`,
  UPLOAD_INVOICE_DOCUMENT: `${base} UPLOAD_INVOICE_DOCUMENT`,
  UPLOAD_INVOICE_DOCUMENT_SUCCESS: `${base} UPLOAD_INVOICE_DOCUMENT_SUCCESS`,
  UPLOAD_INVOICE_DOCUMENT_FAIL: `${base} UPLOAD_INVOICE_DOCUMENT_FAIL`,
  UPLOAD_TWENTYTHREE_DOC: `${base} UPLOAD_TWENTYTHREE_DOC`,
  UPLOAD_TWENTYTHREE_DOC_SUCCESS: `${base} UPLOAD_TWENTYTHREE_DOC_SUCCESS`,
  UPLOAD_TWENTYTHREE_DOC_FAIL: `${base} UPLOAD_TWENTYTHREE_DOC_FAIL`,
  UPLOAD_SIGNED_TWENTYTHREE_DOC: `${base} UPLOAD_SIGNED_TWENTYTHREE_DOC`,
  UPLOAD_SIGNED_TWENTYTHREE_DOC_SUCCESS: `${base} UPLOAD_SIGNED_TWENTYTHREE_DOC_SUCCESS`,
  UPLOAD_SIGNED_TWENTYTHREE_DOC_FAIL: `${base} UPLOAD_SIGNED_TWENTYTHREE_DOC_FAIL`,
  UPLOAD_CIVIL_REGISTRATION_DOC: `${base} UPLOAD_CIVIL_REGISTRATION_DOC`,
  UPLOAD_CIVIL_REGISTRATION_DOC_SUCCESS: `${base} UPLOAD_CIVIL_REGISTRATION_DOC_SUCCESS`,
  UPLOAD_CIVIL_REGISTRATION_DOC_FAIL: `${base} UPLOAD_CIVIL_REGISTRATION_DOC_FAIL`,
  UPLOAD_REGISTRATION_ENTRY_DOC: `${base} UPLOAD_REGISTRATION_ENTRY_DOC`,
  UPLOAD_REGISTRATION_ENTRY_DOC_SUCCESS: `${base} UPLOAD_REGISTRATION_ENTRY_DOC_SUCCESS`,
  UPLOAD_REGISTRATION_ENTRY_DOC_FAIL: `${base} UPLOAD_REGISTRATION_ENTRY_DOC_FAIL`,
  COMPLETE_STEP: `${base} COMPLETE_STEP`,
  COMPLETE_STEP_SUCCESS: `${base} COMPLETE_STEP_SUCCESS`,
  COMPLETE_STEP_FAIL: `${base} COMPLETE_STEP_FAIL`,
  OPEN_APROVE_CERTIFICATE: `${base} OPEN_APROVE_CERTIFICATE`,
  OPEN_APROVE_INVOICE_TEMPLATE: `${base} OPEN_APROVE_INVOICE_TEMPLATE`,
  OPEN_APROVE_INVOICE: `${base} OPEN_APROVE_INVOICE`,
  OPEN_APROVE_MANDATE: `${base} OPEN_APROVE_MANDATE`,
  OPEN_APROVE_WORKSHEET: `${base} OPEN_APROVE_WORKSHEET`,
  OPEN_APROVE_FORM_TWENTYTHREE: `${base} OPEN_APROVE_FORM_TWENTYTHREE`,
  OPEN_APROVE_SIGNED_FORM_TWENTYTHREE: `${base} OPEN_APROVE_SIGNED_FORM_TWENTYTHREE`,
  OPEN_APROVE_CIVIL_REGISTRATION: `${base} OPEN_APROVE_CIVIL_REGISTRATION`,
  OPEN_APROVE_COMPLETE_TRANSFER: `${base} OPEN_APROVE_COMPLETE_TRANSFER`,
  OPEN_APROVE_REGISTRATION_ENTRY: `${base} OPEN_APROVE_REGISTRATION_ENTRY`,
  GET_WORKSHEET: `${base} GET_WORKSHEET`,
  GET_WORKSHEET_SUCCESS: `${base} GET_WORKSHEET_SUCCESS`,
  GET_WORKSHEET_FAIL: `${base} GET_WORKSHEET_FAIL`,
  CREATE_REQUEST_NUMBER: `${base} CREATE_REQUEST_NUMBER`,
  CREATE_REQUEST_NUMBER_SUCCESS: `${base} CREATE_REQUEST_NUMBER_SUCCESS`,
  CREATE_REQUEST_NUMBER_FAIL: `${base} CREATE_REQUEST_NUMBER_FAIL`,
  RESET_FORM: `${base} RESET_FORM`,
  OPEN_REJECT_TRANSFER: `${base} OPEN_REJECT_TRANSFER`,
  SEND_REJECT_TRANSFER: `${base} SEND_REJECT_TRANSFER`,
  SEND_REJECT_TRANSFER_SUCCESS: `${base} SEND_REJECT_TRANSFER_SUCCESS`,
  SEND_REJECT_TRANSFER_FAIL: `${base} SEND_REJECT_TRANSFER_FAIL`,
  TOGGLE_REJECT_TRANSFER: `${base} TOGGLE_REJECT_TRANSFER`,
  TOGGLE_REJECT_STEP: `${base} TOGGLE_REJECT_STEP`,
  REJECT_TRANSFER: `${base} REJECT_TRANSFER`,
  REJECT_TRANSFER_SUCCESS: `${base} REJECT_TRANSFER_SUCCESS`,
  REJECT_TRANSFER_FAIL: `${base} REJECT_TRANSFER_FAIL`,
  TOGGLE_DELETE_TRANSFER: `${base} TOGGLE_DELETE_TRANSFER`,
  DELETE_TRANSFER: `${base} DELETE_TRANSFER`,
  DELETE_TRANSFER_SUCCESS: `${base} DELETE_TRANSFER_SUCCESS`,
  DELETE_TRANSFER_FAIL: `${base} DELETE_TRANSFER_FAIL`,
};

export default TransfersLotDetailsTypes;
