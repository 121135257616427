import { spawn, takeLatest, put } from 'redux-saga/effects';
import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { get } from 'utils/api/api';
import { DirectSaleActions } from '../actions/DirectSale.actions';

function* initialQuery() {
  yield takeLatest(DirectSaleActions.GET_STATE_FROM_API, function* () {
    yield put(appActions.setLoading(true));

    const successFunction = (payload) => ({
      type: DirectSaleActions.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = () => ({ type: DirectSaleActions.GET_STATE_FROM_API_FAIL });

    yield controlledCall(get, 'api/v1/direct_sale', undefined, successFunction, failFunction);
    yield put(appActions.setLoading(false));
  });
}

export default function* DirectSaleSaga() {
  yield spawn(initialQuery);
}
