import { put, spawn, takeLatest } from 'redux-saga/effects';

/* Project */
import apiRequest, { destroy, get, getDocument, objectToQueryString, post } from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { LOTS, lotsActions } from '../reducer/lots.reducer';

function* initialQuery() {
  yield takeLatest(LOTS.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('get-lots', true));
    yield controlledCall(
      get,
      'api/v1/lots',
      objectToQueryString(action.controls),
      lotsActions.initialQuerySuccess,
      lotsActions.initialQueryFail,
    );
    yield put(appActions.setLoader('get-lots', false));
  });
}

function* deleteLot() {
  yield takeLatest(LOTS.DELETE_LOT, function* (action) {
    const result = yield controlledCall(
      destroy,
      `api/v1/lots/${action.id}`,
      undefined,
      lotsActions.deleteLotSuccess,
    );
    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha eliminado el lote exitosamene'));
    }
  });
}

function* massUploadOfLots() {
  yield takeLatest(LOTS.MASS_UPLOAD_LOTS, function* (action) {
    const { controls } = action;
    yield put(appActions.setLoader('mass-upload-lots', true));
    const data = new FormData();
    data.append('file', controls.importedLotsFile);
    data.append('name', 'prueba');
    const response = yield apiRequest(
      `api/v1/lots/massUpload?auctionId=${controls.auctionId}`,
      {
        method: 'post',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );
    yield put(appActions.setLoader('mass-upload-lots', false));
    if (response && !response.error) {
      yield put(lotsActions.massUploadLotsSuccess(response));
    } else {
      yield put(appActions.setError(response.errorMsg));
    }
  });
}

function* cloneLot() {
  yield takeLatest(LOTS.CLONE_LOTS, function* (action) {
    yield put(appActions.setLoader('copy-lots', true));
    const body = {
      originalLotId: action.controls.lotId,
      mandateId: action.controls.mandateId,
      auctionDestinyId: action.controls.auctionId,
    };
    const result = yield controlledCall(
      post,
      'api/v1/lots/clone',
      body,
      lotsActions.cloneLotsSuccess,
      lotsActions.cloneLotsFail,
    );
    yield put(appActions.setLoader('copy-lots', false));
    if (result.validDestiny) history.push(`/lots_manager/${result.lotId}`);
  });
}

function* basicCloneInfo() {
  yield takeLatest(LOTS.GET_CLONE_INFO, function* () {
    yield put(appActions.setLoader('copy-lots', true));
    yield controlledCall(
      get,
      'api/v1/lots/basicCloneInfo',
      undefined,
      lotsActions.getCloneInfoSuccess,
      lotsActions.getCloneInfoFail,
    );
    yield put(appActions.setLoader('copy-lots', false));
  });
}

function* generateTemplate() {
  yield takeLatest(LOTS.GENERATE_TEMPLATE, function* (action) {
    yield put(appActions.setLoader('generate-template', true));
    const { auctionId } = action.controls;

    yield controlledCall(
      getDocument('template', 'xlsx', true),
      `api/v1/lots/generateTemplate/${auctionId}`,
      undefined,
      lotsActions.generateTemplateSuccess,
      lotsActions.generateTemplateFail,
    );

    yield put(appActions.setLoader('generate-template', false));
  });
}

function* getInfoCatalog() {
  // id/get_info_catalog
  yield takeLatest(LOTS.GET_INFO_CATALOG, function* (action) {
    yield put(appActions.setLoader('get-info-catalog', true));
    yield controlledCall(
      get,
      `api/v1/lots/getInfoCatalog/${action.controls.auctionId}`,
      undefined,
      lotsActions.getInfoCatalogSuccess,
      lotsActions.getInfoCatalogFail,
    );
    yield put(appActions.setLoader('get-info-catalog', true));
  });
}

function* copyToSale() {
  yield takeLatest(LOTS.COPY_TO_SALE, function* (action) {
    yield put(appActions.setLoader('mass-upload-lots', true));

    const successFunction = () => ({ type: LOTS.COPY_TO_SALE_SUCCESS });
    const failFunction = () => ({ type: LOTS.COPY_TO_SALE_FAIL });

    const result = yield controlledCall(
      post,
      `api/v1/lots/copyToSale/${action.payload.id}`,
      {},
      successFunction,
      failFunction,
      false,
      true,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess(result.msg || 'Lote copiado exitosamente'));
    } else {
      yield put(appActions.setError(result?.msg || 'Error interno'));
    }

    yield put(appActions.setLoader('mass-upload-lots', false));
  });
}

// api/v1/lots/generateTemplate

export default function* lotsSaga() {
  yield spawn(initialQuery);
  yield spawn(deleteLot);
  yield spawn(massUploadOfLots);
  yield spawn(cloneLot);
  yield spawn(basicCloneInfo);
  yield spawn(generateTemplate);
  yield spawn(getInfoCatalog);
  yield spawn(copyToSale);
}
