import camelize from 'camelcase-keys-deep';

export default class Offer {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  lot() {
    return this.env().Lot.findBy('id', this.lotId);
  }

  guarantee() {
    return this.env().Guarantee.getById(this.guaranteeId);
  }

  adjudication() {
    return this.lot()?.adjudication();
  }

  user() {
    return this.guarantee()?.user();
  }
}
