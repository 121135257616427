import camelize from 'camelcase-keys-deep';

export default class AuctionCategory {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  lotCategories() {
    return this.env().LotCategory.findAllBy('auctionCategoryId', this.id);
  }

  area() {
    return this.env().Area.getById(this.areaId);
  }
}
