import { put, spawn, takeLatest, select } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, post } from 'utils/api/api';
import { getItemInStorage, onlyKeysWithValue } from 'utils/functions';
import { SET_ERROR, SET_SUCCESS, appActions } from 'commons/reducer/commons.reducer';
import TransfersTypes from './Transfers.types';

function* getUnusedLots() {
  yield takeLatest(TransfersTypes.GET_UNUSED_LOTS, function* () {
    const body = {};

    const successFunction = (payload) => ({
      type: TransfersTypes.GET_UNUSED_LOTS_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersTypes.GET_UNUSED_LOTS_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/lots/unused?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
  });
}

function* getStateFromApi() {
  yield takeLatest(TransfersTypes.GET_STATE_FROM_API, function* (action) {
    const socketId = yield select((state) => state.app.socketId);
    const body = onlyKeysWithValue({
      ...action.payload.filters,
      ...action.payload.pagination,
      socketId,
    });

    delete body.page;
    delete body.rowsPerPageOptions;

    if (!body.socketId) {
      yield put({ type: SET_ERROR, msg: 'Error, inténtelo de nuevo' });
      return;
    }

    const successFunction = (payload) => ({
      type: TransfersTypes.DO_NOTHING,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
  });
}

function* createTransfer() {
  yield takeLatest(TransfersTypes.CREATE_TRANSFER, function* (action) {
    const body = { ...action.payload };

    const successFunction = (payload) => ({
      type: TransfersTypes.CREATE_TRANSFER_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersTypes.CREATE_TRANSFER_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      `api/v1/transfers`,
      body,
      successFunction,
      failFunction,
    );
    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
  });
}

function* registerDirectSale() {
  yield takeLatest(TransfersTypes.CREATE_DIRECT_SALE_USER, function* (action) {
    const body = { ...action.payload, fromDirectSale: true };
    const successFunction = (payload) => ({
      type: TransfersTypes.CREATE_DIRECT_SALE_USER_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersTypes.CREATE_DIRECT_SALE_USER_ERROR,
      payload,
    });

    const result = yield controlledCall(
      post,
      'api/v1/users/sign_up',
      body,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    } else {
      yield put({
        type: SET_SUCCESS,
        msg: 'Usuario creado, Se ha enviado un mail al usuario para activar su cuenta',
      });
    }
  });
}

function* getUnusedLotsSale() {
  yield takeLatest(TransfersTypes.GET_UNUSED_LOTS_SALE, function* () {
    const body = {};

    const successFunction = (payload) => ({
      type: TransfersTypes.GET_UNUSED_LOTS_SALE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: TransfersTypes.GET_UNUSED_LOTS_SALE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/lots/unused-sale?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
  });
}

export default function* Transfers() {
  yield spawn(getUnusedLots);
  yield spawn(getStateFromApi);
  yield spawn(createTransfer);
  yield spawn(registerDirectSale);
  yield spawn(getUnusedLotsSale);
}
