import { useState } from 'react';
import { getItemInStorage } from 'utils/functions';

const useApi = (path = '', initialMethod = 'GET', initialBody = null) => {
  const session = getItemInStorage('user');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async ({ url = path, method = initialMethod, body = initialBody }) => {
    setLoading(true);
    setData(null);
    setError(null);
    let result = null;
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...(session?.token && { Authorization: `Bearer ${session && session.token}` }),
        },
        body: method !== 'GET' && body ? JSON.stringify(body) : null,
      };
      const finalUrl = `${process.env.REACT_APP_BACKEND_ALB}/${url}`;
      const response = await fetch(finalUrl, options);
      result = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setData(result);
      return result;
    } catch (e) {
      setError(e.message);
      console.error('Error fetching data:', e);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
};

export default useApi;
