import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import moment from 'moment';
import { defaultCurreny } from 'utils/helpers/helper';

// Local constants
const PATH = 'auctionsManager/';
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const FORM_CONTROLS_CHANGED = `${PATH}FORM_CONTROLS_CHANGED`;
const MANDATES_CONTROLS_CHANGED = `${PATH}MANDATES_CONTROLS_CHANGED`;
const CONTACTS_CONTROLS_CHANGED = `${PATH}MANDATE_CONTROLS_CHANGED`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const CREATE_MANDATE = `${PATH}CREATE_MANDATE`;
const ADD_MANDATES = `${PATH}ADD_MANDATES`;
const EDIT_MANDATE = `${PATH}EDIT_MANDATE`;
const DELETE_MANDATE = `${PATH}DELETE_MANDATE`;
const SET_MANDATE_EDIT = `${PATH}SET_MANDATE_EDIT`;
const SET_AUCTION_EDIT = `${PATH}SET_AUCTION_EDIT`;
const SET_AUCTION_DEFAULT = `${PATH}SET_AUCTION_DEFAULT`;
const RESET_STATE = `${PATH}RESET_STATE`;
const SET_COPY_MANDATE = `${PATH}SET_COPY_MANDATE`;
const COPY_MANDATE = `${PATH}COPY_MANDATE`;
const ADD_UPLOAD_ITEM = `${PATH}ADD_UPLOAD_ITEM`;
const RESET_UPLOAD_ITEM = `${PATH}RESET_UPLOAD_ITEM`;
const MANDATE_FILENAME_CHANGE = `${PATH}MANDATE_FILENAME_CHANGE`;
const TOGGLE_VIEW_DOCUMENT_MODAL = `${PATH}TOGGLE_VIEW_DOCUMENT_MODAL`;
const RESET_VIEW_DOCUMENT_MODAL = `${PATH}RESET_VIEW_DOCUMENT_MODAL`;
const SET_MANDATE_BASES = `${PATH}SET_MANDATE_BASES`;

// Global constants for saga
export const AUCTIONS_MANAGER = {
  // gest state
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  // create
  CREATE_AUCTION: `${PATH}CREATE_AUCTION`,
  CREATE_AUCTION_SUCCESS: `${PATH}CREATE_AUCTION_SUCCESS`,
  CREATE_AUCTION_FAIL: `${PATH}CREATE_AUCTION_FAIL`,
  // edit
  EDIT_AUCTION: `${PATH}EDIT_AUCTION`,
  EDIT_AUCTION_SUCCESS: `${PATH}EDIT_AUCTION_SUCCESS`,
  EDIT_AUCTION_FAIL: `${PATH}EDIT_AUCTION_FAIL`,
};

// actions
export const auctionsManagerActions = {
  resetState: createAction(RESET_STATE),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  formControlsChanged: createAction(FORM_CONTROLS_CHANGED, 'controls'),
  mandatesControlsChanged: createAction(MANDATES_CONTROLS_CHANGED, 'controls', 'invalidControls'),
  contactsControlsChanged: createAction(CONTACTS_CONTROLS_CHANGED, 'controls', 'invalidControls'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  createMandate: createAction(CREATE_MANDATE, 'mandates'),
  addMandates: createAction(ADD_MANDATES, 'mandates'),
  editMandate: createAction(EDIT_MANDATE, 'mandates'),
  deleteMandate: createAction(DELETE_MANDATE, 'controls'),
  setMandateEdit: createAction(SET_MANDATE_EDIT, 'mandate', 'index', 'bases'),
  setAuctionEdit: createAction(SET_AUCTION_EDIT, 'auction', 'contactIds', 'mandates'),
  setAuctionDefault: createAction(SET_AUCTION_DEFAULT),
  setCopyMandate: createAction(SET_COPY_MANDATE, 'mandate'),
  addUploadItem: createAction(ADD_UPLOAD_ITEM, 'mandate'),
  resetUploadItem: createAction(RESET_UPLOAD_ITEM, 'mandate'),
  mandateFileNameChange: createAction(MANDATE_FILENAME_CHANGE, 'index', 'newValue'),
  copyMandate: createAction(COPY_MANDATE, 'sellersIds', 'copyMandate', 'mandates'),
  toggleViewDocumentModal: createAction(TOGGLE_VIEW_DOCUMENT_MODAL, 'mandateBase'),
  resetViewDocumentModal: createAction(RESET_VIEW_DOCUMENT_MODAL),
  // api
  initialQuery: createAction(AUCTIONS_MANAGER.INITIAL_QUERY, 'auctionId'),
  createAuction: createAction(
    AUCTIONS_MANAGER.CREATE_AUCTION,
    'controls',
    'mandatesControls',
    'contactsControls',
    'areaId',
  ),
  editAuction: createAction(
    AUCTIONS_MANAGER.EDIT_AUCTION,
    'controls',
    'mandatesControls',
    'contactsControls',
  ),
  // success
  initialQuerySuccess: createAction(AUCTIONS_MANAGER.INITIAL_QUERY_SUCCESS, 'payload'),
  createAuctionSuccess: createAction(AUCTIONS_MANAGER.CREATE_AUCTION_SUCCESS, 'payload'),
  editAuctionSuccess: createAction(AUCTIONS_MANAGER.EDIT_AUCTION_SUCCESS, 'payload'),
  // fail
  initialQueryFail: createAction(AUCTIONS_MANAGER.INITIAL_QUERY_FAIL, 'payload'),
  createAuctionFail: createAction(AUCTIONS_MANAGER.CREATE_AUCTION_FAIL, 'payload'),
  editAuctionFail: createAction(AUCTIONS_MANAGER.EDIT_AUCTION_FAIL, 'payload'),
  setBases: createAction(SET_MANDATE_BASES, 'bases'),
};
const defaultInvalidControls = {
  name: false,
};
const defaultMandatesControls = {
  formIsOpen: false,
  copyFormIsOpen: false,
  mandates: [],
  sellersToCopy: [],
  commission: '',
  sellerCommission: '',
  sellerId: '0',
  date: moment().format('yyyy-MM-DD'),
  edit: '',
  deleteMandateId: '',
  file: null,
  documentId: null,
  documentName: null,
  copyMandate: null,
  uploadItem: 0,
  // uploadFiles: [],
  // uploadFileNames: [],
  mandateFiles: [],
  viewDocumentModal: false,
};
const defaultFormControls = {
  auctionId: null,
  name: '',
  place: '',
  date: moment().format('YYYY-MM-DDTHH:mm'),
  state: '0',
  auctionTypes: '0',
  inscription: false,
  edit: false,
  initialQuery: false,
  image: null,
  guaranteeAmount: '',
  guaranteePercentage: '',
  areaId: '',
  unitId: '',
  modality: undefined,
  displayLotNumber: true,
  principal: false,
  paymentMethods: [],
};
const defaultContactsControls = {
  filteredName: '',
  contactIds: [],
};
export const initialState = {
  edit: false,
  formControls: {
    ...defaultFormControls,
  },
  mandatesControls: {
    ...defaultMandatesControls,
    mandatesRemoved: [],
    documentsRemoved: [],
    editedMandates: [],
    editedMandate: {},
    mandateBases: {},
    baseIdsRemoved: [],
  },
  contactsControls: {
    ...defaultContactsControls,
  },
  invalidControls: { ...defaultInvalidControls },
};

const auctionsManager = createReducer(initialState, {
  [RESET_STATE](state) {
    state.edit = false;
    state.formControls = {
      ...defaultFormControls,
    };
    state.mandatesControls = {
      ...defaultMandatesControls,
      mandatesRemoved: [],
      documentsRemoved: [],
      editedMandates: [],
      editedMandate: {},
      mandateBases: {},
      baseIdsRemoved: [],
    };
    state.contactsControls = {
      ...defaultContactsControls,
    };
    state.invalidControls = { ...defaultInvalidControls };
  },
  [SET_AUCTION_EDIT](state, action) {
    state.formControls.auctionId = action.auction.id;
    state.formControls.edit = true;
    state.formControls.name = action.auction.name;
    state.formControls.modality = action.auction.modality;
    state.formControls.place = action.auction.place;
    state.formControls.date = moment(action.auction.date).toISOString();
    state.formControls.state = action.auction.stateId;
    state.formControls.areaId = action.auction.areaId;
    state.formControls.inscription = action.auction.inscription;
    state.formControls.displayLotNumber = action.auction.displayLotNumber;
    state.formControls.auctionTypes = action.auction.auctionTypeId;
    state.formControls.image = null;
    state.formControls.documentId = action.auction.documentId;
    state.formControls.paymentMethods = action.auction.paymentMethods.map((pm) => pm.id);
    state.contactsControls.contactIds = action.contactIds;
    state.mandatesControls.mandates = action.mandates;
    state.formControls.guaranteeAmount =
      action.auction.areaId.toString() === '1' ? action.auction.minimumGuarantee : '';
    state.formControls.guaranteePercentage =
      action.auction.areaId.toString() === '1' ? action.auction.guaranteePercentage : '';
    state.formControls.principal = action.auction.principal;
  },
  [SET_AUCTION_DEFAULT](state) {
    state.formControls = { ...defaultFormControls, unitId: defaultCurreny };
    state.mandatesControls = { ...state.mandatesControls, ...defaultMandatesControls };
    state.contactsControls = { ...defaultContactsControls };
  },
  [AUCTIONS_MANAGER.INITIAL_QUERY_SUCCESS](state) {
    state.formControls.initialQuery = true;
    state.formControls.unitId = defaultCurreny;
  },
  [FORM_CONTROLS_CHANGED](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
  },
  [MANDATES_CONTROLS_CHANGED](state, action) {
    state.mandatesControls = {
      ...state.mandatesControls,
      ...action.controls,
    };
  },
  [CONTACTS_CONTROLS_CHANGED](state, action) {
    state.contactsControls = {
      ...state.contactsControls,
      ...action.controls,
    };
  },
  [CREATE_MANDATE](state, action) {
    state.mandatesControls = { ...state.mandatesControls, ...defaultMandatesControls };
    state.mandatesControls.mandates = action.mandates;
  },
  [ADD_MANDATES](state, action) {
    state.mandatesControls.mandates = [...state.mandatesControls.mandates, ...action.mandates];
    state.mandatesControls.mandatesRemoved = [];
  },
  [SET_MANDATE_EDIT](state, action) {
    // let mandateBase = action.mandateBases.filter(base => base.mandateId === action.mandate.id);
    state.mandatesControls.formIsOpen = true;
    state.mandatesControls.commission = action.mandate.commission;
    state.mandatesControls.sellerCommission = action.mandate.sellerCommission;
    state.mandatesControls.date = moment(action.mandate.date).utc().format('YYYY-MM-DD');
    state.mandatesControls.sellerId = action.mandate.newSellerId || action.mandate.sellerId;
    state.mandatesControls.edit = action.index;
    state.mandatesControls.file = action.mandate.file;
    state.mandatesControls.editedMandate = action.mandate;
    state.mandatesControls.documentId = action.mandate.documentId
      ? action.mandate.documentId
      : null;

    if (action.bases && action.mandate.pristine) {
      const newBases = {};
      action.bases.forEach((base) => {
        if (base.document()) {
          newBases[base.id] = {
            id: base.id,
            pristine: true,
            documentUrl: base.document().url,
            fileName: base.name,
            file: null,
          };
        }
      });
      state.mandatesControls.mandateBases = newBases;
    } else {
      state.mandatesControls.mandateBases = action.mandate.bases;
    }
  },
  [TOGGLE_VIEW_DOCUMENT_MODAL](state, action) {
    state.mandatesControls.viewDocumentModal = true;
    state.mandatesControls.documentId = action.mandateBase.documentId;
    state.mandatesControls.documentName = action.mandateBase.fileName;
  },
  [RESET_VIEW_DOCUMENT_MODAL](state) {
    state.mandatesControls.viewDocumentModal = false;
  },
  [TOGGLE_FORM](state, action) {
    const { mandates } = state.mandatesControls;
    const newModalState = !state.mandatesControls[action.modal];
    state.mandatesControls = { ...state.mandatesControls, ...defaultMandatesControls };
    state.mandatesControls[action.modal] = newModalState;
    state.mandatesControls.mandates = mandates;
  },
  [SET_COPY_MANDATE](state, action) {
    state.mandatesControls.copyFormIsOpen = !state.mandatesControls.copyFormIsOpen;
    state.mandatesControls.copyMandate = action.mandate;
    state.mandatesControls.sellersToCopy = [];
  },
  // UPLOAD MANDATE BASE
  [ADD_UPLOAD_ITEM](state) {
    const newId = new Date().getTime();
    state.mandatesControls.mandateBases = {
      ...state.mandatesControls.mandateBases,
      [newId]: {
        id: newId,
        fileName: '',
        file: null,
        pristine: false,
        unkonow: true,
      },
    };
  },
  [RESET_UPLOAD_ITEM](state) {
    state.mandatesControls.mandateBases = {};
  },
  [MANDATE_FILENAME_CHANGE](state, action) {
    state.mandatesControls.mandateFiles[action.index].fileName = action.newValue;
    // state.mandatesControls.uploadItem = 0;
  },
  [COPY_MANDATE](state, action) {
    state.mandatesControls.copyFormIsOpen = !state.mandatesControls.copyFormIsOpen;
    state.mandatesControls.copyMandate = null;
    state.mandatesControls.sellersToCopy = [];
    state.mandatesControls.mandates = [
      ...state.mandatesControls.mandates,
      ...action.sellersIds.map((sellerId) => {
        let newBases = {};

        if (action.copyMandate.id) {
          const mandate = action.mandates[action.copyMandate.id];
          const bases = mandate.allBases();
          bases.forEach((base) => {
            const newId = new Date().getTime();
            newBases[base.id] = {
              ...base,
              id: newId,
              copied: true,
              unkonow: true,
              pristine: false,
              referenceId: base.id,
              referenceSeller: sellerId,
            };
          });
        } else {
          newBases = { ...action.copyMandate.bases };
        }

        return {
          ...action.copyMandate,
          id: null,
          sellerId,
          bases: newBases,
        };
      }),
    ];
  },
  [SET_MANDATE_BASES](state, action) {
    state.mandatesControls.mandateBases = action.bases;
  },
});

export default auctionsManager;
