import { all } from 'redux-saga/effects';
import commonsSaga from 'commons/saga/commons.saga';
import auctionsSaga from 'modules/main/Auctions/saga/auctions.saga';
import sellersManagerSaga from 'modules/main/SellersManager/saga/sellersManager.saga';
import paymentsSaga from 'modules/main/Payments/saga/payments.saga';
import templateImportModalSaga from 'commons/components/TemplateImportModal/saga/templateImportModal.saga';
import userProfileSaga from 'modules/main/UserProfile/saga/userProfile.saga';
import usersSaga from 'modules/main/Users/saga/users.saga';
import webpayFinalSaga from 'modules/main/WebpayFinal/saga/webpayFinal.saga';
import entitledLotsSaga from 'modules/main/EntitledLots/saga/entitledLots.saga';
import newPaymentSaga from 'modules/main/NewPayment/saga/newPayment.saga';
import newPaymentSagaV2 from 'modules/main/GuaranteeManagerv2/components/StepFour/saga/newPayment';
import loginSaga from 'modules/main/Login/saga/login.saga';
import lotCategoriesSaga from 'modules/main/LotCategories/saga/lotCategories.saga';
import lotDetailsSaga from 'modules/main/LotDetails/saga/lotDetails.saga';
import lotsSaga from 'modules/main/Lots/saga/lots.saga';
import newGuaranteeSaga from 'modules/main/GuaranteesAndPayments/saga/newGuarantee.saga';
import auctionCategoriesSaga from 'modules/main/AuctionCategories/saga/auctionCategories.saga';
import auctionManagerSaga from 'modules/main/AuctionsManager/saga/auctionManager.saga';
import contactMessagesSaga from 'modules/main/ContactMessages/saga/contactMessages.saga';
import editUserSaga from 'modules/main/EditUser/saga/editUser.saga';
import resetPasswordSaga from 'modules/main/ResetPassword/saga/resetPassword.saga';
import guaranteeManagerSaga from 'modules/main/GuaranteeManager/saga/guaranteeManager.saga';
import guaranteesSaga from 'modules/main/Guarantees/saga/guarantees.saga';
import lotsManagerSaga from 'modules/main/LotsManager/saga/lotsManager.saga';
import registerSaga from 'modules/main/Register/saga/register.saga';
import sortLotsSaga from 'modules/main/SortLots/saga/sortLots.saga';
import ViewReportSaga from 'modules/main/ViewReport/saga/viewReport.saga';
import FeaturedLotsSaga from 'modules/main/FeaturedLotsManager/saga/FeaturedLotsManager.saga';
import DirectSaleContactsManagerSaga from 'modules/direct-sale/DirectSaleContactsManager/saga/DirectSaleContactsManager.saga';
import DirectSaleModelsManagerReducer from 'modules/direct-sale/DirectSaleModelsManager/saga/DirectSaleModelsManager.saga';
import BrandsManagerSaga from 'modules/main/BrandsManager/saga/BrandsManager.saga';
import DirectSaleFeaturesSaga from 'modules/direct-sale/DirectSaleLots/saga/DirectSaleFeatures.saga';
import DirectSaleSaga from 'modules/direct-sale/DirectSale/saga/DirectSale.saga';
import AdjudicationsSaga from 'modules/main/Adjudications/saga/adjudications.saga';
import CurrencyManagerSaga from 'modules/main/CurrencyManager/saga/CurrencyManager.saga';
import SystemChangesSaga from 'modules/main/SystemChanges/saga/systemChanges.saga';
import FinishedAuctionsSaga from 'modules/main/FinishedAuctions/saga/FinishedAuctions.saga';
import PosturesSaga from 'modules/main/PosturesManager/saga/posturesManager.saga';
import TransfersLoginSaga from './modules/transferences/TransferManagerLogin/saga/transfersLogin.saga';
import TransferLotDetailsSaga from './modules/transferences/TransferManagerLotDetails/saga/TransferLotDetails.saga';
import Transfers from './modules/transferences/Transfers/Transfers.saga';
import RecordCertificateFormSaga from './modules/transferences/RecordCertificateForm/saga/RecordCertificateForm.saga';
import MandatePreviewSaga from './modules/transferences/MandatePreview/saga/MandatePreview.saga';
import WorkSheetFormSaga from './modules/transferences/WorkSheetForm/saga/WorkSheetForm.saga';
import FormTwentyThreeSaga from './modules/transferences/FormTwentyThree/saga/FormTwentyThree.saga';
import VehicleTypesManagerSaga from './modules/main/VehicleTypesManager/saga/VehicleTypesManager.saga';
import PendingTransferencesSaga from './modules/transferences/PendingTransferences/saga/PendingTransferences.saga';
import lotsListSaga from './modules/mandates-tracking/LotsList/saga/lotsList.saga';
import mandatesLoginSaga from './modules/mandates-tracking/Login/saga/mandatesLogin.saga';
import bulkLoadSaga from './modules/mandates-tracking/BulkLoad/saga/bulkLoad.saga';

export default function* mainSaga() {
  yield all([
    commonsSaga(),
    auctionsSaga(),
    sellersManagerSaga(),
    paymentsSaga(),
    templateImportModalSaga(),
    userProfileSaga(),
    usersSaga(),
    webpayFinalSaga(),
    entitledLotsSaga(),
    newPaymentSaga(),
    newPaymentSagaV2(),
    loginSaga(),
    lotCategoriesSaga(),
    lotDetailsSaga(),
    lotsSaga(),
    newGuaranteeSaga(),
    auctionCategoriesSaga(),
    auctionManagerSaga(),
    contactMessagesSaga(),
    editUserSaga(),
    resetPasswordSaga(),
    guaranteeManagerSaga(),
    guaranteesSaga(),
    lotsManagerSaga(),
    registerSaga(),
    sortLotsSaga(),
    ViewReportSaga(),
    FeaturedLotsSaga(),
    DirectSaleContactsManagerSaga(),
    DirectSaleModelsManagerReducer(),
    BrandsManagerSaga(),
    DirectSaleFeaturesSaga(),
    DirectSaleSaga(),
    AdjudicationsSaga(),
    CurrencyManagerSaga(),
    SystemChangesSaga(),
    FinishedAuctionsSaga(),
    PosturesSaga(),
    TransfersLoginSaga(),
    TransferLotDetailsSaga(),
    Transfers(),
    RecordCertificateFormSaga(),
    MandatePreviewSaga(),
    WorkSheetFormSaga(),
    FormTwentyThreeSaga(),
    VehicleTypesManagerSaga(),
    PendingTransferencesSaga(),
    lotsListSaga(),
    mandatesLoginSaga(),
    bulkLoadSaga(),
  ]);
}
