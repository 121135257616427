import userProfile from 'modules/main/UserProfile/reducer/userProfile.reducer';
import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import { formatDatePicker, formatDate } from 'utils/moment';

// Local constants
const PATH = 'lotsList/';

export const LOTS_LIST = {
  REQUEST_FAILED: `${PATH}REQUEST_FAILED`,
  FETCH_LOTS_LIST: `${PATH}LOTS_LIST`,
  FETCH_LOTS_LIST_SUCCESS: `${PATH}FETCH_LOTS_LIST_SUCCESS`,
  FETCH_LOTS_LIST_ERROR: `${PATH}FETCH_LOTS_LIST_ERROR`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
  RESET_PAGINATION: `${PATH}RESET_PAGINATION`,
  CHANGE_FILTERS: `${PATH}CHANGE_FILTERS`,
  CHANGE_MODAL: `${PATH}CHANGE_MODAL`,
  GET_MANDATE_LOT_STATUS: `${PATH}GET_MANDATE_LOT_STATUS`,
  GET_MANDATE_LOT_STATUS_SUCCESS: `${PATH}GET_MANDATE_LOT_STATUS_SUCCESS`,
  GET_MANDATE_LOT_STATUS_ERROR: `${PATH}GET_MANDATE_LOT_STATUS_ERROR`,
  UPDATE_MANDATE_LOT_STATUS: `${PATH}UPDATE_MANDATE_LOT_STATUS`,
  UPDATE_MANDATE_LOT_STATUS_SUCCESS: `${PATH}UPDATE_MANDATE_LOT_STATUS_SUCCESS`,
  CHANGE_CONTROLS: `${PATH}CHANGE_CONTROLS`,
};
// actions
export const lotListActions = {
  getLotsList: createAction(LOTS_LIST.FETCH_LOTS_LIST, 'payload'),
  changePagination: createAction(LOTS_LIST.CHANGE_PAGINATION, 'payload'),
  resetPagination: createAction(LOTS_LIST.RESET_PAGINATION),
  changeModal: createAction(LOTS_LIST.CHANGE_MODAL, 'payload'),
  getLotStatus: createAction(LOTS_LIST.GET_LOT_STATUS),
  changeControls: createAction(LOTS_LIST.CHANGE_CONTROLS, 'payload'),
};

const initialState = {
  lotsList: [],
  loading: false,
  error: false,
  openModal: false,
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15, // para probar la vrsion mandante
  },
  filters: {
    dateFrom: formatDatePicker(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    dateTo: formatDatePicker(new Date()),
    auctionName: '',
    typeState: [],
  },
  totalPages: 0,
  statuses: [],
};

const lotsList = createReducer(initialState, {
  [LOTS_LIST.REQUEST_FAILED]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [LOTS_LIST.FETCH_LOTS_LIST]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [LOTS_LIST.FETCH_LOTS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    lotsList: action.payload.lots,
    totalPages: action.payload.totalPages,
    loading: false,
    error: false,
  }),
  [LOTS_LIST.FETCH_LOTS_LIST_ERROR]: (state) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [LOTS_LIST.CHANGE_PAGINATION](state, action) {
    state.pagination = {
      ...state.pagination,
      ...action.payload.pagination,
    };
  },
  [LOTS_LIST.RESET_PAGINATION](state) {
    state.pagination = initialState.pagination;
  },
  [LOTS_LIST.CHANGE_FILTERS](state, action) {
    state.filters[action.payload.name] = action.payload.value;
  },
  [LOTS_LIST.CHANGE_MODAL](state, action) {
    state.openModal = action.payload;
  },
  [LOTS_LIST.GET_MANDATE_LOT_STATUS]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [LOTS_LIST.GET_MANDATE_LOT_STATUS_SUCCESS](state, action) {
    state.statuses = action.payload;
    state.loading = false;
    state.error = false;
  },
  [LOTS_LIST.UPDATE_MANDATE_LOT_STATUS](state) {
    state.loading = true;
    state.error = false;
  },
  [LOTS_LIST.UPDATE_MANDATE_LOT_STATUS_SUCCESS](state, action) {
    (state.lotsList = [
      ...state.lotsList.map((lot) => (lot.id !== action.payload.id ? lot : action.payload)),
    ]),
      (state.loading = false);
    state.error = false;
    state.openModal = false;
  },
  [LOTS_LIST.CHANGE_CONTROLS](state, action) {
    if (action.payload.name === 'dateFrom' || action.payload.name === 'dateTo') {
      state.filters[action.payload.name] = new Date(action.payload.value)
        .toISOString()
        .split('T')[0];
    } else {
      state.filters[action.payload.name] = action.payload.value;
    }
  },
});

export default lotsList;
