import { newReducer } from 'utils/reducer/utils.reducer';
import { FormTwentyThreeTypes } from '../types/FormTwentyThree.types';

const initialState = {
  controls: {
    loading: true,
    folio: '',
    contributorRut: '',
    expireDate: null,
    vehicleType: '',
    brand: '',
    model: '',
    fabricationYear: '',
    uniquePatent: '',
    engineNumber: '',
    chasisNumber: '',
    communeLastPermission: '',
    permissionYear: '',
    siiCode: '',
    contractPrice: '',
    taxAppraisal: '',
    aucPaternalLastname: '',
    aucMaternalLastname: '',
    aucNames: '',
    aucAddress: '',
    aucPhone: '',
    aucCommune: '',
    aucRepertoire: '',
    aucCause: '',
    aucRut: '',
    sellerPaternalLastname: '',
    sellerMaternalLastname: '',
    sellerNames: '',
    sellerRut: '',
    sellerPhone: '',
    sellerCommune: '',
    sellerAddress: '',
    buyerPaternalLastname: '',
    buyerMaternalLastname: '',
    buyerNames: '',
    buyerRut: '',
    buyerPhone: '',
    buyerCommune: '',
    buyerAddress: '',
    municipalLaw: '',
    taxBase: '',
    rate: '',
    taxisDiscount: '',
    vehicleCode: '',
    totalTaxBase: '',
    ipc: '',
    interests: '',
    totalPay: '',
    validUntil: null,
    broadcastDate: null,
  },
  data: {
    communes: [],
    brands: [],
    models: [],
    vehicleTypes: [],
  },
};

const FormTwentyThreeReducer = newReducer(initialState, {
  [FormTwentyThreeTypes.CHANGE_CONTROLS]: (state, action) => ({
    ...state,
    controls: {
      ...state.controls,
      [action.payload.name]: action.payload.value,
    },
  }),
  [FormTwentyThreeTypes.RESET_FORM]: (state, action) => ({
    controls: {
      ...initialState.controls,
    },
    data: {
      ...initialState.data,
    },
  }),
  [FormTwentyThreeTypes.GET_STATE_FROM_API_SUCCESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      vehicleTypes: action.payload.data.vehicleTypes,
      models: action.payload.data.models,
      brands: action.payload.data.brands,
      communes: action.payload.data.communes,
    },
    controls: {
      ...state.controls,
      folio: action.payload.data.transferForm.folio,
      contributorRut: action.payload.data.transferForm.taxpayerRut,
      expireDate: action.payload.data.transferForm.expirationDate,
      vehicleType: action.payload.data.transferredVehicle.vehicleTypeId,
      brand: action.payload.data.brand,
      model: action.payload.data.transferredVehicle.modelId,
      fabricationYear: action.payload.data.transferredVehicle.year,
      uniquePatent: action.payload.data.transferredVehicle.patent,
      engineNumber: action.payload.data.transferredVehicle.engineNumber,
      chasisNumber: action.payload.data.transferredVehicle.chassisNumber,
      communeLastPermission: action.payload.data.transferredVehicle.circulatoryComuneId,
      permissionYear: action.payload.data.transferredVehicle.yearPermission,
      siiCode: action.payload.data.transferredVehicle.siiCode,
      contractPrice: action.payload.data.transferredVehicle.salePrice,
      taxAppraisal: action.payload.data.transferredVehicle.taxAppraisal,
      aucPaternalLastname: action.payload.data.auctioneer.firstLastName,
      aucMaternalLastname: action.payload.data.auctioneer.secondLastName,
      aucNames: action.payload.data.auctioneer.names,
      aucAddress: action.payload.data.auctioneer.streetNumber,
      aucPhone: action.payload.data.auctioneer.phone,
      aucCommune: action.payload.data.auctioneer.communeId,
      aucRepertoire: action.payload.data.auctioneer.repertoireNumber,
      aucCause: action.payload.data.auctioneer.causeDate,
      aucRut: action.payload.data.auctioneer.rut,
      sellerPaternalLastname: action.payload.data.transferOwner.businessName,
      sellerMaternalLastname: action.payload.data.transferOwner.secondLastName,
      sellerNames: action.payload.data.transferOwner.names,
      sellerRut: action.payload.data.transferOwner.rut,
      sellerPhone: action.payload.data.transferOwner.phone,
      sellerCommune: action.payload.data.transferOwner.communeId,
      sellerAddress: action.payload.data.transferOwner.address,
      buyerPaternalLastname: action.payload.data.transferBuyer.businessName,
      buyerMaternalLastname: action.payload.data.transferBuyer.secondLastName,
      buyerNames: action.payload.data.transferBuyer.names,
      buyerRut: action.payload.data.transferBuyer.rut,
      buyerPhone: action.payload.data.transferBuyer.phone,
      buyerCommune: action.payload.data.transferBuyer.communeId,
      buyerAddress: action.payload.data.transferBuyer.address,
      municipalLaw: action.payload.data.transferForm.municipalLaw,
      taxBase: action.payload.data.transferForm.taxPase,
      rate: action.payload.data.transferForm.rate,
      taxisDiscount: action.payload.data.transferForm.municipal_vehicle_law,
      vehicleCode: action.payload.data.transferForm.vehicleCode,
      totalTaxBase: action.payload.data.transferForm.totalMunicipalLaw,
      ipc: action.payload.data.transferForm.ipc,
      interests: action.payload.data.transferForm.penaltyInterest,
      totalPay: action.payload.data.transferForm.total_pay,
      validUntil: action.payload.data.transferForm.validUntil,
      broadcastDate: action.payload.data.transferForm.issueDate,
    },
  }),
});

export default FormTwentyThreeReducer;
