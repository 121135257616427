import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import apiRequest, { get, update, post, destroy } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { asQuery } from 'utils/functions';
import { VehicleTypesManagerTypes } from '../types/VehicleTypesManager.types';

function* initialQuery() {
  yield takeLatest(VehicleTypesManagerTypes.GET_STATE_FROM_API, function* (action) {
    const successFunction = (payload) => ({
      type: VehicleTypesManagerTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: VehicleTypesManagerTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/vehicle_types`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* createVehicleType() {
  yield takeLatest(VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE, function* (action) {
    const body = { ...action.payload.controls };
    const successFunction = (payload) => ({
      type: VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: VehicleTypesManagerTypes.CREATE_VEHICLE_TYPE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      `api/v1/vehicle_types`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* updateVehicleType() {
  yield takeLatest(VehicleTypesManagerTypes.UPDATE_VEHICLE_TYPE, function* (action) {
    const body = { ...action.payload.controls };
    const successFunction = (payload) => ({
      type: VehicleTypesManagerTypes.UPDATE_VEHICLE_TYPE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: VehicleTypesManagerTypes.UPDATE_VEHICLE_TYPE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      update,
      `api/v1/vehicle_types/${action.payload.id}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* disableVehicleType() {
  yield takeLatest(VehicleTypesManagerTypes.DELETE_VEHICLE_TYPE, function* (action) {
    const successFunction = (payload) => ({
      type: VehicleTypesManagerTypes.DELETE_VEHICLE_TYPE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: VehicleTypesManagerTypes.DELETE_VEHICLE_TYPE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      destroy,
      `/api/v1/vehicle_types/disable/${action.payload.id}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* VehicleTypesManagerSaga() {
  yield spawn(initialQuery);
  yield spawn(createVehicleType);
  yield spawn(updateVehicleType);
  yield spawn(disableVehicleType);
}
