import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export function PasswordField({ enableShowPassword = true, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <TextField
      {...rest}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: enableShowPassword ? (
          <InputAdornment position="end" onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
          </InputAdornment>
        ) : undefined,
        ...rest.InputProps,
      }}
    />
  );
}
