import Box from '@mui/material/Box';
import styled from '@emotion/styled';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  position: 'relative',
  borderRadius: '2%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
}));

export default StyledBox;
