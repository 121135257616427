import React from 'react'
import { Grid, Typography } from '@mui/material';

import pageNotFoundImage from 'utils/images/error404.svg';

import "./styles.css";

const PageNotFound = () => {
  return (
    <div className='pageNotFound'>
      <div className="pageNotFound__text">
        <Typography className='pageNotFound__title' variant="h1">Ooops... <br />No hay nada por aquí</Typography>
        <Typography className="pageNotFound__paragraph" variant='body1' paragraph>La página que estás buscando no existe.</Typography>
      </div>
      <div className="pageNotFound__img">
        <img src={pageNotFoundImage} alt="Imagen página no encontrada" />
      </div>
    </div>
  )
}

export default PageNotFound;
