const base = '[FINISHED_AUCTIONS]';

export const FinishedAuctionsTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_PAGINATION: `${base} CHANGE_PAGINAITON`,
  CLEAR_URL: `${base} CLEAR_URL`,
};

export default FinishedAuctionsTypes;
