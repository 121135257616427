import { createReducer, createAction } from 'utils/reducer/utils.reducer';

const PATH = 'featuredLots';

export const FEATURED_LOTS_MANAGER = {
  GET_STATE_FROM_API: `${PATH}/GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}/GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH}/GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${PATH}/CHANGE_CONTROLS`,
  TOGGLE_LOT_AS_FEATURED: `${PATH}/TOGGLE_LOT_AS_FEATURED`,
  TOGGLE_LOT_AS_FEATURED_SUCCESS: `${PATH}/TOGGLE_LOT_AS_FEATURED_SUCCESS`,
  TOGGLE_LOT_AS_FEATURED_FAIL: `${PATH}/TOGGLE_LOT_AS_FEATURED_FAIL`,
};

export const featuredLotsActions = {
  toggleLotAsFeatured: createAction(FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED, 'controls'),
  toggleLotAsFeaturedSuccess: createAction(
    FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED_SUCCESS,
    'payload',
  ),
  toggleLotAsFeaturedFail: createAction(
    FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED_FAIL,
    'controls',
  ),
};

const initialState = {
  controls: {
    id: '',
    categoryId: '-1',
    isFeatured: false,
    loading: false,
  },
  data: {
    lots: [],
    auctionCategories: [],
    lotCategories: [],
  },
};

const featuredLotsReducer = createReducer(initialState, {
  [FEATURED_LOTS_MANAGER.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [FEATURED_LOTS_MANAGER.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.data.lots = action.payload.objects.lots;
    state.data.auctionCategories = action.payload.objects.auctionCategories;
    state.data.lotCategories = action.payload.objects.lotCategories;
  },
  [FEATURED_LOTS_MANAGER.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [FEATURED_LOTS_MANAGER.CHANGE_CONTROLS](state, action) {
    state.controls[action.payload.name] = action.payload.value;
  },
  [FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED](state) {
    state.controls.loading = true;
  },
  [FEATURED_LOTS_MANAGER.TOGGLE_LOT_AS_FEATURED_SUCCESS](state) {
    state.controls.loading = false;
  },
});

export default featuredLotsReducer;
