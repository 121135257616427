const base = '[FORM_TWENTY_THREE]';

export const FormTwentyThreeTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  CREATE_FORM_TWENTYTHREE: `${base} CREATE_FORM_TWENTYTHREE`,
  CREATE_FORM_TWENTYTHREE_SUCCESS: `${base} CREATE_FORM_TWENTYTHREE_SUCCESS`,
  CREATE_FORM_TWENTYTHREE_FAIL: `${base} CREATE_FORM_TWENTYTHREE_FAIL`,
  RESET_FORM: `${base} RESET_FORM`,
};

export default FormTwentyThreeTypes;
