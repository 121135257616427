import camelize from 'camelcase-keys-deep';

export default class BrandModel {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  lots() {
    return this.env().Lot.findAllBy('brandModelId', this.id);
  }
}
