const base = '[TRANSFER_PENDING]';

export const PendingTransferencesTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  CLEAN_FILTERS: `${base} CLEAN_FILTERS`,
  GET_REPORT: `${base} GET_REPORT`,
  GET_REPORT_SUCCESS: `${base} GET_REPORT_SUCCESS`,
  GET_REPORT_FAIL: `${base} GET_REPORT_FAIL`,
  CHANGE_PAGINATION: `${base} CHANGE_PAGINATION`,
};

export default PendingTransferencesTypes;
