import { put, spawn, takeLatest } from 'redux-saga/effects';

import { destroy, get, post, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { AUCTION_CATEGORIES, auctionCategoriesActions } from '../reducer/auctionCategories.reducer';

function* initialQuery() {
  yield takeLatest(AUCTION_CATEGORIES.INITIAL_QUERY, function* () {
    yield controlledCall(
      get,
      'api/v1/auction_categories',
      {},
      auctionCategoriesActions.initialQuerySuccess,
    );
  });
}

function* createAuctionCategory() {
  yield takeLatest(AUCTION_CATEGORIES.CREATE_AUCTION_CATEGORY, function* (action) {
    yield put(appActions.setLoader('create-edit-auction-category', true));

    const body = action.controls;
    const failFunction = () => ({ type: AUCTION_CATEGORIES.VERIFY_DUPLICATED_CATEGORY_FAIL });
    const result = yield controlledCall(
      post,
      'api/v1/auction_categories',
      body,
      auctionCategoriesActions.createAuctionCategorySuccess,
      failFunction,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha creado la categoría exitosamente'));
      yield put(appActions.setLoader('create-edit-auction-category', false));
      return;
    }
    yield put(appActions.setError('La categoría ya se encuentra registrada'));
    yield put(appActions.setLoader('create-edit-auction-category', false));
  });
}

function* editAuctionCategory() {
  yield takeLatest(AUCTION_CATEGORIES.EDIT_AUCTION_CATEGORY, function* (action) {
    yield put(appActions.setLoader('create-edit-auction-category', true));

    const body = action.controls;
    const result = yield controlledCall(
      update,
      `api/v1/auction_categories/${action.controls.id}`,
      body,
      auctionCategoriesActions.editAuctionCategorySuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha editado la categoría exitosamente'));
    }

    yield put(appActions.setLoader('create-edit-auction-category', false));
  });
}

function* deleteAuctionCategory() {
  yield takeLatest(AUCTION_CATEGORIES.DELETE_AUCTION_CATEGORY, function* (action) {
    yield put(appActions.setLoader('delete-auction-category', true));
    const result = yield controlledCall(
      destroy,
      `api/v1/auction_categories/${action.controls.auctionCategory.id}`,
      null,
      auctionCategoriesActions.deleteAuctionCategorySuccess,
    );

    if (!result?.error) {
      if (result.deleted === true) {
        yield put(appActions.setSuccess('Se ha eliminado la categoría exitosamente'));
      }
    }

    yield put(appActions.setLoader('delete-auction-category', false));
  });
}

export default function* auctionCategoriesSaga() {
  yield spawn(initialQuery);
  yield spawn(createAuctionCategory);
  yield spawn(editAuctionCategory);
  yield spawn(deleteAuctionCategory);
}
