import { createAction, createReducer } from 'utils/reducer/utils.reducer';
import { HIDE_BULK_CLONE_MODAL } from 'modules/main/Auctions/reducer/auctions.reducer';
import { HIDE_BULK_REFUND_MODAL } from 'modules/main/Guarantees/reducer/guarantees.reducer';

const PATH = 'templateImportModal/';

export const TEMPLATE_IMPORT_MODAL = {
  // Local actions
  LOCAL_CHANGED: `${PATH}LOCAL_CHANGED`,
  // API actions
  DOWNLOAD_TEMPLATE: `${PATH}DOWNLOAD_TEMPLATE`,
  DOWNLOAD_TEMPLATE_SUCCESS: `${PATH}DOWNLOAD_TEMPLATE_SUCCESS`,
  DOWNLOAD_TEMPLATE_FAIL: `${PATH}DOWNLOAD_TEMPLATE_FAIL`,
  IMPORT_TEMPLATE: `${PATH}IMPORT_TEMPLATE`,
  IMPORT_TEMPLATE_SUCCESS: `${PATH}IMPORT_TEMPLATE_SUCCESS`,
  IMPORT_TEMPLATE_FAIL: `${PATH}IMPORT_TEMPLATE_FAIL`,
};

export const templateImportModalActions = {
  // Local
  localChanged: createAction(TEMPLATE_IMPORT_MODAL.LOCAL_CHANGED, 'local'),
  // API
  downloadTemplate: createAction(TEMPLATE_IMPORT_MODAL.DOWNLOAD_TEMPLATE, 'url', 'params'),
  downloadTemplateSuccess: createAction(TEMPLATE_IMPORT_MODAL.DOWNLOAD_TEMPLATE_SUCCESS, 'payload'),
  downloadTemplateFail: createAction(TEMPLATE_IMPORT_MODAL.DOWNLOAD_TEMPLATE_FAIL, 'payload'),
  importTemplate: createAction(TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE, 'url', 'file', 'params'),
  importTemplateSuccess: createAction(TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE_SUCCESS, 'payload'),
  importTemplateFail: createAction(TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE_FAIL, 'payload'),
};

const initialState = {
  local: {
    file: null,
    showSuccessMessage: false,
    loading: false,
  },
};

const templateImportModal = createReducer(initialState, {
  [TEMPLATE_IMPORT_MODAL.LOCAL_CHANGED](state, action) {
    state.local = { ...state.local, ...action.local };
  },
  [TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE](state) {
    state.local.loading = true;
  },
  [TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE_FAIL](state) {
    state.local.loading = false;
  },
  [TEMPLATE_IMPORT_MODAL.IMPORT_TEMPLATE_SUCCESS](state) {
    state.local.showSuccessMessage = true;
    state.local.loading = false;
  },
  [HIDE_BULK_CLONE_MODAL](state) {
    state.local.file = null;
    state.local.showSuccessMessage = false;
  },
  [HIDE_BULK_REFUND_MODAL](state) {
    state.local.file = null;
    state.local.showSuccessMessage = false;
  },
});

export default templateImportModal;
