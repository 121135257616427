import camelize from 'camelcase-keys-deep';

export default class DetailLine {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  lot() {
    return this.env().LotCategory.getById(this.lotId);
  }
}
