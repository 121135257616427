import SystemActions from 'utils/roles/SystemActions';

export default class FinancialRole extends SystemActions {
  constructor(payload) {
    super();
    this.payload = payload;
    this.home.goToLive = true;

    this.profile.view = true;
    this.profile.update = true;
    this.profile.updatePassword = true;

    this.user.view = true;
    this.user.actions = this.validateUserActionArea();

    this.userEdition.view = true;
    this.userEdition.update = true;

    this.guarantee.view = true;
    this.guarantee.update = true;
    this.guarantee.report = true;
    this.guarantee.massiveRefund = true;

    this.guaranteeAndPayment.view = true;
    this.guaranteeAndPayment.update = true;
    this.guaranteeAndPayment.report = true;
    this.guaranteeAndPayment.massiveRefund = true;
    this.guaranteeAndPayment.addPayment = true;

    this.payment.view = true;
    this.payment.update = true;

    this.integration.view = true;
    this.integration.guarantees = true;
    this.integration.users = true;

    this.guaranteeManager.view = true;
    this.newPayment.view = true;

    this.mandatesLogin.view = true;
  }

  validateArea() {
    return this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString();
  }

  validateUserActionArea() {
    return (
      this.payload?.areaId?.toString() === '' ||
      !this.payload?.areaId ||
      this.payload?.user?.areaId?.toString() === this.payload?.areaId?.toString()
    );
  }
}
