import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, { post, postForm, apiSuccess } from 'utils/api/api';

import { falsy, formatSATData } from 'utils/functions';
import { history } from 'utils/history';
import { appActions } from 'commons/reducer/commons.reducer';
import controlledCall from 'utils/services/controlledSaga';
import { LOGIN } from '../reducer/login.reducer';

function* login() {
  yield takeLatest(LOGIN.LOGIN_USER, function* (action) {
    yield put(appActions.setLoader('login', true));
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('login', { method: 'post', body });

    if (falsy(response.error)) {
      yield put(apiSuccess(LOGIN.LOGIN_USER_SUCCESS, response));
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      if (!action.isRol) {
        history.replace(action.redirectUrl || '/');
      }
    } else {
      yield put(apiSuccess(LOGIN.LOGIN_USER_FAIL, response));
    }
    yield put(appActions.setLoader('login', false));
  });
}
function* verify() {
  yield takeLatest(LOGIN.VERIFY, function* (action) {
    yield put(appActions.setLoader('login', true));
    const body = JSON.stringify({ token: action.token });
    const response = yield apiRequest('api/v1/users/verify', { method: 'post', body });
    if (falsy(response.error)) {
      yield put(apiSuccess(LOGIN.VERIFY_SUCCESS, response));
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(apiSuccess(LOGIN.VERIFY_FAIL, response));
    }
    yield put(appActions.setLoader('login', false));
  });
}

function* verifyCaptcha() {
  yield takeLatest(LOGIN.VERIFY_CAPTCHA, function* (action) {
    const successFunction = (payload) => ({
      type: LOGIN.VERIFY_CAPTCHA_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: LOGIN.VERIFY_CAPTCHA_FAIL,
      payload,
    });

    const body = { ...action.payload.data };

    const result = yield controlledCall(
      post,
      `api/v1/users/recaptcha_validation`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) {
      console.log('result.error', result.error);
    }
  });
}

export default function* loginSaga() {
  yield spawn(login);
  yield spawn(verify);
  yield spawn(verifyCaptcha);
}
