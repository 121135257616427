import camelize from 'camelcase-keys-deep';

export default class Mandate {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  seller() {
    return this.env().Seller.getById(this.newSellerId);
  }

  entity() {
    return this.env().Entity.getById(this.sellerId);
  }

  auction() {
    return this.env().Auction.getById(this.auctionId);
  }

  lots() {
    // return this.env().Lot.findAllBy('mandateId', this.id);
    return this.allBases().reduce((acum, base) => {
      acum = [...acum, ...base.lots()];
      return acum;
    }, []);
  }

  document() {
    return this.env().Document.getById(this.documentId);
  }

  allBases() {
    return this.env().MandateBase.findAllBy('mandateId', this.id);
  }
}
