import camelize from 'camelcase-keys-deep';

export default class Receipt {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  payment() {
    return this.env().Payment.getById(this.paymentId);
  }

  document() {
    return this.env().Document.getById(this.documentId);
  }
}
