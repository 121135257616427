import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { getDocument, get, post } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { MandatePreviewTypes } from '../types/MandatePreview.types';

function* initialQuery() {
  yield takeLatest(MandatePreviewTypes.GET_STATE_FROM_API, function* (action) {
    const successFunction = (payload) => ({
      type: MandatePreviewTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: MandatePreviewTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/mandate/${action.payload.transferId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

function* createTransferMandate() {
  yield takeLatest(MandatePreviewTypes.CREATE_MANDATE, function* (action) {
    const body = { ...action.payload.data };
    delete body.loading;
    delete body.openEditForm;
    const successFunction = (payload) => ({
      type: MandatePreviewTypes.CREATE_MANDATE_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: MandatePreviewTypes.CREATE_MANDATE_FAIL,
      payload,
    });

    const result = yield controlledCall(
      post,
      `api/v1/transfers/mandate/${action.payload.transferId}`,
      body,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
  });
}

function* exportPdf() {
  yield takeLatest(MandatePreviewTypes.EXPORT_PDF, function* (action) {
    const successFunction = (payload) => ({
      type: MandatePreviewTypes.EXPORT_PDF_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: MandatePreviewTypes.EXPORT_PDF_FAIL,
      payload,
    });

    const result = yield controlledCall(
      getDocument('Mandato', 'pdf', true),
      `api/v1/transfers/mandate/document/${action.payload.transferId}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
  });
}

export default function* MandatePreviewSaga() {
  yield spawn(initialQuery);
  yield spawn(createTransferMandate);
  yield spawn(exportPdf);
}
