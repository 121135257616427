import { call, put } from 'redux-saga/effects';
import { appActions } from 'commons/reducer/commons.reducer';
import { truthty } from 'utils/functions';

/**
 * @param toYield
 * @param path
 * @param body
 * @param succesFunction
 * @param failFunction
 * @param isOpenFile
 * @param withoutFormat
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<*>>|SimpleEffect<"CALL",
 *   CallEffectDescriptor<RT | RT | RT>>|*, *, *>}
 */
export default function* controlledCall(
  toYield,
  path,
  // eslint-disable-next-line default-param-last
  body = undefined,
  succesFunction,
  failFunction = appActions.setError,
  isOpenFile = false,
  withoutFormat = false,
) {
  const result = yield call(toYield, path, body, withoutFormat);
  if (isOpenFile) {
    if (!result.ok) {
      let data = null;
      // eslint-disable-next-line
      yield result.json().then((body) => (data = body));
      yield put(failFunction(data));
    }
  } else {
    const nextAction = truthty(result?.error) ? failFunction : succesFunction;
    if (result?.error) {
      try {
        const message = JSON.parse(result.errorMsg);
        if (message?.errors) {
          yield put(nextAction(message.errors[0].msg));
        } else {
          yield put(nextAction(result.errorMsg));
        }
      } catch (e) {
        yield put(nextAction(result.errorMsg));
      }
    } else {
      yield put(nextAction(result));
    }
  }
  return result;
}
