const PATH = '[CURRENCY_MANAGER]';

export const CurrencyManagerActions = {
  GET_STATE_FROM_API: `${PATH} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${PATH} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${PATH} CHANGE_CONTROLS`,
  TOGGLE_EDIT_FORM: `${PATH} TOGGLE_EDIT_FORM`,
  TOGGLE_FORM: `${PATH} TOGGLE_FORM`,
  CREATE_CURRENCY: `${PATH} CREATE_CURRENCY`,
  CREATE_CURRENCY_SUCCESS: `${PATH} CREATE_CURRENCY_SUCCESS`,
  CREATE_CURRENCY_FAIL: `${PATH} CREATE_CURRENCY_FAIL`,
  EDIT_CURRENCY: `${PATH} EDIT_CURRENCY`,
  EDIT_CURRENCY_SUCCESS: `${PATH} EDIT_CURRENCY_SUCCESS`,
  EDIT_CURRENCY_FAIL: `${PATH} EDIT_CURRENCY_FAIL`,
};
