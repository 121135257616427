const base = '[SYSTEM_CHANGES]';

export const SystemChangesTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_FILTERS: `${base} CHANGE_FILTERS`,
  CHANGE_PAGINATION: `${base} CHANGE_PAGINAITON`,
  TOGGLE_SYSTEM_CHANGES_DETAILS_DIALOG: `${base} TOGGLE_SYSTEM_CHANGES_DETAILS_DIALOG`,
};
