import React from 'react';

function SvgArrow(props) {
  return (
    <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.41 25.29" {...props}>
      <path
        fill="#002F77"
        d="M57.14 10.97H0v3.16h57.14l-8.76 8.8 2.35 2.36 12.68-12.73L50.89 0l-2.27 2.28 8.52 8.69z"
      />
    </svg>
  );
}

export default SvgArrow;
