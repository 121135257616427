import { put, spawn, takeLatest } from 'redux-saga/effects';

import apiRequest, { apiSuccess } from 'utils/api/api';
import { falsy } from 'utils/functions';
import { history } from 'utils/history';
import { appActions } from 'commons/reducer/commons.reducer';
import { TransfersLoginTypes } from '../types/transfersLogin.types';

function* login() {
  yield takeLatest(TransfersLoginTypes.LOGIN_USER, function* (action) {
    yield put(appActions.setLoader('login', true));
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('login', { method: 'post', body });

    if (falsy(response.error)) {
      yield put(apiSuccess(TransfersLoginTypes.LOGIN_USER_SUCCESS, response));
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      const { user } = response;

      if (user.roleCode === 'TRA' || user.roleCode === 'SUA' || user.roleCode === 'CLI') {
        history.replace(action.redirectUrl || '/transfers/lots');
      } else {
        history.replace(action.redirectUrl || '/');
      }
    } else {
      yield put(apiSuccess(TransfersLoginTypes.LOGIN_USER_FAIL, response));
    }
    yield put(appActions.setLoader('login', false));
  });
}
function* verify() {
  yield takeLatest(TransfersLoginTypes.VERIFY, function* (action) {
    yield put(appActions.setLoader('login', true));
    const body = JSON.stringify({ token: action.token });
    const response = yield apiRequest('api/v1/users/verify', { method: 'post', body });
    if (falsy(response.error)) {
      yield put(apiSuccess(TransfersLoginTypes.VERIFY_SUCCESS, response));
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(apiSuccess(TransfersLoginTypes.VERIFY_FAIL, response));
    }
    yield put(appActions.setLoader('login', false));
  });
}

function* verifyCaptcha() {
  yield takeLatest(TransfersLoginTypes.VERIFY_CAPTCHA, function* (action) {
    const { secretKey, token } = action.payload.data;

    const body = JSON.stringify({ secretKey, token });

    const response = yield apiRequest('api/v1/users/recaptcha_validation', {
      method: 'post',
      body,
    });

    if (falsy(response.error)) {
      yield put(apiSuccess(TransfersLoginTypes.VERIFY_CAPTCHA_SUCCESS, response));
    } else {
      yield put(apiSuccess(TransfersLoginTypes.VERIFY_CAPTCHA_FAIL, response));
    }
  });
}

export default function* TransfersLoginSaga() {
  yield spawn(login);
  yield spawn(verify);
  yield spawn(verifyCaptcha);
}
