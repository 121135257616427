import { put, spawn, takeLatest } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get } from 'utils/api/api';
import { SET_ERROR } from 'commons/reducer/commons.reducer';
import { FinishedAuctionsTypes } from '../types/FinishedAuctions.types';

function* getStateFromApi() {
  yield takeLatest(FinishedAuctionsTypes.GET_STATE_FROM_API, function* (action) {
    const body = {
      ...action.payload.filters,
      ...action.payload.pagination,
    };

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: FinishedAuctionsTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: FinishedAuctionsTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/finished_auctions/${body.areaID}?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) yield put({ type: SET_ERROR, msg: result.errorMsg });
  });
}

export default function* FinishedAuctionsSaga() {
  yield spawn(getStateFromApi);
}
