import camelize from 'camelcase-keys-deep';

export default class Polygon {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  location() {
    return this.env().Location.findBy('id', this.locationId);
  }
}
