// natural

export function sendForm(bodyData) {
  const {
    typePerson,
    rut,
    name,
    lastName,
    company,
    email,
    phone,
    message,
    typeBusiness,
    typeInterest,
    typeChannel,
  } = bodyData;
  // NATURAL

  if (typePerson === '1') {
    const meta = document.createElement('meta');
    meta.setAttribute('http-equiv', 'Content-type');
    meta.setAttribute('content', 'text/html; charset=UTF-8');

    const form = document.createElement('Form');
    form.setAttribute(
      'action',
      process.env.REACT_APP_SALESFORCE_FORM_LINK, // https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D8b000002AMQI
    );

    form.setAttribute('method', 'POST');
    const body = document.createElement('body');

    const inputHiddenOid = document.createElement('input');
    inputHiddenOid.type = 'hidden';
    inputHiddenOid.name = 'oid';
    // inputHiddenOid.value = '00D8F0000008hcK';  // 00D8b000002AMQI  PROD
    inputHiddenOid.value = process.env.REACT_APP_FORM_SALESFORCE_OID; // 00D8b000002AMQI  PROD

    form.appendChild(inputHiddenOid);

    const inputHiddenUrl = document.createElement('input');
    inputHiddenUrl.type = 'hidden';
    inputHiddenUrl.name = 'retURL';
    inputHiddenUrl.value = `${process.env.REACT_APP_URL_HOME}/?action=thanksyou`;
    // inputHiddenUrl.value = 'https://www.tattersallgda.cl';

    form.appendChild(inputHiddenUrl);

    // RUT

    const labelRut = document.createElement('label');
    labelRut.textContent = 'Rut';
    form.appendChild(labelRut);

    const inputRut = document.createElement('input');
    inputRut.type = 'text';
    inputRut.id = '00N8b00000HJoiu';
    inputRut.name = '00N8b00000HJoiu';
    inputRut.value = rut;

    form.appendChild(inputRut);

    form.appendChild(document.createElement('br'));

    // NOMBRE

    const labelName = document.createElement('label');
    labelName.setAttribute('for', 'first_name');
    labelName.textContent = 'Nombre';
    form.appendChild(labelName);

    const inputName = document.createElement('input');
    inputName.type = 'text';
    inputName.id = 'first_name';
    inputName.name = 'first_name';
    inputName.value = name;

    form.appendChild(inputName);

    form.appendChild(document.createElement('br'));

    // Apellido

    const labelLastName = document.createElement('label');
    labelLastName.setAttribute('for', 'last_name');
    labelLastName.textContent = 'Apellidos';
    form.appendChild(labelLastName);

    const inputLastName = document.createElement('input');
    inputLastName.type = 'text';
    inputLastName.id = 'last_name';
    inputLastName.name = 'last_name';
    inputLastName.value = lastName;

    form.appendChild(inputLastName);

    form.appendChild(document.createElement('br'));

    // MOVIL

    const labelMovil = document.createElement('label');
    labelMovil.setAttribute('for', 'mobile');
    labelMovil.textContent = 'Movil';
    form.appendChild(labelMovil);

    const inputMovil = document.createElement('input');
    inputMovil.type = 'text';
    inputMovil.id = 'mobile';
    inputMovil.name = 'mobile';
    inputMovil.value = phone;

    form.appendChild(inputMovil);

    form.appendChild(document.createElement('br'));

    // CorreoElectronico

    const labelEmail = document.createElement('label');
    labelMovil.setAttribute('for', 'Email');

    labelEmail.textContent = 'Email';
    form.appendChild(labelEmail);

    const inputEmail = document.createElement('input');
    inputEmail.type = 'text';
    inputEmail.id = 'email';
    inputEmail.name = 'email';
    inputEmail.value = email;

    form.appendChild(inputEmail);

    const tipoNegocioLabel = document.createElement('label');
    tipoNegocioLabel.setAttribute('for', '00N8b00000HJoj0');
    tipoNegocioLabel.textContent = 'Tipo de Negocio';

    const tipoNegocioSelect = document.createElement('select');
    tipoNegocioSelect.setAttribute('id', '00N8b00000HJoj0');
    tipoNegocioSelect.setAttribute('name', '00N8b00000HJoj0');
    tipoNegocioSelect.setAttribute('title', 'Tipo de Negocio');

    form.appendChild(document.createElement('br'));

    const tipoNegocioOptions = document.createElement('option');
    if (!typeBusiness) {
      tipoNegocioOptions.setAttribute('value', 'Mandante');
    } else {
      tipoNegocioOptions.setAttribute('value', 'Oferente');
    }

    tipoNegocioSelect.appendChild(tipoNegocioOptions);

    form.appendChild(tipoNegocioLabel);

    form.appendChild(tipoNegocioSelect);

    form.appendChild(document.createElement('br'));

    const interesLabel = document.createElement('label');
    interesLabel.setAttribute('for', process.env.REACT_APP_FORM_SALESFORCE_INTEREST);
    interesLabel.textContent = 'Interés';

    const interesSelect = document.createElement('select');
    interesSelect.setAttribute('id', process.env.REACT_APP_FORM_SALESFORCE_INTEREST); // 00N8b00000HLcvY prod
    interesSelect.setAttribute('name', process.env.REACT_APP_FORM_SALESFORCE_INTEREST); // 00N8b00000HLcvY
    interesSelect.setAttribute('title', 'Interés');

    const interesOptions = document.createElement('option');

    if (!typeInterest) {
      interesOptions.setAttribute('value', 'Propiedades Urbanas');
    } else if (typeInterest === '1') {
      interesOptions.setAttribute('value', 'Propiedades Rurales');
    } else if (typeInterest === '2') {
      interesOptions.setAttribute('value', 'Arriendo de Propiedades');
    } else if (typeInterest === '3') {
      interesOptions.setAttribute('value', 'Administración de Propiedades');
    } else {
      interesOptions.setAttribute('value', 'Tasación');
    }

    interesSelect.appendChild(interesOptions);

    form.appendChild(interesLabel);
    form.appendChild(interesSelect);

    form.appendChild(document.createElement('br'));

    const allChannel = typeChannel;

    allChannel.forEach((channel) => {
      const inputChannel = document.createElement('input');

      inputChannel.id = '00N8b00000HJoiv';
      inputChannel.name = '00N8b00000HJoiv';
      inputChannel.value = channel;

      form.appendChild(inputChannel);
    });

    const inputHiddenLeadSource = document.createElement('input');
    inputHiddenLeadSource.type = 'hidden';
    inputHiddenLeadSource.name = 'lead_source';
    inputHiddenLeadSource.value = 'Website';

    form.appendChild(inputHiddenLeadSource);

    form.appendChild(document.createElement('br'));

    // mensaje

    const labelMessage = document.createElement('label');
    labelMessage.textContent = 'Mensaje';
    form.appendChild(labelMessage);

    const inputMessage = document.createElement('textarea');
    inputMessage.rows = 5;
    inputMessage.id = '00N8b00000HJsYG';
    inputMessage.name = '00N8b00000HJsYG';
    inputMessage.value = message;
    form.appendChild(inputMessage);

    const inputHiddenRecordType = document.createElement('input');
    inputHiddenRecordType.type = 'hidden';
    inputHiddenRecordType.name = 'recordType';
    inputHiddenRecordType.value = process.env.REACT_APP_FORM_SALESFORCE_RECORDTYPE_NATURAL; // 0128b000000dZTN

    form.appendChild(inputHiddenRecordType);

    // const inputHiddenDebug = document.createElement('input');
    // inputHiddenDebug.type = 'hidden';
    // inputHiddenDebug.name = 'debug';
    // inputHiddenDebug.value = '1';
    // form.appendChild(inputHiddenDebug);

    const inputHiddenDebugEmail = document.createElement('input');
    inputHiddenDebugEmail.type = 'hidden';
    inputHiddenDebugEmail.name = 'debugEmail';
    inputHiddenDebugEmail.value = 'fcatril@tattersall.cl';
    form.appendChild(inputHiddenDebugEmail);

    form.appendChild(document.createElement('br'));
    // Crear el botón de envío
    const submitButton = document.createElement('input');
    submitButton.setAttribute('type', 'submit');
    submitButton.setAttribute('name', 'submitBtn');

    // Agregar el formulario al cuerpo del documento
    form.appendChild(submitButton);

    document.body.appendChild(form);
    form.submit();

    // HTMLFormElement.prototype.submit.call(form)
  } else {
    // JURIDICA

    const meta = document.createElement('meta');
    meta.setAttribute('http-equiv', 'Content-type');
    meta.setAttribute('content', 'text/html; charset=UTF-8');

    const form = document.createElement('Form');
    form.setAttribute(
      'action',
      process.env.REACT_APP_SALESFORCE_FORM_LINK, // https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D8b000002AMQI
    );

    form.setAttribute('method', 'POST');
    // Crear el elemento <body>
    const body = document.createElement('body');

    const inputHiddenOid = document.createElement('input');
    inputHiddenOid.type = 'hidden';
    inputHiddenOid.name = 'oid';
    // inputHiddenOid.value = '00D8F0000008hcK'; //00D8b000002AMQI
    inputHiddenOid.value = process.env.REACT_APP_FORM_SALESFORCE_OID; // 00D8b000002AMQI

    form.appendChild(inputHiddenOid);

    const inputHiddenUrl = document.createElement('input');
    inputHiddenUrl.type = 'hidden';
    inputHiddenUrl.name = 'retURL';
    inputHiddenUrl.value = process.env.REACT_APP_URL_HOME;

    form.appendChild(inputHiddenUrl);

    // RUT

    const labelRut = document.createElement('label');
    labelRut.textContent = 'Rut';
    form.appendChild(labelRut);

    const inputRut = document.createElement('input');
    inputRut.type = 'text';
    inputRut.id = '00N8b00000HJoiu';
    inputRut.name = '00N8b00000HJoiu';
    inputRut.value = rut;

    form.appendChild(inputRut);

    form.appendChild(document.createElement('br'));

    // COMPAÑIA

    const labelCompany = document.createElement('label');
    labelCompany.textContent = 'Compañia';
    form.appendChild(labelCompany);

    const inputCompany = document.createElement('input');
    inputCompany.type = 'text';
    inputCompany.id = 'company';
    inputCompany.name = 'company';
    inputCompany.value = company;

    form.appendChild(inputCompany);

    form.appendChild(document.createElement('br'));

    // NOMBRE

    const labelName = document.createElement('label');
    labelName.setAttribute('for', 'first_name');
    labelName.textContent = 'Nombre';
    form.appendChild(labelName);

    const inputName = document.createElement('input');
    inputName.type = 'text';
    inputName.id = 'first_name';
    inputName.name = 'first_name';
    inputName.value = name;

    form.appendChild(inputName);

    form.appendChild(document.createElement('br'));

    // Apellido

    const labelLastName = document.createElement('label');
    labelLastName.setAttribute('for', 'last_name');
    labelLastName.textContent = 'Apellidos';
    form.appendChild(labelLastName);

    const inputLastName = document.createElement('input');
    inputLastName.type = 'text';
    inputLastName.id = 'last_name';
    inputLastName.name = 'last_name';
    inputLastName.value = lastName;

    form.appendChild(inputLastName);

    form.appendChild(document.createElement('br'));

    // MOVIL

    const labelMovil = document.createElement('label');
    labelMovil.setAttribute('for', 'mobile');
    labelMovil.textContent = 'Movil';
    form.appendChild(labelMovil);

    const inputMovil = document.createElement('input');
    inputMovil.type = 'text';
    inputMovil.id = 'mobile';
    inputMovil.name = 'mobile';
    inputMovil.value = phone;

    form.appendChild(inputMovil);

    form.appendChild(document.createElement('br'));

    // CorreoElectronico

    const labelEmail = document.createElement('label');
    labelMovil.setAttribute('for', 'Email');

    labelEmail.textContent = 'Email';
    form.appendChild(labelEmail);

    const inputEmail = document.createElement('input');
    inputEmail.type = 'text';
    inputEmail.id = 'email';
    inputEmail.name = 'email';
    inputEmail.value = email;

    form.appendChild(inputEmail);

    const tipoNegocioLabel = document.createElement('label');
    tipoNegocioLabel.setAttribute('for', '00N8b00000HJoj0');
    tipoNegocioLabel.textContent = 'Tipo de Negocio';

    const tipoNegocioSelect = document.createElement('select');
    tipoNegocioSelect.setAttribute('id', '00N8b00000HJoj0');
    tipoNegocioSelect.setAttribute('name', '00N8b00000HJoj0');
    tipoNegocioSelect.setAttribute('title', 'Tipo de Negocio');

    form.appendChild(document.createElement('br'));

    const tipoNegocioOptions = document.createElement('option');
    if (!typeBusiness) {
      tipoNegocioOptions.setAttribute('value', 'Mandante');
    } else {
      tipoNegocioOptions.setAttribute('value', 'Oferente');
    }

    tipoNegocioSelect.appendChild(tipoNegocioOptions);
    // });

    form.appendChild(tipoNegocioLabel);

    form.appendChild(tipoNegocioSelect);

    form.appendChild(document.createElement('br'));

    const interesLabel = document.createElement('label');
    interesLabel.setAttribute('for', process.env.REACT_APP_FORM_SALESFORCE_INTEREST); // 00N8b00000HLcvY
    interesLabel.textContent = 'Interés';

    const interesSelect = document.createElement('select');
    interesSelect.setAttribute('id', process.env.REACT_APP_FORM_SALESFORCE_INTEREST); // 00N8b00000HLcvY
    interesSelect.setAttribute('name', process.env.REACT_APP_FORM_SALESFORCE_INTEREST); // 00N8b00000HLcvY
    interesSelect.setAttribute('title', 'Interés');

    const interesOptions = document.createElement('option');

    if (!typeInterest) {
      interesOptions.setAttribute('value', 'Propiedades Urbanas');
    } else if (typeInterest === '1') {
      interesOptions.setAttribute('value', 'Propiedades Rurales');
    } else if (typeInterest === '2') {
      interesOptions.setAttribute('value', 'Arriendo de Propiedades');
    } else if (typeInterest === '3') {
      interesOptions.setAttribute('value', 'Administración de Propiedades');
    } else {
      interesOptions.setAttribute('value', 'Tasación');
    }

    interesSelect.appendChild(interesOptions);
    // });

    form.appendChild(interesLabel);
    form.appendChild(interesSelect);

    form.appendChild(document.createElement('br'));

    const canalPreferenciaLabel = document.createElement('label');
    canalPreferenciaLabel.setAttribute('for', '00N8b00000HJoiv');
    canalPreferenciaLabel.textContent = 'Canal de preferencia';

    const allChannel = typeChannel;

    allChannel.forEach((channel) => {
      const inputChannel = document.createElement('input');

      inputChannel.id = '00N8b00000HJoiv';
      inputChannel.name = '00N8b00000HJoiv';
      inputChannel.value = channel;

      form.appendChild(inputChannel);
    });

    const inputHiddenLeadSource = document.createElement('input');
    inputHiddenLeadSource.type = 'hidden';
    inputHiddenLeadSource.name = 'lead_source';
    inputHiddenLeadSource.value = 'Website';

    form.appendChild(inputHiddenLeadSource);

    form.appendChild(document.createElement('br'));

    // mensaje

    const labelMessage = document.createElement('label');
    labelMessage.textContent = 'Mensaje';
    form.appendChild(labelMessage);

    const inputMessage = document.createElement('textarea');
    inputMessage.rows = 5;
    inputMessage.id = '00N8b00000HJsYG';
    inputMessage.name = '00N8b00000HJsYG';
    inputMessage.value = message;
    form.appendChild(inputMessage);

    const inputHiddenRecordType = document.createElement('input');
    inputHiddenRecordType.type = 'hidden';
    inputHiddenRecordType.name = 'recordType';
    inputHiddenRecordType.value = process.env.REACT_APP_FORM_SALESFORCE_RECORDTYPE_JURIDICO; // 0128b000000dZTM

    form.appendChild(inputHiddenRecordType);

    const inputHiddenDebugEmail = document.createElement('input');
    inputHiddenDebugEmail.type = 'hidden';
    inputHiddenDebugEmail.name = 'debugEmail';
    inputHiddenDebugEmail.value = 'fcatril@tattersall.cl';
    form.appendChild(inputHiddenDebugEmail);

    form.appendChild(document.createElement('br'));
    // Crear el botón de envío
    const submitButton = document.createElement('input');
    submitButton.setAttribute('type', 'submit');
    submitButton.setAttribute('name', 'submitBtn');

    // Agregar el formulario al cuerpo del documento
    form.appendChild(submitButton);

    document.body.appendChild(form);
    form.submit();

    // HTMLFormElement.prototype.submit.call(form)
  }
}
