import { put, spawn, takeLatest } from 'redux-saga/effects';

import controlledCall from 'utils/services/controlledSaga';
import { get, update } from 'utils/api/api';
import { appActions } from 'commons/reducer/commons.reducer';
import { CONTACT_MESSAGES, contactMessagesActions } from '../reducer/contactMessages.reducer';

function* initialQuery() {
  yield takeLatest(CONTACT_MESSAGES.INITIAL_QUERY, function* (action) {
    yield put(appActions.setLoader('filter-messages', true));
    const { areaId, nameSearch, assignedSearch } = action.filters;
    let filters = {
      ...action.filters,
      areaId: areaId === '-1' ? undefined : areaId,
      nameSearch: nameSearch.trim(),
      assignedSearch: assignedSearch.trim(),
    };
    filters = Object.keys(filters).reduce((result, filter) => {
      if (filters[filter] == null) {
        return result;
      }
      result[filter] = filters[filter];
      return result;
    }, {});
    yield controlledCall(
      get,
      `api/v1/contact_messages?${new URLSearchParams(filters)}`,
      undefined,
      contactMessagesActions.initialQuerySuccess,
    );
    yield put(appActions.setLoader('filter-messages', false));
  });
}

function* assignUser() {
  yield takeLatest(CONTACT_MESSAGES.ASSIGN_USER, function* (action) {
    yield put(appActions.setLoader('filter-messages', true));
    const result = yield controlledCall(
      update,
      `api/v1/contact_messages/${action.messageId}/assign`,
      undefined,
      contactMessagesActions.assignUserSuccess,
    );

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha asignado como encargado exitosamente'));
    }
    yield put(appActions.setLoader('filter-messages', false));
  });
}

export default function* contactMessagesSaga() {
  yield spawn(initialQuery);
  yield spawn(assignUser);
}
