const base = '[RECORD_CERTIFICATE_FORM]';

export const RecordCertificateFormTypes = {
  GET_STATE_FROM_API: `${base} GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${base} GET_STATE_FROM_API_SUCCESS`,
  GET_STATE_FROM_API_FAIL: `${base} GET_STATE_FROM_API_FAIL`,
  CHANGE_CONTROLS: `${base} CHANGE_CONTROLS`,
  CREATE_CERTIFICATE: `${base} CREATE_CERTIFICATE`,
  CREATE_CERTIFICATE_SUCCESS: `${base} CREATE_CERTIFICATE_SUCCESS`,
  CREATE_CERTIFICATE_FAIL: `${base} CREATE_CERTIFICATE_FAIL`,
  OPEN_DOMAIN_FORM: `${base} OPEN_DOMAIN_FORM`,
  OPEN_DOMAIN_DIALOG: `${base} OPEN_DOMAIN_DIALOG`,
  RESET_FORM: `${base} RESET_FORM`,
  APROVE_LIMITATION: `${base} APROVE_LIMITATION`,
  APROVE_LIMITATION_SUCCESS: `${base} APROVE_LIMITATION_SUCCESS`,
  APROVE_LIMITATION_FAIL: `${base} APROVE_LIMITATION_FAIL`,
  TOGGLE_CREATE_LIMITATION_TITLE: `${base} TOGGLE_CREATE_LIMITATION_TITLE`,
  TOGGLE_LIST_LIMITATION_TITLE: `${base} TOGGLE_LIST_LIMITATION_TITLE`,
};

export default RecordCertificateFormTypes;
