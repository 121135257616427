import { put, spawn, takeLatest } from 'redux-saga/effects';
import { appActions } from 'commons/reducer/commons.reducer';
import apiRequest from 'utils/api/api';
import { ORDER_LOTS } from '../reducer/sortLots.reducer';

const arrayToBody = (body, array, key) => {
  array.forEach((item) => body.append([key], JSON.stringify(item)));
};

function* sortLots() {
  yield takeLatest(ORDER_LOTS.SORT_LOTS, function* (action) {
    yield put(appActions.setLoader('order-lots', true));

    const body = new FormData();

    const lots = action.lots.map((lot) => ({
      id: lot.id,
      index: lot.index,
    }));

    arrayToBody(body, lots, 'lots[]');

    const response = yield apiRequest(
      'api/v1/order_lot/sort',
      {
        method: 'PUT',
        body,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );

    yield put(appActions.setLoader('order-lots', false));
    if (!response.error) {
      yield put(appActions.setSuccess('Orden de lotes actualizados'));
    } else {
      yield put(appActions.setError('Error al actualizar orden.'));
    }
  });
}

export default function* sortLotsSaga() {
  yield spawn(sortLots);
}
