import { createAction, createReducer } from 'utils/reducer/utils.reducer';

// Local constants
const PATH = 'auctionCategories/';
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const TOGGLE_DELETE_AUCTION_CATEGORY = `${PATH}TOGGLE_DELETE_AUCTION_CATEGORY`;
const TOGGLE_EDIT_AUCTION_CATEGORY = `${PATH}TOGGLE_EDIT_AUCTION_CATEGORY`;
const TOGGLE_CREATE_AUCTION_CATEGORY = `${PATH}TOGGLE_CREATE_AUCTION_CATEGORY`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const APPLY_FILTER = `${PATH}APPLY_FILTER`;
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const CLEAN_DELETE_DATA = `${PATH}CLEAN_DELETE_DATA`;
// Global constants for saga
export const AUCTION_CATEGORIES = {
  // get state from api
  INITIAL_QUERY: `${PATH}INITIAL_QUERY`,
  INITIAL_QUERY_SUCCESS: `${PATH}INITIAL_QUERY_SUCCESS`,
  INITIAL_QUERY_FAIL: `${PATH}INITIAL_QUERY_FAIL`,
  // create auction category
  CREATE_AUCTION_CATEGORY: `${PATH}CREATE_AUCTION_CATEGORY`,
  CREATE_AUCTION_CATEGORY_SUCCESS: `${PATH}CREATE_AUCTION_CATEGORY_SUCCESS`,
  CREATE_AUCTION_CATEGORY_FAIL: `${PATH}CREATE_AUCTION_CATEGORY_FAIL`,
  // delete auction category
  DELETE_AUCTION_CATEGORY: `${PATH}DELETE_AUCTION_CATEGORY`,
  DELETE_AUCTION_CATEGORY_SUCCESS: `${PATH}DELETE_AUCTION_CATEGORY_SUCCESS`,
  DELETE_AUCTION_CATEGORY_FAIL: `${PATH}DELETE_AUCTION_CATEGORY_SUCCESS_FAIL`,
  // edit auction category
  EDIT_AUCTION_CATEGORY: `${PATH}EDIT_AUCTION_CATEGORY`,
  EDIT_AUCTION_CATEGORY_SUCCESS: `${PATH}EDIT_AUCTION_CATEGORY_SUCCESS`,
  EDIT_AUCTION_CATEGORY_FAIL: `${PATH}EDIT_AUCTION_CATEGORY_FAIL`,

  // Verify duplicated category
  VERIFY_DUPLICATED_CATEGORY_FAIL: `${PATH}VERIFY_DUPLICATED_CATEGORY_FAIL`,
};

// actions
export const auctionCategoriesActions = {
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls', 'invalidControls'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  toggleEditAuctionCategory: createAction(TOGGLE_EDIT_AUCTION_CATEGORY, 'controls'),
  toggleCreateAuctionCategory: createAction(TOGGLE_CREATE_AUCTION_CATEGORY),
  toggleDelete: createAction(TOGGLE_DELETE),
  applyFilter: createAction(APPLY_FILTER, 'name', 'areaId'),
  cleanDeleteData: createAction(CLEAN_DELETE_DATA),
  // api
  initialQuery: createAction(AUCTION_CATEGORIES.INITIAL_QUERY),
  createAuctionCategory: createAction(AUCTION_CATEGORIES.CREATE_AUCTION_CATEGORY, 'controls'),
  deleteAuctionCategory: createAction(AUCTION_CATEGORIES.DELETE_AUCTION_CATEGORY, 'controls'),
  editAuctionCategory: createAction(AUCTION_CATEGORIES.EDIT_AUCTION_CATEGORY, 'controls'),
  // success
  initialQuerySuccess: createAction(AUCTION_CATEGORIES.INITIAL_QUERY_SUCCESS, 'payload'),
  createAuctionCategorySuccess: createAction(
    AUCTION_CATEGORIES.CREATE_AUCTION_CATEGORY_SUCCESS,
    'payload',
  ),
  deleteAuctionCategorySuccess: createAction(
    AUCTION_CATEGORIES.DELETE_AUCTION_CATEGORY_SUCCESS,
    'payload',
  ),
  editAuctionCategorySuccess: createAction(
    AUCTION_CATEGORIES.EDIT_AUCTION_CATEGORY_SUCCESS,
    'payload',
  ),
  // fail
  initialQueryFail: createAction(AUCTION_CATEGORIES.INITIAL_QUERY_FAIL, 'payload'),
  createAuctionCategoryFail: createAction(
    AUCTION_CATEGORIES.CREATE_AUCTION_CATEGORY_FAIL,
    'payload',
  ),
  deleteAuctionCategoryFail: createAction(
    AUCTION_CATEGORIES.DELETE_AUCTION_CATEGORY_FAIL,
    'payload',
  ),
  editAuctionCategoryFail: createAction(AUCTION_CATEGORIES.EDIT_AUCTION_CATEGORY_FAIL, 'payload'),
};

const defaultInvalidControls = {
  name: false,
};

const initialState = {
  controls: {
    anchorEl: null,
    name: '',
    areaId: '',
    enabled: true,
    formIsOpen: false,
    edit: false,
    filtered: false,
    toEditId: '',
    filterName: '',
    filterAreaId: '0',
    auctionCategory: {},
    lotCategoryCount: 0,
  },
  invalidControls: { ...defaultInvalidControls },
  toDeleteId: '',
  deleteIsOpen: false,
  error: false,
};

const auctionCategories = createReducer(initialState, {
  [DISABLE_ERRORS](state) {
    state.error = false;
  },
  [AUCTION_CATEGORIES.VERIFY_DUPLICATED_CATEGORY_FAIL](state) {
    state.error = true;
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.controls.formIsOpen = false;
    state.controls.edit = false;
    state.controls.toEditId = '';
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.areaId = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [APPLY_FILTER](state, action) {
    state.controls.filtered = true;
    state.controls.edit = false;
    state.filterAreaId = action.areaId;
    state.filterName = action.name.trim();
    state.controls.areaId = '';
    state.invalidControls = { ...defaultInvalidControls };
  },
  [TOGGLE_DELETE](state) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.controls.anchorEl = null;
  },
  [CLEAN_DELETE_DATA](state) {
    state.controls.lotCategoryCount = 0;
  },
  [TOGGLE_DELETE_AUCTION_CATEGORY](state, action) {
    state.toDeleteId = action.controls.auctionCategory.id;
  },
  [TOGGLE_EDIT_AUCTION_CATEGORY](state, action) {
    state.controls.formIsOpen = true;
    state.toDeleteId = '';
    state.controls.toEditId = action.controls.auctionCategory.id;
    state.controls.name = action.controls.auctionCategory.name;
    state.controls.enabled = action.controls.auctionCategory.enabled;
    state.controls.areaId = action.controls.auctionCategory.areaId;
    state.controls.edit = true;
    state.controls.anchorEl = null;
  },
  [TOGGLE_CREATE_AUCTION_CATEGORY](state) {
    state.controls.areaId = '1';
    state.controls.formIsOpen = true;
    state.controls.edit = false;
  },
  // api
  [AUCTION_CATEGORIES.CREATE_AUCTION_CATEGORY_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.areaId = '';
    state.controls.auctionCategory = {};
    state.invalidControls = { ...defaultInvalidControls };
  },
  [AUCTION_CATEGORIES.DELETE_AUCTION_CATEGORY_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
    state.controls.auctionCategory = {};
    state.controls.lotCategoryCount = action.payload.lotCategoryCount;
  },
  [AUCTION_CATEGORIES.EDIT_AUCTION_CATEGORY_SUCCESS](state) {
    state.controls.formIsOpen = false;
    state.controls.name = '';
    state.controls.enabled = true;
    state.controls.areaId = '';
    state.toDeleteId = '';
    state.controls.toEditId = '';
    state.controls.auctionCategory = {};
    state.controls.edit = false;
  },
});

export default auctionCategories;
