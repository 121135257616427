/* eslint-disable no-plusplus */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import logo from 'utils/images/logo-tattersall.png';
import { Button, Typography, TextField, Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useApi from 'utils/hooks/useApi';
import { history } from 'utils/history';
import StyledBox from 'utils/styledComponent/styledBox';
import { useLocation } from 'react-router-dom';
import { unformatRut, formatRut } from '../../../../utils/format';
import { FIELD_TYPE, validateEmail, isTaxNumber } from '../../../../utils/validators';
import VIEW from '../const/view';

function taxNumberValidator(data) {
  let rut = data;
  // Formato del RUT: 12345678-9
  rut = rut.replace(/[^0-9kK]/g, ''); // Eliminar caracteres no numéricos ni la 'k' en mayúscula
  if (rut.length < 2) return false;

  let suma = 0;
  const caracteres = '1234567890kK';
  const serie = [3, 2, 7, 6, 5, 4, 3, 2];

  for (let i = 0; i < rut.length - 1; i++) {
    const char = rut.charAt(i);
    if (caracteres.indexOf(char) === -1) return false;

    suma += parseInt(char) * serie[i];
  }

  const resto = suma % 11;
  let dv = 11 - resto;
  if (dv === 11) dv = 0;
  if (dv === 10) dv = 'k';
  if (rut.charAt(rut.length - 1).toLowerCase() !== dv.toString().toLowerCase()) {
    return false;
  }
  return true;
}

function FindUserForm() {
  const [emailTaxnumber, setEmailTaxNumber] = useState('');
  const [error, setError] = useState('');
  const { fetchData } = useApi();
  const location = useLocation();
  const currentParams = location.search;

  const handleChange = (event) => {
    let { value } = event.target;
    const type = isTaxNumber(value);
    if (type === FIELD_TYPE.RUT) {
      value = unformatRut(value);
      setEmailTaxNumber(value);
    } else {
      setEmailTaxNumber(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    let response = null;
    let emailTaxnumberValue = emailTaxnumber;
    const type = isTaxNumber(emailTaxnumberValue);
    if (type === FIELD_TYPE.RUT) {
      const isRutValid = taxNumberValidator(emailTaxnumberValue);
      if (isRutValid) {
        response = await fetchData({
          url: `api/v1/users/search?rut=${emailTaxnumber}&email`,
        });
        if (response && response.found) {
          history.push(`${response.active ? VIEW.LOGIN : VIEW.ACTIVE}${currentParams || ''}`, {
            value: emailTaxnumber,
            type: 'taxNumber',
            previusPath: `${VIEW.FINDUSER}`,
          });
        } else {
          history.push(`${VIEW.REGISTER}${currentParams || ''}`, {
            value: emailTaxnumber,
            type: 'taxNumber',
            previusPath: `${VIEW.FINDUSER}`,
          });
        }
      } else {
        setError('RUT no válido');
      }
    } else {
      const isEmailValid = validateEmail(emailTaxnumberValue);
      emailTaxnumberValue = emailTaxnumber.toLowerCase();
      if (isEmailValid) {
        response = await fetchData({
          url: `api/v1/users/search?rut&email=${emailTaxnumberValue}`,
        });
        if (response && response.found) {
          history.push(`${response.active ? VIEW.LOGIN : VIEW.ACTIVE}${currentParams || ''}`, {
            value: emailTaxnumberValue,
            type: 'email',
            previusPath: `${VIEW.FINDUSER}`,
          });
        } else {
          history.push(`${VIEW.REGISTER}${currentParams || ''}`, {
            value: emailTaxnumberValue,
            type: 'email',
            previusPath: `${VIEW.FINDUSER}`,
          });
        }
      } else {
        setError('Email no válido');
      }
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        onSubmit(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        background:
          'linear-gradient(0deg, rgba(7,42,104,1) 0%, rgba(0,23,64,1) 100%, rgba(32,75,154,1) 100%)',
      }}>
      <div
        style={{
          flex: 1,

          backgroundSize: 'contained',
          backgroundPositionX: '50%',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <StyledBox sx={{ maxWidth: 400 }}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 4,
            }}>
            <IconButton
              size="large"
              sx={{ position: 'absolute', left: 32 }}
              onClick={() => history.push('/')}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <img
              src={logo}
              style={{
                width: '70%', // ajusta el ancho de la imagen al 80% del contenedor
                height: 'auto', // altura automática para mantener la proporción original
              }}
              alt="Texto alternativo"
            />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', paddingBottom: 2 }}>
            <Typography sx={{ fontSize: 18 }}>¿Nos conocemos?</Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 4,
              gap: 2,
            }}>
            <TextField
              fullWidth
              helperText={error}
              error={error}
              variant="standard"
              id="txt-tattersall"
              label="Correo Electrónico o RUT"
              name="emailTaxNumber"
              value={
                isTaxNumber(emailTaxnumber) === FIELD_TYPE.RUT
                  ? formatRut(emailTaxnumber)
                  : emailTaxnumber
              }
              onChange={handleChange}
              InputLabelProps={{
                style: { fontSize: 18 }, // Cambia el tamaño del texto del label
              }}
              InputProps={{
                style: { fontSize: 18 }, // Cambia el tamaño del texto del label
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 4,
            }}>
            <Button fullWidth size="large" sx={{ backgroundColor: '#093170' }} onClick={onSubmit}>
              <Typography style={{ color: '#fff', fontSize: 20 }}>SIGUIENTE</Typography>
            </Button>
          </Box>
        </StyledBox>
      </div>
    </div>
  );
}
export default FindUserForm;
