import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, { apiSuccess } from 'utils/api/api';

import { falsy } from 'utils/functions';
import { history } from 'utils/history';
import { appActions } from 'commons/reducer/commons.reducer';
import { MANDATES_LOGIN } from '../reducer/mandatesLogin.reducer';

function* login() {
  yield takeLatest(MANDATES_LOGIN.LOGIN_USER, function* (action) {
    yield put(appActions.setLoader('login', true));
    const body = JSON.stringify(action.controls);
    const response = yield apiRequest('login', { method: 'post', body });

    if (falsy(response.error)) {
      if (
        !(
          response?.user?.roleCode === 'SUA' ||
          response?.user?.roleCode === 'ADM' ||
          response?.user?.roleCode === 'MDT'
        )
      ) {
        yield put(
          apiSuccess(MANDATES_LOGIN.LOGIN_USER_FAIL, {
            error: true,
            errorMsg: 'Usuario no tiene rol Mandante',
          }),
        );
      } else {
        yield put(apiSuccess(MANDATES_LOGIN.LOGIN_USER_SUCCESS, response));
        localStorage.setItem('user', JSON.stringify({ token: response.token }));
        history.replace(action.redirectUrl || '/mandates');
      }
    } else {
      yield put(apiSuccess(MANDATES_LOGIN.LOGIN_USER_FAIL, response));
    }
    yield put(appActions.setLoader('login', false));
  });
}
function* verify() {
  yield takeLatest(MANDATES_LOGIN.VERIFY, function* (action) {
    yield put(appActions.setLoader('login', true));
    const body = JSON.stringify({ token: action.token });
    const response = yield apiRequest('api/v1/users/verify', { method: 'post', body });
    if (falsy(response.error)) {
      yield put(apiSuccess(MANDATES_LOGIN.VERIFY_SUCCESS, response));
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
    } else {
      yield put(apiSuccess(MANDATES_LOGIN.VERIFY_FAIL, response));
    }
    yield put(appActions.setLoader('login', false));
  });
}
export default function* mandatesLoginSaga() {
  yield spawn(login);
  yield spawn(verify);
}
