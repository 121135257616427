import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import styles from './styles';
import tattersallLogo from '../../../utils/images/logo-tattersall.png';
import ReceiptCreated from './components/ReceiptCreated';
import PaymentSuccess from './components/PaymentSuccess';

function PaymentConfirmationModal(props) {
  const { open, onClose, data, objects, externalLogin } = props;
  const useStyles = makeStyles()(styles);
  const { classes } = useStyles();
  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="simple-dialog-title" open={!!open}>
      <DialogContent>
        <Grid container spacing={2}>
          {data !== undefined && data.isReceipt ? (
            <ReceiptCreated classes={classes} data={data} />
          ) : (
            <PaymentSuccess
              classes={classes}
              data={data}
              objects={objects}
              onClose={onClose}
              externalLogin={externalLogin}
            />
          )}
          <div className={classes.imageContainer}>
            <img className={classes.imageLogo} src={tattersallLogo} alt="tattersall-logo" />
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentConfirmationModal;
