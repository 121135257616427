import { createReducer } from 'utils/reducer/utils.reducer';
import { AdjudicationsTypes } from '../types/adjudications.types';

const initialState = {
  filters: {
    adjudicationDate: null,
    lotID: null,
    userName: '',
  },
  pagination: {
    page: 1,
    offset: 0,
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 30, 45, 99],
  },
  controls: {
    loading: false,
    ids: [],
    adjudicationIds: [],
    userIDs: [],
    total: 0,
    displayAdjudications: false,
    userId: '',
    displayAdjudicationsDetail: false,
    adjudicationId: '',
  },
};

const adjudicationsReducer = createReducer(initialState, {
  [AdjudicationsTypes.GET_STATE_FROM_API](state) {
    state.controls.loading = true;
  },
  [AdjudicationsTypes.CHANGE_FILTERS](state, action) {
    state.filters[action.payload.name] = action.payload.value;
  },
  [AdjudicationsTypes.CHANGE_PAGINATION](state, action) {
    state.pagination = {
      ...state.pagination,
      ...action.payload.pagination,
    };
  },
  [AdjudicationsTypes.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.controls.loading = false;
    state.controls.ids = action.payload.ids;
    state.controls.adjudicationIds = action.payload.adjudicationIDs;
    state.controls.userIds = action.payload.userIDs;
    state.controls.total = action.payload.total;
  },
  [AdjudicationsTypes.GET_STATE_FROM_API_FAIL](state) {
    state.controls.loading = false;
  },
  [AdjudicationsTypes.TOGGLE_ADJUDICATIONS_DIALOG](state, action) {
    state.controls.displayAdjudications = !state.controls.displayAdjudications;
    state.controls.userId = action.payload.id;
  },
  [AdjudicationsTypes.TOGGLE_ADJUDICATIONS_DETAILS_DIALOG](state, action) {
    state.controls.displayAdjudicationsDetail = !state.controls.displayAdjudicationsDetail;
    state.controls.adjudicationId = action.payload.id;
  },
});

export default adjudicationsReducer;
