import { combineReducers, compose, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import { createContext } from 'react';
import localForage from 'localforage';

/* Reducers */
import app from 'commons/reducer/commons.reducer';
import webpayFinal from 'modules/main/WebpayFinal/reducer/webpayFinal.reducer';
import lot from 'modules/main/Lots/reducer/lots.reducer';
import user from 'modules/main/Users/reducer/users.reducer';
import login from 'modules/main/Login/reducer/login.reducer';
import register from 'modules/main/Register/reducer/register.reducer';
import lotCategory from 'modules/main/LotCategories/reducer/lotCategories.reducer';
import lotsManager from 'modules/main/LotsManager/reducer/lotsManager.reducer';
import resetPassword from 'modules/main/ResetPassword/reducer/resetPassword.reducer';
import contactMessage from 'modules/main/ContactMessages/reducer/contactMessages.reducer';
import auctions from 'modules/main/Auctions/reducer/auctions.reducer';
import auctionsManager from 'modules/main/AuctionsManager/reducer/auctionManager.reducer';
import auctionCategory from 'modules/main/AuctionCategories/reducer/auctionCategories.reducer';
import guaranteeManager from 'modules/main/GuaranteeManager/reducer/guaranteeManager.reducer';
import payments from 'modules/main/Payments/reducer/payments.reducer';
import guarantees from 'modules/main/Guarantees/reducer/guarantees.reducer';
import guaranteesUnification from 'modules/main/GuaranteesAndPayments/reducer/newGuarantee.reducer';
import paymentsUnification from 'modules/main/GuaranteesAndPayments/reducer/newPayment.reducer';
import lotDetails from 'modules/main/LotDetails/reducer/lotDetails.reducer';
import orderLots from 'modules/main/EntitledLots/reducer/entitledLots.reducer';
import newPayment from 'modules/main/NewPayment/reducer/newPayment.reducer';
import newPaymentStepFour from 'modules/main/GuaranteeManagerv2/components/StepFour/reducer/newPayment.reducer';
import userProfile from 'modules/main/UserProfile/reducer/userProfile.reducer';
import editUser from 'modules/main/EditUser/reducer/editUser.reducer';
import sellersManager from 'modules/main/SellersManager/reducer/sellersManager.reducer';
import viewReportReducer from 'modules/main/ViewReport/reducer/viewReport.reducer';
import featuredLotsReducer from 'modules/main/FeaturedLotsManager/reducer/FeaturedLotsManager.reducer';
import directSaleContactsManagerReducer from 'modules/direct-sale/DirectSaleContactsManager/reducer/DirectSaleContactsManager.reducer';
import brandsManagerReducer from 'modules/main/BrandsManager/reducer/BrandsManager.reducer';
import directSaleModelsManagerReducer from 'modules/direct-sale/DirectSaleModelsManager/reducer/DirectSaleModelsManager.reducer';
import templateImportModal from 'commons/components/TemplateImportModal/reducer/templateImportModal.reducer';
import directSaleFeaturesReducer from 'modules/direct-sale/DirectSaleLots/reducer/DirectSaleFeatures.reducer';
import currencyManager from 'modules/main/CurrencyManager/reducer/CurrencyManager.reducer';
import adjudicationsReducer from 'modules/main/Adjudications/reducer/adjudications.reducer';
import systemChangesReducer from 'modules/main/SystemChanges/reducer/systemChanges.reducer';
import FinishedAuctionsReducer from 'modules/main/FinishedAuctions/reducer/FinishedAuctions.reducer';
import posturesManagerReducer from 'modules/main/PosturesManager/reducer/posturesManager.reducer';
import transfersLogin from 'modules/transferences/TransferManagerLogin/reducer/transfersLogin.reducer';
import FormTwentyThreeReducer from 'modules/transferences/FormTwentyThree/reducer/FormTwentyThree.reducer';
import MandatePreviewReducer from 'modules/transferences/MandatePreview/reducer/MandatePreview.reducer';
import TransfersReducer from 'modules/transferences/Transfers/Transfers.reducer';
import WorkSheetFormReducer from 'modules/transferences/WorkSheetForm/reducer/WorkSheetForm.reducer';
import VehicleTypesManagerReducer from 'modules/main/VehicleTypesManager/reducer/VehicleTypesManager.reducer';
import PendingTransferencesReducer from 'modules/transferences/PendingTransferences/reducer/PendingTransferences.reducer';
import TransferLotDetailsReducer from 'modules/transferences/TransferManagerLotDetails/reducer/TransferLotDetails.reducer';
import RecordCertificateFormReducer from 'modules/transferences/RecordCertificateForm/reducer/RecordCertificateForm.reducer';
import lotsListReducer from '../modules/mandates-tracking/LotsList/reducer/lotsList.reducer';
import mainSaga from '../main.saga';
import enhancers, { sagaMiddleware } from './middleware';
import mandatesLoginReducer from '../modules/mandates-tracking/Login/reducer/mandatesLogin.reducer';
import bulkLoadReducer from '../modules/mandates-tracking/BulkLoad/reducer/bulkLoad.reducer';
//-----------------------------------------------------------

export const ReactReduxContext = createContext({});

// const sanitazeState = (state) => {
//   // console.log({ state });
//
//   return {
//     ...state,
//     app: { ...state.app, objects: '<<LONG_BLOB>>' },
//     lot: { ...state.lot, controls: { ...state.lot.controls, anchorEl: '<<REACT_INSTANCE>>' } },
//   };
// };
//
// const actionSanitizer = (action) => {
//   // console.log({ action });
//
//   return {
//     ...action,
//     payload: { ...action.payload, objects: '<<LONG_BLOB>>' },
//     controls: { ...action.controls, anchorEl: '<<REACT_INSTANCE>>' }
//   };
//
// };

// const composeEnhancers = (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production'
//   && process.env.REACT_APP_REDUX_DEV_TOOLS === 'true'
//   && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//     actionSanitizer,
//     stateSanitizer: (state) => {
//       return sanitazeState(state);
//     }
//   })) || compose;

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [],
  };

  // create reducer
  const reducers = combineReducers({
    app,
    contactMessage,
    resetPassword,
    lotsManager,
    register,
    login,
    user,
    lot,
    auctionCategory,
    lotCategory,
    auctions,
    auctionsManager,
    guaranteeManager,
    webpayFinal,
    payments,
    guarantees,
    guaranteesUnification,
    paymentsUnification,
    lotDetails,
    orderLots,
    newPayment,
    newPaymentStepFour,
    profile: userProfile,
    editUser,
    sellersManager,
    currencyManager,
    templateImportModal,
    transfersLogin,
    viewReport: viewReportReducer,
    featuredLotsManager: featuredLotsReducer,
    contactsManager: directSaleContactsManagerReducer,
    directSaleBrandsManager: brandsManagerReducer,
    directSaleModelsManager: directSaleModelsManagerReducer,
    directSaleFeatures: directSaleFeaturesReducer,
    adjudications: adjudicationsReducer,
    systemChanges: systemChangesReducer,
    finishedAuctions: FinishedAuctionsReducer,
    recordCertificateForm: RecordCertificateFormReducer,
    mandatePreview: MandatePreviewReducer,
    transfers: TransfersReducer,
    transferLotDetails: TransferLotDetailsReducer,
    workSheetForm: WorkSheetFormReducer,
    formTwentyThree: FormTwentyThreeReducer,
    vehicleTypesManager: VehicleTypesManagerReducer,
    pendingTransferences: PendingTransferencesReducer,
    posturesManager: posturesManagerReducer,
    lotsList: lotsListReducer,
    mandatesLogin: mandatesLoginReducer,
    bulkLoad: bulkLoadReducer,
  });

  const pReducer = persistReducer(persistConfig, reducers);

  const store = createStore(pReducer, initialState, composeEnhancers(enhancers));

  // Extensions
  // store.runSaga = sagaMiddleware.run;
  // store.injectedSagas = {}; // Saga registry
  sagaMiddleware.run(mainSaga);

  return store;
}
