import { put, spawn, takeLatest, select } from 'redux-saga/effects';
import controlledCall from 'utils/services/controlledSaga';
import { get, getDocument } from 'utils/api/api';
import { onlyKeysWithValue, asQuery, getItemInStorage } from 'utils/functions';
import { SET_ERROR, appActions } from 'commons/reducer/commons.reducer';
import { PendingTransferencesTypes } from '../types/PendingTransferences.types';

function* getStateFromApi() {
  yield takeLatest(PendingTransferencesTypes.GET_STATE_FROM_API, function* (action) {
    const body = onlyKeysWithValue({ ...action.payload.filter, ...action.payload.pagination });

    delete body.page;
    delete body.rowsPerPageOptions;

    const successFunction = (payload) => ({
      type: PendingTransferencesTypes.GET_STATE_FROM_API_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: PendingTransferencesTypes.GET_STATE_FROM_API_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/pending/transfers?${new URLSearchParams(body)}`,
      null,
      successFunction,
      failFunction,
    );

    if (result.error) {
      yield put({
        type: SET_ERROR,
        msg: result.errorMsg,
      });
    }
    yield put(appActions.setLoader('export-pending-transfer', false));
  });
}

function* getReport() {
  yield takeLatest(PendingTransferencesTypes.GET_REPORT, function* (action) {
    const socketId = yield select((state) => state.app.socketId);
    const body = onlyKeysWithValue({
      ...action.payload.filter,
      ...action.payload.pagination,
      socketId,
    });
    yield put(appActions.setLoader('export-pending-transfer', true));
    delete body.page;
    delete body.rowsPerPageOptions;
    if (!body.socketId) {
      yield put(appActions.setLoader('export-pending-transfer', false));
      yield put(appActions.setError('Error, inténtelo de nuevo'));
      yield put({ type: SET_ERROR, msg: 'No se ha encontrado el socketId' });
      return;
    }
    const successFunction = (payload) => ({
      type: PendingTransferencesTypes.GET_REPORT_SUCCESS,
      payload,
    });

    const failFunction = (payload) => ({
      type: PendingTransferencesTypes.GET_REPORT_FAIL,
      payload,
    });

    const result = yield controlledCall(
      get,
      `api/v1/transfers/pending/report`,
      asQuery(body),
      successFunction,
      failFunction,
    );
    if (!result.error) {
      yield put(appActions.setSuccess('Generando reporte'));
    } else {
      yield put(appActions.setLoader('export-pending-transfer', false));
      yield put(appActions.setError('Error al generar el reporte'));
      yield put({ type: SET_ERROR, msg: result.errorMsg });
    }
  });
}

export default function* PendingTransferencesSaga() {
  yield spawn(getStateFromApi);
  yield spawn(getReport);
}
